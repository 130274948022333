<div class="checkout-modal">
  <div class="close" (click)="close()">
    <i class="fa-solid fa-x c-icon-primary c-icon-primary-sm c-icon-round-hover mr-3 mt-4"></i>
  </div>
  <div class="modal-header relative">
    <h2 class="c-heading-36 font-bold u-border-bottom-grey pb-4 w-100">
      {{ 'addToCartItems.prodDetails' | cxTranslate }}
    </h2>
  </div>
  <!-- Modal body -->
  <div class="modal-body miniCart-popover">
    <div class="row product-details pb-3" id="leftCont-rightNav">
      <!-- Left -->
      <section class="col-sm-8 pb-2">
        <h1
          class="c-heading c-heading-h2 mb-3"
          [innerHTML]="productDetails?.product.name"
        ></h1>
        <p
          class="text-fixed-medium"
          [innerHTML]="productDetails?.product.description"
        ></p>
        <section class="text-fixed-medium">
          <p
            *ngIf="productDetails?.product.batchInfo?.batchNumber"
            class="mb-2"
          >
            <b>{{ 'plp.batchNumber' | cxTranslate }}: </b>
            <span>{{ productDetails?.product.batchInfo?.batchNumber }}</span>
          </p>
          <p
            *ngIf="productDetails?.product.batchInfo?.batchNumber"
            class="mb-2"
          >
            <b>{{ 'plp.expiryDate' | cxTranslate }}: </b>
            <span>{{ productDetails?.product.batchInfo?.expiryDate }}</span>
          </p>
          <p
            *ngIf="productDetails?.product.batchInfo?.batchNumber"
            class="mb-2"
          >
            {{ 'plp.batchInfo' | cxTranslate }}
          </p>
          <p class="mb-2">
            <b>{{ 'plp.productNumber' | cxTranslate }}: </b>
            <span class="desc-details">{{ productDetails?.product.code }}</span>
          </p>
          <p *ngIf="productDetails?.product.actualPotency" class="mb-2">
            <b>{{ 'plp.actualPotency' | cxTranslate }}: </b>
            <span class="desc-details">{{
              productDetails?.product.actualPotency
            }}</span>
          </p>
          <hr class="line" />
          <section
            class="sap-allocation-area"
            *ngIf="
              !productDetails?.product.discontinued &&
              productDetails?.product.available &&
              !productDetails?.product.flu
            "
          >
            <section *ngIf="productDetails?.product.sapAllocation === true">
              <p class="text-danger">
                <b>{{ 'plp.productAllocation' | cxTranslate }}</b>
              </p>
              <p>
                {{ 'plp.allocationDetails' | cxTranslate }}
              </p>
            </section>
          </section>
          <section class="third-col">
            <section class="row unit-calc">
              <!-- Price -->
              <section class="col-md-4 col-xs-6 bottom-spacing price-area">
                <h5 class="mb-2 font-weight-bold color-grey-dark">
                  {{ 'selectVaccines.yourPrice' | cxTranslate }}
                </h5>
                <p class="c-heading c-heading-h3">
                  <span
                    *ngIf="
                      productDetails?.basePrice == undefined ||
                      productDetails?.basePrice?.value === undefined
                    "
                    >-</span
                  >
                  <span
                    *ngIf="
                      productDetails?.basePrice &&
                      productDetails?.basePrice?.value !== undefined
                    "
                  >
                    <span>{{
                      productDetails?.basePrice?.value | currency
                    }}</span
                    >{{ productDetails?.basePrice?.currencyIso }}
                  </span>
                </p>
              </section>
              <!-- Quantity selector -->
              <section
                class="col-md-4 col-xs-6 bottom-spacing units-area"
                *ngIf="isCartPage"
              >
                <section *ngIf="!productDetails?.product?.discontinued">
                  <section *ngIf="productDetails?.product?.available">
                    <h5
                      class="mb-2 text-center font-weight-bold color-grey-dark"
                    >
                      {{ 'plp.units' | cxTranslate }}
                    </h5>

                    <section class="unit-area">
                      <span
                        class="disabled"
                        aria-hidden="true"
                        id="{{
                          'minusIcon' + productDetails?.product?.code + 'modal'
                        }}"
                        (click)="
                          performQuantityComputation(
                            2,
                            productDetails?.product?.code + 'modal',
                            productDetails?.product?.quantityPerUnit
                          )
                        "
                      >
                        <img
                          src="../../assets/icons/less.svg"
                          id="{{
                            'unitMinus' +
                              productDetails?.product?.code +
                              'modal'
                          }}"
                          [ngClass]="{
                            'disabled-img': productDetails?.quantity === 0
                          }"
                          alt=""
                        />
                        <img
                          src="../../assets/icons/less-disable.svg"
                          id="{{
                            'unitMinusDisable' +
                              productDetails?.product?.code +
                              'modal'
                          }}"
                          alt=""
                          [ngClass]="{
                            'disabled-img': productDetails?.quantity > 0
                          }"
                        />
                      </span>
                      <input
                        type="number"
                        class="prod-quantity text-center"
                        value="{{ productDetails?.quantity }}"
                        onkeypress="return /[0-9a-zA-Z]/i.test(event.key)"
                        (keyup)="
                          performQuantityComputation(
                            null,
                            productDetails?.product?.code + 'modal',
                            productDetails?.product?.quantityPerUnit
                          )
                        "
                        id="{{
                          'prodQuantity' +
                            productDetails?.product?.code +
                            'modal'
                        }}"
                        min="1"
                        max="9999"
                      />
                      <span
                        class=""
                        aria-hidden="true"
                        id="{{
                          'plusIcon' + productDetails?.product?.code + 'modal'
                        }}"
                        (click)="
                          performQuantityComputation(
                            1,
                            productDetails?.product?.code + 'modal',
                            productDetails?.product?.quantityPerUnit
                          )
                        "
                      >
                        <img
                          src="../../assets/icons/more.svg"
                          id="{{
                            'unitPlus' + productDetails?.product?.code + 'modal'
                          }}"
                          alt=""
                        />
                        <img
                          src="../../assets/icons/more-disable.svg"
                          id="{{
                            'unitPlusDisable' +
                              productDetails?.product?.code +
                              'modal'
                          }}"
                          class="disabled-img"
                          alt=""
                        />
                      </span>
                    </section>
                    <ng-container *ngIf="salesOrg$ | async as salesOrg">
                      <h5
                        *ngIf="salesOrg != 'CA04'"
                        class="mt-2 text-center font-weight-bold color-grey-dark"
                        id="{{
                          'noOfDoses' + productDetails?.product?.code + 'modal'
                        }}"
                      >
                        {{
                          productDetails?.product?.quantityPerUnit *
                            productDetails?.quantity
                        }}
                        {{ 'plp.doses' | cxTranslate }}
                      </h5>
                  </ng-container >
                  </section>
                </section>
              </section>
              <!-- Add to cart button -->
              <section
                class="col-md-4 col-sm-4 col-xs-12 mt-4"
                *ngIf="isCartPage"
              >
                <button
                  *ngIf="unitSection === 'Update'; else addToCart"
                  class="c-button c-button--primary"
                  data-di-id="di-id-2fea5a72-b2e23002"
                  [ngbPopover]="popTitleSuccess"
                  placement="bottom"
                  triggers="manual"
                  #p2="ngbPopover"
                  placement="bottom"
                  popoverClass="updatecart-msg"
                  (click)="
                    updateCart(productDetails?.product?.code + 'modal', p2)
                  "
                >
                  {{ 'addToCartItems.updateCart' | cxTranslate }}
                </button>

                <section class="added-success-container">
                  <ng-template #popTitleSuccess>
                    <div class="cart-value-added">
                      <img
                        src="../../../assets/icons/confirmation-positive-green.png"
                        class="positive-green-icon"
                        alt="success"
                      />
                      <span class="confirmation-itemsadded"
                        >{{ itemAddedQuantity }}
                        {{
                          'addToCartItems.addUpdateSuccess' | cxTranslate
                        }}</span
                      >
                    </div>
                  </ng-template>
                  <ng-template #popTitleAddSuccess>
                    <div class="cart-value-added">
                      <img
                        src="../../../assets/icons/confirmation-positive-green.png"
                        class="positive-green-icon"
                        alt="success"
                      />
                      <span class="confirmation-itemsadded"
                        >{{ itemAddedQuantity }}
                        {{
                          'addToCartItems.addEntrySuccess' | cxTranslate
                        }}</span
                      >
                    </div>
                  </ng-template>
                </section>

                <ng-template #addToCart>
                  <button
                    class="c-button c-button--primary"
                    data-di-id="di-id-2fea5a72-b2e23002"
                    [ngbPopover]="popTitleAddSuccess"
                    placement="bottom"
                    triggers="manual"
                    #p3="ngbPopover"
                    placement="bottom"
                    popoverClass="addtocart-msg"
                    (click)="
                      addToSessionCart(
                        productDetails?.product?.code + 'modal',
                        productDetails?.product?.code,
                        p3
                      )
                    "
                  >
                    {{ 'addToCartItems.addCart' | cxTranslate }}
                  </button>
                </ng-template>
              </section>
            </section>

            <section
              *ngIf="
                !productDetails?.product.discontinued &&
                productDetails?.product.available
              "
              class="products-accordion clear-fix pt2"
            >
              <section class="panel-group">
                <section
                  class="panel panel-default"
                  *ngIf="volumePrices?.length > 0"
                >
                  <section class="panel-heading panel-endborder">
                    <section class="first-level-acc">
                      <label
                        ><img
                          src="../../assets/icons/price-master.png"
                          class="accordion-img"
                          alt=""
                        />
                        {{ 'selectVaccines.volumePrice' | cxTranslate }}</label
                      >
                      <span
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#pdp-list1"
                        class="acc-plus-icon fa"
                        aria-expanded="panelVolumeExpanded"
                        (click)="panelVolumeExpanded = !panelVolumeExpanded"
                        [ngClass]="
                          panelVolumeExpanded ? 'fa-minus' : 'fa-plus collapsed'
                        "
                      ></span>
                    </section>
                  </section>
                  <section
                    id="pdp-list1"
                    class="panel-collapse collapse"
                    aria-expanded="panelVolumeExpanded"
                    (click)="panelVolumeExpanded = !panelVolumeExpanded"
                    [ngClass]="panelVolumeExpanded ? 'in' : ''"
                  >
                    <section class="panel-body">
                      <section class="discount-list" id="vol-price-pdp">
                        <table aria-describedby="pdpTable">
                          <thead>
                            <th id=""></th>
                          </thead>
                          <tbody
                            *ngFor="
                              let prices of productDetails?.volumePrices;
                              let i = index
                            "
                          >
                            <tr>
                              <td>
                                {{ prices?.minQuantity }} -
                                {{ prices?.maxQuantity }}
                                {{ 'plp.units' | cxTranslate }}:
                              </td>
                              <td>{{ prices?.value | currency }}</td>
                              <td>
                                <span *ngIf="i > 0" class="save-price"
                                  >{{ 'plp.save' | cxTranslate }}
                                  {{
                                    productDetails?.volumePrices[0].value -
                                      productDetails?.volumePrices[i].value
                                      | currency
                                  }}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </section>
            <!-- product updates-->
            <section>
              <section class="products-accordion clear-fix pt2">
                <section class="panel-group">
                  <!-- update2 -->
                  <section
                    class="panel panel-default"
                    *ngIf="productDetails?.product?.productUpdates"
                  >
                    <section class="panel-heading mt-4">
                      <section class="first-level-acc">
                        <label class="mr-2"
                          ><img
                            src="../../assets/icons/info.png "
                            class="accordion-img mr-2"
                            alt=" "
                          />{{ 'plp.productUpdate' | cxTranslate }}
                        </label>
                        <span
                          data-toggle="collapse "
                          data-parent="#accordion "
                          href="#pdp-list3 "
                          class="acc-plus-icon fa"
                          aria-expanded="panelProductExpanded "
                          (click)="panelProductExpanded = !panelProductExpanded"
                          [ngClass]="
                            panelProductExpanded
                              ? 'fa-minus'
                              : 'fa-plus collapsed'
                          "
                        ></span>
                      </section>
                    </section>
                    <section
                      id="pdp-list3 "
                      class="panel-collapse collapse"
                      aria-expanded="panelProductExpanded "
                      (click)="panelProductExpanded = !panelProductExpanded"
                      [ngClass]="panelProductExpanded ? 'in' : ''"
                    >
                      <section class="panel-body productupdate-p">
                        {{ productDetails?.product?.productUpdates }}
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </section>
            <!--product updates end-->
          </section>
        </section>
      </section>
      <!-- Right -->
      <aside aria-label="Product Details" id="prodImg" class="col-sm-4">
        <a
          *ngIf="
            productDetails?.product?.images != undefined ||
            productDetails?.product?.images?.length > 0
          "
        >
          <img class="image-product" src="{{ images?.PRIMARY?.url }}" alt="" />
        </a>
        <a
          *ngIf="
            productDetails?.product?.images === undefined ||
            productDetails?.product?.images?.length === 0
          "
        >
          <img
            class="image-set-in-card product-img"
            [src]="fallbackImage"
            alt=""
          />
        </a>
        <!-- Resources -->
        <section
          *ngIf="monographLink"
          class="prod-detail-pdfs u-border-top-grey pt-4 mt-4"
        >
          <h3 class="font-bold mb-4">
            {{ 'plp.resources' | cxTranslate }}
          </h3>
          <p class="pdfs-list">
            <img
              class="pdf-icon mr-2"
              src=" ../../assets/icons/pdf.png "
              alt=" "
            />
            <a
              alt=" "
              [href]="monographLink"
              target=" _blank "
              class="c-link"
              >{{ 'plp.monograph' | cxTranslate }}</a
            >
          </p>
        </section>
      </aside>
    </div>
  </div>
</div>
