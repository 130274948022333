<section class="row registrationView" id="productList-area">
  <section class="col-md-8 col-sm-8 pay-section float-left account-reg-section">
    <app-account-header
      [selectedHeader]="header"
      [tableCategory]="creditInvoice"
    ></app-account-header>

    <app-billing-information
      *ngIf="header === 'billingDetails'"
      [formDetails]="formData"
      [multipleFormData]="twoFormsData"
    ></app-billing-information>

    <!-- Shipping page form starts  -->

    <app-shipping-information
      *ngIf="header === 'shippingDetails'"
      [formDetails]="formData"
      [multiFormData]="twoFormsData"
    ></app-shipping-information>
    <!-- shipping form ends -->

    <app-contacts-information
      *ngIf="header === 'contacts'"
      [formDetails]="formData"
    ></app-contacts-information>
  </section>
</section>
