import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-display-img',
  templateUrl: './display-img.component.html',
})
export class DisplayImgComponent implements OnInit {
  @Input() image: any;

  constructor(
    private readonly cd: ChangeDetectorRef,
    public activeModal?: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.cd.detectChanges();
  }
}
