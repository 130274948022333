<section class="footer-Container-Section">
  <section class="">
    <!--middlesec-->
    <section class="active-season-Section">
      <section *ngIf="displayActiveSeasons">
        <div class="active-season__title">
          <span>{{ 'plp.exploreInfluenza' | cxTranslate }}</span>
        </div>
        <section
          class="d-flex active-season__reserve-container flex-column flex-sm-row gap-1 justify-content-between"
        >
          <section class="col-md-8 p-0">
            <section class="season-header">
              {{ activeSeasons }} {{ 'selectVaccines.season' | cxTranslate }}
            </section>
          </section>
          <section class="d-flex justify-content-sm-end col-12 p-0 col-sm-3">
            <button class="c-button c-button--primary" (click)="onReserve()">
              {{ 'selectVaccines.reserve_now' | cxTranslate }}
            </button>
          </section>
        </section>
        <!--products-->
        <section
          class="d-flex active-season__product"
          *ngFor="let user of productList; let i = index"
          id="{{ user.code }}"
        >
          <section class="col-8 col-md-2 m-auto m-md-0">
            <a (click)="productDetails(user)">
              <img
                *ngIf="user.thumbNailImg !== ''"
                src="{{ baseUrl }}{{ user.thumbNailImg }}"
                class="vaccine-Image"
                alt="prodImg"
              />
              <img
                *ngIf="user.thumbNailImg == ''"
                [src]="fallbackImage"
                class="vaccine-Image"
                alt="defImg"
              />
            </a>
          </section>
          <section class="col-lg-6 col-xl-7 prod-Desc-Div p-0">
            <section class="row">
              <div class="col-md-12 mb-2">
                <a
                  (click)="productDetails(user)"
                  title="{{ user.name }}"
                  class="vaccine-Name"
                  [innerHTML]="user.name"
                ></a>
              </div>
              <div
                class="col-md-12 col-lg-12 col-xl-12 vaccine-Desc-Section"
                [innerHTML]="user.description"
              ></div>
              <div class="col-md-12 col-lg-12 col-xl-12 prod-No">
                <span class="prodct-No"
                  >{{ 'selectVaccines.product_number' | cxTranslate }}:</span
                >
                <span>{{ user.code }}</span>
              </div>
            </section>
          </section>
          <!-- ============================order acceptance=========================================== -->

          <div class="col-5 col-sm-5 col-md-2 col-xs-6 order-Acceptance">
            <span
              *ngIf="!user.available && user.discontinued === false"
              class=""
            >
              {{
                user?.availabilityText
                  ? user?.availabilityText
                  : ('selectVaccines.product_unavailable' | cxTranslate)
              }}
            </span>
            <span class="" *ngIf="user.discontinued === true">
              {{ 'selectVaccines.product_discontinued' | cxTranslate }}</span
            >
            <span
              class="available-now"
              *ngIf="
                user.onsn === 'ON' &&
                user.discontinued === false &&
                user.available === true
              "
            >
              {{ 'selectVaccines.available_now' | cxTranslate }}
            </span>
          </div>
        </section>
      </section>
    </section>
    <!-- <section class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 ad-Banner-Section">
      <app-flu-dashboard-banner [bannerComponent]="compName"></app-flu-dashboard-banner>
    </section> -->
  </section>
</section>
