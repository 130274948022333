import { Injectable } from '@angular/core';
import {BehaviorSubject, of} from 'rxjs';
import * as config from '../shared/config/app.config';
import { BaseService } from '../shared/services/base.service';
import {switchMap, tap} from 'rxjs/operators';
import {AuthService} from '@spartacus/core';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {

  public catalogs = new BehaviorSubject<any>(null);
  catalogs$ = this.catalogs.asObservable();
  public catalogTypes = new BehaviorSubject<any>(null);
  catalogTypes$ = this.catalogTypes.asObservable();

  constructor(public baseService: BaseService,
              private readonly authService: AuthService) { }

  public getCatalogs() {
    const filter = {
      fields: 'DEFAULT',
    };

    this.authService.isUserLoggedIn().pipe(
      switchMap((isLoggedIn: boolean) => {
        return isLoggedIn ? of('/categories/m1') : of('vscaProductCatalog/Online/categories/m1');
      }),
      tap((query: string) => {
        this.baseService.get(config.CATALOGS + query, filter).subscribe((res) => {
          this.catalogTypes.next(res);
        });
      })
    ).subscribe();
  }

  public getCatalogTypes(type?){
    const filter = {
      fields: 'DEFAULT',
    };
    this.baseService
    .get(`${config.CATALOGSTYPE}${type}`,filter)
    .subscribe((res) => {
      this.catalogs.next(res);
    });
  }

  public getFAQ(){
    this.baseService.get(config.FAQ).subscribe((res) => {
    });
  }
}
