<div class="left-menu pl-2 pl-md-3 pl-lg-0">
  <div class="menu-content">
    <h3
      class="c-text c-heading-h3 font-weight-bold u-border-bottom-grey pb-4 font-bold mb-4"
    >
      {{ 'patientEducation.browseByTopic' | cxTranslate }}
    </h3>
    <p *ngFor="let catalog of catalogTypes" class="list">
      <a class="title" (click)="getCatalogType(catalog.id)">{{
        catalog.name
      }}</a>
    </p>
  </div>
</div>
<div class="dropdown-list">
  <ng-select
    name="browseByTopic"
    [items]="catalogTypes"
    [searchable]="false"
    [clearable]="false"
    bindLabel="name"
    bindValue="id"
    placeholder="Browse by topic"
    class="dropdown-list"
    (ngModelChange)="getCatalogType($event)"
    [(ngModel)]="selectedtype"
  >
  </ng-select>
</div>
