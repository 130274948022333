import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { AuthService, CmsService, RoutingService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import * as $ from 'jquery';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { EmailPopupComponent } from './shared/modules/email-popup/email-popup/email-popup.component';
import { ExternalsiteAlertComponent } from './shared/modules/externalsite-alert/externalsite-alert/externalsite-alert.component';
import { SharedService } from './shared/services/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'spartacusstore';
  eventReference: any;
  isUserLoggedIn = false;
  pageName: string;
  pageId: string;
  modalOption: NgbModalOptions = {};
  emailConsent: any;
  userId: any;
  isAsmEnabled: any;
  isLoggedIn: Observable<boolean> = this.authService.isUserLoggedIn();
  emailConsentEnabled: boolean;
  isSSOEnabled: boolean = false;
  userId$ = new BehaviorSubject<string>('');
  constructor(
    private readonly renderer: Renderer2,
    private readonly pageDate: CmsService,
    private readonly authService: AuthService,
    private readonly sharedService: SharedService,
    private readonly router: RoutingService,
    private readonly route: Router,
    private readonly user: UserAccountFacade,
    private readonly modalService: NgbModal,
    private readonly ref: ChangeDetectorRef,
    private readonly csAgentAuthService: CsAgentAuthService,
    private readonly activatedRoute: ActivatedRoute,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    router.getPageContext().subscribe((page) => {
      if (page?.id == '__HOMEPAGE__') {
        this.document.querySelector('body').classList.add('Homepage');
      }
    });
  }

  ngOnInit(): void {
    this.user.get().subscribe((res) => {
      if (res === undefined) {
        this.isUserLoggedIn = false;
        this.emailConsentEnabled = false;
        this.userId$.next('');
      } else {
        const qresult: any = res;
        this.emailConsent = qresult?.emailConsent;
        this.userId = qresult?.userName;
        this.isUserLoggedIn = true;
        this.userId$.next(res?.uid);
      }
    });
    this.activatedRoute.queryParams.subscribe((res) => {
      if (res.hasOwnProperty('asm')) {
        this.isSSOEnabled = true;
        this.sharedService.setLocalStorage('ssoEnabled', this.isSSOEnabled);
      }
    });
    this.route.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        window.scrollTo(0, 0);
      }
    });
    this.addSnippet();
    this.pageDate.getCurrentPage().subscribe((res) => {
      this.pageName = res?.name;
      this.pageId = res?.pageId;   
      if (this.pageId == 'homepage' || this.pageId == 'login') {
        this.document.querySelector('body').classList.add('rem-title');
      } else {
        this.document.querySelector('body').classList.remove('rem-title');
      }
      if (this.pageId == 'AccountOverviewPage') {
        this.document.querySelector('body').classList.add('rem-leftSect');
      } else {
        this.document.querySelector('body').classList.remove('rem-leftSect');
      }
      this.isLoggedIn.subscribe((res) => {
        this.csAgentAuthService.isCustomerEmulated().subscribe((res) => {
          this.isAsmEnabled = res ? res : false;
        });
        if (
          !this.isAsmEnabled &&
          !(
            this.pageId === 'customerDetails' ||
            this.pageId === 'userManagement'
          )
        ) {
          sessionStorage.removeItem('filterAttributes');
          sessionStorage.removeItem('b2BUnitNameFilter');
          sessionStorage.removeItem('b2BUnitFilter');
          sessionStorage.removeItem('emailFilter');
          sessionStorage.removeItem('cusNameFilter');
          sessionStorage.removeItem('userNameFilter');
          sessionStorage.removeItem('pageSizeperPage');
        }
        this.isSSOEnabled = this.sharedService.getLocalStorage('ssoEnabled');
        if (res && !this.isAsmEnabled && !this.isSSOEnabled) {
          if (this.pageName === 'Homepage') {
            this.router.goByUrl('my-account/account-overview');
          } else if (this.pageId === 'LoginPageReference') {
            this.router.goByUrl('my-account/account-overview');
          } else if (
            this.pageName === 'Account Overview Page' &&
            !this.emailConsentEnabled
          ) {
            this.emailConsentContent();
          }
        } else if (this.isAsmEnabled) {
          if (this.pageId === 'MyAccountInvoicePage') {
            sessionStorage.removeItem('filterAttributes');
            sessionStorage.removeItem('b2BUnitNameFilter');
            sessionStorage.removeItem('b2BUnitFilter');
            sessionStorage.removeItem('emailFilter');
            sessionStorage.removeItem('cusNameFilter');
            sessionStorage.removeItem('userNameFilter');
            sessionStorage.removeItem('pageSizeperPage');
          }
          if (
            this.pageId &&
            !(
              this.pageId === 'customerDetails' ||
              this.pageId === 'userManagement' ||
              this.pageId === 'MyAccountInvoicePage' ||
              this.pageId === 'MyAccountOnlineInvoicePaymentPage' ||
              this.pageId === 'MyAccountInvoiceReviewPage' ||
              this.pageId === 'MyAccountInvoiceConfirmationPage'
            )
          ) {
            this.router.goByUrl('my-account/invoices');
          }
        }
      });
    });
    this.renderer.listen('document', 'click', (event) => {
      // event.preventDefault();
      // window.open("mailto:customer.serviceCA@sanofipasteur.com");

      if (
        event.target.parentElement?.parentElement?.parentElement?.parentElement?.classList.contains(
          'app-navigation-links'
        )
      ) {
        event.preventDefault();
        event.target.parentElement.parentElement.parentElement.parentElement.classList.contains(
          'external'
        );
        if (
          event.target.parentElement.parentElement.parentElement.parentElement.classList.contains(
            'external'
          )
        ) {
          const modalRef = this.modalService.open(ExternalsiteAlertComponent, {
            windowClass: 'externalsite-modal',
            backdrop: 'static',
            keyboard: false,
          });
          modalRef.componentInstance.eventReference =
            event.target.parentElement.parentElement.href;
        } else {
          window.open(event.target.parentElement.parentElement.href);
        }
      }

      if (
        event.target.classList.contains('app-navigation-links') ||
        event.target.parentElement.parentElement.classList.contains(
          'app-navigation-links'
        )
      ) {
        let type;
        if (event.target.nodeName == 'IMG') {
          type = event.target.parentElement.parentElement.type;
          this.eventReference = event.target.parentElement.parentElement.rel;
        } else {
          type = event.target.type;
          this.eventReference = event.target.rel;
        }
        if (type === 'external') {
          const modalRef = this.modalService.open(ExternalsiteAlertComponent, {
            windowClass: 'externalsite-modal',
            backdrop: 'static',
            keyboard: false,
          });
          modalRef.componentInstance.eventReference = this.eventReference;
        } else if (type === 'internal') {
          window.open(this.eventReference);
        }
      }

      $('.SiteLinks').find('a').addClass('login-link');
      if (event.target.classList.contains('close-banner')) {
        const elementArray = document.getElementsByClassName('Section1');
        const displayBanner = document.getElementsByClassName(
          'ContentSlotHeaderBannerOrdersListing'
        );
        const accImpBanner = document.getElementsByClassName(
          'SanofiAccountOverviewBannerSlot'
        );
        for (let item = 0; item < elementArray.length; item++) {
          elementArray[item].className += ' bannerHide';
        }
        for (let item = 0; item < displayBanner.length; item++) {
          displayBanner[item].className += ' bannerHide';
        }
        for (let item = 0; item < accImpBanner?.length; item++) {
          accImpBanner[item].className += ' bannerHide';
        }
      }

      const hamburger = document.getElementsByClassName('cx-hamburger');
      const hideSiteContext = document.getElementsByClassName('SiteContext');
      const hideSiteLink = document.getElementsByClassName('SiteLinks');
      const hideSearchBox = document.getElementsByClassName('SearchBox');
      const hideSiteLogo = document.getElementsByClassName('SiteLogo');
      const hideMiniCart = document.getElementsByClassName('MiniCart');
      const hideSiteLogin = document.getElementsByClassName('SiteLogin');
      let expandedOption;
      for (let i = 0; i < hamburger.length; i++) {
        expandedOption = hamburger[i].classList.contains('is-active');
      }
      if (expandedOption) {
        for (let i = 0; i < hideSiteContext.length; i++) {
          hideSiteContext[i].classList.add('noDisplay');
        }
        for (let i = 0; i < hideSiteLink.length; i++) {
          hideSiteLink[i].classList.add('noDisplay');
        }
        for (let i = 0; i < hideSearchBox.length; i++) {
          hideSearchBox[i].classList.add('noDisplay');
        }
        for (let i = 0; i < hideSiteLogo.length; i++) {
          hideSiteLogo[i].classList.add('noDisplay');
        }
        for (let i = 0; i < hideMiniCart.length; i++) {
          hideMiniCart[i].classList.add('noDisplay');
        }
        for (let i = 0; i < hideSiteLogin.length; i++) {
          hideSiteLogin[i].classList.add('noDisplay');
        }
      } else {
        for (let i = 0; i < hideSiteContext.length; i++) {
          hideSiteContext[i].classList.remove('noDisplay');
        }
        for (let i = 0; i < hideSiteLink.length; i++) {
          hideSiteLink[i].classList.remove('noDisplay');
        }
        for (let i = 0; i < hideSearchBox.length; i++) {
          hideSearchBox[i].classList.remove('noDisplay');
        }
        for (let i = 0; i < hideSiteLogo.length; i++) {
          hideSiteLogo[i].classList.remove('noDisplay');
        }
        for (let i = 0; i < hideMiniCart.length; i++) {
          hideMiniCart[i].classList.remove('noDisplay');
        }
        for (let i = 0; i < hideSiteLogin.length; i++) {
          hideSiteLogin[i].classList.remove('noDisplay');
        }
      }
    });
  }

  emailConsentContent() {
    this.userId$.pipe(take(2)).subscribe((id) => {
      if (id && id != '') {
        if (!sessionStorage.getItem('emailPopUp')) {
          this.sharedService.getEmailConsent(id);
          this.emailConsentEnabled = true;
          this.sharedService.emailConsent$.subscribe((result) => {
            if (result) {
              if (
                !this.modalService.hasOpenModals() &&
                result.emailConsentEnabled === true
              ) {
                this.modalOption.backdrop = 'static';
                this.modalOption.keyboard = false;
                this.modalOption.windowClass = 'email-popup-modal';
                const modalRef = this.modalService.open(
                  EmailPopupComponent,
                  this.modalOption
                );
                modalRef.componentInstance.emailConsentObj = result;
                modalRef.componentInstance.userId = id;
                sessionStorage.setItem('emailPopUp', 'true');
                this.sharedService.emailConsent.next(false);
              }
            }
          });
        }
      }
    })
    
  }

  addSnippet() {
    this.sharedService.getsnippetAPI();
    this.sharedService.snippetData$.subscribe((result) => {
      if (result.snippetConfigs && result.snippetConfigs.length > 0) {
        let snippetCommentStart = this._renderer2.createComment('BEGIN QUALTRICS WEBSITE FEEDBACK SNIPPET');
        let snippetCommentEnd = this._renderer2.createComment('END WEBSITE FEEDBACK SNIPPET');
        for (const config of result.snippetConfigs) {
          const snippetValue = config.value.replace(/\\n/g, '');
          let script = this._renderer2.createElement('script');
          script.type = `text/javascript`;
          script.text = snippetValue;
          this._renderer2.appendChild(this.document.head, snippetCommentStart);
          this._renderer2.appendChild(this.document.head, script);
          let snippetDiv = this._renderer2.createElement('div');
          snippetDiv.id = config.divId;
          let snippetDivComment = this._renderer2.createComment('DO NOT REMOVE-CONTENTS PLACED HERE');
          snippetDiv.appendChild(snippetDivComment);
          this._renderer2.appendChild(this.document.head, snippetDiv);
          this._renderer2.appendChild(this.document.head, snippetCommentEnd);
        }
      }
    });
  }

}