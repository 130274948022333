import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OccEndpointsService, RoutingService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { LoactionStripService } from 'src/app/shared/modules/location-strip/loaction-strip.service';
import * as _ from 'underscore';
import { ProductUtils } from '../../shared/utils/productUtils';
import { CustomAddToCartService } from '../custom-add-to-cart.service';
import { ProductDetailsComponent } from '../custom-add-to-cart/product-details/product-details.component';

@Component({
  selector: 'app-edit-orders',
  templateUrl: './edit-orders.component.html',
  styleUrls: ['./edit-orders.component.scss']
})
export class EditOrdersComponent implements OnInit,OnDestroy {
  renameInSavedCart: string;
  savedCartId:string;
  cartData:any;
  usergetDataObj: any;
  getuserData: any;
  searchedClientList: any;
  selectedData: any;
  userAccountgetData:any;
  selectedLocation:any;
  childCarts:any;
  entries:any;
  baseUrl: string;
  images:any;
  productEntries: any = [];
  disabled:boolean;
  updateEntriesData: any = [];
  stdCartId:any;
  cartId:any;
  cartItems:any;
  errorMsg:any;
  fallbackImage = '../../assets/i18n-assets/sanofi_missing_product.jpg';
  cartItemsSub:Subscription;
  cartDataSub:Subscription;
  allCartItemsSub:Subscription;
  userAccountSub:Subscription;
  renameInvalidSub:Subscription;
  manageSaveCartSub:Subscription;
  faChevronLeft = faChevronLeft;
  salesOrg$ = this.locStrpService.salesOrg$;
  constructor(private readonly route: ActivatedRoute,
    private readonly router: RoutingService,
    private readonly addToCartService:CustomAddToCartService,
    private readonly user:UserAccountFacade,
    private readonly cdr: ChangeDetectorRef,
    private readonly occEndPoint: OccEndpointsService,
    private readonly modalService: NgbModal,
    private locStrpService: LoactionStripService,
    public datepipe: DatePipe) {
      this.disabled=true;
    }

  ngOnInit(): void {
    this.savedCartId=this.route.snapshot.queryParams.savedCartID;
    this.baseUrl = this.occEndPoint.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    });
    this.user.get().subscribe((userData) => {
      if (userData && userData != null) {
        this.userAccountgetData = userData;
        this.getUserAccountData(this.userAccountgetData.userName);
        this.addToCartService.getSavedCart(this.savedCartId);
        this.cartDataSub=this.addToCartService.cartData$.subscribe((res)=>{
          if(res){
            this.productEntries=[];
          this.cartData=res?.savedCartData;
          this.childCarts=res?.savedCartData?.childCarts;
          this.childCarts = Object.assign({}, ...this.childCarts);
          if(this.getuserData && this.getuserData?.length>0){
            if(this.childCarts?.shipToParty){
              this.selectedData=this.childCarts?.shipToParty?.shippingAddresses.filter((item)=>item.unitID ===this.childCarts.shipToParty.uid);
              this.selectedData=_.first(this.selectedData);
              }else{
                if (this.getuserData && this.getuserData?.length > 0) {
                  this.selectedData = _.first(this.getuserData.filter((item) => item.unitID === this.userAccountgetData?.uid));
                }
              }
            this.selectedLocation =
            `${this.selectedData?.companyName} (${this.selectedData?.unitID})`;
          }
          for (let entries of this.childCarts?.entries) {
            const imagesFormat = _.clone(
              _.findWhere(entries.product?.images, { format: 'cartIcon' })
            );
            if (imagesFormat){
              imagesFormat.url = `${this.baseUrl}${imagesFormat?.url}`;
            }
            this.images = { PRIMARY: imagesFormat };
            entries = { ...entries, ...this.images };
            this.productEntries.push(entries);
          }
          this.renameInSavedCart=this.cartData?.name;
          this.cdr.detectChanges();
        }
        });
        this.allCartItemsSub=this.addToCartService.allCartItems$.subscribe((res)=>{
            if(res){
              this.cartItems=res?.carts;
              this.cartItems=this.cartItems.filter((item)=>item?.saveTime===undefined);
              this.cartItems=this.cartItems[this.cartItems?.length - 1];
                this.cartId=this.cartItems?.code;
                this.getSessionCartDetails();
            }
          });
      }
    });
  }
  getSessionCartDetails(){
    this.cartItemsSub=this.addToCartService.cartItems$.subscribe((res1: any) => {
      if (res1) {
        let cartData=res1?.childCarts;
        cartData = cartData.filter(
          (item) => item.cartType === 'STANDARD'
        );
        this.stdCartId=_.pluck(cartData,'code');
      }
      this.cdr.detectChanges();
    });
  }
  toggle(popover) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
    }
    this.renameInSavedCart=this.cartData?.name;
    this.errorMsg = '';
  }
  getUserAccountData(userId): void {
    this.userAccountSub=this.addToCartService.userAccountDetails.subscribe((userDataget) => {
          if(userDataget){
          this.getuserData = userDataget.res.shippingAddresses;
            this.cdr.detectChanges();
        }
        });
  }
  getOrders(childCarts){
    let orderedDate='-';
    if(childCarts){
    childCarts = childCarts.filter(
          (item) => item.cartType === 'STANDARD'
        );
    for(const carts of childCarts){
      if(carts?.lastOrdered){
        orderedDate=carts?.lastOrdered.slice(0,10);
        orderedDate=this.datepipe.transform(orderedDate, 'dd/MM/yyyy');
      }
    }
  }
    return orderedDate;
  }
  saveOrder(): void {
    const reg = /^[^`!@#~$%\^&*()_+\-={};':"\\|[\]\\.,<>/\/?~]*$/;
    if(this.renameInSavedCart && RegExp(reg).test(this.renameInSavedCart)){
    this.updateEntriesData=[];
    for(const entries of this.productEntries){
      const orderEntries={entryNumber: entries?.entryNumber, quantity: entries?.quantity};
      this.updateEntriesData.push(orderEntries);
    }
    const params={
      code:this.cartData?.code,
      name:this.renameInSavedCart,
      user:{
        name:this.cartData?.savedBy?.name,
        uid:this.cartData?.savedBy?.uid,
      },
      childCarts:[{
        code:this.childCarts?.code,
        entries:this.updateEntriesData
      }]
    };
    this.addToCartService.renameSaveCartDetails(params,this.cartData?.code);
    this.renameInvalidSub=this.addToCartService.invalidCartName$.subscribe((res)=>{
      if(res){
        for(const error of res?.error?.errors){
          this.errorMsg=error?.message;
          }
        }
    });
  }else{
    if(this.renameInSavedCart){
      this.errorMsg= 'Save cart name is not valid.';
    }else{
    this.errorMsg = 'Cart name cannot be blank';
    }
  }
  }
  deleteCart():void{
    this.addToCartService.deleteSavedCart(this.cartData?.code);
  }
  performQuantityComputation(operator,idData,quantityOfUnits,entryNumber): void {
    ProductUtils.alterQuantity(operator, idData, quantityOfUnits);
    this.disabled=false;
  }
  updateCart():void{
    this.updateEntriesData=[];
    for(const entries of this.productEntries){
      const inputElement = document.getElementById(
          `prodQuantity${entries?.product?.code}`
         ) as HTMLInputElement;
      const orderEntries={entryNumber: entries?.entryNumber, quantity: inputElement.value};
      this.updateEntriesData.push(orderEntries);
    }
    const params={
      code:this.cartData?.code,
      name:this.renameInSavedCart,
      user:{
        name:this.cartData?.savedBy?.name,
        uid:this.cartData?.savedBy?.uid,
      },
      childCarts:[{
        code:this.childCarts?.code,
        entries:this.updateEntriesData
      }]
    };
    this.addToCartService.updateSavedCart(params,this.cartData?.code);
    this.manageSaveCartSub=this.addToCartService.manageSavedCart$.subscribe((res)=>{
      if(res){
        this.addToCartService.renameSaveCart.next(false);
        this.router.goByUrl('/cart/save');
      }
    });
  }
  navigateSaveOrder(){
    this.addToCartService.renameSaveCart.next(false);
    this.router.goByUrl('/cart/save');
  }
  productDetails(productDetails): void {
    const modalRef = this.modalService.open(ProductDetailsComponent, {
      windowClass: 'product-details-modal',
    });
    modalRef.componentInstance.productDetails = productDetails;
    modalRef.componentInstance.standardCartCode = this.stdCartId;
    modalRef.componentInstance.unitSection = 'AddToCart';
  }
  getQuantity(childCarts){
    let quantity=0;
    if(childCarts){
      childCarts = childCarts.filter(
          (item) => item.cartType === 'STANDARD'
        );
      for(const carts of childCarts){
        for(const entries of carts?.entries){
        quantity+=entries?.quantity;
        }
      }
    }
    return quantity;
  }
  omit_special_char(event) {
    this.errorMsg = '';
    const reg = /^[^`!@#~$%\^&*()_+\-={};':"\\|[\]\\.,<>/\/?~]*$/;
    return new RegExp(reg).test(event.key);
  }
  ngOnDestroy() {
    this.cartDataSub?.unsubscribe();
    this.cartItemsSub?.unsubscribe();
    this.allCartItemsSub?.unsubscribe();
    this.manageSaveCartSub?.unsubscribe();
    this.renameInvalidSub?.unsubscribe();
    this.userAccountSub?.unsubscribe();
  }
}
