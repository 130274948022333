<div class="container mb-1">
  <div class="row">
    <div class="col-md-6 mb-4">
      <p class="text-fixed-medium mb-0">
        {{ 'addToCartItems.orderUpdated' | cxTranslate }}
      </p>
    </div>
    <div class="col-md-6 mb-4 text-right">
      <a
        id="continueShoppingId"
        data-di-id="#continueShoppingId"
        href="/CAD/Open-Catalogue/c/1"
        target=""
        class="c-link"
      >
        <fa-icon [icon]="faChevronLeft" class="icon"></fa-icon>
        {{ 'addToCartItems.continueShopping' | cxTranslate }}</a
      >&nbsp;&nbsp;
      <ng-template #popSaveTitle>{{
        'addToCartItems.saveOrderTitle' | cxTranslate
      }}</ng-template>
      <ng-template #popSaveContent>
        <input
          type="text"
          class="save-order"
          required
          #name="ngModel"
          [(ngModel)]="saveCartName"
          (keypress)="omit_special_char($event)"
          maxlength="30"
        />
        <span *ngIf="name.errors?.required" class="common-error required">{{
          errorMsg
        }}</span>
        <span class="common-error required">{{ errorMsg }}</span>
        <div class="c-popover">
          <button
            type="reset"
            class="popover-close popover-cancel-button c-button c-button--secondary"
            (click)="toggle(p2)"
          >
            {{ 'addToCartItems.cancel' | cxTranslate }}
          </button>
          <button
            type="submit"
            class="popover-cancel-button c-button c-button--primary"
            data-di-id="di-id-3064f17d-e97cc628"
            (click)="saveOrder()"
          >
            {{ 'addToCartItems.save' | cxTranslate }}
          </button>
        </div>
      </ng-template>
      <a
        class="c-link"
        [ngbPopover]="popSaveContent"
        [popoverTitle]="popSaveTitle"
        popoverClass="popover-links saveorder-incart"
        triggers="manual"
        placement="top"
        container="body"
        #p2="ngbPopover"
        href="javascript:;"
        (click)="toggle(p2)"
      >
        <span>{{ 'addToCartItems.saveOrder' | cxTranslate }}</span>
        <fa-icon [icon]="faRectangleList" class="icon"></fa-icon> </a
      >&nbsp;&nbsp;
      <a
        id="tealium-xls"
        data-di-id="#tealium-xls"
        class="c-link"
        aria-label="download as"
        href="javascript:void(0)"
        target=""
        (click)="exportXls()"
      >
        <span>{{ 'addToCartItems.xls' | cxTranslate }}</span>
        <fa-icon [icon]="faFileExcel" class="icon"></fa-icon> </a
      >&nbsp;&nbsp;
      <a
        id="printLinkId"
        data-di-id="#printLinkId"
        class="c-link"
        href="javascript:void(0)"
        target=""
        onclick="window.print();"
      >
        <span>{{ 'addToCartItems.print' | cxTranslate }}</span>
        <fa-icon [icon]="faPrint" class="icon"></fa-icon>
      </a>
    </div>
    <div class="col-md-6"></div>
  </div>
</div>

<section class="container my-4 mt-4">
  <section class="row pb-4">
    <!-- Left -->
    <div class="col-md-8 u-border-right-grey pr-md-4 pr-lg-5">
      <div class="row pb-4 u-border-bottom-grey">
        <div class="col-lg-7">
          <i class="fa fa-map-marker-alt font-n"></i>
          <span class="px-3 text-fixed-medium"
            >{{ orderConfirmationDetails?.shippingAddress?.companyName }}
            {{
              '(' + orderConfirmationDetails?.shippingAddress?.unitID + ')'
            }}</span
          >
        </div>
        <div class="col-lg-5 d-lg-flex justify-content-lg-end">
          <div
            *ngIf="
              orderConfirmationDetails?.purchaseOrderNumber === '';
              else poNum
            "
          >
            <span class="font-bold"
              >{{ 'addToCartItems.po' | cxTranslate }}
              {{ 'addToCartItems.orderspclchar' | cxTranslate }}</span
            >
            <span
              >{{ 'addToCartItems.www' | cxTranslate
              }}{{
                orderConfirmationDetails?.created
                  | slice : 0 : 10
                  | date : 'dd/MM/yyyy'
              }}</span
            >
          </div>
          <ng-template #poNum>
            <div>
              <span class="font-bold">
                {{ 'addToCartItems.po' | cxTranslate }}
                {{ 'addToCartItems.orderspclchar' | cxTranslate }}</span
              >
              <span>{{ orderConfirmationDetails?.purchaseOrderNumber }}</span>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-12">
          <!-- Sold to / Total -->
          <div class="row pb-4">
            <!-- Sold to -->
            <div class="col-md-8">
              <h3 class="font-bold">
                {{ 'addToCartItems.soldTo' | cxTranslate }}
              </h3>
              <p class="text-fixed-medium">
                #{{ orderConfirmationDetails?.soldtoAddress?.unitID }}
              </p>
            </div>
            <!-- Total -->
            <div class="col-md-4 text-fixed-medium">
              <!-- Subtotal -->
              <div class="row">
                <div class="col-md-5">
                  {{ 'addToCartItems.subtotal' | cxTranslate }}
                </div>
                <div class="col-md-7 text-right">
                  {{ orderConfirmationDetails?.subTotal?.value | currency }}
                  {{ 'plp.cad' | cxTranslate }}
                </div>
              </div>
              <!-- Total with tax -->
              <div
                class="row py-1"
                *ngIf="orderConfirmationDetails?.totalTax?.value > 0"
              >
                <div class="col-md-5">
                  {{ 'orderConfirmation.salesTax' | cxTranslate }}
                </div>
                <div class="col-md-7 text-right">
                  {{ orderConfirmationDetails?.totalTax?.value | currency }}
                  {{ 'plp.cad' | cxTranslate }}
                </div>
              </div>
              <!-- Total -->
              <div class="row u-border-top-grey font-bold py-1">
                <div class="col-md-5">
                  {{ 'addToCartItems.total' | cxTranslate }}
                </div>
                <div class="col-md-7 text-right">
                  {{
                    orderConfirmationDetails?.totalPriceWithTax?.value
                      | currency
                  }}
                  {{ 'plp.cad' | cxTranslate }}
                </div>
              </div>
              <div
                class="row"
                *ngIf="
                  orderConfirmationDetails?.subTotal?.value < 250 &&
                  orderConfirmationDetails?.pickupDate === undefined
                "
              >
                <div class="col-md-12">
                  <section class="mt-2 text-lfixed-legal-copy">
                    <span
                      >{{ 'addToCartItems.shippingChargesNote' | cxTranslate }}
                    </span>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <!-- Ship to -->
          <div class="row">
            <div class="col-md-12">
              <h3 class="font-bold">
                {{ 'orderConfirmation.shipTo' | cxTranslate }}
              </h3>
            </div>
            <div class="col-md-12 text-fixed-medium pb-4">
              <p class="mb-0">
                {{
                  orderConfirmationDetails?.shippingAddress?.companyName
                    ? orderConfirmationDetails?.shippingAddress?.companyName
                    : ''
                }}<br />
                {{
                  orderConfirmationDetails?.shippingAddress?.line1
                    ? orderConfirmationDetails?.shippingAddress?.line1 + ','
                    : ''
                }}<br />
                <span *ngIf="orderConfirmationDetails?.shippingAddress?.line2"
                  >{{
                    orderConfirmationDetails?.shippingAddress?.line2
                      ? orderConfirmationDetails?.shippingAddress?.line2 + ','
                      : ''
                  }}<br
                /></span>
                {{
                  orderConfirmationDetails?.deliveryAddress?.town
                    ? orderConfirmationDetails?.deliveryAddress?.town
                    : ''
                }}
                ,
                {{
                  orderConfirmationDetails?.shippingAddress?.region
                    ?.isocodeShort
                    ? orderConfirmationDetails?.shippingAddress?.region
                        ?.isocodeShort
                    : ''
                }}<br />
                {{
                  orderConfirmationDetails?.deliveryAddress?.postalCode
                    ? orderConfirmationDetails?.shippingAddress?.postalCode
                    : ''
                }}
              </p>
            </div>
          </div>
          <!-- Order date -->
          <div class="row">
            <div class="col-md-12">
              <h3 class="font-bold">
                {{ 'addToCartItems.orderDate' | cxTranslate }}
              </h3>
            </div>
            <div class="col-md-12 text-fixed-medium pb-4">
              <p class="mb-0">
                <span class="data">{{
                  orderConfirmationDetails?.created
                    | slice : 0 : 10
                    | date : 'dd/MM/yyyy'
                }}</span>
              </p>
            </div>
          </div>
          <!-- Pickup date -->
          <div
            class="row"
            *ngIf="
              orderConfirmationDetails?.deliveryMode?.code === 'pickup';
              else deliveryDate
            "
          >
            <div class="col-md-12">
              <h3 class="font-bold">
                {{ 'addToCartItems.pickupDate' | cxTranslate }}
              </h3>
            </div>
            <div class="col-md-12 text-fixed-medium pb-4">
              <p class="mb-0">
                <span class="data">{{
                  oorderConfirmationDetails?.pickupDate
                }}</span>
              </p>
            </div>
          </div>
          <!-- Delivery date -->
          <ng-template #deliveryDate>
            <div class="row">
              <div class="col-md-12">
                <h3 class="font-bold">
                  {{ 'addToCartItems.deliveryDate' | cxTranslate }}
                </h3>
              </div>
              <div class="col-md-12 text-fixed-medium pb-4">
                <p class="mb-0">
                  <span class="data">{{
                    estimatedDate | slice : 0 : 10 | date : 'dd/MM/yyyy'
                  }}</span>
                </p>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <div
        class="row u-border-top-grey py-4 mt-4 mt-md-0"
        *ngFor="let productEntry of productEntries"
      >
        <!-- Image -->
        <section class="col-md-2 mb-4">
          <a (click)="productDetails(productEntry)">
            <img
              class="prod-Image"
              *ngIf="productEntry?.PRIMARY !== undefined"
              src="{{ productEntry?.PRIMARY?.url }}"
              alt=""
            />

            <img
              class="prod-Image"
              *ngIf="productEntry?.PRIMARY === undefined"
              [src]="fallbackImage"
              alt=""
            />
          </a>
        </section>
        <!-- details -->
        <section class="col-md-10">
          <section class="row c-prod-details">
            <!-- Title -->
            <div class="col-md-12">
              <a (click)="productDetails(productEntry)">
                <h2
                  class="font-bold c-heading c-heading-h4 mb-4"
                  [innerHTML]="productEntry?.product?.name"
                ></h2>
              </a>
            </div>
            <!-- Units -->
            <div
              class="col-md-12 d-md-flex justify-content-between text-fixed-medium"
            >
              <div>
                <span class="font-bold">{{ 'plp.units' | cxTranslate }}</span>
                <span class="d-inline-block d-md-none"
                  >:&nbsp;{{ productEntry?.quantity }}</span
                >
                <span class="d-none d-md-block"
                  >{{ productEntry?.quantity }} &nbsp; ({{
                    productEntry?.product?.quantityPerUnit *
                      productEntry?.quantity
                  }}
                  {{ 'selectVaccines.doses' | cxTranslate }})
                </span>
              </div>
              <!-- base price -->
              <div>
                <span class="font-bold">{{'plp.yourPrice' | cxTranslate}}</span>
                <span class="d-inline-block d-md-none">
                  :&nbsp;{{ productEntry?.basePrice?.value | currency }}
                  {{ 'plp.cad' | cxTranslate }}</span>
                <span class="d-none d-md-block">
                  {{ productEntry?.basePrice?.value | currency }}
                  {{ 'plp.cad' | cxTranslate }}
                </span>
              </div>
              <!-- Sub total -->
              <div>
                <span class="font-bold">{{'addToCartItems.subtotal' | cxTranslate}}</span>
                <span class="d-inline-block d-md-none">:&nbsp;{{ productEntry?.totalPrice?.value | currency }}
                  {{ 'plp.cad' | cxTranslate }}</span>
                <span class="d-none d-md-block">{{ productEntry?.totalPrice?.value | currency }}
                  {{ 'plp.cad' | cxTranslate }}</span>
              </div>
              <!-- status -->
              <div>
                <span class="font-bold">{{'addToCartItems.status' | cxTranslate}}</span>
                <span class="d-inline-block d-md-none">
                  :&nbsp;{{ 'addToCartItems.statusOpen' | cxTranslate }}
                </span>
                <span class="d-none d-md-block">
                  {{ 'addToCartItems.statusOpen' | cxTranslate }}
                </span>
              </div>
              <div>
                <span class="font-bold">{{
                  'addToCartItems.estDeliveryDate' | cxTranslate
                }}</span>

                <section>
                  {{
                    productEntry?.estimatedDeliveryDate
                      | slice : 0 : 10
                      | date : 'dd/MM/yyyy'
                  }}
                </section>
              </div>
            </div>
            <section class="col-md-12 text-fixed-medium py-4">
              <div
                class="m-0"
                [innerHTML]="productEntry?.product?.description"
              ></div>
            </section>
            <section class="col-md-12 text-fixed-medium">
              <span class="font-bold"
                >{{ 'plp.productNumber' | cxTranslate }}:&nbsp;</span
              >
              <span class="prdct-No">{{ productEntry?.product?.code }}</span>
            </section>
          </section>
        </section>
      </div>
    </div>
    <!-- Right -->
    <div class="col-md-4 pl-md-4 pl-lg-5">
      <div class="row d-md-none">
        <div class="col-md-12 u-border-top-grey pb-4"></div>
      </div>
      <div class="row">
        <!-- Total -->
        <div
          class="col-md-12 py-4 d-flex align-items-center justify-content-center justify-content-md-between flex-column flex-md-row"
        >
          <h3 class="font-bold mb-0">
            {{ 'addToCartItems.orderTotal' | cxTranslate }}
          </h3>
          <h1 class="font-bold mb-0">
            {{ orderConfirmationDetails?.totalPriceWithTax?.value | currency }}
            {{ 'plp.cad' | cxTranslate }}
          </h1>
        </div>
      </div>
      <!--Payment Method-->
      <section class="row">
        <section class="col-md-12">
          <h3 class="font-bold mb-2">
            {{ 'orderConfirmation.paymentMethod' | cxTranslate }}
          </h3>
          <p class="font-bold mb-2">
            {{ orderConfirmationDetails?.paymentType?.displayName }}
          </p>
        </section>
        <div class="col-md-12 mb-4 pb-4 u-border-bottom-grey">
          <section class="note-Size mb-2">
            <img src="../../assets/icons/card.svg" alt="card" />
            <section
              class="card-details"
              *ngIf="orderConfirmationDetails?.paymentInfo !== undefined"
            >
              {{ orderConfirmationDetails?.paymentInfo?.cardType?.name }}
              {{ 'orderConfirmation.endingin' | cxTranslate }} {{ showCard }}
            </section>
          </section>
          <section class="text-fixed-legal-copy">
            {{ 'addToCartItems.paymentNote' | cxTranslate }}
          </section>
        </div>
      </section>
      <!--Reservation contact heading -->
      <section class="row">
        <section class="col-md-12">
          <h3 class="font-bold mb-4">
            {{ 'addToCartItems.orderContact' | cxTranslate }}
          </h3>
        </section>
      </section>

      <!-- Order contact details-->
      <section class="row">
        <!-- Name -->
        <section class="col-4 mb-2">
          <span class="font-bold">{{
            'addToCartItems.name' | cxTranslate
          }}</span>
        </section>
        <section class="col-8">
          <span>{{ orderConfirmationDetails?.orderContact?.name }}</span>
        </section>
        <!-- Phone -->
        <section
          class="col-4 mb-2"
          *ngIf="orderConfirmationDetails?.orderContact?.phoneNo"
        >
          <span class="font-bold">{{
            'addToCartItems.phone' | cxTranslate
          }}</span>
        </section>
        <section
          class="col-8"
          *ngIf="orderConfirmationDetails?.orderContact?.phoneNo"
        >
          <span>{{
            orderConfirmationDetails?.orderContact?.phoneNo
              ? orderConfirmationDetails?.orderContact?.phoneNo
              : ''
          }}</span>
        </section>
        <!-- Mobile -->
        <section
          class="col-4 mb-2"
          *ngIf="orderConfirmationDetails?.orderContact?.mobileNo"
        >
          <span class="font-bold">{{
            'addToCartItems.mobile' | cxTranslate
          }}</span>
        </section>
        <section
          class="col-8"
          *ngIf="orderConfirmationDetails?.orderContact?.mobileNo"
        >
          <span>{{
            orderConfirmationDetails?.orderContact?.mobileNo
              ? orderConfirmationDetails?.orderContact?.mobileNo
              : ''
          }}</span>
        </section>
        <!-- Email -->
        <section class="col-4 mb-2">
          <span class="font-bold">{{
            'addToCartItems.email' | cxTranslate
          }}</span>
        </section>
        <section class="col-8">
          <a
            href="{{
              'mailto:' + orderConfirmationDetails?.orderContact?.email
            }}"
            class="email-field"
            >{{ orderConfirmationDetails?.orderContact?.email }}</a
          >
        </section>
      </section>
      <section class="row mt-2">
        <div class="col-md-6">
          <h5 class="font-bold my-2">
            {{ 'addToCartItems.billingAddress' | cxTranslate }}
          </h5>
          <p class="text-fixed-medium">
            <span
              >{{ 'addToCartItems.accountNo' | cxTranslate }}:
              {{
                orderConfirmationDetails?.billingAddress?.unitID
                  ? orderConfirmationDetails?.billingAddress?.unitID
                  : ''
              }}</span
            ><br />
            <span>{{
              orderConfirmationDetails?.billingAddress?.companyName
                ? orderConfirmationDetails?.billingAddress?.companyName
                : ''
            }}</span
            ><br />
            <span
              >{{
                orderConfirmationDetails?.billingAddress?.line1
                  ? orderConfirmationDetails?.billingAddress?.line1
                  : ''
              }},
              {{
                orderConfirmationDetails?.billingAddress?.line2
                  ? orderConfirmationDetails?.billingAddress?.line2
                  : ''
              }}</span
            ><br />
            <span
              >{{
                orderConfirmationDetails?.billingAddress?.town
                  ? orderConfirmationDetails?.billingAddress?.town
                  : ''
              }},
              {{
                orderConfirmationDetails?.billingAddress?.region?.isocodeShort
                  ? orderConfirmationDetails?.billingAddress?.region
                      ?.isocodeShort
                  : ''
              }}
              {{
                orderConfirmationDetails?.billingAddress?.postalCode
                  ? orderConfirmationDetails?.billingAddress?.postalCode
                  : ''
              }}</span
            >
          </p>
        </div>
        <div class="col-md-6">
          <h5 class="font-bold my-2">
            {{ 'addToCartItems.soldToAddress' | cxTranslate }}
          </h5>
          <p class="text-fixed-medium">
            <span
              >{{ 'addToCartItems.accountNo' | cxTranslate }}:
              {{
                orderConfirmationDetails?.soldtoAddress?.unitID
                  ? orderConfirmationDetails?.soldtoAddress?.unitID
                  : ''
              }}</span
            ><br />
            <span>{{
              orderConfirmationDetails?.soldtoAddress?.companyName
                ? orderConfirmationDetails?.soldtoAddress?.companyName
                : ''
            }}</span
            ><br />
            <span
              >{{
                orderConfirmationDetails?.soldtoAddress?.line1
                  ? orderConfirmationDetails?.soldtoAddress?.line1
                  : ''
              }},
              {{
                orderConfirmationDetails?.soldtoAddress?.line2
                  ? orderConfirmationDetails?.soldtoAddress?.line2
                  : ''
              }}</span
            ><br />
            <span
              >{{
                orderConfirmationDetails?.soldtoAddress?.town
                  ? orderConfirmationDetails?.soldtoAddress?.town
                  : ''
              }},
              {{
                orderConfirmationDetails?.soldtoAddress?.region?.isocodeShort
                  ? orderConfirmationDetails?.soldtoAddress?.region
                      ?.isocodeShort
                  : ''
              }}
              {{
                orderConfirmationDetails?.soldtoAddress?.postalCode
                  ? orderConfirmationDetails?.soldtoAddress?.postalCode
                  : ''
              }}</span
            >
          </p>
        </div>
      </section>
      <section class="row">
        <section class="col-md-12">
          <a class="c-link" target="_blank" (click)="billingAccount()">
            {{ 'addToCartItems.billingInfo' | cxTranslate }}
          </a>
        </section>
      </section>
    </div>
  </section>
</section>
