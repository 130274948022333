<app-loader *ngIf="!showdiagram && userInfo?.defaultB2BUnit?.salesOrg != 'CA04'"></app-loader>
<div class="row d-flex mb-4 pb-4" *ngIf="showdiagram">
  <div class="col-md-6 col-sm-12 mb-4 mb-md-0">
    <div class="st-box">
      <h3 class="c-widget-title c-heading c-heading-h3">
        {{ 'accountOverview.dosesPurchased' | cxTranslate }}
      </h3>
      <div>
        <h5 class="font-bold my-4">
          {{ 'accountOverview.allProducts' | cxTranslate }}
        </h5>
        <div id="rect-progress-bar">
          <span class="{{ remaining_doses_class }}">{{
            progress_msg_doses
          }}</span>
          <progress
            value="{{ actual_doses_value }}"
            max="{{ total_doses_value }}"
          ></progress>
        </div>
        <div *ngIf="days_left_msg !== 'None'">
        <p class="code_red">{{ days_left_msg }} {{ 'accountOverview.daysRemainingToQualify' | cxTranslate }}</p>
        </div>
        <div *ngIf="days_left_msg == 'None'">
        <p class="code_red">{{ days_left_msg }}</p>
        </div>
        <p class="st_calcval">
          <span class="sp_actual">{{ actual_doses_value_formatted }}</span
          >/<span class="sp_total">{{ total_doses_value_formatted }}</span>
        </p>
      </div>
      <div>
        <circle-progress
          class="circle__progress"
          [percent]="actual_doses_percentage"
          [outerStrokeColor]="outerStrokeColor"
          [innerStrokeColor]="innerStrokeColor"
          [subtitle]="original_doses_percentage"
          [subtitleColor]="'#7b00e6'"
          outerStrokeLinecap="'line'"
        ></circle-progress>
        <div *ngIf="remaining_doses_msg !== 'Goal Achieved'">
          <h2 class="{{ remaining_doses_class }} text-center font-bold">
          {{ remaining_doses_msg }} {{ 'accountOverview.dosesToGo' | cxTranslate }}
        </h2>
        </div>
        <div *ngIf="remaining_doses_msg === 'Goal Achieved'">
          <h2 class="{{ remaining_doses_class }} text-center font-bold">
          {{ remaining_doses_msg }}
        </h2>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 col-sm-12 mb-4 mb-md-0">
    <div class="st-box">
      <h3 class="c-widget-title c-heading c-heading-h3">
       {{ 'accountOverview.amountSpent' | cxTranslate }}
      </h3>
      <div>
        <h5 class="font-bold my-4">
          {{ 'accountOverview.allProducts' | cxTranslate }}
        </h5>
        <div id="rect-progress-bar">
          <span
            class="{{ remaining_amount_class }}"
            *ngIf="remaining_amount_status"
            >{{ actual_amount_value | currency }}</span
          >
          <span
            class="{{ remaining_amount_class }}"
            *ngIf="!remaining_amount_status"
            >{{ progress_msg_amount }}</span
          >
          <progress
            value="{{ actual_amount_value }}"
            max="{{ total_amount_value }}"
          ></progress>
        </div>
         <div *ngIf="days_left_msg !== 'None'">
        <p class="code_red">{{ days_left_msg }} {{ 'accountOverview.daysRemainingToQualify' | cxTranslate }}</p>
        </div>
        <div *ngIf="days_left_msg === 'None'">
        <p class="code_red">{{ days_left_msg }}</p>
        </div>
        <p class="st_calcval">
          <span class="sp_actual">{{ actual_amount_value | currency }}</span
          >/<span class="sp_total">{{ total_amount_value | currency }}</span>
        </p>
      </div>
      <div>
        <circle-progress
          class="circle__progress1"
          [percent]="actual_amount_percentage"
          [outerStrokeColor]="outerStrokeColor2"
          [innerStrokeColor]="innerStrokeColor2"
          [subtitle]="original_amount_percentage"
          [subtitleColor]="'#7b00e6'"
          outerStrokeLinecap="'line'"
        ></circle-progress>
        <h2
          class="{{ remaining_amount_class }} text-center font-bold"
          *ngIf="remaining_amount_status"
        >
          {{ remaining_amount_msg | currency }} {{ 'accountOverview.toGo' | cxTranslate }}
        </h2>
      </div>
      <div
        class="st_remaining_days {{ remaining_amount_class }}"
        *ngIf="!remaining_amount_status"
      >
        {{ remaining_amount_msg }}
      </div>
    </div>
  </div>
</div>
