<section class="container mobiledb-Container">
  <section class="row">
    <!-- ===================Left Part====================== -->
    <section class="col-lg-3 col-xl-3 reservation-Summary-Section">
      <section class="container active-season-container" *ngIf="displayActiveSeasons">
        <span class="active-Season">
          {{ activeSeasons }} {{ 'selectVaccines.reservations' | cxTranslate }}
        </span>
        <section class="row" *ngIf="orderHistory?.length > 4">
          <ng-select
            [items]="orderHistory"
            bindLabel="guid"
            bindValue="guid"
            [multiple]="false"
            [clearable]="false"
            [(ngModel)]="chosenReservation"
            (change)="changeReservation(null)"
            class="w-100 reservation-history-dropdown"
          >
          </ng-select>
        </section>

        <section class="row" *ngIf="orderHistory.length <= 4">
          <section class="col-12 col-xs-12 col-sm-12 col-lg-12 guid-Row">
            <ng-select
              [items]="orderHistory"
              bindLabel="guid"
              bindValue="guid"
              [multiple]="false"
              [clearable]="false"
              [(ngModel)]="chosenReservation"
              (change)="changeReservation(null)"
              class="w-100 reservation-history-dropdown"
            ></ng-select>
          </section>
        </section>
        <section class="row m-0">
          <span class="reserve-Now-Link c-link" (click)="reserveNow()">{{
            'selectVaccines.reserve_now' | cxTranslate
          }}</span>
        </section>
      </section>

      <section class="influenza-contact-section">
        <div class="influenza-contact-section__header">
          <section class="influenza-contact-section__title">
            {{ 'selectVaccines.influenzaContact' | cxTranslate }}
          </section>
          <section class="influenza-contact-section__edit" *ngIf="infContact">
            <span
              class="edit-option c-link"
              (click)="editContact()"
              *ngIf="editNotClicked"
            >
              {{ 'selectVaccines.edit' | cxTranslate }}
            </span>
            <fa-icon
              [icon]="faPenToSquare"
              *ngIf="editNotClicked"
              class="icon"
            ></fa-icon>
          </section>
        </div>
        <!--edit is not clicked-->
        <section
          class="infcontact-Container"
          *ngIf="editNotClicked && infContact"
        >
          <section class="inf-Contact-Desc">
            {{ 'selectVaccines.contactMsg' | cxTranslate }}
          </section>
          <section class="res-contact">
            <ul *ngFor="let con of infContact" class="no-Bullets">
              <li>
                <p class="displayCell">
                  {{ 'selectVaccines.name' | cxTranslate }}
                </p>
                <span class="align-Top displayCell" *ngIf="con.firstName"
                  >{{ con.firstName }} {{ con.lastName ? con.lastName : '' }}
                </span>
              </li>
              <li>
                <p>{{ 'selectVaccines.prefer_Contact' | cxTranslate }}</p>
                <a
                  href="{{ 'mailto:' + con?.email }}"
                  class="align-Top prefer-Value"
                  *ngIf="con.contactMethod == 'INT'"
                  ><span class="email-logo"></span>
                  <span class="c-link">{{ con.email }}</span></a
                >

                <span
                  class="align-Top prefer-Value"
                  *ngIf="con.contactMethod == 'FAX'"
                  >{{ con.fax }}</span
                >
              </li>
              <li>
                <p>{{ 'selectVaccines.prefer_Lang' | cxTranslate }}</p>
                <span class="align-Top">{{
                  con.preferredLanguage === 'EN'
                    ? ('selectVaccines.englishLang' | cxTranslate)
                    : ('selectVaccines.frenchLang' | cxTranslate)
                }}</span>
              </li>
            </ul>
          </section>
        </section>
        <section
          class="col-12 col-sm-8 col-md-12"
          *ngIf="editNotClicked && !infContact"
        >
          <button
            type="button"
            class="btn btn-default white-button"
            (click)="editContact()"
            [ngClass]="{ borderRed: reserveNowClicked }"
          >
            {{ 'selectVaccines.addContact' | cxTranslate }}
          </button>
          <span class="text-Contact" *ngIf="reserveNowClicked">{{
            'selectVaccines.add_Contact_Err' | cxTranslate
          }}</span>
        </section>
        <!--edit is clicked-->

        <section
          class="edit-form__container"
          *ngIf="!editNotClicked"
          [formGroup]="editForm"
        >
          <section class="">
            <label class="edit-labels">{{
              'selectVaccines.first_name' | cxTranslate
            }}</label>
          </section>
          <section class="c-text-input">
            <input
              class="form-control input-Field-Styling"
              type="text"
              onkeypress="return /[a-zA-Z0-9-' ]+('[a-zA-Z0-9-])?[-a-zA-Z0-9]*$/i.test(event.key)"
              formControlName="firstName"
              maxlength="255"
              [ngClass]="{
                requiredField:
                  editForm.get('firstName').invalid &&
                  (editForm.get('firstName').touched ||
                    editForm.get('firstName').dirty)
              }"
            />
            <span
              class="text-error"
              *ngIf="
                !editForm.get('firstName').value &&
                editForm.get('firstName').touched
              "
              >{{ 'selectVaccines.required_field' | cxTranslate }}</span
            >
            <span
              class="text-danger"
              *ngIf="
                editForm.get('firstName').value &&
                editForm.get('firstName').invalid &&
                (editForm.get('firstName').touched ||
                  editForm.get('firstName').dirty)
              "
              >{{ 'selectVaccines.invalidName' | cxTranslate }}</span
            >
          </section>
          <section class="">
            <label class="edit-labels">{{
              'selectVaccines.last_name' | cxTranslate
            }}</label>
          </section>
          <section class="c-text-input">
            <input
              class="form-control input-Field-Styling"
              type="text"
              onkeypress="return /[a-zA-Z0-9-' ]+('[a-zA-Z0-9-])?[-a-zA-Z0-9]*$/i.test(event.key)"
              formControlName="lastName"
              maxlength="255"
              [ngClass]="{
                requiredField:
                  editForm.get('lastName').invalid &&
                  (editForm.get('lastName').touched ||
                    editForm.get('lastName').dirty)
              }"
            />
            <span
              class="text-error"
              *ngIf="
                !editForm.get('lastName').value &&
                editForm.get('lastName').touched
              "
              >{{ 'selectVaccines.required_field' | cxTranslate }}</span
            >
            <span
              class="text-danger"
              *ngIf="
                editForm.get('lastName').value &&
                editForm.get('lastName').invalid &&
                (editForm.get('lastName').touched ||
                  editForm.get('lastName').dirty)
              "
              >{{ 'selectVaccines.invalidName' | cxTranslate }}</span
            >
          </section>
          <section class="">
            <h6 class="edit-labels">
              {{ 'selectVaccines.prefer_Contact' | cxTranslate }}
            </h6>
          </section>
          <section class="">
            <label for="email" class="edit-email edit-labels">{{
              'selectVaccines.email' | cxTranslate
            }}</label>
          </section>
          <section class="c-text-input edit-form__radio-container">
            <input
              class="col-3 col-sm-3 col-md-1 preferred-contact"
              type="radio"
              name="preferredContact"
              formControlName="preferredContact"
              value="INT"
              [checked]="emailChecked"
              (change)="onChange($event)"
            />
            <input
              class="form-control contact-Field input-Field-Styling"
              type="email"
              formControlName="emailId"
              maxlength="241"
              [ngClass]="{
                requiredField:
                  editForm.get('emailId').invalid &&
                  (editForm.get('emailId').touched ||
                    editForm.get('emailId').dirty)
              }"
            />
          </section>
          <span
            class="text-error"
            *ngIf="
              editForm.get('emailId').invalid &&
              (editForm.get('emailId').dirty ||
                editForm.get('emailId').touched) &&
              editForm.get('emailId').value
            "
          >
            {{ 'selectVaccines.validEmail_err' | cxTranslate }}
          </span>
          <span
            class="text-error"
            *ngIf="
              editForm.get('preferredContact').value == 'INT' &&
              !editForm.get('emailId').value &&
              editForm.get('emailId').touched
            "
          >
            {{ 'selectVaccines.required_field' | cxTranslate }}
          </span>
          <section class="">
            <label for="fax" class="edit-labels edit-email">{{
              'selectVaccines.fax' | cxTranslate
            }}</label>
          </section>
          <section class="c-text-input edit-form__radio-container">
            <input
              class="col-3 col-sm-3 col-md-1 preferred-contact"
              type="radio"
              name="preferredContact"
              formControlName="preferredContact"
              value="FAX"
              (change)="onChange($event)"
            />
            <input
              class="form-control contact-Field input-Field-Styling"
              appPhoneMask
              placeholder="xxx-xxx-xxxx"
              formControlName="faxNo"
              type="text"
              maxlength="12"
              onkeypress="return /[0-9-]/i.test(event.key)"
              [ngClass]="{
                requiredField:
                  editForm.get('faxNo').invalid &&
                  (editForm.get('faxNo').touched || editForm.get('faxNo').dirty)
              }"
            />
          </section>
          <span
            class="text-error"
            *ngIf="
              editForm.get('faxNo').invalid &&
              (editForm.get('faxNo').dirty || editForm.get('faxNo').touched) &&
              editForm.get('faxNo').value
            "
          >
            {{ 'selectVaccines.validFax_err' | cxTranslate }}
          </span>
          <span
            class="text-error"
            *ngIf="
              editForm.get('preferredContact').value == 'FAX' &&
              !editForm.get('faxNo').value &&
              editForm.get('faxNo').touched
            "
          >
            {{ 'selectVaccines.required_field' | cxTranslate }}
          </span>
          <section class="mt-5">
            <h6 class="edit-labels">
              {{ 'selectVaccines.prefer_Lang' | cxTranslate }}
            </h6>
          </section>
          <section class="c-text-input d-flex mb-5">
            <ng-select
              class="w-100"
              [items]="languages"
              bindLabel="language"
              bindValue="language"
              [multiple]="false"
              formControlName="language"
              [clearable]="false"
              placeholder="Select Language"
              [(ngModel)]="chosenLang"
            >
            </ng-select>
          </section>
          <section
            class="d-inline-flex mt-2 justify-content-between col-12 p-0"
          >
            <button
              (click)="onCancelClick()"
              class="c-button c-button--secondary"
            >
              {{ 'selectVaccines.cancel' | cxTranslate }}
            </button>
            <button (click)="onSave()" class="c-button c-button--primary">
              {{ 'selectVaccines.save' | cxTranslate }}
            </button>
          </section>
        </section>
        <!--form end-->
      </section>
    </section>
    <!-- ===================Middle Part====================== -->
    <section
      class="col-12 col-lg-9 reserve-Div-Charts"
      *ngIf="orderHistory?.length == 0"
    >
      <section class="footer-Container-Section" *ngIf="displayActiveSeasons">
        <div class="active-season__title">
          <span>{{ 'orderConfirmation.infVaccines' | cxTranslate }}</span>
        </div>
        <section
          class="d-flex active-season__reserve-container flex-column flex-sm-row gap-1 justify-content-between"
        >
          <section class="col-md-8 p-0">
            <section class="season-header">
              {{ activeSeasons }} {{ 'selectVaccines.season' | cxTranslate }}
            </section>
          </section>
          <section class="d-flex justify-content-sm-end col-12 p-0 col-sm-3">
            <button class="c-button c-button--primary" (click)="reserveNow()">
              {{ 'selectVaccines.reserve_now' | cxTranslate }}
            </button>
          </section>
        </section>

        <section
          class="d-flex noReserve-Middle-Section-Header justify-content-between align-items-center flex-column flex-sm-row gap-3 gap-lg-0"
        >
          <section [innerHTML]="fluParagraph"></section>
        </section>
      </section>
    </section>

    <section
      class="col-12 col-lg-9 reserve-Div-Charts"
      *ngIf="orderHistory?.length > 0"
    >
      <section class="reserve-Div__container" *ngIf="displayActiveSeasons">
        <section
          class="d-flex noReserve-Middle-Section-Header justify-content-between align-items-center flex-column flex-sm-row gap-3 gap-lg-0"
        >
          <section class="col-12 col-sm-6 p-0">
            <div
              class="d-flex gap-1 align-items-baseline flex-column flex-lg-row"
            >
              <div class="iphone-Center influenza-vaccine-reservation__id">
                <span>{{ 'selectVaccines.reservation' | cxTranslate }} </span>
                <span>{{ idofReservation }}</span>
              </div>
              <div class="iphone-Center influenza-vaccine-reservation__season">
                <span
                  >{{ activeSeasons }}
                  {{ 'selectVaccines.season' | cxTranslate }} |
                  {{ statusDisplay }}</span
                >
              </div>
            </div>
          </section>
          <section
            class="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-4 iphone-Center p-0 d-flex justify-content-lg-end"
          >
            <button
              class="c-button c-button--primary"
              (click)="viewReservation()"
            >
              {{ 'orderConfirmation.viewResDetails' | cxTranslate }}
            </button>
          </section>
        </section>
      </section>

      <app-flu-charts *ngIf="displayActiveSeasons"></app-flu-charts>
      <!-- ==============================Footer Part============================ -->

      <section class="col-lg-12 col-xl-12 footer-Container">
        <app-influenza-dashbaord-footer
          [reserveNowClicked]="reserveNowClicked"
          [displayActiveSeasons]="displayActiveSeasons"
          (newItemEvent)="proceedToCategory($event)"
        ></app-influenza-dashbaord-footer>
      </section>
    </section>
  </section>
</section>
