import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AsmService } from '@spartacus/asm/core';
import { AsmConfig, CustomerSearchPage } from '@spartacus/asm/root';
import { Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/services/shared.service';
import { VscaAsmComponentService } from '../../service/vsca-asm-component.service';
import { User } from '../vsca-customer-selection/user.model';
@Component({
  selector: 'vsca-cx-customer-selection',
  templateUrl: './vsca-customer-selection.component.html',
  styleUrls: ['./vsca-customer-selection.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '(document:click)': 'onDocumentClick($event)',
  },
})
export class VscaCustomerSelectionComponent implements OnInit, OnDestroy {
  customerSelectionForm: FormGroup;
  private subscription = new Subscription();
  searchResultsLoading$: Observable<boolean>;
  searchResults: Observable<CustomerSearchPage>;
  selectedCustomer: User;
  accountId;
  isAccountdId = false;
  isAccountSearch = false;
  @Output()
  submitEvent = new EventEmitter<{ customerId: string }>();

  @ViewChild('resultList') resultList: ElementRef;
  @ViewChild('searchTerm') searchTerm: ElementRef;

  constructor(
    private fb: FormBuilder,
    private asmService: AsmService,
    private config: AsmConfig,
    private sharedService: SharedService,
    private readonly activatedRoute: ActivatedRoute,
    protected asmComponentService: VscaAsmComponentService,

  ) { }

  ngOnInit(): void {
    this.customerSelectionForm = this.fb.group({
      searchTerm: ['', Validators.required],
    });
    this.asmService.customerSearchReset();
    this.searchResultsLoading$ =
      this.asmService.getCustomerSearchResultsLoading();
    this.searchResults = this.asmService.getCustomerSearchResults();

    this.subscription.add(
      this.customerSelectionForm.controls.searchTerm.valueChanges
        .pipe(debounceTime(300))
        .subscribe((searchTermValue) => {
          this.handleSearchTerm(searchTermValue);
        })
    );
    this.activatedRoute.queryParams.subscribe((res) => {
      if (res && res?.accountId) {
        this.asmComponentService.logoutCustomer();
        this.isAccountdId = true;
        this.isAccountSearch = true;
        this.asmService.customerSearch({
          query: res?.accountId,
          pageSize: this.config.asm.customerSearch.maxResults,
        });
        this.searchResultsLoading$ = null;
        this.searchResults = null;
        this.asmService.getCustomerSearchResults().subscribe((res1) => {
          if (res1 && this.isAccountSearch) {
            this.isAccountSearch = false;
            this.submitEvent.emit({ customerId: res1?.entries[0]?.customerId });
          }
        });
      }
    });
  }

  private handleSearchTerm(searchTermValue: string) {
    if (
      Boolean(this.selectedCustomer) &&
      searchTermValue !== this.selectedCustomer.name
    ) {
      this.selectedCustomer = undefined;
    }
    if (Boolean(this.selectedCustomer)) {
      return;
    }
    this.asmService.customerSearchReset();
    if (searchTermValue.trim().length >= 10) {
      this.isAccountSearch = false;
      this.asmService.customerSearch({
        query: searchTermValue,
        pageSize: this.config.asm.customerSearch.maxResults,
      });
      this.searchResultsLoading$ =
        this.asmService.getCustomerSearchResultsLoading();
      this.searchResults = this.asmService.getCustomerSearchResults();
    }
  }

  selectCustomerFromList(customer: User) {
    this.selectedCustomer = customer;
    this.customerSelectionForm.controls.searchTerm.setValue(
      this.selectedCustomer.name
    );
    this.asmService.customerSearchReset();
  }

  onSubmit(): void {
    if (this.customerSelectionForm.valid && Boolean(this.selectedCustomer)) {
      if (this.selectedCustomer.role === "Unknown" || this.selectedCustomer.role === "Inconnu") {
        localStorage.setItem('user_function', "NA");
      } else {
        localStorage.setItem('user_function', this.selectedCustomer.role);
      }
      localStorage.setItem('user_login_id', this.selectedCustomer.userName);
      if (localStorage.getItem('ssoEnabled') || localStorage.getItem('asm_enabled')) {
        localStorage.setItem('user_type', "sales_rep");
      } else {
        localStorage.setItem('user_type', "customer");
      }
      localStorage.setItem('user_id', this.selectedCustomer.customerId);
      localStorage.setItem('user_account_id', this.selectedCustomer.orgUnit.uid);
      this.submitEvent.emit({ customerId: this.selectedCustomer.customerId });
    } else {
      this.customerSelectionForm.markAllAsTouched();
    }
  }

  onDocumentClick(event) {
    if (Boolean(this.resultList)) {
      if (
        this.resultList.nativeElement.contains(event.target) ||
        this.searchTerm.nativeElement.contains(event.target)
      ) {
        return;
      } else {
        this.asmService.customerSearchReset();
      }
    }
  }

  closeResults() {
    this.asmService.customerSearchReset();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.asmService.customerSearchReset();
  }
}
