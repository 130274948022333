import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { CmsService, OccEndpointsService } from '@spartacus/core';
@Component({
  selector: 'app-news-updates',
  templateUrl: './news-updates.component.html',
  styleUrls: ['./news-updates.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewsUpdatesComponent implements OnInit {
  cmsResponse: any = '';
  news: any;
  constructor(
    private readonly cmsData: CmsService,
    private readonly occEndPoint: OccEndpointsService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.cmsData.getComponentData('NewsAndUpdateComponent').subscribe((res) => {
      this.cmsResponse = res;
      this.news = this.cmsResponse.content;
      this.cdr.detectChanges();
    });
  }
}
