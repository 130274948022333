import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FacetService,
  ProductListComponentService,
} from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { LoactionStripService } from '../../shared/modules/location-strip/loaction-strip.service';
import { CustomProdPgService } from '../custom-prod-pg.service';

@Component({
  selector: 'app-custom-product-page',
  templateUrl: './custom-product-page.component.html',
})
export class CustomProductPageComponent implements OnInit, OnDestroy {
  public productList$ = this.productListService.model$;
  pagination: any;
  currentPage = 1;
  facets$: any;
  pageRange: any;
  productLotSize: number;
  pagesize: number;
  userDetails: any;
  selectedData: any;
  flagBivvCatalog: any;
  listSort: any;
  prodListSub: Subscription;
  facetListSub: Subscription;
  usedDataSub: Subscription;
  constructor(
    private readonly productListService: ProductListComponentService,
    private readonly locService: LoactionStripService,
    private readonly user: UserAccountFacade,
    private readonly plpService: CustomProdPgService,
    private readonly facet: FacetService,
  ) {}

  ngOnInit(): void {
    this.locService.userAccountData.subscribe((result) => {
      if (result) {
        this.flagBivvCatalog = result.res?.defaultB2BUnit?.isCurrentCatalogBivv;
      }
    });
    this.prodListSub = this.productList$.subscribe((res) => {
      this.pagesize = res.pagination.pageSize;
      this.productLotSize = res.products.length;
      this.pagination = res.pagination;
      this.listSort = res.sorts;
      if (this.flagBivvCatalog) {
        this.selectedData = res?.sorts?.find(
          (item) => item.code === 'topRated'
        ).name;
      } else {
        this.selectedData = res?.sorts?.find(
          (item) => item.code === 'name-asc'
        )?.name;
        this.listSort = res.sorts.filter((item) => item.code != 'topRated');
      }

      this.performPagination(this.pagination.currentPage);
    });

    this.facetListSub = this.facet.facetList$.subscribe((res) => {
      this.facets$ = res.activeFacets;
    });
    this.usedDataSub = this.user.get().subscribe((res) => {
      this.userDetails = res;
      if (this.userDetails && this.userDetails !== null) {
        this.plpService.getCarts(this.userDetails?.userName);
      }
    });
  }
  sortList(event): void {
    this.productListService.sort(event);
  }
  performPagination(data): void {
    const event = Number(data);
    this.currentPage = event + 1;
    const firstRange = event * this.pagesize + 1;
    const secondRange = (event + 1) * this.pagesize;
    const previousValue = this.pagination.totalResults - event * this.pagesize;
    if (previousValue < this.pagesize) {
      this.pageRange = `${firstRange}-${previousValue + event * this.pagesize}`;
    } else {
      this.pageRange = `${firstRange}-${secondRange}`;
    }
  }
  ngOnDestroy() {
    this.prodListSub?.unsubscribe();
    this.facetListSub?.unsubscribe();
    this.usedDataSub?.unsubscribe();
  }
}
