import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { translationChunksConfig } from '@spartacus/assets';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { FluDashboardBannerModule } from '../shared/modules/flu-dashboard-banner/flu-dashboard-banner.module';
import { InvoicesComponent } from './invoices/invoices.component';
import { PaymentConfirmationComponent } from './payment-confirmation/payment-confirmation.component';
import { PaymentDetailsComponent } from './payment-details/payment-details.component';
import { PaymentHeaderComponent } from './payment-header/payment-header.component';
import { PaymentReviewComponent } from './payment-review/payment-review.component';

@NgModule({
  declarations: [
    InvoicesComponent,
    PaymentDetailsComponent,
    PaymentHeaderComponent,
    PaymentReviewComponent,
    PaymentConfirmationComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    NgbModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    FluDashboardBannerModule,
    FontAwesomeModule,
    ConfigModule.withConfig({
      cmsComponents: {
        AccountInvoiceComponent: {
          component: InvoicesComponent,
        },
        OpenInvoicePaymentReviewComponent: {
          component: PaymentReviewComponent,
        },
        AccountOpenInvoicePaymentComponent: {
          component: PaymentDetailsComponent,
        },
        OpenInvoicePaymentConfirmationComponent: {
          component: PaymentConfirmationComponent,
        },
      },
      i18n: {
        backend: { loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json' },
      },
      chunks: translationChunksConfig,
    } as CmsConfig),
  ],
})
export class CustomMakeAPaymentModule {}
