import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AsmUi } from '@spartacus/asm/root';
import { AsmService } from '@spartacus/asm/core';
import {
  GlobalMessageService,
  GlobalMessageType,
  User
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoactionStripService } from '../../../shared/modules/location-strip/loaction-strip.service';
import { VscaCsAgentAuthService } from '../../facade/vsca-csagent-auth.service';
import { VscaAsmComponentService } from '../../service/vsca-asm-component.service';

@Component({
  selector: 'vsca-cx-customer-emulation',
  templateUrl: './vsca-customer-emulation.component.html',
  styleUrls: ['./vsca-customer-emulation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VscaCustomerEmulationComponent implements OnInit, OnDestroy {
  customer: User;
  isCustomerEmulationSessionInProgress$: Observable<boolean>;
  private subscription = new Subscription();
  isCollapsed$: Observable<boolean>;
  protected startingCustomerSession = false;
  constructor(
    protected asmComponentService: VscaAsmComponentService,
    protected userAccountFacade: UserAccountFacade,
    private asmService: AsmService,
    private csAgentAuthService: VscaCsAgentAuthService,
    private globalMessageService: GlobalMessageService,
    private locService:LoactionStripService
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.userAccountFacade.get().subscribe((user) => (this.customer = user))
    );
    this.isCustomerEmulationSessionInProgress$ =
      this.asmComponentService.isCustomerEmulationSessionInProgress();

    this.isCollapsed$ = this.asmService
      .getAsmUiState()
      .pipe(
        map((uiState: AsmUi) =>
          uiState.collapsed === undefined ? false : uiState.collapsed
        )
      );
  }

  logoutCustomer() {
    this.locService.disableLocStrip(true);
    this.asmComponentService.logoutCustomer();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  startCustomerEmulationSession({ customerId }: { customerId?: string }): void {
    if (customerId) {
      this.csAgentAuthService.startCustomerEmulationSession(customerId);
      this.startingCustomerSession = true;
    } else {
      this.globalMessageService.add(
        { key: 'asm.error.noCustomerId' },
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }
  }
}
