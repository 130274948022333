<div class="custom-product-card">
  <div class="custom-card-header">
    <section class="text-center">
      <a
        *ngIf="productInfo?.images; else noImage"
        [routerLink]="['/product', productInfo.code, productInfo.code]"
      >
        <cx-media
          class="cx-product-image"
          [container]="productInfo.images?.PRIMARY"
          format="product"
          [alt]="productInfo.summary"
        ></cx-media>
      </a>
      <ng-template #noImage>
        <a [routerLink]="['/product', productInfo.code, productInfo.code]">
          <img
            class="image-set-in-card product-img"
            [src]="fallbackImage"
            alt=""
          />
        </a>
      </ng-template>
    </section>

    <a
      class="c-linked-heading"
      [routerLink]="['/product', productInfo.code, productInfo.code]"
    >
      <h3 class="c-heading c-heading-h3 my-2">{{ productInfo.name }}</h3>
    </a>
  </div>

  <section class="custom-card-body" *ngIf="!iconDisplay">
    <p
      class="custom-tab-p display-type text-fixed-medium"
      [innerHTML]="
        sanitizer.bypassSecurityTrustHtml(description | slice : 0 : 300)
      "
    ></p>
    <a
      class="show-more display-type"
      *ngIf="description?.length > 300"
      [routerLink]="['/product', productInfo.code, productInfo.code]"
      >...Show More</a
    >
  </section>

  <section class="custom-card-body" *ngIf="iconDisplay">
    <section class="info-tabs">
      <app-my-tabs>
        <app-my-tab
          *ngIf="productInfo?.batchInfo?.batchNumber"
          tabIcon="icn-batchexpiry.svg"
        >
          <div class="py-4">
            <p class="custom-tab-title">
              {{ 'plp.batchNumber' | cxTranslate }}
            </p>
            <p class="batchinfo-p">{{ productInfo?.batchInfo?.batchNumber }}</p>
            <p class="custom-tab-title">{{ 'plp.expiryDate' | cxTranslate }}</p>
            <p class="batchinfo-p mb-0">
              {{ productInfo?.batchInfo?.expiryDate }}
            </p>
            <!-- <p class="custom-tab-p">{{ "plp.batchInfo" | cxTranslate }}</p> -->
          </div>
        </app-my-tab>
        <app-my-tab tabIcon="info.png" class="product-detail">
          <div class="pt-4 mb-4 mb-md-0 details-text">
            <p class="custom-tab-title">{{ 'plp.details' | cxTranslate }}</p>
            <div
              class="custom-tab-p display-type text-fixed-medium text"
              *ngIf="description != null"
              [innerHTML]="
                sanitizer.bypassSecurityTrustHtml(description | slice : 0 : 50)
              "
            ></div>
            <a
              class="c-link"
              *ngIf="description?.length > 300"
              [routerLink]="['/product', productInfo.code, productInfo.code]"
              >...Show More</a
            >
          </div>
        </app-my-tab>

        <app-my-tab
          *ngIf="
            productInfo?.volumePrices?.length > 0 && userDetails !== undefined
          "
          tabIcon="../../assets/icons/price-master.svg"
        >
          <div class="padding-0">
            <section class="prodtabs-cont prod-promotab active">
              <p class="custom-tab-title">
                {{ 'plp.volumeDiscounts' | cxTranslate }}
              </p>
              <section class="discount-list">
                <table
                  aria-describedby="cartTable"
                  class="c-table c-table--branded"
                >
                  <tbody>
                    <tr
                      *ngFor="
                        let prices of productInfo?.volumePrices;
                        let i = index
                      "
                    >
                      <td>
                        {{ prices?.minQuantity }} - {{ prices?.maxQuantity }}
                        {{ 'plp.units' | cxTranslate }}:
                      </td>

                      <td>
                        {{ prices?.value | currency }}
                        <span
                          *ngIf="i > 0"
                          class="text-success text-fixed-legal text-uppercase"
                          >{{ 'plp.volumePricesSave' | cxTranslate }}</span
                        >
                        <br *ngIf="i > 0" />
                        <span class="font-bold">{{
                          productInfo?.volumePrices[0].value -
                            productInfo?.volumePrices[i].value | currency
                        }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </section>
          </div>
        </app-my-tab>

        <app-my-tab *ngIf="monographLink" tabIcon="excel-sample.png">
          <div class="padding-0">
            <p class="custom-tab-title">{{ 'plp.monograph' | cxTranslate }}</p>
            <p class="custom-tab-p">
              <img class="pdf-icon" src="../../assets/icons/pdf.png" alt="" />
              &nbsp;<a
                alt=""
                [href]="monographLink"
                target="_blank"
                class="c-link"
                >{{ 'plp.monograph' | cxTranslate }}</a
              >
            </p>
          </div>
        </app-my-tab>

        <app-my-tab *ngIf="productInfo?.productUpdates" tabIcon="warning.png">
          <div class="padding-0">
            <p class="custom-tab-title">
              {{ 'plp.productUpdate' | cxTranslate }}
            </p>
            <p class="custom-tab-p productupdate-p text-fixed-medium">
              {{ productInfo?.productUpdates | slice : 0 : 300 }}
            </p>
            <a
              class="show-more display-type"
              *ngIf="productInfo?.productUpdates?.length > 300"
              [routerLink]="['/product', productInfo.code, productInfo.code]"
              >...Show More</a
            >
          </div>
        </app-my-tab>
      </app-my-tabs>
    </section>
  </section>

  <div class="custom-card-footer">
    <p class="text-fixed-legal-copy u-border-bottom-grey mb-1">
      {{ 'plp.productNumber' | cxTranslate }}: {{ productInfo?.code }}
    </p>
    <div *ngIf="userDetails !== undefined">
      <div>
        <div *ngIf="!productInfo.flu">
          <!-- Actual Potency -->
          <h4 class="my-1 d-block text-xl" *ngIf="productInfo?.actualPotency">
            {{ 'plp.actualPotency' | cxTranslate }}:
            <span class="font-bold"> {{ productInfo?.actualPotency }}</span>
          </h4>
          <!-- IU Price -->
          <div
            class="justify-content-sm-around"
            *ngIf="productInfo?.actualPotency"
          >
            <h4 class="d-block text-xl">
              {{ 'plp.iuPrice' | cxTranslate }}:
              <span
                *ngIf="
                  productInfo?.iuPrice == undefined ||
                  productInfo?.iuPrice?.value === undefined
                "
                >-</span
              >
              <span
                class="font-bold"
                *ngIf="
                  productInfo?.iuPrice &&
                  productInfo?.iuPrice?.value !== undefined
                "
              >
                {{ productInfo?.iuPrice?.value | currency }}
              </span>
            </h4>
          </div>

          <!-- Unit price -->
          <div
            class="d-flex justify-content-between flex-wrap-reverse mt-4 mb-2"
          >
            <div class="my-2">
              <h4
                class="d-block"
                *ngIf="productInfo?.actualPotency; else notActualPotency"
              >
                {{ 'plp.unitPrice' | cxTranslate }}
              </h4>
              <ng-template #notActualPotency>
                <h4 class="d-block">
                  {{ 'plp.yourPrice' | cxTranslate }}
                </h4>
              </ng-template>
              <h4 class="font-bold">
                <span
                  *ngIf="
                    productInfo?.price == undefined ||
                    productInfo?.price?.value === undefined
                  "
                  >-</span
                >
                <span
                  *ngIf="
                    productInfo?.price &&
                    productInfo?.price?.value !== undefined
                  "
                >
                  {{ productInfo?.price?.value | currency }}
                </span>
              </h4>
            </div>

            <div
              class="units"
              *ngIf="!productInfo?.discontinued && productInfo?.available"
            >
              <p class="mb-2 text-center font-weight-bold">
                {{ 'plp.units' | cxTranslate }}
              </p>
              <div
                class="unit-area d-flex flex-nowrap align-items-center justify-content-center"
              >
                <button
                  id="{{ 'minusIcon' + productInfo.code }}"
                  class="c-count-change-btn disabled"
                  aria-hidden="true"
                  (click)="
                    performQuantityComputation(
                      2,
                      productInfo.code,
                      productInfo.quantityPerUnit
                    )
                  "
                >
                  <i class="fa fa-minus" aria-hidden="true"></i>
                </button>
                <input
                  type="number"
                  class="prod-quantity text-center"
                  value="1"
                  maxlength="4"
                  (keyup)="
                    performQuantityComputation(
                      null,
                      productInfo.code,
                      productInfo.quantityPerUnit
                    )
                  "
                  onkeypress="return /[0-9a-zA-Z]/i.test(event.key)"
                  id="{{ 'prodQuantity' + productInfo.code }}"
                />
                <button
                  id="{{ 'plusIcon' + productInfo.code }}"
                  class="c-count-change-btn"
                  aria-hidden="true"
                  (click)="
                    performQuantityComputation(
                      1,
                      productInfo.code,
                      productInfo.quantityPerUnit
                    )
                  "
                >
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>

          <section *ngIf="!productInfo?.discontinued && productInfo?.available">
            <section *ngIf="productInfo.sapAllocation">
              <b class="prod-allocation">{{
                'plp.productAllocation' | cxTranslate
              }}</b>
              <p class="allocation-p text-fixed-legal-copy">
                {{ 'plp.allocationDetails' | cxTranslate }}
              </p>
            </section>
          </section>
        </div>

        <div *ngIf="productInfo?.discontinued || !productInfo?.available">
          <div *ngIf="productInfo?.discontinued && productInfo?.available">
            <p class="product-discontinued-txt1">
              {{ 'plp.discontinued' | cxTranslate }}
            </p>
          </div>
          <div *ngIf="productInfo?.discontinued && !productInfo?.available">
            <p class="product-discontinued-txt1">
              {{ 'plp.discontinued' | cxTranslate }}
            </p>
          </div>
          <div
            *ngIf="!productInfo.discontinued && !productInfo?.available"
            class="m0"
          >
            <b class="prod-allocation" *ngIf="productInfo?.availabilityText">{{
              productInfo?.availabilityText
            }}</b>
            <b class="prod-allocation" *ngIf="!productInfo?.availabilityText">{{
              'plp.productUnavailable' | cxTranslate
            }}</b>
          </div>
        </div>

        <section *ngIf="productInfo.flu">
          <section>
            <p
              *ngIf="
                productInfo.flu &&
                productInfo.available &&
                !productInfo.discontinued
              "
              class="contact-info m0"
            >
              {{ 'plp.productAllocationInfo1' | cxTranslate
              }}<b> {{ 'plp.emergencycontact' | cxTranslate }}</b
              >{{ 'plp.productAllocationInfo2' | cxTranslate }}
            </p>
          </section>
        </section>
        <div
          class="custom-card-cta"
          *ngIf="
            !productInfo?.discontinued &&
            productInfo?.available &&
            !productInfo.sapAllocation
          "
        >
          <span
            *ngIf="
              productInfo?.stockAvailableStatus == 'LIMITED' &&
              productInfo?.allowLimitedStockProducts !== true
            "
            class="c-text color-error"
            disabled
          >
            {{ 'plp.unavailableToOrderText' | cxTranslate }}
          </span>

          <button
            type="button"
            class="c-button c-button--primary"
            *ngIf="
              !productInfo?.discontinued &&
              productInfo?.available &&
              productInfo?.price?.value != undefined &&
              productInfo?.price?.value != 0 &&
              productInfo?.price?.value != 0.0 &&
              (productInfo?.stockAvailableStatus !== 'LIMITED' ||
                (productInfo?.stockAvailableStatus == 'LIMITED' &&
                  productInfo?.allowLimitedStockProducts == true))
            "
            [ngbPopover]="popTitleSuccess"
            placement="bottom"
            triggers="manual"
            #p2="ngbPopover"
            popoverClass="savesuccess-msg addToCart"
            container="body"
            placement="bottom"
            [closeDelay]="500"
            (click)="addToCart(productInfo?.code, p2)"
            [disabled]="
              productInfo?.stockAvailableStatus == 'LIMITED' &&
              productInfo?.allowLimitedStockProducts !== true
            "
          >
            <i
              *ngIf="buttonName === 'Reserve now'"
              class="fa fa-calendar"
              aria-hidden="true"
            ></i>
            <span *ngIf="buttonName === 'Add to cart'">{{
              'addToCartItems.addCart' | cxTranslate
            }}</span>
            <div
              *ngIf="
                buttonName === 'Add to cart' &&
                productInfo?.stockAvailableStatus == 'LIMITED'
              "
              style="
                background-color: transparent;
                width: 100%;
                height: 100%;
                position: absolute;
              "
              (mouseenter)="p2.open()"
              [ngbPopover]="popOverLimited"
              #p2="ngbPopover"
              placement="top"
              (mouseleave)="p2.close()"
            ></div>
            <span *ngIf="buttonName === 'Login to order'">{{
              'addToCartItems.loginToOrder' | cxTranslate
            }}</span>
            <span *ngIf="buttonName === 'Reserve now'">{{
              'addToCartItems.reserveNow' | cxTranslate
            }}</span>
          </button>
          <ng-template #popTitleSuccess>
            <div class="cart-value-added">
              <img
                src="../../../assets/icons/confirmation-positive-green.png"
                class="positive-green-icon"
                alt="success"
              />
              <span
                class="confirmation-itemsadded"
                *ngIf="!flagRemoved; else NA"
                >{{ itemAddedQuantity }}
                {{ 'addToCartItems.addEntrySuccess' | cxTranslate }}</span
              >
              <ng-template #NA>
                <span class="confirmation-itemsadded"
                  >{{ itemAddedQuantity }}
                  {{ 'addToCartItems.updateEntrySuccess' | cxTranslate }}</span
                >
              </ng-template>
            </div>
          </ng-template>

          <button
            *ngIf="
              (!productInfo?.price ||
                productInfo?.price?.value == undefined ||
                productInfo?.price?.value == 0 ||
                productInfo?.price?.value == 0.0) &&
              !(
                productInfo?.stockAvailableStatus == 'LIMITED' &&
                productInfo?.allowLimitedStockProducts !== true
              )
            "
            class="c-button c-button--primary"
            disabled
          >
            {{ 'plp.unavailableToOrder' | cxTranslate }}
          </button>
          <ng-template #popOverLimited>
            <div class="c-popover-black">
              <p *ngIf="productInfo?.stockAvailableStatus == 'LOW'">
                Low stock<br /><span>Stock is Limited</span>
              </p>
              <p *ngIf="productInfo?.stockAvailableStatus == 'NORMAL'">
                Normal stock<br /><span>Stock is available</span>
              </p>
              <p
                *ngIf="
                  productInfo?.stockAvailableStatus == 'LIMITED' &&
                  productInfo?.allowLimitedStockProducts !== true
                "
              >
                Stock Unavailable<br /><span>Please call Customer Support</span>
              </p>
              <p
                *ngIf="
                  productInfo?.stockAvailableStatus == 'LIMITED' &&
                  productInfo?.allowLimitedStockProducts == true
                "
              >
                Stock is very Limited <br />
              </p>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <section *ngIf="userDetails == undefined">
    <button
      type="button"
      class="c-button c-button--primary w-100"
      (click)="goToLogin()"
    >
      <span *ngIf="buttonName === 'Add to cart'">{{
        'addToCartItems.addCart' | cxTranslate
      }}</span>
      <span *ngIf="buttonName === 'Login to order'">{{
        'addToCartItems.loginToOrder' | cxTranslate
      }}</span>
      <span *ngIf="buttonName === 'Reserve now'">{{
        'addToCartItems.reserveNow' | cxTranslate
      }}</span>
    </button>
  </section>
</div>
