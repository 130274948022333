import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { CustomProfileAndPreferencesService } from 'src/app/custom-profile-and-preferences/custom-profile-and-preferences.service';
import { LoactionStripService } from './loaction-strip.service';

@Component({
  selector: 'app-location-strip',
  templateUrl: './location-strip.component.html',
})
export class LocationStripComponent implements OnInit {
  usergetData: any;
  usergetDataObj: any;
  userAccountgetData: any;
  showLocStrip = false;
  isCustomerSupportAgentLoggedIn = false;
  isCustomerEmulationSessionInProgress = false;
  constructor(
    private readonly locService: LoactionStripService,
    private readonly user: UserAccountFacade,
    private readonly ref: ChangeDetectorRef,
    private readonly pfService: CustomProfileAndPreferencesService
  ) {}

  getUserAccountData(userId): void {
    this.locService.getUserAccountDataFromDb(userId);
    this.locService.userAccountData.subscribe((userData) => {
      if (
        userData.res != null &&
        userData.res != undefined &&
        userData.res?.length != 0
      ) {
        this.usergetData = userData.res.defaultB2BUnit.shippingAddress;
        this.usergetDataObj = userData.res.defaultB2BUnit;
        this.ref.detectChanges();
      }
    });
  }

  ngOnInit() {
    this.user.get().subscribe((res) => {
      if (res != null && res != undefined) {
        this.userAccountgetData = res;
        this.showLocStrip = true;
        this.getUserAccountData(this.userAccountgetData.userName);
      } else {
        this.showLocStrip = false;
      }
    });
    this.locService.endSession$.subscribe((res) => {
      if (res) {
        this.showLocStrip = !res;
        this.locService.endSession.next(false);
      }
    });
  }
}
