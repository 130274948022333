<section class="statement-dialog" *ngIf="showPopUp" [ngClass]="{
    'visible-ui': toShow === true,
    'invisible-ui': toShow === false
  }">
  <div class="modal-header">
    <div class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <i class="fa-solid fa-x c-icon-primary c-icon-primary-sm c-icon-round-hover mt-4"></i>
    </div>
  </div>
  <div class="modal-body" [ngClass]="{
      'visible-ui': toShow === true,
      'invisible-ui': toShow === false
    }">
    <section class="makePaymentView" id="productList-areax" *ngIf="!!allStatementData">
      <section class="row row-20" id="leftCont-rightNav">
        <section class="col-xs-12 col-sm-12 col-md-12 spl">
          <section class="row">
            <section class="logo-account statement-logo">
              <img src="../../assets/img/sanofi-vaccineshoppe-canada.png" alt="vaccine_shop_canada" />
            </section>

            <section class="float-left col-md-12 spl-add">
              <button *ngIf="!isPDF" (click)="onPrint()" class="c-button c-button--secondary printStatementBtn">
                <fa-icon [icon]="faPrint" class="icon"></fa-icon>
                {{ "statementDisplay.print_txt" | cxTranslate }}
              </button>
              <span class="col-xs-12 col-sm-7 col-md-7 mb1 px0">
                <ng-container *ngIf="salesOrg$ | async as salesOrg">
                  <ng-container *ngIf="salesOrg !='CA04'">
                    {{ "statementDisplay.sanofi_address_line1" | cxTranslate}}
                  </ng-container>
                  <ng-container *ngIf="salesOrg =='CA04'">
                    {{ "statementDisplay.sanofi_address_line1_synv" | cxTranslate}}
                  </ng-container>
                </ng-container>
                <br />
                {{ "statementDisplay.sanofi_address_line2" | cxTranslate }}
                <br />
                <ng-container *ngIf="salesOrg$ | async as salesOrg">
                  <ng-container *ngIf="salesOrg !='CA04'">
                    {{ "statementDisplay.sanofi_address_line3" | cxTranslate}}
                  </ng-container>
                  <ng-container *ngIf="salesOrg =='CA04'">
                    {{ "statementDisplay.sanofi_address_line3_synv" | cxTranslate}}
                  </ng-container>
                </ng-container>
                <br />
                {{ "statementDisplay.sanofi_address_line4" | cxTranslate }}
                <br />
                {{
                "statementDisplay.sanofi_address_line5" | cxTranslate
                }}</span>
              <span>&nbsp;</span>
              <section class="
                  col-xs-12 col-sm-6 col-md-6
                  spl-accno
                  px0
                  account-section
                ">
                <span class="col-xs-12 col-sm-8 col-md-8 px0">{{
                  "statementDisplay.account_number_label" | cxTranslate
                  }}</span>
                <span class="col-xs-12 col-sm-4 col-md-4 px0 account-number-align">{{ accountNumber }}</span>
                <span>&nbsp;</span>
              </section>
              <section class="
                  col-xs-12 col-sm-6 col-md-6
                  spl-accno
                  px0
                  statement-section
                ">
                <span class="col-xs-12 col-sm-9 col-md-9 px0">{{
                  "statementDisplay.statement_date_label" | cxTranslate
                  }}</span>
                <span class="col-xs-12 col-sm-3 col-md-3 px0 account-number-align">
                  {{ statement.toDte | date: "dd/MM/yyyy" }}</span>
              </section>
            </section>
          </section>
          <section class="acc-order-details">
            <!--table starts-->
            <section class="row">
              <section class="col-md-12">
                <section id="example2_wrapper" class="dataTables_wrapper no-footer">
                  <section class="payment-section">
                    <table aria-describedby="creditsTable" id="applyCreditsTable" class="c-table c-table--branded credits-table" [ngClass]="{ 'pdf-view': isPDF === true }">
                      <thead>
                        <tr>
                          <th scope="col">
                            {{
                            "statementDisplay.document_date_label"
                            | cxTranslate
                            }}&nbsp;
                          </th>

                          <th scope="col">
                            {{
                            "statementDisplay.due_date_label" | cxTranslate
                            }}
                          </th>
                          <th scope="col">
                            {{
                            "statementDisplay.trans_type_label" | cxTranslate
                            }}
                          </th>
                          <th scope="col">
                            {{
                            "statementDisplay.document_number_label"
                            | cxTranslate
                            }}
                          </th>
                          <th scope="col">
                            {{
                            "statementDisplay.original_amount_label"
                            | cxTranslate
                            }}
                          </th>
                          <th scope="col">
                            {{
                            "statementDisplay.amount_due_label" | cxTranslate
                            }}
                          </th>
                          <th scope="col">
                            {{
                            "statementDisplay.currency_label" | cxTranslate
                            }}
                          </th>
                          <th scope="col">
                            {{
                            "statementDisplay.terms_of_payment_label"
                            | cxTranslate
                            }}
                          </th>
                        </tr>
                      </thead>
                      <tbody *ngIf="!!allStatementData">
                        <tr *ngIf="
                            allStatementData?.statementTableData.length === 0
                          ">
                          <td colspan="8">
                            {{ "statementDisplay.no_data" | cxTranslate }}
                          </td>
                        </tr>
                        <tr *ngFor="
                            let stData of allStatementData?.statementTableData;
                            let i = index
                          " class="table-row-section">
                          <td>
                            <span>{{ stData?.documentDate }}</span>
                          </td>
                          <td>
                            <span>{{ stData?.dueDate }}</span>
                          </td>
                          <td>
                            <span>{{ stData?.transType }}</span>
                          </td>
                          <td>
                            <a *ngIf="
                                  stData?.docType === 'RV' ||
                                  stData?.docType === 'RD' ||
                                  stData?.docType === 'RF' ||
                                  (stData?.docType === 'DZ' &&
                                  stData?.documentNumber?.startsWith('7'));
                                else noLink"
                               class="" (click)="generateInvoicePDF(stData.documentNumber)">
                              <span>{{ stData?.documentNumber }}</span>
                            </a>
                            <ng-template #noLink>
                              <span>{{
                                stData?.documentNumber
                                }}</span>
                            </ng-template>
                          </td>
                          <td>
                            <span><span *ngIf="checkNegativeValue(stData)">(</span>{{ stData?.originalAmount | number:
                              "1.2-2"
                              }}<span *ngIf="checkNegativeValue(stData)">)</span></span>
                          </td>
                          <td>
                            <span><span *ngIf="checkNegativeValue(stData)">(</span>{{ stData?.amountDue | number:
                              "1.2-2"
                              }}<span *ngIf="checkNegativeValue(stData)">)</span></span>
                          </td>
                          <td>
                            <span>{{ stData?.currency }}</span>
                          </td>
                          <td>
                            <span>{{ stData?.termsOfPayment }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table aria-describedby="creditsTable" id="applyStatementdisplayTable"
                      class="credits-table table table-striped no-print" [ngClass]="{ 'pdf-view': isPDF === true }"
                      *ngIf="!isPDF">
                      <tr class="sample-header">
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </tr>
                      <tr *ngIf="
                          allStatementData?.statementTableData.length === 0
                        ">
                        <td colspan="4" class="two-column-lay header">
                          {{
                          "statementDisplay.document_date_label" | cxTranslate
                          }}
                        </td>
                        <td colspan="8" class="no-data-align">
                          {{ "statementDisplay.no_data" | cxTranslate }}
                        </td>
                      </tr>
                      <tr *ngFor="
                          let stData of allStatementData?.statementTableData;
                          let i = index
                        " class="table-row-section">
                        <td class="two-column-lay header">
                          {{
                          "statementDisplay.document_date_label" | cxTranslate
                          }}
                        </td>
                        <td class="two-column-lay">
                          <span>{{ stData?.documentDate }}</span>
                        </td>
                        <td class="two-column-lay header">
                          {{ "statementDisplay.due_date_label" | cxTranslate }}
                        </td>
                        <td class="two-column-lay">
                          <span>{{ stData?.dueDate }}</span>
                        </td>
                        <td class="two-column-lay header">
                          {{
                          "statementDisplay.trans_type_label" | cxTranslate
                          }}
                        </td>
                        <td class="two-column-lay">
                          <span>{{ stData?.transType }}</span>
                        </td>
                        <td class="two-column-lay header">
                          {{
                          "statementDisplay.document_number_label"
                          | cxTranslate
                          }}
                        </td>
                        <td class="two-column-lay">
                          <a *ngIf="
                              stData?.docType === 'RV' ||
                                stData?.docType === 'RD' ||
                                stData?.docType === 'RF' ||
                                (stData?.docType === 'DZ' &&
                                  stData?.documentNumber?.startsWith('7'));
                              else noLink
                            " class="doc-number" (click)="generateInvoicePDF(stData.documentNumber)"><span>{{
                              stData?.documentNumber }}</span></a>
                          <ng-template #noLink>
                            <span>{{
                              stData?.documentNumber
                              }}</span>
                          </ng-template>
                        </td>
                        <td class="two-column-lay header">
                          {{
                          "statementDisplay.original_amount_label"
                          | cxTranslate
                          }}
                        </td>
                        <td class="two-column-lay">
                          <span><span *ngIf="checkNegativeValue(stData)">(</span>{{ stData?.originalAmount | number:
                            "1.2-2"
                            }}<span *ngIf="checkNegativeValue(stData)">)</span></span>
                        </td>
                        <td class="two-column-lay header">
                          {{
                          "statementDisplay.amount_due_label" | cxTranslate
                          }}
                        </td>
                        <td class="two-column-lay">
                          <span><span *ngIf="checkNegativeValue(stData)">(</span>{{ stData?.amountDue | number: "1.2-2"
                            }}<span *ngIf="checkNegativeValue(stData)">)</span></span>
                        </td>
                        <td class="two-column-lay header">
                          {{ "statementDisplay.currency_label" | cxTranslate }}
                        </td>
                        <td class="two-column-lay">
                          <span>{{ stData?.currency }}</span>
                        </td>
                        <td class="two-column-lay header">
                          {{
                          "statementDisplay.terms_of_payment_label"
                          | cxTranslate
                          }}
                        </td>
                        <td class="two-column-lay">
                          <span>{{ stData?.termsOfPayment }}</span>
                        </td>
                      </tr>
                    </table>
                  </section>
                </section>
                <table class="sub_total lower-section" aria-describedby="amountTable">
                  <tr class="sample-header">
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                  <tbody>
                    <tr class="non-res even" role="row">
                      <td class="hide"></td>
                      <td class="hide"></td>
                      <td class="hide"></td>
                      <td class="hide"></td>
                      <td colspan="4" class="comp-nam">
                        <ng-container *ngIf="salesOrg$ | async as salesOrg">
                          <ng-container *ngIf="salesOrg != 'CA04'">
                            {{ "statementDisplay.sanofi_name" | cxTranslate }}
                          </ng-container>
                          <ng-container *ngIf="salesOrg == 'CA04'">
                            {{ "statementDisplay.sanofi_name_synv" | cxTranslate }}
                          </ng-container>
                        </ng-container>
                      </td>
                      <td class="tot tot-Styling">
                        <span *ngIf="
                            checkNegativeValue(
                              allStatementData?.amountDueTotal,
                              true
                            )
                          ">(</span>{{
                        allStatementData?.amountDueTotal
                        | formatNegative
                        | number: "1.2-2"
                        }}<span *ngIf="
                            checkNegativeValue(
                              allStatementData?.amountDueTotal,
                              true
                            )
                          ">)</span>
                      </td>
                      <td class="cad cad-Styling">
                        <span>{{
                          "statementDisplay.currency_symbol" | cxTranslate
                          }}</span>
                      </td>
                      <td class="sep-Td"></td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </section>
            <section class="row spl-ny lower-section" id="lower-section">
              <section class="col-xs-6 col-sm-6 col-md-6 statement-address" *ngIf="!!allStatementData">
                <p>{{ allStatementData?.soldToAddress?.companyName }}</p>
                <p>
                  {{ allStatementData?.soldToAddress?.line1 | uppercase }}
                  {{ allStatementData?.soldToAddress?.line2 | uppercase }}
                </p>
                <p>
                  {{ allStatementData?.soldToAddress?.town | uppercase }} &nbsp;
                  {{
                  allStatementData?.soldToAddress?.region?.isocodeShort
                  | uppercase
                  }}
                  &nbsp;
                  {{ allStatementData?.soldToAddress?.postalCode | uppercase }}
                </p>
              </section>
              <section class="col-xs-6 col-sm-6 col-md-6 analytics" *ngIf="!!allStatementData">
                <h3>
                  {{ "statementDisplay.analysis_account_label" | cxTranslate }}
                </h3>
                <section class="">
                  <table class="table table-analysis" aria-describedby="statementTable">
                    <thead>
                      <tr>
                        <th scope="col" colspan="3" class="p-0"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {{
                          "statementDisplay.current_account_label"
                          | cxTranslate
                          }}
                        </td>
                        <td>
                          {{ "statementDisplay.currency_symbol" | cxTranslate }}
                        </td>
                        <td class="text-right">
                          <span *ngIf="
                              checkNegativeValue(
                                allStatementData?.currentPaymentAmountTotal,
                                true
                              )
                            ">(</span>{{
                          allStatementData?.currentPaymentAmountTotal
                          | formatNegative
                          | number: "1.2-2"
                          }}<span *ngIf="
                              checkNegativeValue(
                                allStatementData?.currentPaymentAmountTotal,
                                true
                              )
                            ">)</span>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          {{
                          "statementDisplay.thirty_days_label" | cxTranslate
                          }}
                        </td>
                        <td>
                          {{ "statementDisplay.currency_symbol" | cxTranslate }}
                        </td>
                        <td class="text-right">
                          <span *ngIf="
                              checkNegativeValue(
                                allStatementData?.last30DaysPaymentAmountTotal,
                                true
                              )
                            ">(</span>
                          {{
                          allStatementData?.last30DaysPaymentAmountTotal
                          | formatNegative
                          | number: "1.2-2"
                          }}
                          <span *ngIf="
                              checkNegativeValue(
                                allStatementData?.last30DaysPaymentAmountTotal,
                                true
                              )
                            ">)</span>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          {{
                          "statementDisplay.sixty_days_label" | cxTranslate
                          }}
                        </td>
                        <td>
                          {{ "statementDisplay.currency_symbol" | cxTranslate }}
                        </td>
                        <td class="text-right">
                          <span *ngIf="
                              checkNegativeValue(
                                allStatementData?.last60DaysPaymentAmountTotal,
                                true
                              )
                            ">(</span>
                          {{
                          allStatementData?.last60DaysPaymentAmountTotal
                          | formatNegative
                          | number: "1.2-2"
                          }}
                          <span *ngIf="
                              checkNegativeValue(
                                allStatementData?.last60DaysPaymentAmountTotal,
                                true
                              )
                            ">)</span>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          {{
                          "statementDisplay.ninety_days_label" | cxTranslate
                          }}
                        </td>
                        <td>
                          {{ "statementDisplay.currency_symbol" | cxTranslate }}
                        </td>
                        <td class="text-right">
                          <span *ngIf="
                              checkNegativeValue(
                                allStatementData?.last90DaysPaymentAmountTotal,
                                true
                              )
                            ">(</span>
                          {{
                          allStatementData?.last90DaysPaymentAmountTotal
                          | formatNegative
                          | number: "1.2-2"
                          }}
                          <span *ngIf="
                              checkNegativeValue(
                                allStatementData?.last90DaysPaymentAmountTotal,
                                true
                              )
                            ">)</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>
              </section>
            </section>
            <section class="
                float-right-logo
                logo-account-bottom
                pasteur-logo
                lower-section
              " id="logo-section">
              <img src="../../assets/img/vs-canada-sanofi-pasteur-logo.png" alt="sanofi-pastuer-logo" />
            </section>
            <!--table ends-->
          </section>
        </section>
      </section>
    </section>
  </div>
</section>
