<div class="Registration-nav col-xs-12 col-sm-12 col-md-12 p0 multipleHeader">
  <div
    class="header-item payment"
    *ngIf="tableCategory == 'invoiceTable'; else creditTable"
  >
    <div
      class="Common-registration"
      [ngClass]="selectedHeader == 'paymentDetails' ? 'Common-active' : ''"
    >
      <span>{{ 'makeAPayment.no1' | cxTranslate }}</span>
      <span>{{ 'makeAPayment.payment' | cxTranslate }}</span>
    </div>
  </div>
  <ng-template #creditTable>
    <div class="header-item payment">
      <div
        class="Common-registration"
        [ngClass]="selectedHeader == 'creditDetails' ? 'Common-active' : ''"
      >
        <span>{{ 'makeAPayment.no1' | cxTranslate }}</span>
        <span>{{ 'makeAPayment.credits_title' | cxTranslate }}</span>
      </div>
    </div>
  </ng-template>

  <div class="header-item">
    <div
      class="Common-registration"
      [ngClass]="selectedHeader == 'review' ? 'Common-active' : ''"
    >
      <span>{{ 'makeAPayment.no2' | cxTranslate }}</span>
      <span>{{ 'makeAPayment.review' | cxTranslate }}</span>
    </div>
  </div>
  <div class="header-item confirmation">
    <div
      class="Common-registration icon-header-hide"
      [ngClass]="selectedHeader == 'confirmation' ? 'Common-active' : ''"
    >
      <span>{{ 'makeAPayment.no3' | cxTranslate }}</span>
      <span class="icon-hide">{{
        'makeAPayment.confirmation' | cxTranslate
      }}</span>
    </div>
  </div>
</div>

<div *ngIf="selectedHeader == 'review'" class="Registration-nav singleHeader">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
    <div
      class="Common-registration"
      [ngClass]="selectedHeader == 'review' ? 'Common-active' : ''"
    >
      <span>{{ 'makeAPayment.no2' | cxTranslate }}</span>
      <span>{{ 'makeAPayment.review' | cxTranslate }}</span>
    </div>
  </div>
</div>
<div
  *ngIf="selectedHeader == 'paymentDetails'"
  class="Registration-nav singleHeader"
>
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 payment">
    <div
      class="Common-registration"
      [ngClass]="selectedHeader == 'paymentDetails' ? 'Common-active' : ''"
    >
      <span>{{ 'makeAPayment.no1' | cxTranslate }}</span>
      <span>{{ 'makeAPayment.payment' | cxTranslate }}</span>
    </div>
  </div>
</div>
<div
  *ngIf="selectedHeader == 'creditDetails'"
  class="Registration-nav singleHeader"
>
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 payment">
    <div
      class="Common-registration"
      [ngClass]="selectedHeader == 'creditDetails' ? 'Common-active' : ''"
    >
      <span>{{ 'makeAPayment.no1' | cxTranslate }}</span>
      <span>{{ 'makeAPayment.credits_title' | cxTranslate }}</span>
    </div>
  </div>
</div>
<div
  *ngIf="selectedHeader == 'confirmation'"
  class="Registration-nav singleHeader"
>
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
    <div
      class="Common-registration icon-header-hide"
      [ngClass]="selectedHeader == 'confirmation' ? 'Common-active' : ''"
    >
      <span>{{ 'makeAPayment.no3' | cxTranslate }}</span>
      <span class="icon-hide">{{
        'makeAPayment.confirmation' | cxTranslate
      }}</span>
    </div>
  </div>
</div>
