<section class="container-fluid My-Account-Container">
  <section class="my-Account-Main-Header row">
    <section class="
        my-Account-Header-Name
        col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12
      ">
      {{'myAcountPandPre.myAcount'| cxTranslate}}
    </section>
  </section>
  <section class="account-Holder-Info-Div row mb-3 pb-3 pl-3">

    <section class="
        account-Details-Div
        w-100
        pt-1
        col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12
      ">
      <section class="account-Name-Label">
        <label class="labels-Of-Account account-Font-Values">{{'myAcountPandPre.accountName'| cxTranslate}}</label>
      </section>
      <section class="name-Of-Account">
        <span class="account-Name-Span account-Font-Values">{{defaultB2BUnitObject?.name}}</span>
      </section>
    </section>
    <section class="
        account-Details-Div
        w-100
        col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12
      ">
      <section class="account-Name-Label">
        <label class="labels-Of-Account account-Font-Values">{{'myAcountPandPre.phone'| cxTranslate}}</label>
      </section>
      <section class="name-Of-Account">
        <span class="account-Phone-No account-Font-Values">
          {{usergetData?.phone}}</span>
      </section>
    </section>
    <hr />
  </section>
  <section class="container address-Main-Div mt-2">
    <hr />
    <section class="billing-Div row">
      <section class="billing-Icon-Div">
        <img src="../../../assets/img/invoice2.png" class="price-Mater-Icon" />
      </section>
      <section class="billing-Header-Div">
        <span class="billing-Name-Header">{{'myAcountPandPre.billing'| cxTranslate}}</span>
      </section>
      <section class="address-Header-Div container ">
        <section class="row mt-2 text-fixed-medium">
          <section class="
              col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12
              w-100
              house-Number-Div
            ">
            <span>{{usergetData?.unitID}}</span>
          </section>
          <section class="
              col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12
              w-100
              org-Name-Div
            ">
            <span> {{usergetData?.companyName}}</span>
          </section>
          <section class="
              col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12
              w-100
              street-Address-Div
            ">
            <span>{{usergetData?.line1}} {{usergetData?.line2}}</span>
          </section>
          <section class="
              col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12
              w-100
              city-Div
            ">
            <span>{{usergetData?.town+","}} {{usergetData?.region?.isocodeShort +","}}
              {{usergetData?.postalCode}}</span>
          </section>
        </section>
      </section>
    </section>
    <hr />
    <section class="payer-Div row mt-3">
      <section class="payer-Icon-Div">
        <img src="/assets/icons/price-master2.png" class="price-Mater-Icon" />
      </section>
      <section class="payer-Header-Div">
        <span class="payer-Name-Header">{{'myAcountPandPre.payer'| cxTranslate}}</span>
      </section>
      <section class="address-Header-Div container ">
        <section class="row mt-2 text-fixed-medium">
          <section class="
              col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12
              w-100
              houseNumberDiv
            ">
            <span>{{payerObject?.unitID}}</span>
          </section>
          <section class="
              col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12
              w-100
              org-Name-Div
            ">
            <span> {{payerObject?.companyName}}</span>
          </section>
          <section class="
              col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12
              w-100
              street-Address-Div
            ">
            <span>{{payerObject?.line1}} {{payerObject?.line2}}</span>
          </section>
          <section class="
              col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12
              w-100
              city-Div
            ">
            <span>{{ payerObject?.town+","}} {{ payerObject?.region?.isocodeShort +","}} {{
              payerObject?.postalCode}}</span>
          </section>

        </section>
      </section>
    </section>
    <hr />
    <section class="img-Div">
      <app-profile-ad-banner></app-profile-ad-banner>
    </section>

  </section>
</section>
