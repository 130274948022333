<section class="row makePaymentView" id="productList-area">
  <aside class="col-md-2 col-sm-3 float-left" *ngIf="!showSection">
    <section class="vertical-banner"></section>
  </aside>
  <section
    class="{{ statementWidth }} col-xs-12 table-s-section float-right"
    [ngClass]="{ 'acc-overview-sp': showSection === true }"
  >
    <section
      class="credits-section"
      [ngClass]="{ 'acc-overview-sp': showSection === true }"
    >
      <section class="row my-4 my-lg-0">
        <section
          class="col-sm-6 col-md-6 col-xs-12 op_ovice"
          [ngClass]="{ 'acc-overview-sp': showSection === true }"
        >
          <h5
            class="font-bold mt-2"
            [ngClass]="{ 'acc-overview-sp': showSection === true }"
          >
            {{ 'statements.statementList.title' | cxTranslate }}
          </h5>
        </section>
        <section
          class="my-4 my-md-0 d-flex justify-content-md-end col-6 col-md-6 col-sm-6 col-xs-12 text-right openstatement-btn"
        >
          <button
            type="button"
            (click)="viewCurrent()"
            class="c-button c-button--primary mr-2"
            [ngClass]="{ 'acc-overview-sp': showSection === true }"
          >
            {{ 'statements.statementList.view_text' | cxTranslate }}
          </button>
          <button
            type="button"
            (click)="viewPDF()"
            class="c-button c-button--primary pdf btnIphone"
            [ngClass]="{ 'acc-overview-sp': showSection === true }"
          >
            <img src="../../assets/icons/pdf_white.png" alt="pdf" class="pdf" />
            {{ 'statements.statementList.pdf_text' | cxTranslate }}
          </button>
        </section>
      </section>
    </section>

    <section
      class="payment-section"
      [ngClass]="{ 'acc-overview-sp': showSection === true }"
    >
      <!-- Desktop -->
      <table
        aria-describedby="creditsTable"
        id="applyCreditsTable"
        class="credits-table c-table c-table--branded d-none d-lg-table"
      >
        <thead>
          <tr>
            <th
              scope="col"
              class="statement"
              [ngClass]="{ 'acc-overview-sp': showSection === true }"
            >
              {{
                'statements.statementList.statement_table_label' | cxTranslate
              }}&nbsp;
            </th>

            <th
              scope="col"
              class="text-right"
              [ngClass]="{ 'acc-overview-sp': showSection === true }"
            ></th>
            <th
              scope="col"
              [ngClass]="{ 'acc-overview-sp': showSection === true }"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let data of statementsData?.dateMap.slice(0, recordLength);
              let i = index
            "
          >
            <td>
              <span>{{ data?.value | date : 'dd/MM/yyyy' }}</span>
            </td>
            <td>
              <a
                title="view statement link"
                (click)="openNewModalPopup(data.value, data.key)"
                class="c-link"
                >{{ 'statements.statementList.view_text' | cxTranslate }}</a
              >
            </td>
            <td>
              <a
                title="download PDF link"
                (click)="generateSavePdf(data.value, data.key)"
                class="c-link"
                ><fa-icon [icon]="faFilePdf" class="icon"></fa-icon>&nbsp;{{
                  'statements.statementList.pdf_text' | cxTranslate
                }}</a
              >
            </td>
          </tr>
        </tbody>
      </table>
      <!-- Mobile -->
      <table
        aria-describedby="statementsTable"
        id="applyStatementsTable"
        class="c-table c-table--branded c-table--branded--two-col d-table d-lg-none not-pdf"
      >
        <tr
          *ngFor="
            let data of statementsData?.dateMap.slice(0, recordLength);
            let i = index
          "
        >
          <td class="two-column-lay header">
            {{ 'statements.statementList.statement_table_label' | cxTranslate }}
          </td>
          <td class="two-column-lay">
            <span>{{ data?.value | date : 'dd/MM/yyyy' }}</span>
          </td>
          <td class="two-column-lay header"></td>
          <td class="two-column-lay">
            <a
              title="view statement link"
              (click)="openNewModalPopup(data.value, data.key)"
              class="c-link"
              >{{ 'statements.statementList.view_text' | cxTranslate }}</a
            >
          </td>
          <td class="two-column-lay header"></td>
          <td class="two-column-lay">
            <a
              title="download PDF link"
              class="c-link"
              (click)="generateSavePdf(data.value, data.key)"
              ><fa-icon [icon]="faFilePdf" class="icon"></fa-icon>&nbsp;{{
                'statements.statementList.pdf_text' | cxTranslate
              }}</a
            >
          </td>
        </tr>
      </table>
    </section>
  </section>
</section>
<section id="temp-pdf" *ngIf="showPortal">
  <app-statement-display
    [statement]="details"
    [toShow]="showModal"
  ></app-statement-display>
</section>
