<div class="acc-order-details c-widget c-quick-order-widget d-sm-flex">
  <div>
    <h6 class="c-heading c-heading-h5 color-secondary mt-0">
      {{ 'accountOrders.quick_order_label' | cxTranslate }}
    </h6>
    <p class="text-fixed-legal-copy">
      {{ 'accountOrders.search_for_label' | cxTranslate }}
    </p>
  </div>
  <app-quick-order class="col-12 algn-Search"></app-quick-order>
</div>
<div class="acc-order-details c-widget c-quick-order-widget d-sm-flex">
  <app-monograph-widget></app-monograph-widget>
</div>
