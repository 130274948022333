import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-header',
  templateUrl: './account-header.component.html',
})
export class AccountHeaderComponent implements OnInit {
  @Input() selectedHeader: string;
  @Input() tableCategory: string;
  constructor() {}

  ngOnInit(): void {}
}
