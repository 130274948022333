<section (mouseleave)="closepop()" class="cart-icons" id="miniCart">
  <a
    [ngbPopover]="popTitleSaveSuccess"
    #s1="ngbPopover"
    triggers="manual"
    placement="bottom"
    popoverClass="savedcartsuccess-msg"
    (click)="s1.close()"
  >
    <img
      class="pdf-icon save-cart-icon cart-icon"
      src="../../assets/icons/saved-cart-icon-original.svg"
      alt=""
      (click)="navigateTominiSavedOrder()"
  /></a>
  <a
    class="clear-cart"
    *ngIf="isEmpty && !isFlu"
    [ngbPopover]="popContentEmpty"
    [popoverTitle]="popTitleEmpty"
    (mouseenter)="p1.open()"
    #p1="ngbPopover"
    placement="bottom"
    (click)="toggleWithGreeting(p1)"
  >
    <img
      class="pdf-icon cart-icon"
      src="../../assets/icons/cart-icon.svg"
      alt=""
    />
  </a>
  <a
    class="clear-cart"
    *ngIf="!isEmpty && !isFlu"
    [ngbPopover]="popContentStd"
    [popoverTitle]="popTitleStd"
    popoverClass="minicart-std-popover"
    (mouseenter)="p2.open()"
    #p2="ngbPopover"
    placement="bottom"
    (click)="toggleWithGreeting(p2)"
  >
    <img
      class="pdf-icon cart-icon"
      src="../../assets/icons/cart-icon.svg"
      alt=""
      [ngbPopover]="popTitleSessionCart"
      #c1="ngbPopover"
      triggers="manual"
      placement="bottom"
      popoverClass="itemincart-success-msg"
      (click)="c1.close()"
      container="body"
    />
    <p class="cartvalue">{{ quantityDisplay }}</p>
  </a>
  <a
    class="clear-cart"
    *ngIf="!isEmptyFlu && isFlu"
    popoverClass="minicart-std-popover"
    [ngbPopover]="popContentFlu"
    [popoverTitle]="popTitleFlu"
    (mouseenter)="p4.open()"
    #p4="ngbPopover"
    placement="bottom"
    (click)="toggleWithGreeting(p4)"
  >
    <img
      class="pdf-icon cart-icon"
      src="../../assets/icons/cart-icon.svg"
      alt=""
    />
    <p class="cartvalue">{{ quantityDisplayFlu }}</p>
  </a>
  <a
    class="clear-cart"
    *ngIf="isEmptyFlu && isFlu"
    [ngbPopover]="popContentEmpty"
    [popoverTitle]="popTitleEmpty"
    (mouseenter)="p1.open()"
    #p1="ngbPopover"
    placement="bottom"
    (click)="toggleWithGreeting(p1)"
  >
    <img
      class="pdf-icon cart-icon"
      src="../../assets/icons/cart-icon.svg"
      alt=""
    />
  </a>
</section>
<section class="cart-popover">
  <ng-template #popTitleEmpty>
    <div class="minicart-header font-bold">
      <span class="mini-triangle"></span>
      <p class="text-center mb-0">
        {{ 'addToCartItems.emptycart' | cxTranslate }}
      </p>
      <hr />
    </div>
  </ng-template>
  <ng-template #popContentEmpty>
    <div class="miniCart-listitem">
      <div class="text-center mt-4">
        <a
          class="c-link a-no-styles"
          title="Order Products"
          (click)="navigateToPdp()"
          >{{ 'addToCartItems.emptyOrderProduct' | cxTranslate }}</a
        ><br /><br />
        <a
          class="c-link a-no-styles"
          title="Save Order"
          (click)="navigateToFlu()"
          *ngIf="usergetDataObj?.salesOrg != 'CA04'"
          >{{ 'addToCartItems.emptyFluProducts' | cxTranslate }}</a
        >
        <br *ngIf="usergetDataObj?.salesOrg != 'CA04'"/><br *ngIf="usergetDataObj?.salesOrg != 'CA04'"/>
        <a
          class="c-link a-no-styles"
          title="Save Order"
          (click)="navigateToSavedOrder()"
          >{{ 'addToCartItems.emptySaveOrders' | cxTranslate }}</a
        ><br /><br /><br />
      </div>
    </div>
  </ng-template>
</section>

<section class="cart-popover">
  <ng-template #popTitleStd>
    <div class="minicart-header pb-4 font-bold">
      <span class="mini-triangle"></span>
      <span class="mini-cart-title"></span>
      {{ 'addToCartItems.orderSubtotal' | cxTranslate }}
      {{ stdCartEntries?.subTotal?.value | currency }}
      {{ stdCartEntries?.subTotal?.currencyIso }}
    </div>
  </ng-template>
  <ng-template #popContentStd>
    <section class="cart-prod-list">
      <section class="unit-numbers">
        <span class="">
          {{ totalQuantity }} {{ 'addToCartItems.units' | cxTranslate }}
        </span>
      </section>
      <section class="cart-details-minicart">
        <ul
          class="cart-prod-details"
          *ngFor="let productentry of productEntries.slice().reverse()"
        >
          <li>
            <a
              class="miniCart-prod-details text-decoration-none"
              (click)="navigatetoProduct(productentry?.product?.code)"
            >
              <div class="img-info">
                <div class="img-holder">
                  <img
                    *ngIf="productentry?.product?.images != null"
                    class="flu-poster img_loaded"
                    src="{{ productentry?.PRIMARY?.url }}"
                    alt=""
                  />
                  <img
                    *ngIf="productentry?.product?.images == null"
                    [src]="fallbackImage"
                    alt=""
                    class="flu-poster img_loaded"
                  />
                </div>
                <div class="info">
                  <span
                    class="cart-prod-title"
                    [innerHTML]="productentry?.product?.name"
                  ></span>

                  <p class="miniCart-price">
                    {{ productentry?.basePrice?.value | currency }}
                  </p>
                </div>
              </div>
              <div class="count mr-4 pl-2">
                <span class="minicart-prod-doses">{{
                  productentry?.quantity
                }}</span>
              </div>
            </a>
          </li>
        </ul>
      </section>
      <section class="cart-footer savepopover">
        <button
          type="button"
          (click)="navigateToCart()"
          class="c-button c-button--primary"
        >
          {{ 'addToCartItems.viewcart' | cxTranslate }}
        </button>

        <button
          type="button"
          class="c-button c-button--secondary"
          [ngbPopover]="popSaveContent"
          [popoverTitle]="popSaveTitle"
          triggers="manual"
          #s3="ngbPopover"
          placement="top"
          popoverClass="savedordermini-popover"
          (click)="toggle(s3)"
        >
          {{ 'addToCartItems.savecart' | cxTranslate }}
        </button>
        <ng-template #popSaveTitle
          ><span class="minicart-savepopover">{{
            'addToCartItems.saveOrderTitle' | cxTranslate
          }}</span>
        </ng-template>
        <ng-template #popSaveContent>
          <input
            type="text"
            class="save-order save-order-mini mt-4 mb-2 py-4"
            #name="ngModel"
            required
            [(ngModel)]="saveCartName"
            (keypress)="omit_special_char($event)"
            maxlength="30"
          />
          <span *ngIf="name.errors?.required" class="common-error required">{{
            errorMsg
          }}</span>
          <span class="common-error required">{{ errorMsg }}</span>
          <section class="cart-footer savepopover">
            <button
              type="button"
              class="c-button c-button--secondary"
              (click)="toggle(s3)"
            >
              {{ 'addToCartItems.cancel' | cxTranslate }}
            </button>
            <button
              class="c-button c-button--primary"
              type="submit"
              data-di-id="di-id-3064f17d-e97cc628"
              (click)="saveOrder()"
            >
              {{ 'addToCartItems.save' | cxTranslate }}
            </button>
          </section>
        </ng-template>
      </section>
    </section>
  </ng-template>
</section>

<section class="cart-popover">
  <ng-template #popTitleFlu>
    <div class="minicart-header pb-4 font-bold">
      <span class="mini-triangle"></span>
      <span class="mini-cart-title"></span>
      {{ 'addToCartItems.orderSubtotal' | cxTranslate }}
      {{ fluCartEntries?.subTotal?.value | currency }}
      {{ fluCartEntries?.subTotal?.currencyIso }}
    </div>
  </ng-template>
  <ng-template #popContentFlu>
    <section class="cart-prod-list">
      <section class="unit-numbers">
        <span class=""
          >{{ totalQuantityFlu }}
          {{ 'addToCartItems.units' | cxTranslate }}</span
        >
      </section>
      <section class="cart-details-minicart">
        <ul
          class="cart-prod-details"
          *ngFor="let productentry of productFluEntries"
        >
          <li>
            <a
              class="miniCart-prod-details text-decoration-none"
              (click)="navigatetoProduct(productentry?.product?.code)"
            >
              <div class="img-info">
                <div class="img-holder">
                  <img
                    *ngIf="productentry?.product?.images != null"
                    class="flu-poster img_loaded"
                    src="{{ productentry?.PRIMARY?.url }}"
                    alt=""
                  />
                  <img
                    *ngIf="productentry?.product?.images == null"
                    [src]="fallbackImage"
                    alt=""
                    class="flu-poster img_loaded"
                  />
                </div>
                <div class="info">
                  <span
                    class="cart-prod-title"
                    [innerHTML]="productentry?.product?.name"
                  ></span>

                  <p class="miniCart-price">
                    {{ productentry?.basePrice?.value | currency }}
                  </p>
                </div>
              </div>
              <div class="count mr-4 pl-2">
                <span class="minicart-prod-doses">{{
                  productentry?.quantity
                }}</span>
              </div>
            </a>
          </li>
        </ul>
      </section>
      <section class="cart-footer savepopover">
        <button
          type="button"
          (click)="navigateToFlufromCart()"
          class="c-button c-button--primary"
        >
          {{ 'addToCartItems.viewcart' | cxTranslate }}
        </button>
      </section>
    </section>
  </ng-template>
</section>

<section class="added-success-container">
  <ng-template #popTitleSuccess>
    <div class="cart-value-added">
      <img
        src="../../../assets/icons/confirmation-positive-green.png"
        class="positive-green-icon"
        alt="success"
      />
      <span class="confirmation-itemsadded"
        >{{ itemAddedQuantity }}
        {{ 'addToCartItems.addEntrySuccess' | cxTranslate }}</span
      >
    </div>
  </ng-template>
</section>
<ng-template #popTitleSaveSuccess>
  <div class="cart-value-added">
    <img
      src="../../../assets/icons/confirmation-positive-green.png"
      class="positive-green-icon"
      alt="success"
    />
    <span class="confirmation-itemsadded"
      >{{ cartSavedSuccess }}
      {{ 'addToCartItems.savesuccess' | cxTranslate }}</span
    >
  </div>
</ng-template>

<ng-template #popMinicartItems>
  <div class="cart-value-added">
    <img
      src="../../../assets/icons/confirmation-positive-green.png"
      class="positive-green-icon"
      alt="success"
    />
    <span class="confirmation-itemsadded"
      >{{ quantityDisplay }}
      {{ 'addToCartItems.sessionCartMsg' | cxTranslate }}</span
    >
  </div>
</ng-template>
<ng-template #popTitleSessionCart>
  <div class="cart-value-added">
    <img
      src="../../../assets/icons/info.png"
      class="positive-green-icon"
      alt="info"
    />
    <span class="confirmation-itemsadded"
      >{{ quantityDisplay }}
      {{ 'addToCartItems.sessionCartMsg' | cxTranslate }}</span
    >
  </div>
</ng-template>
