<section class="container">
  <div class="row">
    <div class="col-md-8 text-md-right mb-4 mb-md-0">
      <a
        class="c-link"
        [routerLink]="['/my-account/orders']"
        href="javascript: void(0);"
      >
        <fa-icon [icon]="faChevronLeft" class="icon"></fa-icon>
        {{ 'orderDetail.backToOrder' | cxTranslate }}
      </a>
    </div>
    <div
      class="col-md-4 text-right d-flex flex-wrap gap-1 justify-content-md-end mb-4 mb-md-0"
    >
      <ng-template #popSaveTitle>{{
        'orderDetail.orderName' | cxTranslate
      }}</ng-template>
      <ng-template #popSaveContent>
        <input
          type="text"
          class="save-order"
          [(ngModel)]="saveCartName"
          maxlength="30"
          (keypress)="omit_special_char($event)"
        />
        <span class="common-error required">{{ errorMsg }}</span>
        <button
          type="reset"
          class="popover-close btn white-button popover-cancel-button"
          (click)="toggle(p1)"
        >
          {{ 'orderDetail.cancel' | cxTranslate }}
        </button>
        <button
          class="btn blue-button popover-cancel-button"
          type="submit"
          data-di-id="di-id-3064f17d-e97cc628"
          (click)="saveOrder()"
        >
          {{ 'orderDetail.save' | cxTranslate }}
        </button>
      </ng-template>
      <a
        class="c-link"
        [ngbPopover]="popSaveContent"
        [popoverTitle]="popSaveTitle"
        triggers="manual"
        #p1="ngbPopover"
        placement="top"
        (click)="toggle(p1)"
        container="body"
        popoverClass="popover-links order-detail-save"
      >
        <span>{{ 'orderDetail.saveOrder' | cxTranslate }}</span>
        <fa-icon [icon]="faRectangleList" class="icon"></fa-icon>
      </a>
      <a (click)="excelDownload()" class="c-link">
        <span>{{ 'orderDetail.xls' | cxTranslate }}</span>
        <fa-icon [icon]="faFileExcel" class="icon"></fa-icon>
      </a>
      <a (click)="print()" class="c-link">
        <span>{{ 'orderDetail.print' | cxTranslate }}</span>
        <fa-icon [icon]="faPrint" class="icon"></fa-icon>
      </a>
    </div>
  </div>
</section>

<section class="container mb-4">
  <!-- Mobile buttons -->
  <div class="row d-block d-md-none">
    <div class="col-md-12">
      <section class="d-flex mt-4 justify-content-between">
        <button
          type="button"
          class="c-button c-button--primary"
          *ngIf="orderDetailsData?.cartType !== 'INFLUENZA'"
          (click)="openModal(content)"
        >
          {{ 'orderDetail.reorderCheckout' | cxTranslate }}
        </button>
        <button
          type="submit"
          *ngIf="orderDetailsData?.cartType !== 'INFLUENZA'"
          class="c-button c-button--secondary"
          (click)="getCartId('addCart')"
        >
          {{ 'orderDetail.addToCart' | cxTranslate }}
        </button>
      </section>
      <!-- Modal Start -->
      <ng-template #content let-modal>
        <section class="checkout-modal">
          <section class="modal-header">
            <h2 class="c-heading-36 font-bold u-border-bottom-grey pb-4 w-100">
              {{ 'orderDetail.reorder' | cxTranslate }}
            </h2>
          </section>
          <section class="modal-body confirm-order">
            <section class="text-medium-fixed">
              <p>{{ 'orderDetail.clearCartDescription' | cxTranslate }}</p>
            </section>
          </section>
          <section class="miniCart-foot d-md-flex px-4">
            <button
              type="button"
              class="c-button c-button--secondary w-100 mb-4 mr-4"
              (click)="existingCart()"
            >
              {{ 'orderDetail.existingCart' | cxTranslate }}
            </button>
            <button
              type="submit"
              class="c-button c-button--primary w-100 mb-4"
              (click)="clearChildCart()"
            >
              {{ 'orderDetail.clearReorder' | cxTranslate }}
            </button>
          </section>
        </section>
      </ng-template>
      <!-- Modal End -->
    </div>
  </div>
  <!-- Mobile Only Order Total -->
  <div class="row mb-4 mt-4">
    <section
      class="col-md-12 py-4 u-border-top-grey d-flex align-items-center justify-content-center justify-content-md-between flex-column flex-md-row d-block d-sm-none"
    >
      <h3 class="font-bold mb-0">
        {{ 'orderDetail.orderTotal' | cxTranslate }}
      </h3>
      <h1 class="font-bold mb-0">
        {{ orderDetailsData?.totalPriceWithTax?.formattedValue }}
        <span>{{ 'orderDetail.cad' | cxTranslate }}</span>
      </h1>
    </section>
  </div>

  <!-- Main content -->
  <div class="row text-fixed-medium">
    <div class="col-md-8 u-border-right-grey pr-md-4 pr-lg-5">
      <div class="row pb-4 u-border-bottom-grey">
        <div class="col-md-6 mb-4">
          <i class="fa fa-map-marker-alt font-n"></i>
          <span class="px-3 text-fixed-medium">
            {{ orderDetailsData?.shipToLoc_name }}
            {{ '(' + orderDetailsData?.shipToLoc_id + ')' }}
          </span>
        </div>
        <div class="col-md-6">
          <p class="mb-2">
            <span class="font-bold">
              {{ 'orderDetail.order' | cxTranslate }}
            </span>
            <span>{{ orderDetailsData?.guid }}</span>
          </p>
          <p class="m-0">
            <span class="font-bold">{{
              'orderDetail.orderDate' | cxTranslate
            }}</span
            >&nbsp;
            <span>{{ orderDetailsData?.created | date : 'dd/MM/yyyy' }}</span>
          </p>
        </div>
      </div>
      <!-- Order Status -->
      <div class="row pt-4 pb-md-4 u-border-bottom-grey">
        <div class="col-md-4">
          <h3 class="font-bold">
            {{ 'orderDetail.orderStatus' | cxTranslate }}
          </h3>
          <p
            class="m-md-0"
            *ngIf="
              orderDetailsData?.statusDisplay !== undefined &&
              orderDetailsData?.statusDisplay !== ''
            "
          >
            {{ orderDetailsData?.statusDisplay }}
          </p>
          <p
            class="m-md-0"
            *ngIf="
              orderDetailsData?.statusDisplay === undefined ||
              orderDetailsData?.statusDisplay === ''
            "
          ></p>
        </div>
        <div class="col-md-4">
          <h3 class="font-bold">{{ 'orderDetail.placedBy' | cxTranslate }}</h3>
          <p
            class="m-md-0"
            *ngIf="
              orderDetailsData?.user?.name !== undefined &&
              orderDetailsData?.user?.name !== ''
            "
          >
            {{ orderDetailsData?.user?.name }}
          </p>
          <p
            class="m-md-0"
            *ngIf="
              orderDetailsData?.user?.name === undefined ||
              orderDetailsData?.user?.name === ''
            "
          >
            -
          </p>
        </div>
        <div class="col-md-4">
          <h3 class="font-bold">
            {{ 'orderDetail.po' | cxTranslate }}
          </h3>
          <p
            class="m-md-0"
            *ngIf="
              orderDetailsData?.purchaseOrderNumber !== undefined &&
              orderDetailsData?.purchaseOrderNumber !== ''
            "
          >
            {{ orderDetailsData?.purchaseOrderNumber }}
          </p>
          <p
            class="m-md-0"
            *ngIf="
              orderDetailsData?.purchaseOrderNumber === undefined ||
              orderDetailsData?.purchaseOrderNumber === ''
            "
          >
            -
          </p>
        </div>
      </div>
      <!-- Billing -->
      <div class="row row pt-4 pb-4 u-border-bottom-grey">
        <div class="col-md-12 mb-4">
          <h2 class="font-bold">{{ 'orderDetail.billing' | cxTranslate }}</h2>
        </div>
        <div class="col-md-7">
          <h3 class="font-bold">{{ 'orderDetail.billTo' | cxTranslate }}</h3>
          <p class="mb-1">
            {{ 'orderDetail.accountNumber' | cxTranslate
            }}{{ orderDetailsData?.billingAddress?.unitID }}
          </p>
          <p class="mb-1">
            {{ orderDetailsData?.billingAddress?.companyName }}
          </p>
          <p class="mb-1">
            {{ orderDetailsData?.billingAddress?.line1 }},
            {{ orderDetailsData?.billingAddress?.line2 }}
          </p>
          <p class="mb-3">
            {{ orderDetailsData?.billingAddress?.town }},
            {{ orderDetailsData?.billingAddress?.region?.isocodeShort }},
            {{ orderDetailsData?.billingAddress?.postalCode }}
          </p>
          <h4 class="font-bold">
            {{ 'orderDetail.paymentType' | cxTranslate }}
          </h4>
          <p>{{ orderDetailsData?.paymentType?.displayName }}</p>
        </div>
        <div class="col-md-5 mt-4 mt-md-0">
          <div class="row mb-2">
            <div class="col-6">
              {{ 'orderDetail.orderSubtotal' | cxTranslate }}
            </div>
            <div class="col-6 text-right">
              {{ orderDetailsData?.subTotal?.formattedValue }}
              <span>{{ 'orderDetail.cad' | cxTranslate }}</span>
            </div>
          </div>
          <div
            class="row pb-2 u-border-bottom-grey"
            *ngIf="
              orderDetailsData?.totalTax?.value !== 0 &&
              orderDetailsData?.totalTax?.value !== undefined
            "
          >
            <div class="col-6">
              {{ 'orderDetail.salesTax' | cxTranslate }}
            </div>
            <div class="col-6 text-right">
              ${{ orderDetailsData?.totalTax?.value | number : '1.2-2' }}
            </div>
          </div>
          <div class="row mt-2 font-bold">
            <div class="col-6">
              {{ 'orderDetail.orderTotals' | cxTranslate }}
            </div>
            <div class="col-6 text-right">
              {{ orderDetailsData?.totalPrice?.formattedValue }}
              <span>{{ 'orderDetail.cad' | cxTranslate }}</span>
            </div>
          </div>
          <!--
          <div class="row mt-5">
            <div class="col-md-12">
              <h3 class="font-bold mb-4">
                {{ "addToCartItems.paymentMethod" | cxTranslate }}
              </h3>
              <p class="font-bold">
                {{ "orderConfirmation.invoiceLater" | cxTranslate }}
              </p>
              <p class="text-fixed-medium">
                {{ "addToCartItems.paymentNote" | cxTranslate }}
              </p>
            </div>
          </div>
-->
        </div>
      </div>
      <!-- Shipping -->
      <div class="row row pt-4 pb-4 u-border-bottom-grey">
        <div class="col-md-12 mb-4">
          <h2 class="font-bold">
            {{ 'orderDetail.shippingMethod' | cxTranslate }}
          </h2>
        </div>
        <div class="col-md-6">
          <h3 class="font-bold">{{ 'orderDetail.shipTo' | cxTranslate }}</h3>
          <p class="mb-1">
            {{ 'orderDetail.accountNumber' | cxTranslate
            }}{{ orderDetailsData?.deliveryAddress?.unitID }}
          </p>
          <p class="mb-1">
            {{ orderDetailsData?.deliveryAddress?.companyName }}
          </p>
          <p class="mb-1">
            {{ orderDetailsData?.deliveryAddress?.line1 }},
            {{ orderDetailsData?.deliveryAddress?.line2 }}
          </p>
          <p class="mb-1">
            {{ orderDetailsData?.deliveryAddress?.town }},
            {{ orderDetailsData?.deliveryAddress?.region?.isocodeShort }},
            {{ orderDetailsData?.deliveryAddress?.postalCode }}
          </p>
        </div>
        <div class="col-md-6">
          <h3 class="font-bold">
            {{ 'orderDetail.shippingMethod' | cxTranslate }}
          </h3>
          <p
            class="mb-1"
            *ngFor="
              let shipMethod of orderDetailsData?.shippingMethodArr;
              let i = index
            "
          >
            {{ shipMethod
            }}<span *ngIf="i + 1 != orderDetailsData?.shippingMethodArr?.length"
              >,</span
            >
          </p>
        </div>
      </div>
      <section
        class="row py-4 mt-2 mt-md-0"
        *ngFor="let productEntry of orderDetailsData?.entries"
      >
        <!-- image -->
        <div class="col-md-2 mb-4">
          <a
            class="in-active"
            (click)="openProductDetails(productEntry)"
            title="{{ productEntry?.product?.name }}"
          >
            <img
              class="prod-Image"
              *ngIf="productEntry?.PRIMARY !== undefined; else undefinedImg"
              src="{{ productEntry?.PRIMARY?.url }}"
              alt="Product Image"
            />
            <ng-template #undefinedImg>
              <img
                class="prod-Image"
                [src]="fallbackImage"
                alt="Product Image"
              />
            </ng-template>
          </a>
        </div>
        <!-- details -->
        <div class="col-md-10">
          <a
            class="c-link mb-1"
            title="{{ productEntry?.product?.name }}"
            (click)="openProductDetails(productEntry)"
          >
            <span
              [innerHTML]="getProductName(productEntry?.product?.name)"
            ></span>
          </a>
          <p
            class="mb-1"
            [innerHtml]="getDescription(productEntry?.product?.description)"
          ></p>
          <p class="mb-1">
            <span class="font-bold">{{
              'orderDetail.productNumber' | cxTranslate
            }}</span>
            {{ productEntry?.product?.code }}
          </p>
        </div>
        <!-- table -->
        <div class="col-md-12">
          <table
            class="c-table c-table--branded c-table--small d-none d-lg-table"
          >
            <thead>
              <tr>
                <th scope="col">
                  {{ 'orderDetail.status' | cxTranslate }}
                </th>
                <th scope="col">
                  {{ 'orderDetail.totalUnits' | cxTranslate }}
                </th>
                <th scope="col">
                  {{ 'orderDetail.confirmed' | cxTranslate }} <br />{{
                    'orderDetail.shippedUnits' | cxTranslate
                  }}
                </th>
                <th scope="col">
                  {{ 'orderDetail.shipDate' | cxTranslate }}
                </th>
                <th scope="col">
                  {{ 'orderDetail.tracking' | cxTranslate }}
                </th>
                <th scope="col">
                  {{ 'orderDetail.lot' | cxTranslate }}
                </th>
                <th scope="col" class="text-center">
                  <!-- BIVV -->
                  <span *ngIf="productEntry?.product?.actualPotency">{{
                    'orderDetail.iuPrice' | cxTranslate
                  }}</span>
                  <!--GenMed -->
                  <span *ngIf="!productEntry?.product?.actualPotency">{{
                    'orderDetail.yourPrice' | cxTranslate
                  }}</span>
                </th>
                <th scope="col" class="text-center">
                  {{ 'orderDetail.subtotal' | cxTranslate }}
                </th>
              </tr>
            </thead>
            <tbody
              class="datatable-row"
              *ngIf="productEntry?.prodConsignments?.length === 1"
            >
              <tr
                class="text-center"
                *ngFor="
                  let data of productEntry?.prodConsignments;
                  let i = index
                "
              >
                <td>{{ productEntry?.quantityStatus }}</td>
                <td>{{ productEntry?.quantity }}</td>
                <td class="extra-width text-center">
                  {{ data?.entries[0]?.shippedQuantity }}
                </td>
                <td>
                  {{ data?.statusDate | date : 'dd/MM/yyyy' }}
                </td>
                <td>
                  <span *ngIf="data?.carrier !== 'YB'">
                    <a
                      class="tracking-Num-Span app-navigation-links"
                      href="unsafe:javascript:void(0)"
                      rel="{{ fullTrackingUrl }}"
                      type="external"
                      (click)="
                        navigateToTracking(trackingIdValue, data.trackingURL)
                      "
                      *ngFor="
                        let trackingIdValue of data?.trackingIdData;
                        let idIndex = index
                      "
                    >
                      {{ trackingIdValue }}
                    </a>
                  </span>
                </td>
                <td>
                  <span *ngIf="data?.lotNumber !== null">{{
                    data?.lotNumber
                  }}</span>
                </td>
                <td>
                  ${{ productEntry?.basePrice?.value | number : '1.2-2' }}
                  {{ 'orderDetail.cad' | cxTranslate }}
                </td>
                <td>
                  ${{ productEntry?.totalPrice?.value | number : '1.2-2' }}
                  {{ 'orderDetail.cad' | cxTranslate }}
                </td>
              </tr>
            </tbody>
            <tbody
              class="datatable-row"
              *ngIf="productEntry?.prodConsignments?.length > 1"
            >
              <tr
                class="text-center"
                *ngFor="
                  let data of productEntry?.prodConsignments;
                  let i = index
                "
              >
                <td
                  *ngIf="i === 0"
                  [attr.rowspan]="productEntry?.prodConsignments?.length"
                >
                  {{ productEntry?.quantityStatus }}
                </td>
                <td
                  *ngIf="i === 0"
                  [attr.rowspan]="productEntry?.prodConsignments?.length"
                >
                  {{ productEntry?.quantity }}
                </td>
                <td>
                  {{ data?.entries[0]?.shippedQuantity }}
                </td>
                <td>
                  {{ data?.statusDate | date : 'dd/MM/yyyy' }}
                </td>
                <td>
                  <span *ngIf="data?.carrier !== 'YB'">
                    <a
                      class="tracking-Num-Span app-navigation-links"
                      href="unsafe:javascript:void(0)"
                      rel="{{ fullTrackingUrl }}"
                      type="external"
                      (click)="
                        navigateToTracking(trackingIdValue, data.trackingURL)
                      "
                      *ngFor="
                        let trackingIdValue of data?.trackingIdData;
                        let idIndex = index
                      "
                    >
                      {{ trackingIdValue }}
                    </a>
                  </span>
                </td>
                <td>
                  <span *ngIf="data?.lotNumber !== null">{{
                    data?.lotNumber
                  }}</span>
                </td>
                <td
                  *ngIf="i === 0"
                  [attr.rowspan]="productEntry?.prodConsignments?.length"
                >
                  ${{ productEntry?.basePrice?.value | number : '1.2-2' }}
                  {{ 'orderDetail.cad' | cxTranslate }}
                </td>
                <td
                  *ngIf="i === 0"
                  [attr.rowspan]="productEntry?.prodConsignments?.length"
                >
                  ${{ productEntry?.totalPrice?.value | number : '1.2-2' }}
                  {{ 'orderDetail.cad' | cxTranslate }}
                </td>
              </tr>
            </tbody>
            <tbody
              class="datatable-row"
              *ngIf="
                productEntry?.prodConsignments === undefined ||
                productEntry?.prodConsignments?.length === 0
              "
            >
              <tr class="details-col text-center">
                <td>{{ productEntry?.quantityStatus }}</td>
                <td>{{ productEntry?.quantity }}</td>
                <td>
                  {{ productEntry?.confirmedQuantity }}
                </td>
                <td>{{ productEntry?.confirmedDate }}</td>
                <td>-</td>
                <td></td>
                <td>
                  ${{ productEntry?.basePrice?.value | number : '1.2-2' }}
                  {{ 'orderDetail.cad' | cxTranslate }}
                </td>
                <td>
                  ${{ productEntry?.totalPrice?.value | number : '1.2-2' }}
                  {{ 'orderDetail.cad' | cxTranslate }}
                </td>
              </tr>
            </tbody>
          </table>
          <!-- mobile table -->
          <table
            class="c-table c-table--branded c-table--branded--two-col d-table d-lg-none"
            *ngIf="productEntry?.prodConsignments?.length > 0"
          >
            <tr
              class="commonViewTable"
              *ngFor="let data of productEntry?.prodConsignments; let i = index"
            >
              <td class="header">{{ 'orderDetail.status' | cxTranslate }}</td>
              <td>{{ productEntry?.quantityStatus }}</td>

              <td class="header">
                {{ 'orderDetail.totalUnits' | cxTranslate }}
              </td>
              <td>{{ productEntry?.quantity }}</td>

              <p>
                <label
                  >{{ 'orderDetail.confirmed' | cxTranslate }}
                  {{ 'orderDetail.shippedUnits' | cxTranslate }}</label
                >
                <span>{{ data?.entries[0]?.shippedQuantity }}</span>
              </p>
              <p>
                <label>{{ 'orderDetail.shipDate' | cxTranslate }}</label>
                <span>{{ data?.statusDate | date : 'dd/MM/yyyy' }} </span>
              </p>
              <p>
                <label>{{ 'orderDetail.tracking' | cxTranslate }}</label>
                <span *ngIf="data?.carrier !== 'YB'">
                  <a
                    class="tracking-Num-Span app-navigation-links"
                    href="unsafe:javascript:void(0)"
                    rel="{{ fullTrackingUrl }}"
                    type="external"
                    (click)="
                      navigateToTracking(trackingIdValue, data.trackingURL)
                    "
                    *ngFor="
                      let trackingIdValue of data?.trackingIdData;
                      let idIndex = index
                    "
                  >
                    {{ trackingIdValue }}
                  </a>
                </span>
              </p>
              <p>
                <label>{{ 'orderDetail.lot' | cxTranslate }}</label>
                <span
                  ><span *ngIf="data?.lotNumber !== null">{{
                    data?.lotNumber
                  }}</span></span
                >
              </p>
              <p>
                <label>{{ 'orderDetail.yourPrice' | cxTranslate }}</label>
                <span
                  >${{ item?.basePrice?.value | number : '1.2-2' }}
                  {{ 'orderDetail.cad' | cxTranslate }}</span
                >
              </p>
              <p>
                <label>{{ 'orderDetail.subtotal' | cxTranslate }}</label>
                <span
                  >${{ item?.totalPrice?.value | number : '1.2-2' }}
                  {{ 'orderDetail.cad' | cxTranslate }}</span
                >
              </p>
            </tr>
          </table>
          <table
            class="c-table c-table--branded c-table--branded--two-col d-table d-lg-none"
            *ngIf="
              productEntry?.prodConsignments === undefined ||
              productEntry?.prodConsignments?.length === 0
            "
          >
            <tr>
              <td class="header">{{ 'orderDetail.status' | cxTranslate }}</td>
              <td>{{ productEntry?.quantityStatus }}</td>

              <td class="header">
                {{ 'orderDetail.totalUnits' | cxTranslate }}
              </td>
              <td>{{ productEntry?.quantity }}</td>

              <td class="header">
                {{ 'orderDetail.confirmed' | cxTranslate }}
                {{ 'orderDetail.shippedUnits' | cxTranslate }}
              </td>
              <td>{{ productEntry?.confirmedQuantity }}</td>
              <td class="header">
                {{ 'orderDetail.shipDate' | cxTranslate }}
              </td>
              <td>{{ productEntry?.confirmedDate }}</td>

              <td class="header">
                {{ 'orderDetail.tracking' | cxTranslate }}
              </td>
              <td>-</td>

              <td class="header">{{ 'orderDetail.lot' | cxTranslate }}</td>
              <td class="text-center"></td>
              <td class="header">
                {{ 'orderDetail.yourPrice' | cxTranslate }}
              </td>
              <td>
                ${{ productEntry?.basePrice?.value | number : '1.2-2' }}
                {{ 'orderDetail.cad' | cxTranslate }}
              </td>

              <td class="header">
                {{ 'orderDetail.subtotal' | cxTranslate }}
              </td>
              <td>
                ${{ productEntry?.totalPrice?.value | number : '1.2-2' }}
                {{ 'orderDetail.cad' | cxTranslate }}
              </td>
            </tr>
          </table>
        </div>
      </section>
    </div>
    <!-- Sidebar -->
    <div class="col-md-4 pl-md-4 pl-lg-5">
      <section class="row">
        <section
          class="col-md-12 py-4 u-border-top-grey u-border-bottom-grey d-flex align-items-center justify-content-center justify-content-md-between flex-column flex-md-row"
        >
          <h3 class="font-bold mb-0">
            {{ 'orderDetail.orderTotal' | cxTranslate }}
          </h3>
          <h1 class="font-bold mb-0">
            {{ orderDetailsData?.totalPriceWithTax?.formattedValue }}
            <span>{{ 'orderDetail.cad' | cxTranslate }}</span>
          </h1>
        </section>
      </section>
      <div class="row">
        <div class="col-md-12">
          <section class="d-lg-flex mt-4 justify-content-between">
            <button
              type="button"
              class="c-button c-button--primary mb-4"
              *ngIf="orderDetailsData?.cartType !== 'INFLUENZA'"
              (click)="openModal(content)"
            >
              {{ 'orderDetail.reorderCheckout' | cxTranslate }}
            </button>
            <button
              type="submit"
              *ngIf="orderDetailsData?.cartType !== 'INFLUENZA'"
              class="c-button c-button--secondary"
              (click)="getCartId('addCart')"
            >
              {{ 'orderDetail.addToCart' | cxTranslate }}
            </button>
          </section>
          <!-- Modal Start -->
          <ng-template #content let-modal>
            <section class="checkout-modal">
              <section class="modal-header">
                <h2
                  class="c-heading-36 font-bold u-border-bottom-grey pb-4 w-100"
                >
                  {{ 'orderDetail.reorder' | cxTranslate }}
                </h2>
              </section>
              <section class="modal-body confirm-order">
                <section class="text-medium-fixed">
                  <p>{{ 'orderDetail.clearCartDescription' | cxTranslate }}</p>
                </section>
              </section>
              <section class="miniCart-foot d-md-flex px-4">
                <button
                  type="button"
                  class="c-button c-button--secondary w-100 mb-4 mr-4"
                  (click)="existingCart()"
                >
                  {{ 'orderDetail.existingCart' | cxTranslate }}
                </button>
                <button
                  type="submit"
                  class="c-button c-button--primary w-100 mb-4"
                  (click)="clearChildCart()"
                >
                  {{ 'orderDetail.clearReorder' | cxTranslate }}
                </button>
              </section>
            </section>
          </ng-template>
          <!-- Modal End -->
        </div>
      </div>
    </div>
  </div>
</section>
