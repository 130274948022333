<div class="row patient-education patient-three-row">
  <div
    class="col-md-4 patient-education-item"
    *ngFor="let catalog of catalogsList"
  >
    <div class="img-holder text-center">
      <img
        [src]="getImage(catalog.localizedPicture)"
        alt=""
        class="flu-poster"
      />
      <video controls *ngIf="isVideo(catalog.localizedPicture)">
        <source [src]="getImage(catalog.localizedPicture)" type="video/mp4">
        {{"patientEducation.videoTag.notSupported" | cxTranslate}}
      </video>
    </div>
    <div
      class="zoom text-right"
      (click)="openNewModalPopup(catalog.localizedPicture)"
      *ngIf="catalog && catalog.localizedPicture"
    >
      <fa-icon *ngIf="!isVideo(catalog.localizedPicture)" [icon]="faExpand" class="icon"></fa-icon>
    </div>
    <div class="info">
      <h3 innerHtml="{{ catalog.name }}"></h3>
      <!-- <p class="poster-numb">
        <span class="bold"> {{ catalog.code }}</span> |
        {{ catalog?.downloadedMediaType }}
      </p> -->
      <a
        *ngIf="!isVideo(catalog.localizedPicture)"
        class="c-link"
        (click)="downloadFile(catalog.localizedDownloadedMedia, catalog.code)"
        >Download {{ catalog?.downloadedMediaType }}
        <fa-icon
          *ngIf="catalog?.downloadedMediaType == 'PDF'"
          [icon]="faFilePdf"
          class="icon"
        ></fa-icon
      ></a>
    </div>
  </div>
</div>
