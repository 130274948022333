<ng-container>

<div class="row account-overview-header" *ngIf="userInfo?.salesOrg !='CA04'">
  <div class="col-md-12 flu-reservation-title">
    <h3 class="c-heading c-heading-h2">
      {{ 'accountOverview.FluReservations.title' | cxTranslate }}
    </h3>
  </div>
</div>

<section class="row mb-4 pb-4" *ngIf="userInfo?.salesOrg !='CA04'">
  <section class="col-md-8">
    <section *ngIf="displayActiveSeasons">
      <div class="d-flex align-items-center mb-4 mt-4">
        <img src="../../assets/icons/calendar.svg" alt="calendar" class />&nbsp;
        <h4 class="font-bold m-0" *ngIf="!fluResevartion">
          {{ activeSeasons }}
          {{ 'accountOverview.FluContent.activeSeasonsopen' | cxTranslate }}
        </h4>
        <h4 class="font-bold m-0" *ngIf="fluResevartion">
          {{ activeSeasons }} &nbsp;
          {{ 'accountOverview.FluContent.reservation' | cxTranslate }}
          {{ reservationId }}
        </h4>
      </div>
      <p class="ml-4 pl-2" *ngIf="!fluResevartion">
        {{ 'accountOverview.FluContent.makeyour' | cxTranslate }}
        {{ activeSeasons }}
        {{ 'accountOverview.FluContent.influenzareserve' | cxTranslate }}
        {{ endDate | date : 'mediumDate' }}
      </p>
      <p class="ml-4 pl-2" *ngIf="fluResevartion">
        {{ 'accountOverview.FluContent.viewDetails' | cxTranslate }}
      </p>
      <div class="ml-4 pl-2 pt-3">
        <button
          type="button"
          class="c-button c-button--primary"
          (click)="onInfClick()"
        >
          {{ fluResevartion ? viewBtnText : createBtnText }}
        </button>
      </div>
    </section>
    <section
      *ngIf="!displayActiveSeasons"
      class="text-fixed-legal-copy mt-4"
    >
      <p>
        {{ 'accountOverview.FluContent.click' | cxTranslate }}
        <a (click)="navigatetoDb()" class="navigatetoDb-link">{{
          'accountOverview.FluContent.here' | cxTranslate
        }}</a>
        {{ 'accountOverview.FluContent.noactiveSeasonMsg' | cxTranslate }}
      </p>
    </section>
  </section>
  <section class="col-md-4 mt-4 mt-md-0">
    <app-flu-dashboard-banner
      [bannerComponent]="compNameAdBanner6"
    ></app-flu-dashboard-banner>
  </section>
</section>
</ng-container>