import {
  Component,
  HostBinding,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TokenTarget, AsmUi } from '@spartacus/asm/root';
import { AsmService } from '@spartacus/asm/core';
import {
  AuthActions,
  AuthService,
  GlobalMessageService,
  GlobalMessageType,
  RoutingService,
  StateWithClientAuth,
  User
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { DateTimeProvider, OAuthStorage } from 'angular-oauth2-oidc';
import { Observable, of, Subject } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { BaseService } from 'src/app/shared/services/base.service';
import { VscaCsAgentAuthService } from '../../facade/vsca-csagent-auth.service';
import { VscaAsmAuthStorageService } from '../../service/vsca-asm-auth-storage.service';
import { VscaAsmComponentService } from '../../service/vsca-asm-component.service';

@Component({
  selector: 'vsca-cx-asm-main-ui',
  templateUrl: './vsca-asm-main-ui.component.html',
  styleUrls: ['./vsca-asm-main-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VscaAsmMainUiComponent implements OnInit {
  customerSupportAgentLoggedIn$: Observable<boolean>;
  csAgentTokenLoading$: Observable<boolean>;
  customer$: Observable<User | undefined>;
  isCollapsed$: Observable<boolean>;
  protected destroyed$ = new Subject();
  @HostBinding('class.hidden') disabled = false;

  protected startingCustomerSession1 = false;

  constructor(
    private authService: AuthService,
    private csAgentAuthService: VscaCsAgentAuthService,
    private userAccountFacade: UserAccountFacade,
    private asmComponentService: VscaAsmComponentService,
    private globalMessageService: GlobalMessageService,
    private routingService: RoutingService,
    private asmService: AsmService,
    private authStorageService: VscaAsmAuthStorageService,
    private readonly baseService: BaseService,
    private readonly activatedRoute: ActivatedRoute,
    private store: Store<StateWithClientAuth>,
    private _storage: OAuthStorage,
    protected dateTimeService: DateTimeProvider
  ) { }

  ngOnInit(): void {
    this.csAgentTokenLoading$ =
      this.csAgentAuthService.getCustomerSupportAgentTokenLoading();
    this.customer$ = this.authService.isUserLoggedIn().pipe(
      switchMap((isLoggedIn) => {
        if (true) {
          this.handleCustomerSessionStartRedirection1();
          return this.userAccountFacade.get();
        } else {
          return of(undefined);
        }
      })
    );
    this.isCollapsed$ = this.asmService
      .getAsmUiState()
      .pipe(
        map((uiState: AsmUi) =>
          uiState.collapsed === undefined ? false : uiState.collapsed
        )
      );

    let idValue;
    this.activatedRoute.queryParams.subscribe((res) => {
      if (res.hasOwnProperty('asm')) {
        idValue = res.id;
        localStorage.setItem('id_token', idValue);
      }
      if (idValue != null) {
        this.baseService
          .post(`/authenticate/token/${idValue}`, {
            client_id: 'mobile_android',
          })
          .subscribe((res) => {
            this.authStorageService.switchTokenTargetToCSAgent();
            this.authStorageService.setToken(res);
            this.authStorageService.setTokenTarget(TokenTarget.CSAgent);
            this.authStorageService.setEmulatedUserToken(res);
            this.storeAccessTokenResponse(
              res.access_token,
              res.expiration,
              res.refresh_token,
              res.scope
            );
            this.store.dispatch(new AuthActions.Login());
            this.customerSupportAgentLoggedIn$ =
              this.csAgentAuthService.isCustomerSupportAgentLoggedIn();
            this.csAgentTokenLoading$ = of(false);
          });
      } else if (localStorage.getItem('id_token') && localStorage.getItem('id_token') != 'undefined') {
        var idToken = localStorage.getItem('id_token');
        this.baseService
          .post(`/authenticate/token/${idToken}`, {
            client_id: 'mobile_android',
          })
          .subscribe((res) => {
            this.authStorageService.switchTokenTargetToCSAgent();
            this.authStorageService.setToken(res);
            this.authStorageService.setTokenTarget(TokenTarget.CSAgent);
            this.authStorageService.setEmulatedUserToken(res);
            this.storeAccessTokenResponse(
              res.access_token,
              res.expiration,
              res.refresh_token,
              res.scope
            );
            this.store.dispatch(new AuthActions.Login());
            this.customerSupportAgentLoggedIn$ =
              this.csAgentAuthService.isCustomerSupportAgentLoggedIn();
            this.csAgentTokenLoading$ = of(false);
          });
      }
    });
  }

  protected storeAccessTokenResponse(
    accessToken: string,
    expiresIn: Date,
    refreshToken: string,
    grantedScopes: String,
    customParameters?: Map<string, string>
  ): void {
    this._storage.setItem('access_token', accessToken);
    if (grantedScopes && !Array.isArray(grantedScopes)) {
      this._storage.setItem(
        'granted_scopes',
        JSON.stringify(grantedScopes.split(' '))
      );
    } else if (grantedScopes && Array.isArray(grantedScopes)) {
      this._storage.setItem('granted_scopes', JSON.stringify(grantedScopes));
    }

    this._storage.setItem(
      'access_token_stored_at',
      '' + this.dateTimeService.now()
    );

    this._storage.setItem('expires_at', '' + expiresIn);

    if (refreshToken) {
      this._storage.setItem('refresh_token', refreshToken);
    }
    if (customParameters) {
      customParameters.forEach((value: string, key: string) => {
        this._storage.setItem(key, value);
      });
    }
  }

  protected handleCustomerSessionStartRedirection1(): void {
    this.asmComponentService
      .isCustomerEmulationSessionInProgress()
      .pipe(take(1))
      .subscribe((isCustomerEmulated) => {
        if (this.startingCustomerSession1 && isCustomerEmulated) {
          this.startingCustomerSession1 = false;
          this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
          this.routingService.go('/');
        }
      });
  }

  startCustomerEmulationSession({ customerId }: { customerId?: string }): void {
    if (customerId) {
      this.csAgentAuthService.startCustomerEmulationSession(customerId);

      this.startingCustomerSession1 = true;
      window.location.href =
        window.location.origin + '/vsca/en/CAD/my-account/invoices';
    } else {
      this.globalMessageService.add(
        { key: 'asm.error.noCustomerId' },
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }
  }

  hideUi(): void {
    this.disabled = true;
    this.asmComponentService.unload();
  }
}
