<section class="row row-20">
  <section class="container-pdp" *ngIf="displaySection">
    <h3 class="pdp-title">{{ productDetails?.name }}</h3>
    <aside
      aria-label="Product Details"
      class="col-xs-12 col-sm-4 col-md-4 right-navigation float-right"
    >
      <a
        *ngIf="
          productDetails?.images != undefined ||
          productDetails?.images?.length > 0
        "
        (click)="openImage(content)"
      >
        <cx-media
          class="cx-product-image"
          [container]="productDetails?.images?.PRIMARY"
          format="product"
          [alt]="productDetails?.summary"
        ></cx-media>
      </a>
      <a
        *ngIf="
          productDetails?.images === undefined ||
          productDetails?.images?.length === 0
        "
      >
        <img
          class="image-set-in-card product-img"
          [src]="fallbackImage"
          alt=""
        />
      </a>
      <ng-template #content let-modal>
        <ngb-carousel
          *ngIf="
            productDetails?.images != undefined ||
            productDetails?.images?.length > 0
          "
          ngClass="productDetails?.images?.GALLERY.length > 0 ? 'carousel-nopagination' : 'carousel-pagination' "
        >
          <ng-template
            ngbSlide
            *ngFor="let carousel of productDetails?.images?.GALLERY"
          >
            <div class="picsum-img-wrapper">
              <cx-media
                class="cx-product-image carousel-pdp"
                [container]="carousel?.product"
                format="product"
                [alt]="img"
              >
              </cx-media>
              <div class="xzoom-thumbs">
                <a class="item">
                  <cx-media
                    class="cx-product-image carousel-zoom"
                    [container]="carousel?.product"
                    format="product"
                    [alt]="img"
                  >
                  </cx-media>
                </a>
              </div>
            </div>
          </ng-template>
        </ngb-carousel>
      </ng-template>
      <aside
        aria-label="Product Details Pdf "
        class="right-navigation resources-section"
        *ngIf="monographLink"
      >
        <section>
          <section class="prod-detail-pdfs">
            <p class="pdfs-title">
              {{ 'plp.resources' | cxTranslate }}
            </p>
            <p class="pdfs-list d-flex">
              <img
                class="pdf-icon"
                src=" ../../assets/icons/pdf.png "
                alt=" "
              />
              <a
                alt=" "
                [href]="monographLink"
                target=" _blank "
                class="file-link blue"
                >{{ 'plp.monograph' | cxTranslate }}</a
              >
            </p>
          </section>
        </section>
      </aside>
    </aside>
    <section class="col-xs-12 col-sm-8 col-md-8 left-section pb-2 float-left">
      <p class="desc-class" [innerHTML]="description"></p>
      <br />
      <section class="batch-info">
        <p class="desc-title" *ngIf="productDetails?.batchInfo?.batchNumber">
          {{ 'plp.batchNumber' | cxTranslate }}
          <span class="desc-details">{{
            productDetails?.batchInfo?.batchNumber
          }}</span>
        </p>
        <p class="desc-title" *ngIf="productDetails?.batchInfo?.batchNumber">
          {{ 'plp.expiryDate' | cxTranslate }} :
          <span class="desc-details">{{
            productDetails?.batchInfo?.expiryDate
          }}</span>
        </p>
        <p *ngIf="productDetails?.batchInfo?.batchNumber">
          {{ 'plp.batchInfo' | cxTranslate }}
        </p>
        <p class="desc-title">
          {{ 'plp.productNumber' | cxTranslate }} :
          <span class="desc-details">{{ productDetails?.code }}</span>
        </p>
        <hr class="line" />

        <section *ngIf="userDetails !== undefined">
          <section *ngIf="!productDetails?.flu">
            <section class="third-col">
              <section class="row unit-calc">
                <section class="col-md-4 col-xs-6 bottom-spacing price-area">
                  <b class="mb-2 text-center font-weight-bold">{{
                    'plp.unitPrice' | cxTranslate
                  }}</b>
                  <section class="number">
                    <span
                      *ngIf="
                        productDetails?.price == undefined ||
                        productDetails?.price?.value === undefined
                      "
                      >-</span
                    >
                    <span
                      *ngIf="
                        productDetails?.price &&
                        productDetails?.price?.value !== undefined
                      "
                    >
                      <span>{{ productDetails?.price?.value | currency }}</span>
                      {{ 'plp.cad' | cxTranslate }}
                    </span>
                  </section>
                </section>
              </section>

              <section class="row unit-calc d-none d-xl-block">
                <section class="col-md-4 col-xs-6 bottom-spacing price-area">
                  <b class="mb-2 text-center font-weight-bold">{{
                    'orderConfirmation.subTotal' | cxTranslate
                  }}</b>
                  <section class="number">
                    <span
                      *ngIf="
                        productDetails?.price == undefined ||
                        productDetails?.price?.value === undefined
                      "
                      >-</span
                    >
                    <span
                      *ngIf="
                        productDetails?.price &&
                        productDetails?.price?.value !== undefined
                      "
                    >
                      <span>{{
                        productDetails?.price?.value * getProductInputQuantity()
                          | currency
                      }}</span>
                      {{ 'plp.cad' | cxTranslate }}
                    </span>
                  </section>
                </section>
              </section>

              <section class="col-md-4 col-xs-6 bottom-spacing units-area">
                <section *ngIf="!productDetails?.discontinued">
                  <section *ngIf="productDetails?.available">
                    <b class="mb-2 text-center font-weight-bold">{{
                      'plp.units' | cxTranslate
                    }}</b>

                    <section
                      class="unit-area d-flex flex-nowrap align-items-center justify-content-center"
                    >
                      <span
                        class="disabled c-count-change-btn"
                        aria-hidden="true"
                        id="{{ 'minusIcon' + productDetails?.code }}"
                        (click)="
                          performQuantityComputation(
                            2,
                            productDetails?.code,
                            productDetails?.quantityPerUnit
                          )
                        "
                      >
                        <i class="fa fa-minus" aria-hidden="true"></i>
                      </span>
                      <input
                        type="number"
                        class="prod-quantity text-center"
                        value="1"
                        #productInputQuantity
                        (keyup)="
                          performQuantityComputation(
                            null,
                            productDetails?.code,
                            productDetails?.quantityPerUnit
                          )
                        "
                        id="{{ 'prodQuantity' + productDetails?.code }}"
                        min="1"
                        max="9999"
                        onkeypress="return /[0-9a-zA-Z]/i.test(event.key)"
                      />
                      <span
                        class="c-count-change-btn"
                        aria-hidden="true"
                        id="{{ 'plusIcon' + productDetails?.code }}"
                        (click)="
                          performQuantityComputation(
                            1,
                            productDetails?.code,
                            productDetails?.quantityPerUnit
                          )
                        "
                      >
                        <i class="fa fa-plus" aria-hidden="true"></i>
                      </span>
                    </section>
                    <ng-container *ngIf="salesOrg$ | async as salesOrg">
                      <section
                        *ngIf="salesOrg !='CA04'"
                        class="doses-section"
                        id="{{ 'noOfDoses' + productDetails?.code }}"
                      >
                        {{ productDetails?.quantityPerUnit }}
                        {{ 'plp.doses' | cxTranslate }}
                      </section>
                    </ng-container>
                  </section>
                </section>
              </section>
              <section
                class="col-12 col-md-3 no-gutters p-0 mt-3 mt-md-0"
                *ngIf="
                  !productDetails?.discontinued &&
                  productDetails?.available &&
                  !productDetails?.flu
                "
              >
                <button
                  class="c-button c-button--primary w-100"
                  disabled
                  *ngIf="!productDetails?.price ||
                  !productDetails?.price?.value"
                >
                  {{ "plp.unavailableToOrder" | cxTranslate }}
                </button>
                <button
                  class="c-button c-button--primary w-100 added-success"
                  data-di-id="di-id-2fea5a72-b2e23002"
                  [ngbPopover]="popTitleSuccess"
                  placement="bottom"
                  triggers="manual"
                  #p2="ngbPopover"
                  placement="bottom"
                  popoverClass="pdp-itemsuccess-msg"
                  (click)="addToCart(productDetails?.code, p2)"
                  *ngIf="!productDetails?.discontinued &&
                   productDetails?.available &&
                   productDetails?.price &&
                   productDetails?.price?.value"
                >
                  <i
                    *ngIf="buttonName === 'Reserve now'"
                    class="fa fa-calendar"
                    aria-hidden="true"
                  ></i>
                  <span *ngIf="buttonName === 'Add to cart'">{{
                    'addToCartItems.addCart' | cxTranslate
                  }}</span>
                  <span *ngIf="buttonName === 'Login to order'">{{
                    'addToCartItems.loginToOrder' | cxTranslate
                  }}</span>
                  <span
                    class="reserve-now-button"
                    *ngIf="buttonName === 'Reserve now'"
                    >{{ 'addToCartItems.reserveNow' | cxTranslate }}</span
                  >
                </button>
              </section>
            </section>
            <section
              class="sap-allocation-area"
              *ngIf="!productDetails?.discontinued && productDetails?.available"
            >
              <section *ngIf="productDetails?.sapAllocation === true">
                <b class="prod-allocation">{{
                  'plp.productAllocation' | cxTranslate
                }}</b>
                <p class="allocation-p">
                  {{ 'plp.allocationDetails' | cxTranslate }}
                </p>
              </section>
            </section>
          </section>

          <section
            *ngIf="productDetails?.discontinued || !productDetails?.available"
          >
            <section
              *ngIf="productDetails?.discontinued && productDetails?.available"
            >
              <p class="text-center product-discontinued-txt1">
                {{ 'plp.discontinued' | cxTranslate }}
              </p>
            </section>
            <section
              *ngIf="productDetails?.discontinued && !productDetails?.available"
            >
              <p class="text-center product-discontinued-txt1">
                {{ 'plp.discontinued' | cxTranslate }}
              </p>
            </section>
            <section
              *ngIf="
                !productDetails?.discontinued && !productDetails?.available
              "
            >
              <section class="text-center1">
                <b
                  class="text-center product-discontinued-txt1"
                  *ngIf="productDetails?.availabilityText"
                  >{{ productDetails?.availabilityText }}</b
                >
                <b
                  class="text-center product-discontinued-txt1"
                  *ngIf="!productDetails?.availabilityText"
                  >{{ 'plp.productUnavailable' | cxTranslate }}</b
                >
              </section>
            </section>
          </section>

          <section class="added-success-container">
            <ng-template #popTitleSuccess>
              <div class="cart-value-added">
                <img
                  src="../../../assets/icons/confirmation-positive-green.png"
                  class="positive-green-icon"
                  alt="success"
                />
                <span class="confirmation-itemsadded"
                  >{{ itemAddedQuantity }}
                  {{ 'addToCartItems.addEntrySuccess' | cxTranslate }}</span
                >
              </div>
            </ng-template>
          </section>

          <section
            class="flu-class"
            *ngIf="
              productDetails.flu &&
              productDetails?.available &&
              !productDetails?.discontinued
            "
          >
            <section>
              <p class="contact-info">
                {{ 'plp.productAllocationInfo1' | cxTranslate
                }}<b> {{ 'plp.emergencycontact' | cxTranslate }}</b
                >{{ 'plp.productAllocationInfo2' | cxTranslate }}
              </p>
            </section>
            <section
              class="col-md-4 col-sm-4 col-xs-12 button-area flu-button-area"
              *ngIf="
                productDetails.flu &&
                productDetails?.available &&
                !productDetails?.discontinued
              "
            >
              <button
                class="blue-button login-btn ml10 flu-button"
                data-di-id="di-id-2fea5a72-b2e23002 "
                [ngbPopover]="popTitleSuccess"
                placement="bottom"
                triggers="manual"
                #p2="ngbPopover"
                placement="bottom"
                (click)="addToCart(productDetails?.code, p2)"
              >
                <i
                  *ngIf="buttonName === 'Reserve now'"
                  class="fa fa-calendar"
                  aria-hidden="true"
                ></i>
                <span *ngIf="buttonName === 'Add to cart'">{{
                  'addToCartItems.addCart' | cxTranslate
                }}</span>
                <span *ngIf="buttonName === 'Login to order'">{{
                  'addToCartItems.loginToOrder' | cxTranslate
                }}</span>
                <span
                  class="reserve-now-button"
                  *ngIf="buttonName === 'Reserve now'"
                  >{{ 'addToCartItems.reserveNow' | cxTranslate }}</span
                >
              </button>
            </section>
          </section>
        </section>

        <!-- The End -->
        <section>
          <section *ngIf="userDetails == undefined" class="">
            <button type="button" class="btn blue-button" (click)="goToLogin()">
              <span *ngIf="buttonName === 'Add to cart'">{{
                'addToCartItems.addCart' | cxTranslate
              }}</span>
              <span *ngIf="buttonName === 'Login to order'">{{
                'addToCartItems.loginToOrder' | cxTranslate
              }}</span>
              <span
                class="reserve-now-button"
                *ngIf="buttonName === 'Reserve now'"
                >{{ 'addToCartItems.reserveNow' | cxTranslate }}</span
              >
            </button>
          </section>

          <section *ngIf="userDetails !== undefined">
            <section
              *ngIf="!productDetails?.discontinued && productDetails?.available"
              class="products-accordion clear-fix pt2"
            >
              <section class="panel-group">
                <section
                  class="panel panel-default"
                  *ngIf="productDetails?.volumePrices?.length > 0"
                >
                  <section class="panel-heading panel-endborder">
                    <section class="first-level-acc">
                      <label
                        ><img
                          src="../../assets/icons/price-master.png"
                          class="accordion-img"
                          alt=""
                        />
                        {{ 'plp.volumePricing' | cxTranslate }}</label
                      >
                      <span
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#pdp-list1"
                        class="acc-plus-icon fa"
                        aria-expanded="panelVolumeExpanded"
                        (click)="panelVolumeExpanded = !panelVolumeExpanded"
                        [ngClass]="
                          panelVolumeExpanded ? 'fa-minus' : 'fa-plus collapsed'
                        "
                      ></span>
                    </section>
                  </section>
                  <section
                    id="pdp-list1"
                    class="panel-collapse collapse"
                    aria-expanded="panelVolumeExpanded"
                    (click)="panelVolumeExpanded = !panelVolumeExpanded"
                    [ngClass]="panelVolumeExpanded ? 'in' : ''"
                  >
                    <section class="panel-body">
                      <section class="discount-list" id="vol-price-pdp">
                        <table aria-describedby="pdpTable">
                          <thead>
                            <th id=""></th>
                          </thead>
                          <tbody
                            *ngFor="
                              let prices of productDetails?.volumePrices;
                              let i = index
                            "
                          >
                            <tr>
                              <td>
                                {{ prices?.minQuantity }} -
                                {{ prices?.maxQuantity }}
                                {{ 'plp.units' | cxTranslate }}:
                              </td>
                              <td>{{ prices?.value | currency }}</td>
                              <td>
                                <span *ngIf="i > 0" class="save-price"
                                  >{{ 'plp.save' | cxTranslate }}
                                  {{
                                    productDetails?.volumePrices[0].value -
                                      productDetails?.volumePrices[i].value
                                      | currency
                                  }}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </section>
          </section>
          <section class="products-accordion clear-fix pt2">
            <section class="panel-group">
              <!-- update2 -->
              <section
                class="panel panel-default"
                *ngIf="productDetails?.productUpdates"
              >
                <section class="panel-heading">
                  <section class="first-level-acc">
                    <label
                      ><img
                        src="../../assets/icons/warning.png "
                        class="accordion-img"
                        alt=" "
                      />{{ 'plp.productUpdate' | cxTranslate }}
                    </label>
                    <span
                      data-toggle="collapse "
                      data-parent="#accordion "
                      href="#pdp-list3 "
                      class="acc-plus-icon fa"
                      aria-expanded="panelProductExpanded "
                      (click)="panelProductExpanded = !panelProductExpanded"
                      [ngClass]="
                        panelProductExpanded ? 'fa-minus' : 'fa-plus collapsed'
                      "
                    ></span>
                  </section>
                </section>
                <section
                  id="pdp-list3 "
                  class="panel-collapse collapse"
                  aria-expanded="panelProductExpanded "
                  (click)="panelProductExpanded = !panelProductExpanded"
                  [ngClass]="panelProductExpanded ? 'in' : ''"
                >
                  <section class="panel-body productupdate-p">
                    {{ productDetails?.productUpdates }}
                  </section>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
    </section>
    <section class="col-xs-12 col-sm-8 col-md-8 left-section last-section">
      <div class="yCmsContentSlot productDetailsPageSectionCrossSelling">
        <div
          class="yCmsComponent productDetailsPageSectionCrossSelling-component"
        >
          <div class="row">
            <div class="col-md-12" *ngIf="relatedProducts?.length > 0">
              <p class="carousel-title">
                {{ 'plp.relatedProducts' | cxTranslate }}
              </p>
              <hr class="hr-line-12" />

              <ngx-slick-carousel
                class="carousel"
                #slickModal="slick-carousel"
                [config]="slideConfig"
                (init)="slickInit($event)"
                (breakpoint)="breakpoint($event)"
                (afterChange)="afterChange($event)"
                (beforeChange)="beforeChange($event)"
              >
                <app-custom-product-card
                  ngxSlickItem
                  *ngFor="let product of relatedProducts"
                  [product]="product"
                  [iconDisplay]="false"
                >
                </app-custom-product-card>
              </ngx-slick-carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</section>
