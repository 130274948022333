<!-- Product Monograph -->
<div class="acc-order-details">
  <section class="row">
    <section class="col-12 col-sm-12 col-md-12">
      <div class="col-xs-4 col-sm-12 col-md-7">
        <h3 class="monograph-Title">
          {{ 'accountOverview.product_monograph.title' | cxTranslate }}
        </h3>
      </div>
      <hr class="hr-Style" />
      <div *ngIf="productCodes">
        <div
          class="panel-group"
          id="accr-new"
          *ngFor="let prod of prodcutsInfo"
        >
          <div class="panel panel-default">
            <div class="panel-heading">
              <div class="firstLevel-loc product-text-img">
                <label class="prod-Name"
                  >{{ prod?.name
                  }}<a
                    href="{{ prod.productDocuments?.productDocument[0]?.url }}"
                    target="_blank"
                    class="pdf-Style"
                    data-di-id="di-id-61494698-8d975ab0"
                  >
                    <img src="..\..\assets\icons\pdf.png" alt="pdf" />
                    {{
                      'accountOverview.Financials.pdf_label' | cxTranslate
                    }}</a
                  >
                  <div
                    class="sub-text"
                    [innerHTML]="
                      sanitizer.bypassSecurityTrustHtml(prod?.description)
                    "
                  ></div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</div>

<section class="col-12 displayBanner">
  <app-news-updates></app-news-updates>
</section>
