import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { SharedService } from 'src/app/shared/services/shared.service';
import { CustomInfluenzaService } from '../custom-influenza.service';

@Component({
  selector: 'app-flu-charts',
  templateUrl: './flu-charts.component.html',
  styleUrls: ['./flu-charts.component.scss'],
})
export class FluChartsComponent implements OnInit, OnDestroy {
  orderHistory: any = [];
  productsArray: any = [];
  totalDoses: any = 0;
  entriesForChart: any = [];
  chartTableArray: any = [];
  duplicateQuantityStatusArray: any = [];
  chartsBgColorArray: any = [];
  statusInChrtsDisplay: any = 'Shipped';
  fullTrackingUrl: any = '';
  urlPath: any;

  public doughnutChartOptions: ChartOptions;

  public chartColors: any[] = [{ backgroundColor: this.chartsBgColorArray }];
  public doughnutChartLabels: Label[] = [];
  public doughnutChartData: any = [];
  public doughnutChartType: ChartType = 'doughnut';

  dosesInChartDisplay: any = 0;
  url: any;

  chartSubscriber: any;
  consignmentEntries: any = [];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly ref: ChangeDetectorRef,
    private readonly sharedService: SharedService,
    private readonly user: UserAccountFacade,
    private readonly influenzaService: CustomInfluenzaService
  ) {}

  ngOnInit() {
    this.url = this.activatedRoute?.url;
    this.urlPath = this.url?._value[this.url?._value.length - 1]?.path;
    this.chartSubscriber = this.influenzaService.detailedReservation.subscribe(
      (res) => {
        if (res) {
          const data = this.sharedService.getSessionStorage('fluOverviewData');
          this.chartDataFormation(data);
        }
      }
    );
    this.initializeChartOptions();
  }
  ngOnDestroy() {
    this.chartSubscriber?.unsubscribe();
  }

  initializeChartOptions() {
    this.doughnutChartOptions = {
      responsive: true,
      legend: {
        position: 'bottom',
        align: this.urlPath === 'dashboard' ? 'start' : 'center',
        labels: {
          fontSize: 12,
          usePointStyle: true,
        },
      },
      cutoutPercentage: 70,
      maintainAspectRatio: true,
    };
  }

  chartDataFormation(data) {
    this.dosesInChartDisplay = 0;
    this.chartsBgColorArray = [];
    this.chartColors = [{ backgroundColor: this.chartsBgColorArray }];
    this.totalDoses = 0;
    this.orderHistory = [];
    this.entriesForChart = [];
    this.duplicateQuantityStatusArray = [];
    this.doughnutChartData = [];
    this.doughnutChartLabels = [];
    this.chartTableArray = [];
    const dataSetArray = [];
    this.orderHistory.push(data);
    this.entriesForChart = this.orderHistory[0]?.entries;
    this.consignmentEntries = this.orderHistory[0]?.consignments;
    for (let i = 0; i < this.entriesForChart?.length; i++) {
      let status = '';
      let doses = 0;
      let TotalQuantity = 0;

      if (
        this.duplicateQuantityStatusArray.indexOf(
          this.entriesForChart[i]?.quantityStatus
        ) === -1
      ) {
        status = this.entriesForChart[i]?.quantityStatus;
        this.productsArray.push(this.entriesForChart[i]?.product);
        this.doughnutChartLabels.push(status);
        this.setChartColor(status, i);
        let shipDate = '';
        this.duplicateQuantityStatusArray.push(status);
        for (let j = 0; j < this.entriesForChart.length; j++) {
          if (status === this.entriesForChart[j]?.quantityStatus) {
            TotalQuantity += this.entriesForChart[j].quantity;
            const a =
              this.entriesForChart[j]?.quantity *
              this.entriesForChart[j]?.product?.quantityPerUnit;
            doses = doses + a;
            shipDate = this.entriesForChart[j]?.estimatedDeliveryDate;
          }

          j + 1 === this.entriesForChart.length
            ? this.settingTableDataArray(
                doses,
                TotalQuantity,
                dataSetArray,
                status,
                shipDate
              )
            : '';
        }
      }

      i + 1 === this.entriesForChart.length
        ? this.chartFinalDataSet(dataSetArray)
        : '';
    }
  }
  settingTableDataArray(dosesNum, TotalQuan, dataSetArr, statusData, shipDate) {
    const chartObj = {
      doses: dosesNum,
      totalQuantity: TotalQuan,
      status: statusData,
      shippingDate: shipDate,
    };
    chartObj.status === 'Shipped'
      ? (this.dosesInChartDisplay += chartObj.doses)
      : '';
    this.chartTableArray.push(chartObj);
    dataSetArr.push(dosesNum);
  }
  setChartColor(status, index) {
    if (status === 'Shipped') {
      this.chartsBgColorArray.push('#b3a8e6');
    } else if (status === 'Open') {
      this.chartsBgColorArray.push('#7a00e6');
    } else if (status === 'Cancelled') {
      this.chartsBgColorArray.push('#C4D0D3');
    } else if (status === 'Scheduled') {
      this.chartsBgColorArray.push('#003D7C');
    } else if (status === 'InProcess') {
      this.chartsBgColorArray.push('#A0C3E3');
    } else if (status === 'Completed') {
      this.chartsBgColorArray.push('#00501C');
    } else {
      this.chartsBgColorArray.push('#666');
    }
    if (index === this.entriesForChart.length) {
      this.chartColors = [
        {
          backgroundColor: this.chartsBgColorArray,
        },
      ];
    }
  }

  chartFinalDataSet(dataSetArr) {
    dataSetArr.forEach((dataRes, index) => {
      this.totalDoses += parseInt(dataRes);
      this.doughnutChartData.push(dataRes);
    });
    this.chartTableArray.forEach((element) => {
      const filteredData = this.consignmentEntries.filter((item) => {
        if (item?.carrier !== 'YB') {
          return (
            element?.status === item.entries[0]?.orderEntry?.quantityStatus
          );
        }
      });
      let trackingurlArray = [];
      filteredData?.forEach((data) => {
        let obj = {
          trackingUrl: data?.trackingURL,
          trackingUrlId: data?.trackingID,
        };
        trackingurlArray.push(obj);
        element.trackingData = trackingurlArray;
      });
    });
    this.ref.detectChanges();
  }

  navigateToTracking(id, url) {
    let a = url.split('<TRACKN>');
    this.fullTrackingUrl = a[0] + id;
  }
}
