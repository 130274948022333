<section class="container my-4">
  <div class="row">
    <div class="col-md-8 u-border-right-grey pr-md-4 pr-lg-5">
      <section class="edit-saved-cart u-border-top-grey py-4">
        <h3 class="font-bold u-border-bottom-grey pb-4">
          {{ 'addToCartItems.emptyStdCart' | cxTranslate }}
        </h3>
        <div class="darkblue font-14 empty-cart-options">
          <div>
            <a
              class="c-link my-2"
              href="/CAD/Open-Catalogue/c/1"
              title="Continue Shopping"
              >{{ 'addToCartItems.emptyOrderProduct' | cxTranslate }}</a
            >
          </div>
          <div>
            <a
              class="c-link my-2"
              href="/my-account/account-overview"
              title="My Account"
              >{{ 'addToCartItems.myAccount' | cxTranslate }}</a
            >
          </div>
        </div>
      </section>
      <!-- <div class="darkblue empty-cart-options" [innerHTML]="emptycontent"></div> -->
    </div>
    <div class="col-md-4 pl-md-4 pl-lg-5">
      <div class="c-widget c-quick-order-widget">
        <div>
          <h3 class="c-widget-title c-heading c-heading-h3">
            {{ 'addToCartItems.addProducts' | cxTranslate }}
          </h3>
          <h6 class="c-heading c-heading-h5 color-secondary">
            {{ 'addToCartItems.quickOrder' | cxTranslate }}
          </h6>
          <p class="text-fixed-legal-copy">
            {{ 'addToCartItems.searchItems' | cxTranslate }}
          </p>
        </div>
        <app-quick-order class="w-100"></app-quick-order>
      </div>
    </div>
  </div>
</section>
