import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CmsService} from '@spartacus/core';
import {switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

@Component({
  selector: 'app-need-to-go-back',
  templateUrl: './need-to-go-back.component.html',
  styleUrls: ['./need-to-go-back.component.scss']
})
export class NeedToGoBackComponent implements OnInit {

  cmsContent: any;

  constructor(
    private readonly cmsData: CmsService,
    private readonly cd: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.cmsData.getComponentData('NeedAssistanceComponent-orders').pipe(
      switchMap(res => {
        if (res) {
          return of(res);
        } else {
          return this.cmsData.getComponentData('SynviscNeedAssistanceComponent-orders');
        }
      })
    ).subscribe(res => {
      let needAssistanceContent: any = JSON.parse(JSON.stringify(res));
      this.cmsContent = needAssistanceContent?.content;
      this.cd.detectChanges();
    });

  }

}
