import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { LoactionStripService } from 'src/app/shared/modules/location-strip/loaction-strip.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { SharedService } from '../../../shared/services/shared.service';
import { AccountOverviewService } from '../../account-overview.service';

@Component({
  selector: 'app-salestrack',
  templateUrl: './salestrack.component.html',
  styleUrls: ['./salestrack.component.scss'],
})
export class SalestrackComponent implements OnInit {
  SalesTrackAPIurl: string;
  userId: any;
  accountNumber: any;
  userAccSub: Subscription;
  public userAccountIdDetailsData = new Subject<any>();
  usergetData: any;
  usergetDataObj: any;
  ref: any;

  sales_track_doses_title: string;
  sales_track_amount_title: string;
  progress_msg_doses: string;
  progress_msg_amount: string;
  actual_doses_value: number;
  actual_amount_value: number;
  total_doses_value: number;
  total_amount_value: number;
  days_left: number;
  days_left_msg: string;
  remaining_doses: number;
  remaining_doses_msg: string;
  remaining_doses_class: string;
  remaining_amount: number;
  remaining_amount_msg: string;
  remaining_amount_class: string;
  actual_doses_percentage: number;
  original_doses_percentage: string;
  actual_amount_percentage: number;
  original_amount_percentage: string;
  innerStrokeColor: string;
  outerStrokeColor: string;
  clockdirection: boolean;
  innerStrokeColor2: string;
  outerStrokeColor2: string;
  clockdirection2: boolean;
  showdiagram: boolean;
  remaining_amount_status: boolean;
  total_doses_value_formatted: string;
  actual_doses_value_formatted: string;
  remaining_doses_fomatted: string;
  userInfo: any;

  constructor(
    private readonly sharedService: SharedService,
    private readonly user: UserAccountFacade,
    private readonly baseService: BaseService,
    private readonly acService: AccountOverviewService,
    private readonly cdr: ChangeDetectorRef,
    private readonly locService: LoactionStripService
  ) {}

  ngOnInit(): void {
    this.showdiagram = false;
    this.remaining_amount_status = false;
    this.user.get().pipe(take(2)).subscribe((res) => {
      if (res) {
        const qresult: any = res;
      this.userId = qresult?.userName;
      this.getUserAccountData(this.userId);
      this.sharedService.getSalesTrackAPI(this.userId, this.usergetDataObj);
      }
      
    });
  }

  getUserAccountData(userId): void {
    this.locService.getUserAccountDataFromDb(userId);
    this.locService.userAccountData.pipe(distinctUntilChanged()).subscribe((userData) => {
      if (
        userData.res != null &&
        userData.res != undefined &&
        userData.res?.length != 0
      ) {
        
        
        this.usergetData = userData.res.defaultB2BUnit.shippingAddress;
        this.usergetDataObj = userData.res.defaultB2BUnit.uid;
        this.userInfo = userData.res;
        this.getsalestrackingdet();
        this.getsalestrackingamount();
      }
    });
  }

  async getsalestrackingdet() {

    await this.sharedService.salesTrackDet$.subscribe((result) => {
      /*this.sales_track_doses_title = 'Doses Purchased';*/
      this.actual_doses_value = Math.floor(result.totalDoses);
      this.actual_doses_value_formatted =
        this.actual_doses_value.toLocaleString('en-US');
      this.total_doses_value = 2000;
      this.total_doses_value_formatted =
        this.total_doses_value.toLocaleString('en-US');
      this.days_left = result.daysLeft;
      this.progress_msg_doses =
        this.actual_doses_value >= this.total_doses_value
          ? 'Goal Met'
          : this.actual_doses_value_formatted + ' Doses'; //GOAL MET
      this.days_left_msg =
        this.days_left > 0
          ? this.days_left + ' '
          : 'None';
      this.remaining_doses = this.total_doses_value - this.actual_doses_value;
      this.remaining_doses_fomatted =
        this.remaining_doses.toLocaleString('en-US');
      this.remaining_doses_msg =
        this.remaining_doses > 0
          ? this.remaining_doses_fomatted + ' '
          : 'Goal Achieved';
      this.remaining_doses_class =
        this.remaining_doses > 0 ? 'pending' : 'success';
      //this.actual_doses_percentage = Math.floor(this.actual_doses_value / this.total_doses_value * 100);
      this.actual_doses_percentage = Math.floor(result.totalDosesPercentage);
      this.original_doses_percentage = this.actual_doses_percentage + '%';
      if (this.remaining_doses <= 0) {
        /* If Equal to 100 percent */
        this.original_doses_percentage = '100%';
        this.innerStrokeColor = '';
        this.outerStrokeColor = '#7b00e6';
      } else if (this.remaining_doses > 0) {
        /* If below 100 percent */
        this.innerStrokeColor = '#bbbec2';
        this.outerStrokeColor = '#7b00e6';
      }
      if (this.userInfo?.defaultB2BUnit?.salesOrg != "CA04") {
        this.showdiagram = true;
      }
      
      this.cdr.detectChanges();
    });
  }
  async getsalestrackingamount() {
  
    await this.sharedService.salesTrackDet$.subscribe((result) => {
      /*this.sales_track_amount_title = 'Amount Spent (in CAD)';*/
      this.actual_amount_value = result.totalAmount;
      this.total_amount_value = 75000;
      this.days_left = result.daysLeft;
      this.progress_msg_amount =
        this.actual_amount_value >= this.total_amount_value
          ? 'Goal Met'
          : '$' + this.actual_amount_value; //GOAL MET
      this.days_left_msg =
        this.days_left > 0
          ? this.days_left + ' '
          : 'None';
      this.remaining_amount =
        this.total_amount_value - this.actual_amount_value;
      this.remaining_amount_status = this.remaining_amount > 0 ? true : false;
      this.remaining_amount_msg =
        this.remaining_amount > 0
          ? this.remaining_amount.toFixed(2) + ' '
          : 'Goal Achieved';
      this.remaining_amount_class =
        this.remaining_amount > 0 ? 'pending' : 'success';
      //this.actual_amount_percentage = Math.floor(this.actual_amount_value / this.total_amount_value * 100);
      this.actual_amount_percentage = Math.floor(result.totalAmountPercentage);
      this.original_amount_percentage = this.actual_amount_percentage + '%';
      if (this.remaining_amount <= 0) {
        /* If Equal to 100 percent */
        this.original_amount_percentage = '100%';
        this.innerStrokeColor2 = '';
        this.outerStrokeColor2 = '#7b00e6';
      } else if (this.remaining_amount > 0) {
        /* If below 100 percent */
        this.innerStrokeColor2 = '#bbbec2';
        this.outerStrokeColor2 = '#7b00e6';
      }
      if (this.userInfo?.defaultB2BUnit?.salesOrg != "CA04") {
        this.showdiagram = true;
      }
      this.cdr.detectChanges();
    });
  }

  checkRespValue(value) {

  }
}
