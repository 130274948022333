<div class="row">
  <div class="col-xs-12 col-sm-12 col-md-12 mb-2 py-3">
    <div class="col-xs-12 col-md-12 mb-5">
      <h2 class="mb-4 c-form-title">
        {{ 'userRegistration.accountInformation.header' | cxTranslate }}
      </h2>
      <h4 class="mb-4 c-form-copy">
        {{ 'userRegistration.accountInformation.title' | cxTranslate }}
      </h4>
      <p class="text-fixed-legal-copy">
        {{ 'userRegistration.accountInformation.formTitle' | cxTranslate }}
      </p>
      <div
        class="col-md-12 c-alert-banner c-alert-banner--fail"
        [ngClass]="{ 'acc-invalid': billToShipError === true }"
        *ngIf="errorMsg"
      >
        <span class="alert-icon">
          <cx-icon
            class="cx-icon fas fa-exclamation-circle"
            ng-reflect-type="ERROR"
          >
          </cx-icon>
        </span>
        <span class="dismissible-error-message" *ngIf="!billToShipError">
          {{
            'userRegistration.accountInformation.formValidationMsg'
              | cxTranslate
          }}</span
        >
        <span class="dismissible-error-message" *ngIf="billToShipError">
          {{
            'userRegistration.accountInformation.billToShipToValidationMsg'
              | cxTranslate
          }}</span
        >
      </div>
    </div>
    <form
      [formGroup]="accountInfoForm"
      id="accountInfoForm"
      (ngSubmit)="validateAccountInfo()"
    >
      <div class="col-xs-12 col-sm-6 col-md-6 mb-2">
        <div id="registerBilling" class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 mb-3 c-text-input">
            <ng-template #accountNumberPopContent>{{
              'userRegistration.accountInformation.accountNumber.popoverMsg'
                | cxTranslate
            }}</ng-template>
            <label
              for="accountNumber"
              class="Fields-label icon-label d-inline c-form-label"
              >{{
                'userRegistration.accountInformation.accountNumber.label'
                  | cxTranslate
              }}
            </label>
            <a
              placement="top"
              class="d-inline"
              [ngbPopover]="accountNumberPopContent"
            >
              <!-- <img
              src="../../assets/icons/faqs_reg.png" alt="faq_reg" class="faq" /> -->
              <i
                color="red"
                class="fa fa-question-circle c-icon-primary-sm"
              ></i>
            </a>
            <input
              id="accountNumber"
              name="accountNumber"
              data-valid="valid_facility"
              aria-labelledby="accountNumber tooltipFacilityName"
              type="text"
              placeholder="XXXXXXXXX"
              class="Fields-input"
              autocomplete="off"
              formControlName="accountNumber"
              data-di-id="#accountNumber"
            />

            <cx-form-errors
              *ngIf="
                !accountInfoForm.get('accountNumber').errors?.required &&
                !accountInfoForm.get('accountNumber').valid &&
                accountInfoForm.get('accountNumber').touched
              "
              class="errorMessage"
            >
              <p>
                {{
                  'userRegistration.accountInformation.accountNumber.validationMessage.acceptsNumberValidation'
                    | cxTranslate
                }}
              </p>
            </cx-form-errors>
            <cx-form-errors
              *ngIf="
                accountInfoForm.get('accountNumber').errors?.required &&
                !accountInfoForm.get('accountNumber').valid &&
                accountInfoForm.get('accountNumber').touched
              "
              class="defaultMessage"
              role="alert"
              aria-live="assertive"
            >
              <p>
                {{
                  'userRegistration.accountInformation.requiredMsg'
                    | cxTranslate
                }}
              </p>
            </cx-form-errors>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 mb-3 c-text-input">
            <label for="zipCode" class="Fields-label c-form-label">{{
              'userRegistration.accountInformation.postalCode.label'
                | cxTranslate
            }}</label>
            <input
              id="postalCode"
              name="postalCode"
              data-valid="valid_zip_canada"
              type="text"
              maxlength="7"
              class="Fields-input"
              autocomplete="off"
              formControlName="postalCode"
              data-di-id="#postalCode"
            />

            <cx-form-errors
              *ngIf="
                accountInfoForm.get('postalCode').errors?.pattern &&
                !accountInfoForm.get('postalCode').valid &&
                accountInfoForm.get('postalCode').touched
              "
              class="errorMessage"
              role="alert"
              aria-live="assertive"
            >
              <p>
                {{
                  'userRegistration.accountInformation.postalCode.invalidFormatMsg'
                    | cxTranslate
                }}
              </p>
            </cx-form-errors>
            <cx-form-errors
              *ngIf="
                accountInfoForm.get('postalCode').errors?.required &&
                !accountInfoForm.get('postalCode').valid &&
                accountInfoForm.get('postalCode').touched
              "
              class="defaultMessage"
              role="alert"
              aria-live="assertive"
            >
              <p>
                {{
                  'userRegistration.accountInformation.requiredMsg'
                    | cxTranslate
                }}
              </p>
            </cx-form-errors>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mb-4 c-text-input">
          <div class="col-xs-12 col-sm-12 col-md-6 captcha-x">

            <re-captcha
              formControlName="recaptchaReactive"
              (resolved)="resolved($event)"
            ></re-captcha>
            <cx-form-errors
              *ngIf="
                accountInfoForm.get('recaptchaReactive').errors?.required &&
                !accountInfoForm.get('recaptchaReactive').valid &&
                accountInfoForm.get('recaptchaReactive').touched
              "
              class="defaultMessage"
              role="alert"
              aria-live="assertive"
            >
              <p>
                {{
                  'userRegistration.userInformation.requiredMsg' | cxTranslate
                }}
              </p>
            </cx-form-errors>

          </div>
        </div>
      </div>
      <div class="col-sm-8 col-md-8 mb-4">
        <button
          type="submit"
          class="c-button c-button--primary c-button--lg reviewvalidbtn"
          data-di-id="di-id-7a6cb2cd-cb37948a"
        >
          {{ 'userRegistration.accountInformation.buttonLabel' | cxTranslate }}
        </button>
      </div>
    </form>
  </div>
</div>
