<div class="container mb-1">
  <div class="row">
    <div class="col-md-6 mb-4">
      <p class="text-fixed-medium mb-0">
        {{ 'addToCartItems.orderUpdated' | cxTranslate }}
      </p>
    </div>
    <div class="col-md-6 mb-4 text-right">
      <a
        id="continueShoppingId"
        data-di-id="#continueShoppingId"
        (click)="backToDashBoard()"
        target=""
        class="c-link"
      >
        <fa-icon [icon]="faChevronLeft" class="icon"></fa-icon>
        {{ 'selectVaccines.back_to_db' | cxTranslate }} </a
      >&nbsp;&nbsp;
      <a
        id="tealium-xls"
        data-di-id="#tealium-xls"
        class="c-link"
        aria-label="download as"
        href="javascript:void(0)"
        target=""
        (click)="downloadAsExcelFlu()"
      >
        <span>{{ 'addToCartItems.xls' | cxTranslate }}</span>
        <fa-icon [icon]="faFileExcel" class="icon"></fa-icon> </a
      >&nbsp;&nbsp;
      <a
        id="printLinkId"
        data-di-id="#printLinkId"
        class="c-link"
        href="javascript:void(0)"
        target=""
        onclick="window.print();"
      >
        <span>{{ 'addToCartItems.print' | cxTranslate }}</span>
        <fa-icon [icon]="faPrint" class="icon"></fa-icon>
      </a>
    </div>
    <div class="col-md-12">
      <h3 class="font-bold">
        {{ activeSeasonId }} {{ 'selectVaccines.season' | cxTranslate }}
      </h3>
    </div>
  </div>
</div>
<section class="container mb-4 mt-4">
  <section class="row pb-4">
    <!-- Left -->
    <div class="col-md-8 u-border-right-grey pr-md-4 pr-lg-5">
      <div class="row pb-4 u-border-bottom-grey">
        <div class="col-lg-8">
          <i class="fa fa-map-marker-alt font-n"></i>
          <span class="px-3 text-fixed-medium"
            >{{ selectedData?.companyName }}
            {{ '(' + selectedData?.unitID + ')' }}</span
          >
        </div>
        <div class="col-lg-4 d-lg-flex justify-content-between">
          <span class="font-bold"
            >{{ 'orderConfirmation.po' | cxTranslate }}:</span
          >
          <span *ngIf="purchaseOrderNumber; else NA">{{
            purchaseOrderNumber
          }}</span>
          <ng-template #NA>
            WWW:{{ orderDate | slice : 0 : 10 | date : 'dd/MM/yyyy' }}
          </ng-template>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-12">
          <!-- Sold to -->
          <div class="row pb-4">
            <div class="col-md-8">
              <h3 class="font-bold">
                {{ 'orderConfirmation.soldTo' | cxTranslate }}
              </h3>
              <p class="text-fixed-medium">#{{ selectedData?.unitID }}</p>
            </div>
            <div class="col-md-4 text-fixed-medium">
              <div class="row">
                <div class="col-md-6">
                  {{ 'orderConfirmation.subTotal' | cxTranslate }}
                </div>
                <div class="col-md-6 text-right">
                  {{ dataForFluConfirmation[0]?.subTotal?.value | currency }}
                  {{ 'plp.cad' | cxTranslate }}
                </div>
              </div>
              <div
                class="row py-1"
                *ngIf="dataForFluConfirmation[0]?.totalTax?.value > 0"
              >
                <div class="col-md-6">
                  {{ 'orderConfirmation.salesTax' | cxTranslate }}
                </div>
                <div class="col-md-6 text-right">
                  {{ dataForFluConfirmation[0]?.totalTax?.value | currency }}
                  {{ 'plp.cad' | cxTranslate }}
                </div>
              </div>
              <div class="row u-border-top-grey font-bold py-1">
                <div class="col-md-6">
                  {{ 'orderConfirmation.totalWithSaving' | cxTranslate }}
                </div>
                <div class="col-md-6 text-right">
                  {{
                    dataForFluConfirmation[0]?.totalPriceWithTax?.value
                      | currency
                  }}
                  {{ 'plp.cad' | cxTranslate }}
                </div>
              </div>
              <div class="row mt-2" *ngIf="freightProduct">
                <section class="col-md-12 text-fixed-legal">
                  <p>
                    {{ 'addToCartItems.shippingChargesNote' | cxTranslate }}
                  </p>
                </section>
              </div>
            </div>
          </div>
          <!-- Ship to -->
          <div class="row">
            <div class="col-md-12">
              <h3 class="font-bold">
                {{ 'orderConfirmation.shipTo' | cxTranslate }}
              </h3>
            </div>
            <div class="col-md-12 text-fixed-medium pb-4">
              <p class="mb-0">
                {{ selectedData?.companyName ? selectedData?.companyName : ''
                }}<br />
                {{ selectedData?.line1 ? selectedData?.line1 + ',' : '' }}<br />
                <span *ngIf="selectedData?.line2"
                  >{{ selectedData?.line2 ? selectedData?.line2 + ',' : ''
                  }}<br
                /></span>
                {{ selectedData?.town ? selectedData?.town + ',' : '' }}|<br />
                {{ selectedData?.postalCode ? selectedData?.postalCode : '' }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <h3 class="font-bold">
                {{ 'orderConfirmation.orderDate' | cxTranslate }}
              </h3>
            </div>
            <div class="col-md-12 text-fixed-medium pb-4">
              <p class="mb-0">
                <span class="data">{{
                  orderDate | slice : 0 : 10 | date : 'dd/MM/yyyy'
                }}</span>
              </p>
            </div>
          </div>
          <!-- Order / Estimate date -->
          <div class="row" *ngIf="estimatedDeliveryDate != ''">
            <div class="col-md-12">
              <h3 class="font-bold">
                {{ 'orderConfirmation.reqDeliveryDate' | cxTranslate }}
              </h3>
            </div>
            <div class="col-md-12 text-fixed-medium pb-4">
              <p class="mb-0">
                <span class="data">{{
                  estimatedDeliveryDate | slice : 0 : 10 | date : 'dd/MM/yyyy'
                }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <section
        class="row u-border-top-grey py-4 mt-4 mt-md-0"
        *ngFor="let productEntry of productEntries"
      >
        <!-- Image -->
        <section class="col-md-2 mb-4">
          <a (click)="productDetails(productEntry)">
            <img
              class="prod-Image"
              *ngIf="productEntry.thumbNailImg"
              src="{{ baseUrl }}{{ productEntry.thumbNailImg }}"
              alt=""
            />

            <img
              class="prod-Image"
              *ngIf="!productEntry?.thumbNailImg"
              [src]="fallbackImage"
              alt=""
            />
          </a>
        </section>
        <!-- details -->
        <section class="col-md-10">
          <section class="row c-prod-details">
            <!-- Title -->
            <div class="col-md-12">
              <a (click)="productDetails(productEntry)">
                <h2
                  class="font-bold c-heading c-heading-h4 mb-4"
                  [innerHTML]="productEntry?.product?.name"
                ></h2>
              </a>
            </div>
            <!-- Units -->
            <div
              class="col-md-12 d-md-flex justify-content-between text-fixed-medium"
            >
              <div>
                <span class="font-bold">{{ 'plp.units' | cxTranslate }}</span>
                <span class="d-inline-block d-sm-none"
                  >:&nbsp;{{ productEntry?.quantity }}</span
                >
                <span class="d-none d-md-block"
                  >{{ productEntry?.quantity }} &nbsp; ({{
                    productEntry?.product?.quantityPerUnit *
                      productEntry?.quantity
                  }}
                  {{ 'selectVaccines.doses' | cxTranslate }})
                </span>
              </div>

              <div>
                <span class="font-bold">{{
                  'addToCartItems.subtotal' | cxTranslate
                }}</span>
                <span class="d-inline-block d-sm-none"
                  >:&nbsp;{{ productEntry?.totalPrice?.value | currency }}
                  {{ 'plp.cad' | cxTranslate }}</span
                >
                <span class="d-none d-md-block"
                  >{{ productEntry?.totalPrice?.value | currency }}
                  {{ 'plp.cad' | cxTranslate }}</span
                >
              </div>
              <div>
                <span class="font-bold">{{
                  'addToCartItems.estDeliveryDate' | cxTranslate
                }}</span>
                <section *ngIf="productEntry?.product.onsn !== 'ON'">
                  {{ 'orderConfirmation.tbd' | cxTranslate }}
                </section>
                <section *ngIf="productEntry?.product.onsn === 'ON'">
                  {{
                    productEntry?.estimatedDeliveryDate
                      | slice : 0 : 10
                      | date : 'dd/MM/yyyy'
                  }}
                </section>
              </div>
            </div>
            <section class="col-md-12 text-fixed-medium py-4">
              <div
                class="m-0"
                [innerHTML]="productEntry?.product?.description"
              ></div>
            </section>
            <section class="col-md-12 text-fixed-medium">
              <span class="font-bold"
                >{{ 'plp.productNumber' | cxTranslate }}:&nbsp;</span
              >
              <span class="prdct-No">{{ productEntry?.product?.code }}</span>
            </section>
          </section>
        </section>
      </section>
    </div>

    <!-- Sidebar -->
    <div class="col-md-4 pl-md-4 pl-lg-5">
      <div class="row d-md-none">
        <div class="col-md-12 u-border-top-grey pb-4"></div>
      </div>
      <div class="row">
        <!-- Units/Doses -->
        <div class="col-md-12 mb-2 text-center">
          <h4 class="mb-0">
            {{ totalProducts ? totalProducts : '' }}
            {{ 'selectVaccines.units' | cxTranslate }} |
            {{ totalDoses ? totalDoses : '' }}
            {{ 'selectVaccines.doses' | cxTranslate }}
          </h4>
        </div>
        <!-- Total -->
        <div
          class="col-md-12 py-4 d-flex align-items-center justify-content-center justify-content-md-between flex-column flex-md-row"
        >
          <h3 class="font-bold mb-0">
            {{ 'orderConfirmation.estimatedTotal' | cxTranslate }}
          </h3>
          <h1 class="font-bold mb-0">
            {{ estTotal ? (estTotal | currency) : '' }}
            {{ 'plp.cad' | cxTranslate }}
          </h1>
        </div>
      </div>
      <app-flu-confirmation-right-content></app-flu-confirmation-right-content>
    </div>
  </section>
</section>
