import { NgModule } from '@angular/core';
import { AsmCoreModule } from '@spartacus/asm/core';
import { AsmOccModule } from '@spartacus/asm/occ';
import { provideConfig } from '@spartacus/core';
import { VscaAsmComponentsModule } from './vsca-asm-components.module';

@NgModule({
  imports: [VscaAsmComponentsModule, AsmCoreModule, AsmOccModule],
  providers: [
    provideConfig({
      asm: {
        agentSessionTimer: {
          startingDelayInSeconds: 32400,
        },
      },
    }),
  ],
})
export class VscaAsmModule {}
