<form (ngSubmit)="onSubmit()" [formGroup]="form">
  <div
    class="customer-login c-login-form"
    [style.margin]="formMargin"
    [style.border]="backgroundImg ? 'none' : null"
  >
    <h2 class="c-heading c-heading-h2 mb-3">
      {{ 'loginDetails.customerLogin' | cxTranslate }}
    </h2>

    <div class="c-login-form--content">
      <div class="fields-container c-text-input">
        <label>{{ 'loginDetails.userName' | cxTranslate }}</label>
        <input
          type="text"
          formControlName="userId"
          [placeholder]="'loginDetails.userNamePlaceholder' | cxTranslate"
        />
        <cx-form-errors [control]="form.get('userId')"></cx-form-errors>
      </div>

      <div class="fields-container c-text-input">
        <label>{{ 'loginDetails.password' | cxTranslate }}</label>
        <input
          type="password"
          formControlName="password"
          [placeholder]="'loginDetails.passwordPlaceholder' | cxTranslate"
        />
        <cx-form-errors [control]="form.get('password')"></cx-form-errors>
      </div>

      <div>
        <button
          type="submit"
          [disabled]="form.disabled"
          class="c-button c-button--primary"
        >
          {{ 'loginDetails.login' | cxTranslate }}
        </button>
      </div>

      <p class="c-login-form--forgot text-fixed-medium mb-5">
        <span>{{ 'loginDetails.forgot' | cxTranslate }} </span>
        <a
          class="c-link"
          href=" javascript: void(0); "
          (click)="gotoLoginHelp()"
        >
          {{ 'loginDetails.userName' | cxTranslate | lowercase }}
        </a>
        <span> {{ 'loginDetails.or' | cxTranslate }} </span>
        <a
          class="c-link"
          href=" javascript: void(0);"
          (click)="gotoLoginHelp()"
        >
          {{ 'loginDetails.password' | cxTranslate | lowercase }}?
        </a>
      </p>

      <p class="c-login-form--are-you-new-user">
        {{ 'loginDetails.newUserLabel' | cxTranslate }}
      </p>

      <a
        [routerLink]="['/', 'customer-register']"
        href="javascript: void(0);"
        class="c-link"
      >
        {{ 'loginDetails.newUserExistingAccount' | cxTranslate }}
      </a>

      <p>
        <a
          class="c-link"
          [routerLink]="['/', 'register']"
          href=" javascript: void(0); "
          >{{ 'loginDetails.newAccount' | cxTranslate }}</a
        >
      </p>
    </div>
  </div>

  <div class="Fields-btn-group visible-xs mob-login">
    <button
      type=" button "
      [routerLink]="['/', 'login']"
      class="c-button c-button--primary focus"
      data-toggle=" modal "
      data-target=" #mob-Modal "
      tabindex=" 0 "
      data-di-id=" di-id-bde3b1e1-54925d6b "
    >
      {{ 'loginDetails.login' | cxTranslate }}
    </button>
    <a
      [routerLink]="['/', 'customer-register']"
      role=" presentation "
      data-di-id=" di-id-f1f329d5-b2fa61f4 "
    >
      <button type=" button " class="c-button c-button--secondary">
        {{ 'loginDetails.createAccount' | cxTranslate }}
      </button>
    </a>
  </div>
</form>
