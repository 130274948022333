import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as config from '../shared/config/app.config';
import { BaseService } from '../shared/services/base.service';
import { ExcelDownloadService } from '../shared/services/excel-download.service';

@Injectable({
  providedIn: 'root',
})
export class AccountOverviewService {
  public userAccountIdDetailsData = new Subject<any>();

  constructor(
    private readonly baseService: BaseService,
    public readonly excelService: ExcelDownloadService
  ) { }

  public getAccountId() {
    this.baseService.get(config.GET_ACCOUNT_ID).subscribe((res) => {
      if (res !== null && res !== undefined && res?.length !== 0) {
        this.userAccountIdDetailsData.next(res);
        this.getAccountDetailsForLocalStorage();
      }
    });

  }


  public getAccountDetailsForLocalStorage() {

    this.baseService.get(config.USER_NAME).subscribe((resp) => {
      if (resp !== null && resp !== undefined && resp?.length !== 0) {
        if (resp?.janrainId !== undefined) {
          localStorage.setItem('user_id', resp?.janrainId)
        }
        else {
          localStorage.setItem('user_id', 'NA')
        }
        if (localStorage.getItem('sso_enabled')) {
          localStorage.setItem('user_type', "sales_rep");
        } else {
          localStorage.setItem('user_type', "customer");
        }
        if (resp?.role !== "unknown") {
          localStorage.setItem('user_function', resp?.role);
        } else {
          localStorage.setItem('user_function', "NA");
        }
        localStorage.setItem('user_login_id', resp?.userName);
        localStorage.setItem('user_account_id', resp?.orgUnit.uid);
      }
    });
  }
  public downloadXls(data, name, filenameNoChange = true) {
    this.excelService.exportAsExcelFile(data, name, filenameNoChange);
  }
}
