import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomCmsParagraph } from './custom-cms-paragraph.component';

@NgModule({
  declarations: [CustomCmsParagraph],
  exports: [CustomCmsParagraph],
  imports: [CommonModule],
})
export class CustomCmsParagraphModule {}
