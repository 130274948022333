import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  LanguageService,
  OccEndpointsService,
  RoutingService,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { CustomInfluenzaService } from '../custom-influenza.service';
import { ProductDetailsInfluenzaComponent } from '../product-details-influenza/product-details-influenza.component';
@Component({
  selector: 'app-influenza-dashbaord-footer',
  templateUrl: './influenza-dashbaord-footer.component.html',
  styleUrls: ['./influenza-dashbaord-footer.component.scss'],
})
export class InfluenzaDashbaordFooterComponent implements OnInit, OnDestroy {
  userData: any = [];
  activeSeasons: any;
  productList = [];
  infContact: any = [];

  @Input() reserveNowClicked: any;
  @Input() displayActiveSeasons: any;
  @Output() newItemEvent = new EventEmitter<string>();
  compName = 'SanofiFluDashboardBannerComponent';

  languages = [];

  preferredContact = '';
  baseUrl: any;
  emailChecked: boolean;
  faxChecked: boolean;
  fnameEdited: boolean;
  fname: any;
  screenwidth: any;
  fallbackImage = '../../assets/i18n-assets/sanofi_missing_product.jpg';
  addClicked: boolean;
  languageUsed = 'en';
  preferredLang: string;
  activeSeasonSubscriber: any;
  influenzaConatctSubscriber: any;
  userAccsubs: Subscription;
  constructor(
    private readonly influenzaService: CustomInfluenzaService,
    private readonly user: UserAccountFacade,
    private readonly ref: ChangeDetectorRef,
    private readonly occEndPoint: OccEndpointsService,
    private readonly router: RoutingService,
    private readonly modalService: NgbModal,
    private readonly lang: LanguageService
  ) {
    this.screenwidth = window.screen.width;
    this.baseUrl = this.occEndPoint.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    });
  }

  ngOnInit(): void {
    this.lang.getActive().subscribe((res) => {
      this.languages = [];
      this.languageUsed = res;
      if (this.languageUsed === 'en') {
        this.languages = ['English', 'French'];
      } else {
        this.languages = ['Anglais', 'Français'];
      }
    });
    this.userAccsubs = this.user.get().subscribe((res) => {
      if (res) {
        this.userData = res;
        this.influenzaService.getActiveSeasonsFromDb(this.userData.userName);
        this.influenzaService.getFluOrderContact(this.userData.userName);
        this.getActiveSeasons();
        this.getInfContact();
      }
    });
  }
  ngOnDestroy() {
    this.activeSeasonSubscriber?.unsubscribe();
    this.influenzaConatctSubscriber?.unsubscribe();
    this.userAccsubs?.unsubscribe();
  }
  getActiveSeasons() {
    this.activeSeasonSubscriber = this.influenzaService.activeSeaons?.subscribe(
      (data) => {
        if (data) {
          this.productList = data?.categoryData[0]?.products;
          if (this.productList) {
            this.productList.forEach((ele) => {
              let thumbNailImg = '';
              if (ele?.images) {
                thumbNailImg = ele?.images[2]?.url;
              }
              ele['thumbNailImg'] = thumbNailImg;
            });
            this.activeSeasons = data.categoryData[0].code;
          }

          this.ref.detectChanges();
        }
        this.activeSeasonSubscriber?.unsubscribe();
      }
    );
  }
  getInfContact() {
    this.influenzaConatctSubscriber =
      this.influenzaService.influenzaContact.subscribe((resp) => {
        if (resp) {
          this.infContact = resp?.defaultB2BUnit?.influenzaContactInfos;
          if (this.infContact) {
            this.newItemEvent.emit(this.infContact[0].firstName);
            this.ref.detectChanges();
          }
        }
        this.influenzaConatctSubscriber?.unsubscribe();
      });
  }

  onReserve() {
    if (this.infContact) {
      if (this.infContact[0]?.firstName) {
        this.router.goByUrl('/influenza/category');
      }
    } else {
      this.reserveNowClicked = true;
    }
  }
  productDetails(productDetails) {
    const modalRef = this.modalService.open(ProductDetailsInfluenzaComponent, {
      windowClass: 'product-details-inf-modal',
    });
    modalRef.componentInstance.productDetails = productDetails;
    modalRef.componentInstance.standardCartCode = productDetails.code;
  }
}
