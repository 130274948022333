<section class="checkout-modal tools-dialog mb-4">
  <div class="close" (click)="activeModal.dismiss('Cross click')">
    <i
      class="fa-solid fa-x c-icon-primary c-icon-primary-sm c-icon-round-hover mr-3 mt-4"
    ></i>
  </div>
  <div class="modal-header mb-4"></div>
  <div class="modal-body">
    <section>
      <img [src]="image" alt="" class="flu-poster" />
    </section>
  </div>
</section>
