import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CmsConfig, I18nModule, provideDefaultConfig} from '@spartacus/core';
import {GenericLinkModule, MediaModule, SpinnerModule} from '@spartacus/storefront';
import {CustomVideoComponent} from './custom-video.component';
import {SafePipe} from '../../shared/pipe/safe.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    GenericLinkModule,
    MediaModule,
    I18nModule,
    SpinnerModule,
  ],
  providers: [
    provideDefaultConfig({
      cmsComponents: {
        VideoComponent: {
          component: CustomVideoComponent,
        },
      },
    } as CmsConfig),
  ],
  declarations: [CustomVideoComponent, SafePipe],
  exports: [CustomVideoComponent],
})
export class CustomVideoModule {
}
