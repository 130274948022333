<ng-container
  *ngIf="
    isCustomerEmulationSessionInProgress$ | async;
    else realCustomerSession
  "
>
  <input
    formcontrolname="customer"
    type="text"
    disabled="true"
    placeholder="{{ customer?.name }}, {{ customer?.uid }}"
  />
  <button class="end-session" (click)="logoutCustomer()">
    {{ "asm.endSession" | cxTranslate }}
  </button>
</ng-container>

<ng-template #realCustomerSession>
  <vsca-cx-customer-selection
    *ngIf="isCollapsed$"
    (submitEvent)="startCustomerEmulationSession($event)"
  ></vsca-cx-customer-selection>
</ng-template>
