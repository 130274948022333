<!-- =========================LEFT SECTION============================================= -->
<div
  class="pl-3 pr-3 d-flex justify-content-between justify-content-sm-end gap-3 ml-sm-auto"
>
  <span class="c-link" (click)="backToDashBoard()">
    <i class="fa fa-chevron-left"></i>
    {{ 'selectVaccines.back_to_db' | cxTranslate }}</span
  >
  <span class="c-link" (click)="OnFluPrint()">
    <i class="fa fa-print"></i>
    {{ 'selectVaccines.print' | cxTranslate }}</span
  >
</div>
<div class="container header-Container"></div>
<section class="container mt-5 mt-sm-0">
  <section>
    <aside
      class="col-lg-4 col-xl-3 col-sm-12 right-navigation totals-width p0 est-Rwd d-flex flex-column align-items-center float-md-right p-sm-3"
    >
      <div>
        <img
          src="/assets/icons/saved-cart-icon-original.svg"
          alt="chart-image"
          class="chart-image-css"
        />
        <span
          >{{ totalProducts ? totalProducts : '' }} of
          {{ totalDoses ? totalDoses : '' }}
          {{ 'selectVaccines.doses' | cxTranslate }} shipped</span
        >
      </div>
      <section class="estimation-Container">
        <section class="row est-Bground">
          <section class="col-md-12 col-lg-12 col-xl-12 text-center">
            <div class="est-total">
              <span>{{
                'orderConfirmation.reservationTotal' | cxTranslate
              }}</span>
              <span>{{ estTotal ? (estTotal | currency) : 0 }} CAD</span>
            </div>
          </section>
        </section>
        <section class="d-flex flex-column mt-3">
          <section class="sub-Total-Div">
            <span class="rese-Name">{{
              'orderConfirmation.subTotal' | cxTranslate
            }}</span>
            <span class=""
              >{{ subTotal ? (subTotal | currency) : 0 }}
              {{ currencyIso }}</span
            >
          </section>
          <section class="sub-Total-Div" *ngIf="salesTax > 0">
            <span class="">{{
              'orderConfirmation.salesTax' | cxTranslate
            }}</span>
          </section>
          <section class="sub-Total-Div" *ngIf="salesTax > 0">
            <span class=""
              >{{ salesTax ? (salesTax | currency) : 0 }}
              {{ currencyIso }}</span
            >
          </section>

          <section class="total-Bground">
            <span class="rese-Name">{{
              'orderConfirmation.total' | cxTranslate
            }}</span>
            <span class=""
              >{{ estTotal ? (estTotal | currency) : 0 }}
              {{ currencyIso }}</span
            >
          </section>
        </section>
      </section>
    </aside>
    <section
      class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-9 bord-Right border-Right float-left shipments-Section"
    >
      <div
        class="d-flex flex-column flex-sm-row gap-1 align-items-sm-baseline mb-3 influenza-vaccine-reservation"
      >
        <div class="reservation-Div p-0 influenza-vaccine-reservation__id">
          <span class="reservation-Id"> Reservation </span>
          <span> {{ reservationId }}</span>
        </div>
        <div class="influenza-vaccine-reservation__season">
          <span class="activeSeason-Id"
            >{{ seasonId }} {{ 'selectVaccines.season' | cxTranslate }} |</span
          ><span class="status-Id">{{ statusDisplay }}</span>
        </div>
      </div>
      <app-flu-charts></app-flu-charts>
      <section class="container p-0">
        <section class="row">
          <section
            class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 myPresentations-Section"
          >
            <h3>{{ 'orderConfirmation.myPresentations' | cxTranslate }}</h3>

            <table
              aria-describedby="cardDetailsTable"
              class="table table-reservation"
            >
              <tbody *ngFor="let user of displayProductForUiArray">
                <tr class="content-row">
                  <td class="col-4 col-sm-2 p-0 mb-3 m-sm-0">
                    <a (click)="productDetails(user)">
                      <img
                        [src]="user.thumbNailImg"
                        class="prod-Image"
                        alt="prodImg"
                        *ngIf="user.thumbNailImg !== ''; else fallBack"
                      />

                      <ng-template #fallBack>
                        <img
                          [src]="fallbackImage"
                          class="prod-Image"
                          title="{{ user.name }}"
                          alt="defImg"
                        />
                      </ng-template>
                    </a>
                  </td>
                  <td class="prod-td">
                    <a
                      class="c-heading-link c-link mb-3"
                      (click)="productDetails(user)"
                    >
                      <span class="prodName" [innerHTML]="user?.prodName">
                      </span>
                    </a>
                    <div>
                      <span class="prodDesc" [innerHTML]="user?.prodDesc">
                      </span>
                    </div>
                    <div class="units-and-doses">
                      <div class="d-flex flex-column">
                        <span>Units/Doses</span>
                        <span> {{ user.quantity * user.doses }}</span>
                      </div>
                      <div class="d-flex flex-column">
                        <span>Price</span>
                        <span class="trackingNumber">
                          {{ user?.basePrice | currency }} CAD
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </section>
      </section>
    </section>

    <!-- ==================RIGHT Section=================================== -->

    <section
      class="col-12 col-lg-4 col-xl-3 right-Section-Top-Col-Span flu-confirmation-right-content-container float-right"
    >
      <app-flu-confirmation-right-content></app-flu-confirmation-right-content>
    </section>
  </section>
</section>
