import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { translationChunksConfig } from '@spartacus/assets';
import { ConfigModule, I18nModule, provideConfig } from '@spartacus/core';
import { OutletPosition, provideOutlet } from '@spartacus/storefront';
import { FluDashboardBannerModule } from '../shared/modules/flu-dashboard-banner/flu-dashboard-banner.module';
import { ResourcesBannerModule } from '../shared/modules/resources-banner/resources-banner.module';
import { BrowseByTopicComponent } from './browse-by-topic/browse-by-topic.component';
import { DisplayImgComponent } from './display-img/display-img.component';
import { HealthcareProviderInformationComponent } from './healthcare-provider-information/healthcare-provider-information.component';
import { PatientEducationComponent } from './patient-education/patient-education.component';
import { ProductMonogragphComponent } from './product-monograph/product-monograph.component';
@NgModule({
  declarations: [
    PatientEducationComponent,
    HealthcareProviderInformationComponent,
    BrowseByTopicComponent,
    DisplayImgComponent,
    ProductMonogragphComponent,
  ],
  imports: [
    CommonModule,
    ResourcesBannerModule,
    FluDashboardBannerModule,
    NgSelectModule,
    FormsModule,
    ConfigModule,
    I18nModule,
    FontAwesomeModule,
  ],
  providers: [
    provideOutlet({
      id: 'patientEducation-Horizontal',
      position: OutletPosition.REPLACE,
      component: PatientEducationComponent,
    }),
    provideOutlet({
      id: 'patientEducation-vertical',
      position: OutletPosition.REPLACE,
      component: BrowseByTopicComponent,
    }),
    provideOutlet({
      id: 'monographCompSlot',
      position: OutletPosition.REPLACE,
      component: ProductMonogragphComponent,
    }),
    provideConfig({
      i18n: {
        backend: {
          loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json',
        },
        chunks: translationChunksConfig,
      },
    }),
  ],
})
export class ToolsAndResourcesModule {}
