<!--details section-->
<section class="row" *ngIf="urlPath === 'reservation-overview'">
  <div class="col-md-12">
    <div>
      <section class="details-Labels">
        <h3 class="font-bold mb-2">
          {{ 'orderConfirmation.po' | cxTranslate }}
        </h3>
      </section>
      <div class="mb-4">
        <span class="">{{ poId }}</span>
      </div>
      <section class="details-Labels">
        <h3 class="font-bold mb-2">
          {{ 'orderConfirmation.soldTo' | cxTranslate }}
        </h3>
      </section>
      <div class="mb-4">
        <span class="">{{
          soldToAddress?.unitID ? soldToAddress?.unitID : ''
        }}</span>
      </div>
      <section class="details-Labels">
        <h3 class="font-bold mb-2">
          {{ 'orderConfirmation.orderDate' | cxTranslate }}
        </h3>
      </section>
      <div class="mb-4">
        <span class="">{{
          orderDate | slice : 0 : 10 | date : 'dd/MM/yyyy'
        }}</span>
      </div>
      <section class="details-Labels">
        <h3 class="font-bold mb-2">
          {{ 'orderConfirmation.status' | cxTranslate }}
        </h3>
      </section>
      <div class="mb-4">
        <span class="">{{ status }}</span>
      </div>
    </div>
    <section class="col-6 col-md-6 mt-3">
      <section class="row">
        <section class="details-Labels">
          <h3 class="font-bold mb-2">
            {{ 'orderConfirmation.shipTo' | cxTranslate }}
          </h3>
        </section>
        <div class="col-12 p-0">
          <span
            >{{ 'orderConfirmation.accountNo' | cxTranslate }}:
            {{ selectedData?.unitID ? selectedData?.unitID : '' }}</span
          >
        </div>
        <section class="col-12 p-0">
          <span class="">{{
            selectedData?.companyName ? selectedData?.companyName : ''
          }}</span>
        </section>
        <section class="col-12 p-0">
          <span
            >{{ selectedData?.line1 ? selectedData?.line1 : '' }},
            {{ selectedData?.line2 ? selectedData?.line2 : '' }}</span
          >
        </section>
        <section class="col-12 p-0">
          <span
            >{{ selectedData?.town ? selectedData?.town : '' }},
            {{
              selectedData?.region?.isocodeShort
                ? selectedData?.region?.isocodeShort + ','
                : ''
            }}
            {{ selectedData?.postalCode ? selectedData?.postalCode : '' }}</span
          >
        </section>
      </section>
    </section>
  </div>
</section>
<!--Payment Method-->
<section class="row mt-4">
  <section class="col-md-12">
    <h3 class="font-bold mb-2">
      {{ 'orderConfirmation.paymentMethod' | cxTranslate }}
    </h3>
    <p class="font-bold mb-2">{{ displayName }}</p>
  </section>
  <div class="col-md-12 mb-4 pb-4 u-border-bottom-grey">
    <section class="note-Size mb-2">
      <img
        *ngIf="
          paymentType !== '0000' ||
          !cardType ||
          (cardType !== 'Visa' && cardType !== 'Mastercard')
        "
        src="../../assets/icons/card.svg"
        alt="card"
      />
      <img *ngIf="cardType === 'Visa'" src="/assets/img/VISA.svg" alt="visa" />
      <img
        *ngIf="cardType === 'Mastercard'"
        src="/assets/img/MC.svg"
        alt="mastercard"
      />

      <div class="pay-Type" *ngIf="paymentType === '0000' && cardType">
        {{ cardType ? cardType : '' }}
        {{ 'orderConfirmation.endingin' | cxTranslate }}
        {{ ccNumber ? ccNumber : '' }}
      </div>
    </section>
    <section class="text-fixed-legal-copy">
      {{ 'orderConfirmation.multipleShipmentsMsg' | cxTranslate }}
    </section>
  </div>
</section>
<!--Reservation contact heading -->
<section class="row">
  <section class="col-md-12">
    <h3 class="font-bold mb-4">
      {{ 'orderConfirmation.reservationContact' | cxTranslate }}
    </h3>
  </section>
  <section class="col-md-12 mb-4">
    <span class="text-fixed-legal-copy"
      >{{ 'selectVaccines.contactMsg' | cxTranslate }}
    </span>
  </section>
</section>

<!--contact details-->
<section class="row contact-Main-Row">
  <section class="col-4 mb-2">
    <span class="font-bold">{{ 'selectVaccines.name' | cxTranslate }}</span>
  </section>
  <section class="col-8">
    <span>{{ infContact?.name }}</span>
  </section>
  <section class="col-4 mb-2" *ngIf="infContact?.fax">
    <span class="font-bold">{{ 'selectVaccines.fax' | cxTranslate }}</span>
  </section>
  <section class="col-8" *ngIf="infContact?.fax">
    <span>{{ infContact?.fax }}</span>
  </section>
  <section class="col-4 mb-2">
    <span class="font-bold">{{ 'selectVaccines.placedBy' | cxTranslate }}</span>
  </section>
  <section class="col-8">
    <span>{{ userName }}</span>
  </section>
  <section class="col-4 mb-2">
    <span class="font-bold">{{ 'orderConfirmation.email' | cxTranslate }}</span>
  </section>
  <section class="col-8">
    <a
      href="{{
        'mailto:' + (infContact?.email ? infContact?.email : userEmail)
      }}"
      class="email-field"
      >{{ infContact?.email ? infContact?.email : userEmail }}</a
    >
  </section>
</section>
<!--address-->
