<!-- Shipping page form stars  -->

<div class="col-xs-12 col-sm-12 col-md-12 Registration-split" #target>
  <h3 class="pdp-title">
    {{ 'accountRegistration.shippingInformation.title' | cxTranslate }}
  </h3>
  <span class="title-sub-heading">{{
    'accountRegistration.title_sub_heading' | cxTranslate
  }}</span>
  <div class="col-xs-12 col-sm-8 col-md-8 p0 account-form">
    <form
      [formGroup]="shippingForm"
      id="shippingAddressForm"
      (ngSubmit)="proceedToContacts()"
    >
      <div id="registerBilling" formGroupName="shippingAddress">
        <div
          class="col-xs-12 col-sm-12 col-md-12 p0 c-text-input doubleLineContainer"
        >
          <div>
            <label
              for="shippingAddressForm.shippingAddress"
              id="shippingAddress"
              class="Fields-label"
              >{{
                'accountRegistration.shippingInformation.is_address_same'
                  | cxTranslate
              }}</label
            >
            <div class="radio_grp">
              <div class="custom-control custom-radio label-location">
                <input
                  type="radio"
                  id="shippingAddressForm.shippingAddress1"
                  name="sameAsBillingAddress"
                  class="custom-control-input"
                  [value]="isShippingAddressSame[0]"
                  formControlName="sameAsBillingAddress"
                />
                <label
                  class="custom-control-label secloct"
                  for="shippingAddressForm.shippingAddress1"
                >
                  {{
                    'accountRegistration.form.yellow_fever.accepted_shipping'
                      | cxTranslate
                  }}
                </label>
              </div>
            </div>
            <div class="radio_grp">
              <div class="custom-control custom-radio label-location">
                <input
                  type="radio"
                  id="shippingAddressForm.shippingAddress2"
                  name="sameAsBillingAddress"
                  class="custom-control-input"
                  [value]="isShippingAddressSame[1]"
                  formControlName="sameAsBillingAddress"
                />
                <label
                  class="custom-control-label"
                  for="shippingAddressForm.shippingAddress2"
                >
                  {{
                    'accountRegistration.form.yellow_fever.rejected'
                      | cxTranslate
                  }}
                </label>
              </div>
            </div>
          </div>

          <ng-template #errorPopContent>{{
            'accountRegistration.special_validation_msg_popover' | cxTranslate
          }}</ng-template>

          <label for="facilityName" class="Fields-label icon-label"
            >{{ 'accountRegistration.form.facilityName.label' | cxTranslate }}
          </label>
          <a placement="top" [ngbPopover]="errorPopContent"
            ><img
              src="../../assets/icons/faqs_reg.png"
              alt="faq_reg"
              class="faq"
          /></a>
          <input
            id="facilityName"
            name="facilityName"
            data-valid="valid_facility"
            aria-labelledby="shippingAddressForm.facilityName tooltipFacilityName"
            type="text"
            [readonly]="
              shippingForm.get('shippingAddress.sameAsBillingAddress').value
            "
            class="Fields-input"
            formControlName="facilityName"
            data-di-id="#shippingAddressForm.facilityName"
			maxlength="40"
          />

          <span
            *ngIf="
              !!shippingForm.get('shippingAddress.facilityName').value &&
              !shippingForm.get('shippingAddress.facilityName').valid &&
              shippingForm.get('shippingAddress.facilityName').touched
            "
            class="errorMessage"
            >{{
              'accountRegistration.special_validation_msg' | cxTranslate
            }}</span
          >
          <span
            *ngIf="
              shippingForm.get('shippingAddress.facilityName').errors
                ?.required &&
              !shippingForm.get('shippingAddress.facilityName').valid &&
              shippingForm.get('shippingAddress.facilityName').touched
            "
            class="defaultMessage"
            role="alert"
            aria-live="assertive"
            >{{ 'accountRegistration.requiredMsg' | cxTranslate }}
          </span>
        </div>
        <div
          class="col-xs-12 col-sm-12 col-md-12 p0 c-text-input doubleLineContainer"
        >
          <label for="formLegalName" class="Fields-label"
            >{{ 'accountRegistration.form.legalName.label' | cxTranslate }}
          </label>
          <input
            id="formLegalName"
            name="legalName"
            data-valid="valid_facility"
            type="text"
            [readonly]="
              shippingForm.get('shippingAddress.sameAsBillingAddress').value
            "
            class="Fields-input"
            formControlName="legalName"
            autocomplete="off"
            data-di-id="#formLegalName"
			maxlength="40"
          />

          <span
            *ngIf="
              !!shippingForm.get('shippingAddress.legalName').value &&
              !shippingForm.get('shippingAddress.legalName').valid &&
              shippingForm.get('shippingAddress.legalName').touched
            "
            class="errorMessage"
            >{{
              'accountRegistration.special_validation_msg' | cxTranslate
            }}</span
          >
          <span
            *ngIf="
              shippingForm.get('shippingAddress.legalName').errors?.required &&
              !shippingForm.get('shippingAddress.legalName').valid &&
              shippingForm.get('shippingAddress.legalName').touched
            "
            class="defaultMessage"
            role="alert"
            aria-live="assertive"
            >{{ 'accountRegistration.requiredMsg' | cxTranslate }}
          </span>
        </div>
        <div
          class="col-xs-12 col-sm-12 col-md-12 p0 c-text-input doubleLineContainer"
        >
          <label for="shippingAddressForm.line1" class="Fields-label icon-label"
            >{{ 'accountRegistration.form.address_line_1.label' | cxTranslate }}
          </label>
          <a placement="top" [ngbPopover]="errorPopContent">
            <img
              src="../../assets/icons/faqs_reg.png"
              alt="faq_reg"
              class="faq"
            />
          </a>
          <input
            id="shippingAddressForm.line1"
            name="shippingAddress.line1"
            data-valid="valid_facility"
            aria-labelledby="shippingAddressForm.line1 tooltipshippingAddress1"
            type="text"
            [readonly]="
              shippingForm.get('shippingAddress.sameAsBillingAddress').value
            "
            class="Fields-input"
            formControlName="line1"
            data-di-id="#shippingAddressForm.line1"
			maxlength="60"
          />

          <span
            *ngIf="
              !!shippingForm.get('shippingAddress.line1').value &&
              !shippingForm.get('shippingAddress.line1').valid &&
              shippingForm.get('shippingAddress.line1').touched
            "
            class="errorMessage"
            >{{
              'accountRegistration.special_validation_msg' | cxTranslate
            }}</span
          >
          <span
            *ngIf="
              shippingForm.get('shippingAddress.line1').errors?.required &&
              !shippingForm.get('shippingAddress.line1').valid &&
              shippingForm.get('shippingAddress.line1').touched
            "
            class="defaultMessage"
            role="alert"
            aria-live="assertive"
            >{{ 'accountRegistration.requiredMsg' | cxTranslate }}
          </span>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 p0 c-text-input">
          <label for="shippingAddressForm.line2" class="Fields-label"
            >{{
              'accountRegistration.form.address_line_2.label' | cxTranslate
            }}
            <span>{{
              'accountRegistration.optional_tag' | cxTranslate
            }}</span></label
          >
          <input
            id="shippingAddressForm.line2"
            name="shippingAddressForm.line2"
            data-valid="valid_facility"
            placeholder="Suite/Floor/Building"
            type="text"
            [readonly]="
              shippingForm.get('shippingAddress.sameAsBillingAddress').value
            "
            class="Fields-input field-optional"
            formControlName="line2"
            data-di-id="#shippingAddressForm.line2"
			maxlength="10"
          />

          <span
            *ngIf="
              !!shippingForm.get('shippingAddress.line2').value &&
              !shippingForm.get('shippingAddress.line2').valid &&
              shippingForm.get('shippingAddress.line2').touched
            "
            class="errorMessage"
            >{{ 'accountRegistration.alpha_numeric_msg' | cxTranslate }}</span
          >
        </div>

        <div class="col-xs-12 col-sm-12 col-md-12 p0 c-text-input">
          <label for="shippingAddressForm.town" class="Fields-label">{{
            'accountRegistration.form.city.label' | cxTranslate
          }}</label>
          <input
            id="shippingAddressForm.town"
            name="shippingAddressForm.town"
            data-valid="valid_city"
            type="text"
            [readonly]="
              shippingForm.get('shippingAddress.sameAsBillingAddress').value
            "
            class="Fields-input"
            formControlName="town"
            data-di-id="#shippingAddressForm.town"
			maxlength="40"
          />

          <span
            *ngIf="
              !!shippingForm.get('shippingAddress.town').value &&
              !shippingForm.get('shippingAddress.town').valid &&
              shippingForm.get('shippingAddress.town').touched
            "
            class="errorMessage"
            >{{ 'accountRegistration.alpha_space_msg' | cxTranslate }}</span
          >
          <span
            *ngIf="
              shippingForm.get('shippingAddress.town').errors?.required &&
              !shippingForm.get('shippingAddress.town').valid &&
              shippingForm.get('shippingAddress.town').touched
            "
            class="defaultMessage"
            role="alert"
            aria-live="assertive"
            >{{ 'accountRegistration.requiredMsg' | cxTranslate }}
          </span>
        </div>

        <div
          class="col-xs-12 col-sm-12 col-md-12 p0 c-text-input asses_dropdown"
        >
          <label
            for="shippingAddressForm.state"
            id="provinceLabel"
            class="Fields-label"
            >{{
              'accountRegistration.form.province.label' | cxTranslate
            }}</label
          >
          <div class="">
            <div class="" formGroupName="region">
              <ng-select
                [items]="provinces"
                [searchable]="false"
                [clearable]="false"
                bindLabel="name"
                bindValue="isocode"
                placeholder="Please select"
                formControlName="isocode"
                class="dropdown-credit"
                [readonly]="
                  shippingForm.get('shippingAddress.sameAsBillingAddress').value
                "
              ></ng-select>
            </div>

            <span
              *ngIf="
                shippingForm.get('shippingAddress.region.isocode').errors
                  ?.required &&
                !shippingForm.get('shippingAddress.region.isocode').valid &&
                shippingForm.get('shippingAddress.region.isocode').touched
              "
              class="errorMessage drop-downs"
              role="alert"
              aria-live="assertive"
              >{{ 'accountRegistration.requiredMsg' | cxTranslate }}
            </span>
          </div>
        </div>
        <div
          class="col-xs-12 col-sm-12 col-md-12 p0 c-text-input zip-container"
        >
          <label for="shippingAddressForm.postalCode" class="Fields-label">{{
            'accountRegistration.form.postalCode.label' | cxTranslate
          }}</label>
          <input
            id="shippingAddressForm.postalCode"
            name="shippingAddressForm.postalCode"
            data-valid="valid_zip_canada"
            maxlength="7"
            type="text"
            [readonly]="
              shippingForm.get('shippingAddress.sameAsBillingAddress').value
            "
            class="Fields-input"
            formControlName="postalCode"
            data-di-id="#shippingAddressForm.postalCode"
          />

          <span
            *ngIf="
              shippingForm.get('shippingAddress.postalCode').errors?.pattern &&
              !shippingForm.get('shippingAddress.postalCode').valid &&
              shippingForm.get('shippingAddress.postalCode').touched
            "
            class="errorMessage"
            role="alert"
            aria-live="assertive"
            >{{
              'accountRegistration.form.postalCode.postal_code_validation'
                | cxTranslate
            }}</span
          >
          <span
            *ngIf="
              shippingForm.get('shippingAddress.postalCode').errors?.required &&
              !shippingForm.get('shippingAddress.postalCode').valid &&
              shippingForm.get('shippingAddress.postalCode').touched
            "
            class="defaultMessage"
            role="alert"
            aria-live="assertive"
            >{{ 'accountRegistration.requiredMsg' | cxTranslate }}
          </span>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 p0 c-text-input small-device">
          <div class="phone-container">
            <label for="formPhone" class="Fields-label">{{
              'accountRegistration.form.phone.label' | cxTranslate
            }}</label>
            <input
              id="formPhone"
              name="shippingAddressForm.phone"
              data-valid="valid_phone"
              placeholder="123-456-7890"
              type="text"
              maxlength="12"
              [readonly]="
                shippingForm.get('shippingAddress.sameAsBillingAddress').value
              "
              appPhoneMask
              class="Fields-input phone-no-mask"
              formControlName="phone"
              autocomplete="off"
              data-di-id="#formPhone"
            />

            <span
              class="errorMessage"
              *ngIf="
                !!shippingForm.get('shippingAddress.phone').value &&
                !shippingForm.get('shippingAddress.phone').valid &&
                shippingForm.get('shippingAddress.phone').touched
              "
              >{{
                'accountRegistration.form.phone.ph_validation_msg' | cxTranslate
              }}</span
            >
            <span
              *ngIf="
                shippingForm.get('shippingAddress.phone').errors?.required &&
                !shippingForm.get('shippingAddress.phone').valid &&
                shippingForm.get('shippingAddress.phone').touched
              "
              class="defaultMessage"
              role="alert"
              aria-live="assertive"
              >{{ 'accountRegistration.requiredMsg' | cxTranslate }}
            </span>
          </div>
          <div class="phone-container doubleLineContainer">
            <label for="shippingAddressForm.extension" class="Fields-label"
              >{{ 'accountRegistration.form.extension.label' | cxTranslate
              }}<span>{{
                'accountRegistration.optional_tag' | cxTranslate
              }}</span></label
            >
            <input
              id="shippingAddressForm.extension"
              name="shippingAddressForm.extension"
              data-valid="valid_extension"
              type="text"
              [readonly]="
                shippingForm.get('shippingAddress.sameAsBillingAddress').value
              "
              class="Fields-input field-optional extension"
              formControlName="extension"
              data-di-id="#shippingAddressForm.extension"
			  maxlength="40"
            />

            <span
              class="errorMessage"
              *ngIf="
                !!shippingForm.get('shippingAddress.extension').value &&
                !shippingForm.get('shippingAddress.extension').valid &&
                shippingForm.get('shippingAddress.extension').touched
              "
              >{{
                'accountRegistration.form.extension.ext_validation_msg'
                  | cxTranslate
              }}</span
            >
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 p0 c-text-input">
          <label for="shippingAddressForm.fax" class="Fields-label"
            >{{ 'accountRegistration.form.office_fax.label' | cxTranslate
            }}<span>{{
              'accountRegistration.optional_tag' | cxTranslate
            }}</span></label
          >
          <input
            id="shippingAddressForm.fax"
            name="shippingAddressForm.fax"
            data-valid="valid_phone"
            placeholder="123-456-7890"
            type="text"
            maxlength="12"
            [readonly]="
              shippingForm.get('shippingAddress.sameAsBillingAddress').value
            "
            appPhoneMask
            class="Fields-input phone-no-mask field-optional"
            formControlName="fax"
            data-di-id="#shippingAddressForm.fax"
          />

          <span
            class="errorMessage"
            *ngIf="
              !!shippingForm.get('shippingAddress.fax').value &&
              !shippingForm.get('shippingAddress.fax').valid &&
              shippingForm.get('shippingAddress.fax').touched
            "
            >{{
              'accountRegistration.form.office_fax.fax_validation_msg'
                | cxTranslate
            }}</span
          >
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 p0 c-text-input">
          <label for="shippingAddressForm.email" class="Fields-label"
            >{{ 'accountRegistration.form.email.label' | cxTranslate }}
          </label>
          <input
            id="shippingAddressForm.email"
            name="shippingAddressForm.email"
            data-valid="valid_mail"
            type="text"
            [readonly]="
              shippingForm.get('shippingAddress.sameAsBillingAddress').value
            "
            class="Fields-input"
            data-view="newemail"
            formControlName="email"
            data-di-id="#shippingAddressForm.email"
			maxlength="241"
          />

          <span
            *ngIf="
              !!shippingForm.get('shippingAddress.email').value &&
              shippingForm.get('shippingAddress.email').invalid &&
              shippingForm.get('shippingAddress.email').touched
            "
            class="defaultMessage"
            role="alert"
            aria-live="assertive"
            >{{
              'accountRegistration.form.email.email_validation_msg'
                | cxTranslate
            }}</span
          >
          <span
            *ngIf="
              shippingForm.get('shippingAddress.email').errors?.required &&
              !shippingForm.get('shippingAddress.email').valid &&
              shippingForm.get('shippingAddress.email').touched
            "
            class="defaultMessage"
            role="alert"
            aria-live="assertive"
            >{{ 'accountRegistration.requiredMsg' | cxTranslate }}
          </span>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 p0 c-text-input">
          <label for="shippingAddressForm.confirmemail" class="Fields-label"
            >{{ 'accountRegistration.form.confirm_email.label' | cxTranslate }}
          </label>
          <input
            id="shippingAddressForm.confirmemail"
            name="shippingAddressForm.confirmemail"
            data-valid="valid_mail"
            type="text"
            [readonly]="
              shippingForm.get('shippingAddress.sameAsBillingAddress').value
            "
            class="Fields-input"
            data-view="confirmemail"
            formControlName="confirmEmail"
            data-di-id="#shippingAddressForm.confirmemail"
			maxlength="241"
          />

          <span
            *ngIf="
              !!shippingForm.get('shippingAddress.confirmEmail').value &&
              shippingForm.get('shippingAddress.confirmEmail').invalid &&
              shippingForm.get('shippingAddress.confirmEmail').touched
            "
            class="defaultMessage"
            role="alert"
            aria-live="assertive"
            >{{
              'accountRegistration.form.email.email_validation_msg'
                | cxTranslate
            }}</span
          >

          <span
            *ngIf="
              !!shippingForm.get('shippingAddress.confirmEmail').value &&
              !shippingForm.get('shippingAddress.confirmEmail').invalid &&
              shippingForm.get('shippingAddress.confirmEmail').value !==
                shippingForm.get('shippingAddress.email').value
            "
            class="errorMessage"
            >{{
              'accountRegistration.form.confirm_email.email_not_match'
                | cxTranslate
            }}</span
          >
          <span
            *ngIf="
              shippingForm.get('shippingAddress.confirmEmail').errors
                ?.required &&
              !shippingForm.get('shippingAddress.confirmEmail').valid &&
              shippingForm.get('shippingAddress.confirmEmail').touched
            "
            class="defaultMessage"
            role="alert"
            aria-live="assertive"
            >{{ 'accountRegistration.requiredMsg' | cxTranslate }}
          </span>
        </div>
        <div
          class="col-xs-12 col-sm-12 col-md-12 p0 c-text-input asses_dropdown"
        >
          <label
            for="shippingAddressForm.practiceFacility"
            id="practiceFacilityLabel"
            class="Fields-label"
            >{{
              'accountRegistration.form.practice_or_facility_type.label'
                | cxTranslate
            }}</label
          >
          <div class="pdp-dropdown">
            <div class="">
              <ng-select
                [items]="practiceOrFacility"
                [searchable]="false"
                [clearable]="false"
                bindLabel="name"
                bindValue="code"
                placeholder="Please select"
                formControlName="practiceOrFacilityType"
                class="dropdown-credit"
                [readonly]="
                  shippingForm.get('shippingAddress.sameAsBillingAddress').value
                "
              ></ng-select>
            </div>

            <span
              *ngIf="
                shippingForm.get('shippingAddress.practiceOrFacilityType')
                  .errors?.required &&
                !shippingForm.get('shippingAddress.practiceOrFacilityType')
                  .valid &&
                shippingForm.get('shippingAddress.practiceOrFacilityType')
                  .touched
              "
              class="errorMessage drop-downs"
              role="alert"
              aria-live="assertive"
              >{{ 'accountRegistration.requiredMsg' | cxTranslate }}
            </span>
          </div>
        </div>

        <hr class="c-text-input-line billingPage" />
        <div>
          <label
            for="shippingAddressForm.yellowFever"
            id="yellowFever"
            class="Fields-label-new"
            >{{
              'accountRegistration.form.yellow_fever.label' | cxTranslate
            }}</label
          >
          <div class="radio_grp">
            <div class="custom-control custom-radio label-location">
              <input
                type="radio"
                id="customRadio1"
                name="yellowFever"
                class="custom-control-input"
                [value]="yellowFever[0]"
                formControlName="yellowFever"
              />
              <label class="custom-control-label secloct" for="customRadio1">
                {{
                  'accountRegistration.form.yellow_fever.accepted_shipping' | cxTranslate
                }}
              </label>
            </div>
          </div>
          <div class="radio_grp">
            <div class="custom-control custom-radio label-location">
              <input
                type="radio"
                id="shippingAddressForm.yellowFever2"
                name="yellowFever"
                class="custom-control-input"
                [value]="yellowFever[1]"
                formControlName="yellowFever"
              />
              <label
                class="custom-control-label"
                for="shippingAddressForm.yellowFever2"
              >
                {{
                  'accountRegistration.form.yellow_fever.rejected' | cxTranslate
                }}
              </label>
            </div>
          </div>
        </div>
        <hr class="c-text-input-line billingPage" />
      </div>
      <div class="invoice-btngrps d-flex gap-1">
        <button
          type="button"
          (click)="back()"
          class="c-button c-button--secondary"
          data-di-id="di-id-7a6cb2cd-cb37948a"
        >
          {{ 'accountRegistration.form.back_button' | cxTranslate }}
        </button>
        <button
          type="submit"
          class="c-button c-button--primary"
          data-di-id="di-id-7a6cb2cd-cb37948a"
        >
          {{ 'accountRegistration.form.continue_button' | cxTranslate }}
        </button>
      </div>
    </form>
  </div>
</div>

<!-- shipping form ends -->
