<app-faq-title></app-faq-title>

<div class="faq-container">
  <div class="child-container-one">
    <!-- FAQ List -->
    <div class="faq-item" *ngFor="let item of faq">
      <div
        class="title d-flex align-items-center justify-content-between faq-panel-header"
      >
        <h2>{{ item.item }}</h2>
        <button class="item-btn" (click)="showItem($event)"></button>
      </div>
      <div class="item-child-container">
        <div class="toggle-item">
          <button class="all-child-btn show" (click)="showAllChild($event)">
            {{ 'faq.showAll' | cxTranslate }}
          </button>
          <button class="all-child-btn" (click)="hideAllChild($event)">
            {{ 'faq.hideAll' | cxTranslate }}
          </button>
        </div>
        <div class="item-child" *ngFor="let itemChild of item.questions">
          <div class="item-child-question">
            <h5 class="text-fixed-medium m-0">{{ itemChild.question }}</h5>
            <div class="toggle-item">
              <button class="child-btn" (click)="toggleChild($event)"></button>
            </div>
          </div>
          <div class="item-child-answer">
            <app-accordian-answers
              [answers]="itemChild.answer"
            ></app-accordian-answers>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
