import { LayoutConfig } from '@spartacus/storefront';
import { VscaAsmMainUiComponent } from './components/vsca-asm-main-ui/vsca-asm-main-ui.component';


export const defaultVscaAsmLayoutConfig: LayoutConfig = {
  launch: {
    ASM: {
      outlet: 'cx-storefront',
      component: VscaAsmMainUiComponent,
    },
  },
};
