<section class="container-fluid userField-ContainerFluid">
  <!-- =========================Name Form============================================ -->
  <section class="row">
    <section class="col-md-9 col-9 col-sm-9">
      <h3 class="header-box">
        <span class="header-Css">
          {{ 'profile.name' | cxTranslate }}
        </span>
      </h3>
    </section>
    <section class="col-md-3 col-3 col-sm-3">
      <h3 class="float-right" (click)="editNameField()" *ngIf="nameEditIsNotClicked">
        <span class="edit-Option">{{ 'profile.edit' | cxTranslate }}</span>
        <img
          alt=""
          src="/assets/icons/edit_icon.png"
          class="edit-Icon-Image"
        />
      </h3>
    </section>
  </section>
  <section
    class="container p-0"
    [ngClass]="{
      'margin-Down': nameEditIsNotClicked,
      'edited-Fields-Margin-Value': !nameEditIsNotClicked
    }"
  >
    <section class="row" *ngIf="nameEditIsNotClicked">
      <section class="col-md-12">
        <label class="inner-Headers">
          {{ 'profile.firstName' | cxTranslate }}</label
        >
      </section>
      <section class="col-md-12 mb-3">
        <span class="inner-Content inner-Content-Font-Color">{{
          nameForm.get('actualFirstName').value
        }}</span>
      </section>
      <section class="col-md-12">
        <label class="inner-Headers">{{
          'profile.lastName' | cxTranslate
        }}</label>
      </section>
      <section class="col-md-12">
        <span class="inner-Content inner-Content-Font-Color">{{
          nameForm.get('actualLastName').value
        }}</span>
      </section>
    </section>

    <section class="row" [formGroup]="nameForm" *ngIf="!nameEditIsNotClicked">
      <section class="col-md-12">
        <label class="inner-Headers">{{
          'profile.firstName' | cxTranslate
        }}</label>
      </section>
      <section class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 fields-Margin-Value c-text-input">
        <input
          class="form-control"
          formControlName="actualFirstName"
          [ngClass]="{
            required:
              nameForm.get('actualFirstName').invalid &&
              (nameForm.get('actualFirstName').touched ||
                nameForm.get('actualFirstName').dirty)
          }"
        />
        <span
          class="text-danger"
          *ngIf="
            nameForm.get('actualFirstName').value.length == 0 &&
            (nameForm.get('actualFirstName').touched ||
              nameForm.get('actualFirstName').dirty)
          "
          >{{ 'profile.requiredError' | cxTranslate }}</span
        >
        <span
          class="text-danger"
          *ngIf="
            nameForm.get('actualFirstName').value.length != 0 &&
            nameForm.get('actualFirstName').invalid &&
            (nameForm.get('actualFirstName').touched ||
              nameForm.get('actualFirstName').dirty)
          "
          >{{ 'profile.invalidName' | cxTranslate }}</span
        >
      </section>
      <section class="col-md-12">
        <label class="inner-Headers">{{
          'profile.lastName' | cxTranslate
        }}</label>
      </section>
      <section
        class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 fields-Margin-Value c-text-input"
      >
        <input
          class="form-control"
          formControlName="actualLastName"
          [ngClass]="{
            required:
              nameForm.get('actualLastName').invalid &&
              (nameForm.get('actualLastName').touched ||
                nameForm.get('actualLastName').dirty)
          }"
        />
        <span
          class="text-danger"
          *ngIf="
            (nameForm.get('actualLastName').touched ||
              nameForm.get('actualLastName').dirty) &&
            nameForm.get('actualLastName').value.length == 0
          "
          >{{ 'profile.requiredError' | cxTranslate }}</span
        >
        <span
          class="text-danger"
          *ngIf="
            nameForm.get('actualLastName').value.length != 0 &&
            nameForm.get('actualLastName').invalid &&
            (nameForm.get('actualLastName').touched ||
              nameForm.get('actualLastName').dirty)
          "
          >{{ 'profile.invalidName' | cxTranslate }}</span
        >
      </section>
    </section>
    <section class="row w-100 btn-Row" *ngIf="!nameEditIsNotClicked">
      <section class="col-md-4 col-12 col-sm-12 col-lg-3 col-xl-3 btn-Padding">
        <button
          class="cancelBtn-Css c-button c-button--secondary"
          (click)="cancelNameUpdate()"
        >
          {{ 'profile.cancel' | cxTranslate }}
        </button>
      </section>
      <section
        class="col-md-4 col-12 col-sm-12 col-lg-3 col-xl-3 btnMargin-Top btn-Padding"
      >
        <button
          class="updateBtn-Css c-button c-button--primary"
          (click)="updateNameField()"
          [disabled]="
            nameForm.get('actualFirstName').invalid ||
            nameForm.get('actualLastName').invalid
          "
        >
          {{ 'profile.update' | cxTranslate }}
        </button>
      </section>
    </section>
  </section>
  <hr />
  <!-- =========================Role============================================ -->
  <section class="row">
    <section class="col-md-9 col-9 col-sm-9">
      <h3 class="header-box">
        <span class="header-Css">{{ 'profile.role' | cxTranslate }}</span>
      </h3>
    </section>
    <section class="col-md-3 col-3 col-sm-3">
      <h3 class="float-right" *ngIf="roleEditIsNotClicked" (click)="editRoleField()">
        <span class="edit-Option">{{ 'profile.edit' | cxTranslate }}</span>
        <img
          alt=""
          src="/assets/icons/edit_icon.png"
          class="edit-Icon-Image"
        />
      </h3>
    </section>
  </section>
  <section
    class="container p-0"
    [ngClass]="{
      'margin-Down': roleEditIsNotClicked,
      'edited-Fields-Margin-Value': !roleEditIsNotClicked
    }"
  >
    <section class="row" *ngIf="roleEditIsNotClicked">
      <section class="col-md-12">
        <span class="inner-Content inner-Content-Font-Color">{{
          displayRoleofUser
        }}</span>
      </section>
    </section>
    <section class="row" *ngIf="!roleEditIsNotClicked">
      <section
        class="col-md-12 col-12 col-sm-12 col-lg-12 col-xs-12 col-xl-5 fields-Margin-Value role-Margin-Bottom user-Profile"
      >
        <ng-select
          class="w-100"
          [items]="roleOfUserArray"
          bindLabel="name"
          bindValue="code"
          [multiple]="false"
          name="chosenRoleofUser"
          [clearable]="false"
          placeholder="Select Role"
          [(ngModel)]="chosenRoleofUser"
        >
        </ng-select>
      </section>
    </section>
    <section class="row w-100 btn-Row" *ngIf="!roleEditIsNotClicked">
      <section class="col-md-4 col-12 col-sm-12 col-lg-3 col-xl-3 btn-Padding">
        <button
          class="cancelBtn-Css c-button c-button--secondary"
          (click)="cancelRoleUpdate()"
        >
          {{ 'profile.cancel' | cxTranslate }}
        </button>
      </section>
      <section
        class="col-md-4 col-12 col-sm-12 col-lg-3 col-xl-3 btnMargin-Top btn-Padding"
      >
        <button
          class="updateBtn-Css c-button c-button--primary"
          (click)="updateRoleField()"
        >
          {{ 'profile.update' | cxTranslate }}
        </button>
      </section>
    </section>
  </section>
  <hr />
  <!-- =========================password Form============================================ -->
  <section class="row">
    <section class="col-md-9 col-9 col-sm-9 col-lg-9 col-xl-9">
      <h3 class="header-box">
        <span class="header-Css">{{ 'profile.password' | cxTranslate }}</span>
      </h3>
    </section>
    <section class="col-md-3 col-3 col-sm-3">
      <h3 class="float-right" *ngIf="passwordEditIsNotClicked" (click)="editPasswordField()">
        <span class="edit-Option">{{ 'profile.edit' | cxTranslate }}</span>
        <img
          alt=""
          src="/assets/icons/edit_icon.png"
          class="edit-Icon-Image"
        />
      </h3>
    </section>
  </section>
  <section
    class="container p-0"
    [ngClass]="{
      'margin-Down': passwordEditIsNotClicked,
      'edited-Fields-Margin-Value': !passwordEditIsNotClicked
    }"
  >
    <section class="row" *ngIf="passwordEditIsNotClicked">
      <section
        class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 fields-Margin-Value password-Display-Div"
      >
        <span class="inner-Content-Font-Color">********</span>
      </section>
    </section>
    <section
      class="container p-0"
      [formGroup]="passwordForm"
      *ngIf="!passwordEditIsNotClicked"
    >
      <section class="row">
        <!-- =================passwordSection==================== -->
        <section class="col-md-12 col-lg-12 col-xl-7 col-12 col-sm-12 p-0">
          <section class="container">
            <section class="row">
              <section class="col-md-12">
                <label class="inner-Headers">{{
                  'profile.currentPassword' | cxTranslate
                }}</label>
              </section>
              <section class="col-md-12 fields-Margin-Value c-text-input">
                <input
                  class="form-control"
                  type="password"
                  formControlName="currentPassword"
                  [ngClass]="{
                    required:
                      currentPasswordIsNotValid &&
                      passwordForm.get('currentPassword').value.length >= 0 &&
                      (passwordForm.get('currentPassword').touched ||
                        passwordForm.get('currentPassword').dirty)
                  }"
                />

                <span
                  class="text-danger"
                  *ngIf="
                    (passwordForm.get('currentPassword').dirty ||
                      passwordForm.get('currentPassword').touched) &&
                    passwordForm.get('currentPassword').value.length == 0
                  "
                  >{{ 'profile.requiredError' | cxTranslate }}</span
                >

                <span
                  class="text-danger"
                  *ngIf="
                    (passwordForm.get('currentPassword').dirty ||
                      passwordForm.get('currentPassword').touched) &&
                    currentPasswordIsNotValid &&
                    passwordForm.get('currentPassword').value.length > 0
                  "
                  >{{ 'profile.PasswordNotValid' | cxTranslate }}</span
                >
              </section>
              <section class="col-md-12">
                <label class="inner-Headers">{{
                  'profile.newPassword' | cxTranslate
                }}</label>
              </section>
              <section class="col-md-12 fields-Margin-Value c-text-input">
                <input
                  class="form-control"
                  type="password"
                  formControlName="newPassword"
                  [ngClass]="{
                    required:
                      passwordForm.get('newPassword').value.length >= 0 &&
                      (passwordIsNotValid ||
                        passwordForm.get('newPassword').value ==
                          passwordForm.get('currentPassword').value) &&
                      (passwordForm.get('newPassword').dirty ||
                        passwordForm.get('newPassword').touched)
                  }"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    (passwordForm.get('newPassword').dirty ||
                      passwordForm.get('newPassword').touched) &&
                    passwordForm.get('newPassword').value.length == 0
                  "
                  >{{ 'profile.requiredError' | cxTranslate }}</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    (passwordForm.get('newPassword').dirty ||
                      passwordForm.get('newPassword').touched) &&
                    !passwordIsNotValid &&
                    passwordForm.get('currentPassword').value.length > 0 &&
                    passwordForm.get('newPassword').value ==
                      passwordForm.get('currentPassword').value
                  "
                >
                  {{ 'profile.newOldSamePass' | cxTranslate }}</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    (passwordForm.get('newPassword').dirty ||
                      passwordForm.get('newPassword').touched) &&
                    passwordIsNotValid &&
                    passwordForm.get('newPassword').value.length > 0
                  "
                  >{{ 'profile.PasswordNotValid' | cxTranslate }}</span
                >
              </section>
              <section class="col-md-12">
                <label class="inner-Headers">{{
                  'profile.confirmPassword' | cxTranslate
                }}</label>
              </section>
              <section class="col-md-12 fields-Margin-Value c-text-input">
                <input
                  id="confirmPasswordField"
                  class="form-control"
                  type="password"
                  formControlName="confirmNewPassword"
                  [ngClass]="{
                    required:
                      (passwordForm.get('confirmNewPassword').value.length ==
                        0 ||
                        passwordForm.get('confirmNewPassword').value !=
                          passwordForm.get('newPassword').value) &&
                      (passwordForm.get('confirmNewPassword').touched ||
                        passwordForm.get('confirmNewPassword').dirty),
                    block:
                      passwordForm.get('newPassword').value.length == 0 ||
                      passwordForm.get('currentPassword').value.length == 0 ||
                      passwordForm.get('newPassword').value ==
                        passwordForm.get('currentPassword').value
                  }"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    passwordForm.get('confirmNewPassword').value !=
                      passwordForm.get('newPassword').value &&
                    passwordForm.get('confirmNewPassword').value.length > 0 &&
                    (passwordForm.get('confirmNewPassword').dirty ||
                      passwordForm.get('confirmNewPassword').touched)
                  "
                  >{{ 'profile.passMisMatch' | cxTranslate }}</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    passwordForm.get('confirmNewPassword').value.length == 0 &&
                    (passwordForm.get('confirmNewPassword').dirty ||
                      passwordForm.get('confirmNewPassword').touched)
                  "
                  >{{ 'profile.requiredError' | cxTranslate }}</span
                >
              </section>
            </section>
          </section>
        </section>
        <!-- ===================PasswordInfoSection========================== -->
        <section
          class="col-md-12 col-12 col-sm-12 col-lg-12 col-xl-5 password-Main-Div largeDevice-Passinfo"
        >
          <section class="passwordInfo-Div">
            <section class="passowrdInfo-Span">
              {{ 'profile.passInstruction' | cxTranslate }}
            </section>
            <ul class="password-Instruction">
              <li>{{ 'profile.lowerCaseLetter' | cxTranslate }}</li>
              <li>{{ 'profile.upperCaseLetter' | cxTranslate }}</li>
              <li>{{ 'profile.number' | cxTranslate }}</li>
              <li>
                {{ 'profile.symbol' | cxTranslate }}
              </li>
            </ul>
          </section>
        </section>
      </section>
    </section>
    <span class="text-danger" *ngIf="cPassError !== ''">
      {{ cPassError }}
    </span>
    <section class="row w-100 btn-Row" *ngIf="!passwordEditIsNotClicked">
      <section class="col-md-4 col-12 col-sm-12 col-lg-3 col-xl-3 btn-Padding">
        <button
          class="cancelBtn-Css c-button c-button--secondary"
          (click)="cancelPasswordUpdate()"
        >
          {{ 'profile.cancel' | cxTranslate }}
        </button>
      </section>
      <section
        class="col-md-4 col-12 col-sm-12 col-lg-3 col-xl-3 btnMargin-Top btn-Padding"
      >
        <button
          class="updateBtn-Css c-button c-button--primary"
          (click)="updatePasswordField()"
          [disabled]="
            passwordForm.invalid ||
            passwordIsNotValid ||
            currentPasswordIsNotValid ||
            passwordForm.get('newPassword').value !=
              passwordForm.get('confirmNewPassword').value
          "
        >
          {{ 'profile.update' | cxTranslate }}
        </button>
      </section>
    </section>
    <!-- =========================SmallDevice PassInfo=========================== -->
    <section
      class="col-md-12 col-12 col-sm-12 col-lg-12 col-xl-5 password-Main-Div smallDevice-PassInfo"
      *ngIf="!passwordEditIsNotClicked"
    >
      <section class="passwordInfo-Div">
        <section class="passowrdInfo-Span">
          {{ 'profile.passInstruction' | cxTranslate }}
        </section>
        <ul class="password-Instruction">
          <li>{{ 'profile.lowerCaseLetter' | cxTranslate }}</li>
          <li>{{ 'profile.upperCaseLetter' | cxTranslate }}</li>
          <li>{{ 'profile.number' | cxTranslate }}</li>
          <li>
            {{ 'profile.symbol' | cxTranslate }}
          </li>
        </ul>
      </section>
    </section>
  </section>
  <hr />
  <!-- ==========================================Password Recocecry============================== -->
  <section class="row">
    <section class="col-md-9 col-9 col-sm-9">
      <h3 class="header-box">
        <span class="header-Css"
          >{{ 'profile.passRecQue' | cxTranslate }}
        </span>
      </h3>
    </section>
    <section class="col-md-3 col-3 col-sm-3">
      <h3 class="float-right" *ngIf="passRecEditIsNotClicked" (click)="editPassRecField()">
        <span class="edit-Option">{{ 'profile.edit' | cxTranslate }}</span>
        <img
          alt=""
          src="/assets/icons/edit_icon.png"
          class="edit-Icon-Image"
        />
      </h3>
    </section>
  </section>
  <section
    class="container p-0"
    [ngClass]="{
      'margin-Down': passRecEditIsNotClicked,
      'edited-Fields-Margin-Value': !passRecEditIsNotClicked
    }"
  >
    <section class="row" *ngIf="passRecEditIsNotClicked">
      <section class="col-md-12">
        <span class="inner-Content inner-Content-Font-Color">{{
          displayQueForPassRecovery
        }}</span>
      </section>
      <section
        class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 fields-Margin-Value"
      >
        <span class="inner-Content-Font-Color">********</span>
      </section>
    </section>
    <section class="row" *ngIf="!passRecEditIsNotClicked">
      <section class="col-md-12">
        <label class="inner-Headers"
          >{{ 'profile.passRecQue' | cxTranslate }}
        </label>
      </section>
      <section
        class="col-md-12 col-12 col-sm-12 col-lg-12 col-xs-12 col-xl-5 fields-Margin-Value user-Profile"
      >
        <ng-select
          [items]="passRecQuesArray"
          bindLabel="name"
          bindValue="code"
          [multiple]="false"
          name="chosenRoleofUser"
          [clearable]="false"
          placeholder="Select Question"
          [(ngModel)]="chosenQueForPassRecovery"
          class="w-100"
        >
        </ng-select>
      </section>
      <section class="col-md-12">
        <label class="inner-Headers">
          {{ 'profile.passRecAns' | cxTranslate }}
        </label>
      </section>
      <section
        class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 fields-Margin-Value c-text-input"
      >
        <input
          class="w-100 form-control"
          [ngClass]="{
            required:
              ansForQuePassRecovery == '' && ansForQuePassRecovery != null
          }"
          [(ngModel)]="ansForQuePassRecovery"
        />
        <span
          class="text-danger"
          *ngIf="ansForQuePassRecovery == '' && ansForQuePassRecovery != null"
        >
          {{ 'profile.requiredError' | cxTranslate }}
        </span>
      </section>
    </section>
    <section class="row w-100 btn-Row" *ngIf="!passRecEditIsNotClicked">
      <section class="col-md-4 col-12 col-sm-12 col-lg-3 col-xl-3 btn-Padding">
        <button
          class="cancelBtn-Css c-button c-button--secondary"
          (click)="cancelPassRecUpdate()"
        >
          {{ 'profile.cancel' | cxTranslate }}
        </button>
      </section>
      <section
        class="col-md-4 col-12 col-sm-12 col-lg-3 col-xl-3 btnMargin-Top btn-Padding"
      >
        <button
          [disabled]="
            ansForQuePassRecovery == '' || chosenQueForPassRecovery == ''
          "
          class="updateBtn-Css c-button c-button--primary"
          (click)="updatePassRecQues()"
        >
          {{ 'profile.update' | cxTranslate }}
        </button>
      </section>
    </section>
  </section>
  <hr />
  <!-- ==========================================Email============================== -->
  <section class="row">
    <section class="col-md-9 col-9 col-sm-9">
      <h3 class="header-box">
        <span class="header-Css"> {{ 'profile.email' | cxTranslate }}</span>
      </h3>
    </section>
    <section class="col-md-3 col-3 col-sm-3">
      <h3 class="float-right" *ngIf="emailEditIsNotClicked" (click)="editEmailField()">
        <span class="edit-Option">{{ 'profile.edit' | cxTranslate }}</span>
        <img
          alt=""
          src="/assets/icons/edit_icon.png"
          class="edit-Icon-Image"
        />
      </h3>
    </section>
  </section>
  <section
    class="container p-0"
    [ngClass]="{
      'margin-Down': emailEditIsNotClicked,
      'edited-Fields-Margin-Value': !emailEditIsNotClicked
    }"
  >
    <section class="row" *ngIf="emailEditIsNotClicked">
      <section class="col-md-12">
        <span class="inner-Content inner-Content-Font-Color">{{
          emailForm.get('emailId').value
        }}</span>
      </section>
    </section>

    <section class="row" [formGroup]="emailForm" *ngIf="!emailEditIsNotClicked">
      <section class="col-md-12">
        <label class="inner-Headers">
          {{ 'profile.email' | cxTranslate }}</label
        >
      </section>
      <section
        class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 fields-Margin-Value c-text-input"
      >
        <input
          class="form-control"
          formControlName="emailId"
          [ngClass]="{
            required:
              (emailForm.get('emailId').value.length == 0 ||
                emailForm.get('emailId').invalid) &&
              (emailForm.get('emailId').touched ||
                emailForm.get('emailId').dirty)
          }"
        />

        <span
          class="text-danger"
          *ngIf="
            emailForm.get('emailId').invalid &&
            (emailForm.get('emailId').dirty ||
              emailForm.get('emailId').touched) &&
            emailForm.get('emailId').value.length != 0
          "
        >
          {{ 'profile.invalidEmail' | cxTranslate }}
        </span>
        <span
          class="text-danger"
          *ngIf="
            (emailForm.get('emailId').dirty ||
              emailForm.get('emailId').touched) &&
            emailForm.get('emailId').value.length == 0
          "
        >
          {{ 'profile.requiredError' | cxTranslate }}
        </span>
      </section>

      <section class="col-md-12">
        <label class="inner-Headers">{{
          'profile.confirmEmail' | cxTranslate
        }}</label>
      </section>
      <section
        class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 fields-Margin-Value c-text-input"
      >
        <input
          class="form-control"
          formControlName="confirmEmailId"
          [ngClass]="{
            required:
              (emailForm.get('confirmEmailId').value.length == 0 ||
                emailForm.get('confirmEmailId').value !=
                  emailForm.get('emailId').value) &&
              (emailForm.get('confirmEmailId').touched ||
                emailForm.get('confirmEmailId').dirty),
            block: emailForm.get('emailId').value.length == 0
          }"
        />
        <span
          class="text-danger"
          *ngIf="
            emailForm.get('confirmEmailId').value !=
              emailForm.get('emailId').value &&
            emailForm.get('confirmEmailId').value != 0 &&
            (emailForm.get('confirmEmailId').touched ||
              emailForm.get('confirmEmailId').dirty)
          "
        >
          {{ 'profile.EmailIdnotMisMatch' | cxTranslate }}
        </span>
        <span
          class="text-danger"
          *ngIf="
            emailForm.get('confirmEmailId').value == 0 &&
            (emailForm.get('confirmEmailId').touched ||
              emailForm.get('confirmEmailId').dirty)
          "
        >
          {{ 'profile.requiredError' | cxTranslate }}
        </span>
      </section>
    </section>
    <section class="row w-100 btn-Row" *ngIf="!emailEditIsNotClicked">
      <section class="col-md-4 col-12 col-sm-12 col-lg-3 col-xl-3 btn-Padding">
        <button
          class="cancelBtn-Css c-button c-button--secondary"
          (click)="cancelEmailUpdate()"
        >
          {{ 'profile.cancel' | cxTranslate }}
        </button>
      </section>
      <section
        class="col-md-4 col-12 col-sm-12 col-lg-3 col-xl-3 btnMargin-Top btn-Padding"
      >
        <button
          class="updateBtn-Css c-button c-button--primary"
          (click)="updateEmailField()"
          [disabled]="
            emailForm.invalid ||
            emailForm.get('confirmEmailId').value !=
              emailForm.get('emailId').value
          "
        >
          {{ 'profile.update' | cxTranslate }}
        </button>
      </section>
    </section>
  </section>
  <hr />
  <!-- ====================================================================== -->
  <section class="row">
    <section class="col-md-9 col-9 col-sm-9">
      <h3 class="header-box">
        <span class="header-Css">{{
          'profile.communication' | cxTranslate
        }}</span>
      </h3>
    </section>
    <section class="col-md-3 col-3 col-sm-3">
      <h3 class="float-right" *ngIf="commConfmEditIsNotClicked" (click)="editEmailCommField()">
        <span class="edit-Option">{{ 'profile.edit' | cxTranslate }}</span>
        <img
          alt=""
          src="/assets/icons/edit_icon.png"
          class="edit-Icon-Image"
        />
      </h3>
    </section>
  </section>
  <section
    class="container p-0"
    [ngClass]="{
      'margin-Down': commConfmEditIsNotClicked,
      'edited-Fields-Margin-Value': !commConfmEditIsNotClicked,
      'emailDiv-Top': !commConfmEditIsNotClicked
    }"
  >
    <section class="row" *ngIf="commConfmEditIsNotClicked">
      <section class="col-md-12 inner-Content inner-Content-Font-Color">
        <span
          class="emailConstent-msg"
          *ngIf="emailCommunicationStatus === 'Yes'"
          >{{ 'profile.emailCommStatusYes' | cxTranslate }}</span
        >
        <span
          class="emailConstent-msg"
          *ngIf="emailCommunicationStatus != 'Yes'"
          >{{ 'profile.emailCommStatusNo' | cxTranslate }}</span
        >
      </section>
    </section>
    <section
      class="row"
      *ngIf="
        commConfmEditIsNotClicked &&
        alternateEmailForm.get('alternateEmailId').value.length > 0
      "
    >
      <section class="col-md-12">
        <span class="alternate-Mail-Header">{{
          'profile.alternateEmail' | cxTranslate
        }}</span>
      </section>
      <section class="col-md-12">
        <span class="alternate-Mail-Value">{{
          alternateEmailForm.get('alternateEmailId').value
        }}</span>
      </section>
    </section>
    <section class="row" *ngIf="!commConfmEditIsNotClicked">
      <section class="col-md-12 col-12 col-sm-12">
        <span class="emailCommunication-Que">{{
          'userRegistration.userInformation.emailConsent.marketingLabel'
            | cxTranslate
        }}</span>
      </section>
      <section class="row radio-Div w-100">
        <section class="col-xl-2 col-lg-2 col-md-3 col-3 col-sm-3 w-50">
          <div class="custom-control custom-radio radio_grp label-location">
            <input
              type="radio"
              id="emailConsentYesRadio"
              name="emailConsent"
              class="custom-control-input"
              value="true"
              [(ngModel)]="emailConsent"
            />
            <label
              class="custom-control-label secloct"
              for="emailConsentYesRadio"
            >
              Yes
            </label>
          </div>
        </section>
        <section class="col-xl-1 col-lg-1 col-md-3 col-3 col-sm-3 p-0">
          <div
            class="custom-control custom-radio radio_grp label-location w-50"
          >
            <input
              type="radio"
              id="emailConsentNoRadio"
              name="emailConsent"
              class="custom-control-input"
              value="false"
              [(ngModel)]="emailConsent"
            />
            <label
              class="custom-control-label secloct"
              for="emailConsentNoRadio"
            >
              No
            </label>
          </div>
        </section>
      </section>
    </section>

    <section
      class="row alternate-Email-Row"
      *ngIf="emailConsent == 'true' && !commConfmEditIsNotClicked"
    >
      <span (click)="addAlternateEmail()" class="addAlternateEmail-Span">
        {{ 'profile.AddAlterMail' | cxTranslate }}</span
      >
    </section>
    <section
      class="row"
      [formGroup]="alternateEmailForm"
      *ngIf="showAlternateEmailField && emailConsent == 'true'"
    >
      <section class="col-md-12">
        <label class="inner-Headers">{{
          'profile.alterMailHeader' | cxTranslate
        }}</label>
      </section>
      <section
        class="col-9 col-xs-9 col-sm-10 col-md-10 col-lg-12 col-xl-7 fields-Margin-Value c-text-input"
      >
        <input
          class="form-control"
          formControlName="alternateEmailId"
          [ngClass]="{
            required:
              (alternateEmailForm.get('alternateEmailId').invalid ||
                alternateEmailForm.get('alternateEmailId').value.length == 0) &&
              (alternateEmailForm.get('alternateEmailId').touched ||
                alternateEmailForm.get('alternateEmailId').dirty)
          }"
        />
        <span
          class="text-danger"
          *ngIf="
            (alternateEmailForm.get('alternateEmailId').dirty ||
              alternateEmailForm.get('alternateEmailId').touched) &&
            alternateEmailForm.get('alternateEmailId').value.length == 0
          "
          >{{ 'profile.requiredError' | cxTranslate }}</span
        >
        <span
          class="text-danger"
          *ngIf="
            alternateEmailForm.get('alternateEmailId').invalid &&
            (alternateEmailForm.get('alternateEmailId').dirty ||
              alternateEmailForm.get('alternateEmailId').touched) &&
            alternateEmailForm.get('alternateEmailId').value.length != 0
          "
        >
          {{ 'profile.invalidEmail' | cxTranslate }}</span
        >
      </section>
      <div class="email-Delete-Div">
        <i class="fas fa-trash-alt pointer"></i> &nbsp;<span
          class="email-Delete"
          (click)="deleteAlternateEmail()"
          >{{ 'profile.delete' | cxTranslate }}</span
        >
      </div>
      <section class="col-md-12">
        <label class="inner-Headers">{{
          'profile.confirmAlterMail' | cxTranslate
        }}</label>
      </section>
      <section
        class="col-9 col-xs-9 col-sm-10 col-md-10 col-lg-12 col-xl-7 fields-Margin-Value c-text-input"
      >
        <input
          class="form-control"
          formControlName="confirmAlternateEmailId"
          [ngClass]="{
            required:
              (alternateEmailForm.get('confirmAlternateEmailId').value.length ==
                0 ||
                alternateEmailForm.get('confirmAlternateEmailId').value !=
                  alternateEmailForm.get('alternateEmailId').value) &&
              (alternateEmailForm.get('confirmAlternateEmailId').touched ||
                alternateEmailForm.get('confirmAlternateEmailId').dirty)
          }"
        />
        <span
          class="text-danger"
          *ngIf="
            (alternateEmailForm.get('confirmAlternateEmailId').touched ||
              alternateEmailForm.get('confirmAlternateEmailId').dirty) &&
            alternateEmailForm.get('confirmAlternateEmailId').value.length == 0
          "
          >{{ 'profile.requiredError' | cxTranslate }}</span
        >
        <span
          class="text-danger"
          *ngIf="
            alternateEmailForm.get('confirmAlternateEmailId').value !=
              alternateEmailForm.get('alternateEmailId').value &&
            alternateEmailForm.get('confirmAlternateEmailId').value.length >
              0 &&
            (alternateEmailForm.get('confirmAlternateEmailId').dirty ||
              alternateEmailForm.get('confirmAlternateEmailId').touched)
          "
          >Fields Doesn't Match</span
        >
      </section>
    </section>
    <!-- =======================================///alternateEMAILButton================== -->
    <section
      class="row w-100 btn-Row"
      *ngIf="
        !commConfmEditIsNotClicked &&
        showAlternateEmailField == true &&
        emailConsent == 'true'
      "
    >
      <section class="col-md-4 col-12 col-sm-12 col-lg-3 col-xl-3 btn-Padding">
        <button
          class="cancelBtn-Css c-button c-button--secondary"
          (click)="cancelEmailConsentUpdate()"
        >
          {{ 'profile.cancel' | cxTranslate }}
        </button>
      </section>
      <section
        class="col-md-4 col-12 col-sm-12 col-lg-3 col-xl-3 btnMargin-Top btn-Padding"
      >
        <button
          class="updateBtn-Css c-button c-button--primary"
          [disabled]="
            alternateEmailForm.invalid ||
            alternateEmailForm.get('confirmAlternateEmailId').value !=
              alternateEmailForm.get('alternateEmailId').value
          "
          (click)="updateEmailCommunField()"
        >
          {{ 'profile.update' | cxTranslate }}
        </button>
      </section>
    </section>
    <section
      class="row w-100 btn-Row radioBtn-Button-Row"
      *ngIf="
        !commConfmEditIsNotClicked &&
        (showAlternateEmailField == false || emailConsent == 'false')
      "
    >
      <section class="col-md-4 col-12 col-sm-12 col-lg-3 col-xl-3 btn-Padding">
        <button
          class="cancelBtn-Css c-button c-button--secondary"
          (click)="cancelEmailCommuUpdate()"
        >
          {{ 'profile.cancel' | cxTranslate }}
        </button>
      </section>
      <section
        class="col-md-4 col-12 col-sm-12 col-lg-3 col-xl-3 btnMargin-Top btn-Padding"
      >
        <button
          class="updateBtn-Css c-button c-button--primary"
          (click)="updateEmailCommunField()"
        >
          {{ 'profile.update' | cxTranslate }}
        </button>
      </section>
    </section>
  </section>
</section>
