<div>
  <div class="asm-bar-branding"></div>
  <div class="asm-bar-actions"></div>
</div>

<ng-container *ngIf="!(isCollapsed$ | async) as notCollapsed">
  <ng-container *ngIf="customerSupportAgentLoggedIn$ | async">
    <ng-container *ngIf="customer$ | async; else showCustomerSelection">
      <vsca-cx-customer-emulation
        *ngIf="notCollapsed"
      ></vsca-cx-customer-emulation>
    </ng-container>
    <ng-template #showCustomerSelection>
      <vsca-cx-customer-selection
        *ngIf="notCollapsed"
        (submitEvent)="startCustomerEmulationSession($event)"
      ></vsca-cx-customer-selection>
    </ng-template>
  </ng-container>
</ng-container>
