<section class="container accountField-ContainerFluid">

  <section class="col-lg-12 col-md-12 col-sm-12 col-xs-12 accnt-Div ">
    <section class="preference-content">
      <form action="">
        <section class="">
          <p class="locview-info">{{'account.select'| cxTranslate}}</p>
          <section class="locleft">
            <section class="ship-address">
              <section id="location-select">
                <section class="location-icon">
                  <span class="glyph-icon">
                    <i class="fas fa-map-marker-alt icon mr-3"></i>
                  </span>
                </section>
                <section class="location-Details">
                  <span class="selected-loc" *ngIf="!!selectedData">{{selectedData?.companyName}} {{"("+selectedData?.unitID+")"}} </span>
                  <span class="selected-loc" *ngIf="!selectedData"> -- </span>
                  <span class="change-loc" role="button" *ngIf="changeLocation"
                    (click)="open(content)">{{'account.changeLocation'| cxTranslate}}</span>
                </section>
                <section class="custome-modal">
                  <ng-template #content let-modal>
                    <section class="modal-selector">
                      <section class="modal-header">
                        <button type="button" class="close" aria-label="Close" (click)='onCancel()'
                                (click)="modal.dismiss('Cross click')">
                          <img alt="" src="../../assets/icons/close2.png">
                        </button>
                        <h4 class="modal-title" id="modal-basic-title">{{'account.selectLocation'| cxTranslate}}</h4>
                      </section>

                      <section class="modal-body">

                        <section class="input-group1 loc-SearchBar1">
                          <div class="c-text-input">
                            <cx-icon class="cx-icon fas fa-search"></cx-icon>
                            <input type="text" class="form-control searchboxnew" #clientName (keyup)="SearchClientName(clientName.value)"
                                   placeholder="{{'account.filterByLocation'| cxTranslate}}"></div>
                        </section>

                        <hr [ngClass]="{'displayNone':searchedClientList?.length===0}"/>
                        <section>
                          <section [ngClass]="{'displayNone':searchedClientList?.length===0}" class="custom-control custom-radio radio_grp label-location w-100 m-0">
                            <table *ngFor="let data of searchedClientList;  let i = index" class="w-100" aria-describedby="locTable">
                              <tr class="sample-header">
                                <th scope="col"></th>
                                <th scope="col"></th>
                              </tr>
                              <tr class="line-location"
                                  [ngClass]="{'label-location': (i !== searchedClientList.length-1)}">

                                <input type="radio" (change)='onOptionsSelected(data)' [id]="data.id"
                                       [checked]="selectedData.id == data.id" name="customRadio"
                                       class="custom-control-input"/>
                                <label class="custom-control-label secloct w-100" [for]="data.id">
                                  {{data?.companyName}} {{"(" + data?.unitID + ")"}}
                                </label>
                                <p class="labelpp">  {{data?.line1 ? data?.line1 + ', ' : ''}}
                                  {{data?.line2 ? data?.line2 + ', ' : ''}}
                                  {{data?.town ? data?.town + ', ' : ''}}
                                  {{data?.region?.isocodeShort ? data?.region?.isocodeShort + ' ' : ''}}
                                  {{data?.postalCode}}
                                </p>

                              </tr>
                            </table>
                          </section>
                        </section>

                      </section>
                      <section class="modal-footer">
                        <button type="button" class="location-done c-button c-button--primary" (click)='onSubmit()'
                                (click)="modal.close('Save click')">
                          {{'account.done'| cxTranslate}}
                        </button>
                      </section>
                    </section>
                  </ng-template>


                </section>
              </section>

            </section>

          </section>
        </section>
        <section class="shipping-address shipping-address-loc">
          <section class="col-xs-12 col-sm-12 col-md-12  Fields-container ">
            <section class="contact-details name-edit">
              <h3>{{'account.shippingAddress'| cxTranslate}}</h3>
              <section class="post-edit">
                <section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.facilityName'| cxTranslate}}</label>
                    <span>{{selectedData?.companyName || "--"}}</span>
                  </section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.address'| cxTranslate}}</label>
                    <span>{{selectedData?.line1 }} {{selectedData?.line2 }}</span>
                  </section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.city'| cxTranslate}}</label>
                    <span>{{selectedData?.town || "--"}}</span>
                  </section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.province'| cxTranslate}}</label>
                    <span>{{selectedData?.region?.isocodeShort || "--"}}</span>

                  </section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.postalCode'| cxTranslate}}</label>
                    <span>{{selectedData?.postalCode || "--"}}</span>
                  </section>
                  <section class="Fields-container">
                    <section class="phone">
                      <section class="row">
                        <section class="col-md-4 col-lg-8 col-xl-8">
                          <label class="Fields-label">{{'account.phone'| cxTranslate}}</label>
                          <span id="phone-mask-text" maxlength="12" autocomplete="off">
                            {{selectedData?.phone || "--"}}</span>

                        </section>
                        <section class="col-md-4 col-lg-4 col-xl-4">
                          <label class="Fields-label">{{'account.extension'| cxTranslate}}</label>
                           <span> {{selectedData?.extension || "--"}}</span>
                        </section>
                      </section>
                    </section>
                  </section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.practiceFacility'| cxTranslate}}</label>
                    <span>{{usergetDataObj?.practiceOrFacilityType || "--"}}</span>

                  </section>
                </section>
              </section>
            </section>
          </section>
        </section>
      </form>
    </section>
  </section>

</section>
