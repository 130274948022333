<section class="col-md-8 col-sm-8 pay-section float-left account-reg-section">
  <app-account-header
    [selectedHeader]="accountData?.header"
    [tableCategory]="creditInvoice"
  ></app-account-header>

  <div
    class="col-xs-12 col-sm-12 col-md-12 Registration-split confirmation-split"
  >
    <h3 class="pdp-title">
      {{ 'accountRegistration.accountConfirmation.title' | cxTranslate }}
    </h3>
    <span class="confirmation-txt">{{
      'accountRegistration.accountConfirmation.content' | cxTranslate
    }}</span
    ><br /><br />
    <span class="confirmation-txt">{{
      'accountRegistration.accountConfirmation.sub_content' | cxTranslate
    }}</span>

    <div class="invoice-btngrps">
      <button
        type="button"
        class="c-button c-button--primary"
        data-di-id="di-id-41a05d18-6b04a424"
        [routerLink]="['/Open-Catalogue/c/1']"
      >
        {{
          'accountRegistration.accountConfirmation.catalog_button' | cxTranslate
        }}
      </button>
    </div>
  </div>
</section>
