import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { RoutingService } from '@spartacus/core';
import { VscaLoginFormComponentService } from './vsca-login-form-component.service';

@Component({
  selector: 'vsca-cx-login-form',
  templateUrl: './vsca-login-form.component.html',
  styleUrls: ['./vsca-login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VscaLoginFormComponent implements AfterContentInit {
  constructor(
    protected service: VscaLoginFormComponentService,
    private readonly route: RoutingService,
    private sanitizer: DomSanitizer
  ) {}

  form: FormGroup = this.service.form;
  isUpdating$ = this.service.isUpdating$;
  backgroundImg = '';
  formMargin = '';

  @HostBinding('class.user-form') style = true;

  @HostBinding('style')
  get applyBanner(): SafeStyle {
    if (!this.backgroundImg) {
      return null;
    }

    return this.sanitizer.bypassSecurityTrustStyle(`
      background-image: ${this.backgroundImg};
      background-repeat: no-repeat;
      background-size: cover;
      right: initial;
      top: initial;
    `);
  }

  onSubmit(): void {
    this.service.login();
  }

  gotoLoginHelp() {
    this.route.goByUrl('/login/loginhelp');
  }

  // tslint:disable-next-line:typedef
  ngAfterContentInit() {
    const loginPageWrapper =
      document.getElementsByClassName('LoginPageTemplate');
    const smartEditCollection = document.getElementsByTagName('body');
    const isLoginPage = !!loginPageWrapper.length;
    if (
      smartEditCollection[0].classList.contains('smartedit-page-uid-homepage')
    ) {
      return;
    }
    if (isLoginPage) {
      this.backgroundImg = '';
      this.formMargin = '';
      return;
    }
    const sectionB2Collection = document.getElementsByClassName('Section2B');
    if (!sectionB2Collection.length) {
      return;
    }
    const sectionB2 = Array.from(sectionB2Collection)[0];
    const bannerCollection = sectionB2.getElementsByTagName('cx-banner');

    if (!bannerCollection.length) {
      return;
    }
    const bannerElement = Array.from(bannerCollection)[0];
    const imageCollection = bannerElement.getElementsByTagName('img');
    if (!imageCollection.length) {
      return;
    }
    const imgSrc = Array.from(imageCollection)[0].getAttribute('src');
    if (!imgSrc) {
      return;
    }
  }
}
