import { Component, OnInit } from '@angular/core';
import { RoutingService } from '@spartacus/core';

@Component({
  selector: 'app-monograph-widget',
  templateUrl: './monograph-widget.component.html',
  styleUrls: ['./monograph-widget.component.scss'],
})
export class MonograpWidgetComponent implements OnInit {
  constructor(private readonly router: RoutingService) {}
  ngOnInit(): void {}
  goToMonographPage() {
    this.router.goByUrl('/resources/monographs');
  }
}
