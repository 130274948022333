<section class="product-refinement" *ngIf="productList$ | async as productList">
  <section class="header">
    <aside aria-label="Product Category" class="content content-left">
      <section *ngIf="false" class="selectBox">
        <b>{{ 'plp.category' | cxTranslate }} </b>
        <ng-select
          [items]="categoryFormat"
          bindLabel="name"
          bindValue="value"
          [multiple]="false"
          name="selectedCategory"
          placeholder="Select Category"
          [(ngModel)]="selectedCategory"
        >
        </ng-select>
      </section>
    </aside>
  </section>
  <section class="main">
    <aside aria-label="Product Filterby" class="content content-left">
      <section class="title" *ngIf="facets$?.length > 0">
        <h3
          class="c-text c-heading-h3 font-weight-bold u-border-bottom-grey pb-4 font-bold"
          *ngIf="facets$?.length > 0; else NaFacets"
        >
          {{ 'plp.filterby' | cxTranslate }}
        </h3>
        <cx-facet
          class="facets"
          *ngFor="let facet of facets$"
          #facetRef
          [facet]="facet"
        ></cx-facet>
      </section>
      <section
        class="title"
        *ngIf="
          productList?.products?.length == 0 &&
          productList?.freeTextSearch?.length > 0
        "
      >
        <h3 class="font-bold">
          {{ 'plp.explore' | cxTranslate }}
        </h3>
        <div *ngIf="userDetails != undefined">
          <a href="/ca/vsca/en/CAD/Product-Catalog/c/1" class="c-link products-link mb-2">
            {{ 'plp.exploreProducts' | cxTranslate | titlecase }}</a
          ><br>
          <a href="/influenza/dashboard" class="products-link c-link mb-2" *ngIf="salesOrg != 'CA04'">
            {{ 'plp.exploreInfluenza' | cxTranslate | titlecase }}</a
          ><br *ngIf="salesOrg != 'CA04'">
          <a href="/cart/save" class="products-link c-link mb-2">
            {{ 'plp.exploreSavedOrders' | cxTranslate | titlecase }}</a
          >
        </div>
      
          <a *ngIf="userDetails == undefined" href="/ca/vsca/en/CAD/Product-Catalog/c/1" class="products-link c-link mb-2">
            {{ 'plp.products' | cxTranslate | titlecase }}</a>
      </section>
    </aside>
  </section>
  <ng-template #facetsRwd>
    <section class="modal-class">
      <section class="modal-heading">
        <h2>
          <span class="close-icon" aria-hidden="true" (click)="hide()"
            >&times;</span
          >
        </h2>
        <h2 class="select-heading">
          {{ 'plp.selectRefinement' | cxTranslate }}
        </h2>
      </section>
      <section
        class="filterByTitle-main"
        *ngIf="
          productList?.products?.length > 0 ||
            productList?.freeTextSearch?.length == 0;
          else noProductsExplorer
        "
      >
        {{ 'plp.filterby' | cxTranslate }}
      </section>
      <ng-template #noProductsExplorer>
        <section class="filterByTitle-main explore-refine">
          {{ 'plp.explore' | cxTranslate }}
        </section>
      </ng-template>
      <section class="facets">
        <cx-facet
          class="facets"
          *ngFor="let facet of facets$"
          #facetRef
          [facet]="facet"
        ></cx-facet>
      </section>
      <span id="clearAll-filters">
        <a
          href="/ca/vsca/en/CAD/Product-Catalog/c/1"
          data-di-id="di-id-37774359-a6b0cba1"
          *ngIf="
            productList?.products?.length > 0 ||
              productList?.freeTextSearch?.length == 0;
            else noProducts
          "
        >
          {{ 'plp.clearFliter' | cxTranslate }}</a
        >
        <ng-template #noProducts>
          <a
            href="/ca/vsca/en/CAD/Product-Catalog/c/1"
            class="float-left products-link"
          >
            {{ 'plp.products' | cxTranslate | titlecase }}</a
          >
        </ng-template>
      </span>
    </section>
  </ng-template>
  <button
    class="btn btn-default"
    id="refineBtn"
    data-di-id="#refineBtn"
    (click)="refine()"
  >
    {{ 'plp.refineButton' | cxTranslate
    }}<span class="activeclass" *ngIf="activefacets">({{ activefacets }})</span
    ><span></span>
  </button>
</section>
