<div class="no-print container mb-3">
  <div class="row">
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 text-right mb-2 mb-sm-0">
      <a
        href="/CAD/Open-Catalogue/c/1"
        target=""
        id="continueShoppingId"
        data-di-id="#continueShoppingId"
        class="c-link"
      >
        <fa-icon [icon]="faChevronLeft" class="icon"></fa-icon>
        {{ 'addToCartItems.continueShopping' | cxTranslate }}</a
      >
    </div>
  </div>
</div>

<div *ngIf="renamedCart !== ''" class="global-alerts">
  <div class="c-alert-banner alert-info alert-dismissable getAccAlert">
    <button
      (click)="closeAlert()"
      class="close closeAccAlert"
      aria-hidden="true"
      data-dismiss="alert"
      type="button"
      data-di-id="di-id-4f6d7b93-70563360"
    >
      ×
    </button>
    {{ 'addToCartItems.order' | cxTranslate }} {{ renamedCart }}
    {{ 'addToCartItems.successMsg' | cxTranslate }}.
  </div>
</div>

<div class="container saved-orders" id="main-container">
  <div class="row">
    <div class="col-md-8 u-border-right-grey pr-md-4 pr-lg-5">
      <ng-template #emptySavedCart>
        <section class="edit-saved-cart u-border-top-grey py-4">
          <div class="bold-12 empty-cart-content">
            <h3 class="font-bold u-border-bottom-grey pb-4">
              {{ 'addToCartItems.emptySaveOrder' | cxTranslate }}
            </h3>
            <div class="darkblue font-14 empty-cart-options">
              <div>
                <a
                  class="c-link my-2"
                  href="/USD/Open-Catalogue/c/1"
                  title="Order products"
                  target=""
                  data-di-id="di-id-66e71f9c-2a8224d"
                  >{{ 'addToCartItems.emptyOrderProduct' | cxTranslate }}</a
                >
              </div>
              <div>
                <a
                  class="c-link my-2"
                  href="/my-account/account-overview"
                  title="My Account"
                  target=""
                  data-di-id="di-id-4bc01e1-da980c73"
                  >{{ 'addToCartItems.myAccount' | cxTranslate }}</a
                >
              </div>
            </div>
          </div>
        </section>
      </ng-template>
      <section
        *ngIf="savedcarts && savedcarts?.length > 0; else emptySavedCart"
      >
        <section
          class="text-fixed-medium u-border-top-grey py-4"
          *ngFor="let carts of savedcarts"
        >
          <div class="row add-to-cart d-flex mb-md-4">
            <!-- icon & name -->
            <div
              class="col-md-12 col-lg-5 d-flex align-items-center mb-4 mb-md-0"
            >
              <img
                class="save-cart-icon c-link-icon"
                src="../../assets/icons/cart-icon.svg"
                alt=""
                style="width: 20px"
              />&nbsp;
              <p class="font-bold m-0">
                {{ carts?.name }}
              </p>
            </div>
            <!-- view edit -->
            <div
              class="col-md-6 col-lg-4 d-none d-md-flex align-items-center mb-4 mb-md-0"
            >
              <a
                id="linkViewEdit"
                aria-labelledby="cartHeading"
                aria-describedby="linkViewEdit"
                target=""
                class="c-link mr-4"
                data-di-id="#linkViewEdit"
                (click)="viewOrEditOrder(carts?.code)"
                >{{ 'addToCartItems.view/cart' | cxTranslate
                }}<img class="icon" src="../../assets/icons/pencil.svg" alt=""
              /></a>
              <a
                href="javascript:void(0)"
                class="c-link"
                data-toggle="popover"
                data-placement="top"
                data-target="save-cart-0"
                data-original-title=""
                id="linkViewDelete"
                aria-labelledby="cartHeading"
                data-di-id="#linkViewDelete"
                [ngbPopover]="popDeltContent"
                [popoverTitle]="popDeltTitle"
                triggers="manual"
                #p3="ngbPopover"
                placement="top"
                (click)="toggle(p3)"
                container="body"
                popoverClass="popover-links saveorder-incart"
              >
                {{ 'addToCartItems.delete' | cxTranslate
                }}<img
                  class="icon"
                  src="../../assets/icons/trash-brand.svg"
                  alt=""
              /></a>
              <!--Popover content for Save cart start-->
              <!--Popover content for Save cart end-->
              <ng-template #popDeltTitle
                ><span class="text-header"
                  >{{ 'addToCartItems.delete' | cxTranslate }}
                  {{ carts?.name }}
                  {{
                    'addToCartItems.deleteTitleSavedOrder' | cxTranslate
                  }}</span
                ></ng-template
              >
              <ng-template #popDeltContent>
                <div class="c-popover">
                  <button
                    type="reset"
                    class="popover-close popover-cancel-button c-button c-button--secondary"
                    data-di-id="di-id-d01008be-fb6e135c"
                    (click)="toggle(p3)"
                  >
                    {{ 'addToCartItems.cancel' | cxTranslate }}
                  </button>
                  <button
                    class="popover-cancel-button c-button c-button--primary"
                    type="submit"
                    data-di-id="di-id-3064f17d-e97cc628"
                    (click)="deleteCart(carts?.code)"
                  >
                    {{ 'addToCartItems.delete' | cxTranslate }}
                  </button>
                </div>
              </ng-template>
            </div>
            <!-- button -->
            <div class="col-md-6 col-lg-3 mb-4 mb-md-0">
              <button
                class="c-button c-button--primary"
                (click)="restoreClick(restorecart, carts?.code)"
              >
                {{ 'addToCartItems.restore' | cxTranslate }}
              </button>
              <ng-template #restorecart class="restore-popover">
                <div class="checkout-modal">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="close" (click)="closeModal()">
                        <i
                          class="fa-solid fa-x c-icon-primary c-icon-primary-sm c-icon-round-hover mr-3 mt-4"
                        ></i>
                      </div>
                      <div class="modal-header">
                        <h2
                          class="c-heading-36 font-bold u-border-bottom-grey pb-4 w-100"
                        >
                          {{ 'addToCartItems.restoreheader' | cxTranslate }}
                        </h2>
                      </div>
                      <div class="modal-body">
                        <p class="text-fixed-medium">
                          {{
                            'addToCartItems.restoreContentBody' | cxTranslate
                          }}
                        </p>
                      </div>
                      <div class="u-border-top-grey py-5">
                        <div class="col-md-12">
                          <button
                            type="submit"
                            class="c-button c-button--secondary mb-4"
                            data-toggle="modal"
                            data-target="#loading-order"
                            id="orderConfirmbtn"
                            data-di-id="#orderConfirmbtn"
                            (click)="clearSessionRestoreCart(carts?.code)"
                          >
                            {{
                              'addToCartItems.restoreClearCart' | cxTranslate
                            }}
                          </button>
                          <button
                            type="button"
                            class="c-button c-button--primary"
                            data-toggle="modal"
                            data-target="#overlay"
                            data-di-id="di-id-35119eec-5a7dc742"
                            (click)="closeModal()"
                          >
                            {{
                              'addToCartItems.restoreExistingBtn' | cxTranslate
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
          <!-- table -->
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <table
                class="c-table c-table--branded d-none d-lg-table mt-0"
                aria-describedby="saveCart"
              >
                <thead>
                  <tr>
                    <th id="restoreTableCol1">
                      {{
                        'addToCartItems.mySavedOrder_TotalUnits' | cxTranslate
                      }}
                    </th>
                    <th id="restoreTableCol2">
                      {{
                        'addToCartItems.mySavedOrder_LastOrdered' | cxTranslate
                      }}
                    </th>
                    <th id="restoreTableCol3">
                      {{
                        'addToCartItems.mySavedOrder_LastUpdated' | cxTranslate
                      }}
                    </th>
                    <th id="restoreTableCol4">
                      {{
                        'addToCartItems.mySavedOrder_dateCreated' | cxTranslate
                      }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td headers="restoreTableCol1">
                      {{ getQuantity(carts.childCarts) }}
                    </td>
                    <td headers="restoreTableCol2">
                      {{ getOrders(carts.childCarts) }}
                    </td>
                    <td headers="restoreTableCol3">
                      {{
                        carts?.modifiedTime
                          | slice : 0 : 10
                          | date : 'dd/MM/yyyy'
                      }}
                    </td>
                    <td headers="restoreTableCol4">
                      {{
                        carts?.saveTime | slice : 0 : 10 | date : 'dd/MM/yyyy'
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                class="c-table c-table--branded c-table--branded--two-col d-table d-lg-none mb-4"
              >
                <tr class="mb-0">
                  <td class="header">
                    {{ 'addToCartItems.mySavedOrder_TotalUnits' | cxTranslate }}
                  </td>
                  <td>{{ getQuantity(carts.childCarts) }}</td>
                  <td class="header">
                    {{
                      'addToCartItems.mySavedOrder_LastOrdered' | cxTranslate
                    }}
                  </td>
                  <td>{{ getOrders(carts.childCarts) }}</td>
                  <td class="header">
                    {{
                      'addToCartItems.mySavedOrder_LastUpdated' | cxTranslate
                    }}
                  </td>
                  <td>
                    {{
                      carts?.modifiedTime | slice : 0 : 10 | date : 'dd/MM/yyyy'
                    }}
                  </td>
                  <td class="header">
                    {{
                      'addToCartItems.mySavedOrder_dateCreated' | cxTranslate
                    }}
                  </td>
                  <td>
                    {{ carts?.saveTime | slice : 0 : 10 | date : 'dd/MM/yyyy' }}
                  </td>
                </tr>
              </table>
            </div>
            <!-- view edit mobile -->
            <div class="col-md-12 d-flex d-md-none justify-content-center mb-4">
              <a
                id="linkViewEdit"
                aria-labelledby="cartHeading"
                aria-describedby="linkViewEdit"
                target=""
                class="c-link mr-4"
                data-di-id="#linkViewEdit"
                (click)="viewOrEditOrder(carts?.code)"
                >{{ 'addToCartItems.view/cart' | cxTranslate }}
                <img class="icon" src="../../assets/icons/pencil.svg" alt=""
              /></a>
              <a
                href="javascript:void(0)"
                class="c-link"
                data-toggle="popover"
                data-placement="top"
                data-target="save-cart-0"
                data-original-title=""
                id="linkViewDelete"
                aria-labelledby="cartHeading"
                data-di-id="#linkViewDelete"
                popoverClass="popover-links deleterwd-small"
                [ngbPopover]="popDeltContentrwd"
                [popoverTitle]="popDeltTitlerwd"
                triggers="manual"
                #d1="ngbPopover"
                placement="top"
                (click)="toggle(d1)"
                container="body"
              >
                {{ 'addToCartItems.delete' | cxTranslate
                }}<img
                  class="icon"
                  src="../../assets/icons/trash-brand.svg"
                  alt=""
              /></a>
              <ng-template #popDeltTitlerwd
                ><span class="text-header"
                  >{{ 'addToCartItems.delete' | cxTranslate }}
                  {{ carts?.name }}
                  {{
                    'addToCartItems.deleteTitleSavedOrder' | cxTranslate
                  }}</span
                ></ng-template
              >
              <ng-template #popDeltContentrwd>
                <button
                  type="reset"
                  class="popover-close popover-cancel-button c-button c-button--secondary"
                  data-di-id="di-id-d01008be-fb6e135c"
                  (click)="toggle(d1)"
                >
                  {{ 'addToCartItems.cancel' | cxTranslate }}
                </button>
                <button
                  class="popover-cancel-button c-button c-button--primary"
                  type="submit"
                  data-di-id="di-id-3064f17d-e97cc628"
                  (click)="deleteCart(carts?.code)"
                >
                  {{ 'addToCartItems.delete' | cxTranslate }}
                </button>
              </ng-template>
            </div>
            <!-- edit/delete options for small screen -->
          </div>
        </section>
      </section>
    </div>
    <div class="col-md-4 pl-md-4 pl-lg-5">
      <div class="item-numb u-border-top-grey pt-4">
        <p class="text-fixed-medium" *ngIf="cartItemsNo === 0; else noOfCart">
          {{ 'addToCartItems.emptycart' | cxTranslate }}
        </p>
        <ng-template #noOfCart>
          <p class="text-fixed-medium">
            {{ 'addToCartItems.currentCart' | cxTranslate }} {{ cartItemsNo }}
            {{ 'addToCartItems.itemsCart' | cxTranslate }}
          </p>
        </ng-template>
        <button
          type="button"
          class="c-button c-button--primary mb-4"
          [ngClass]="{ disabled: cartItemsNo === 0 }"
          [disabled]="cartItemsNo === 0"
          [ngbPopover]="popSaveContent"
          [popoverTitle]="popSaveTitle"
          triggers="manual"
          #p2="ngbPopover"
          placement="top"
          (click)="toggle(p2)"
          container="body"
          popoverClass="popover-links saveorder-incart"
        >
          {{ 'addToCartItems.saveOrderLabel' | cxTranslate }}
        </button>
        <section class="savecart-saveorder">
          <ng-template #popSaveTitle>{{
            'addToCartItems.saveOrderTitle' | cxTranslate
          }}</ng-template>
          <ng-template #popSaveContent>
            <input
              type="text"
              class="save-order"
              required
              #name="ngModel"
              maxlength="30"
              [(ngModel)]="saveCartName"
              (keypress)="omit_special_char($event)"
            />
            <span *ngIf="name.errors?.required" class="common-error required">{{
              errorMsg
            }}</span>
            <span class="common-error required">{{ errorMsg }}</span>
            <div class="c-popover">
              <button
                type="reset"
                class="popover-close popover-cancel-button c-button c-button--secondary"
                (click)="toggle(p2)"
              >
                {{ 'addToCartItems.cancel' | cxTranslate }}
              </button>
              <button
                class="popover-cancel-button c-button c-button--primary"
                type="submit"
                data-di-id="di-id-3064f17d-e97cc628"
                (click)="saveOrder()"
              >
                {{ 'addToCartItems.save' | cxTranslate }}
              </button>
            </div>
          </ng-template>
        </section>
        <!--Popover content for Save cart start-->
        <div class="hide" id="save-cart-order-pop-up">
          <form
            id="saveCartForm"
            class="edit-popover savedcartpop"
            action="/cart/saveOrder"
            method="post"
          >
            <div class="col-xs-12 col-sm-12 col-md-12 p0"></div>

            <div>
              <input
                type="hidden"
                name="CSRFToken"
                value="955a299b-a8e5-4a08-936d-b86589e8bc4a"
                data-di-id="di-id-31b63261-66d9a693"
              />
            </div>
          </form>
        </div>
        <p class="text-fixed-medium pt-4 u-border-top-grey">
          {{ 'addToCartItems.saveThisOrder' | cxTranslate }}
        </p>
      </div>

      <!-- Quick order -->
      <div class="c-widget c-quick-order-widget" aria-label="quickOrderSection">
        <div>
          <h3 class="c-widget-title c-heading c-heading-h3">
            {{ 'addToCartItems.addProducts' | cxTranslate }}
          </h3>
          <h6 class="c-heading c-heading-h5 color-secondary">
            {{ 'addToCartItems.quickOrder' | cxTranslate }}
          </h6>
          <p class="text-fixed-legal-copy">
            {{ 'addToCartItems.searchItems' | cxTranslate }}
          </p>
        </div>
        <app-quick-order class="w-100"></app-quick-order>
      </div>
    </div>
  </div>
</div>
