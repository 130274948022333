<div class="bc-makePayment-Div row no-print mb-4">
  <div
    class="back-To-makePayment col-md-12 text-md-right mt-4 mt-lg-0 pl-4 pl-md-0 pr-md-4"
  >
    <a
      href="javascript: void(0);"
      [routerLink]="['/', 'my-account/invoices']"
      class="c-link"
    >
      <fa-icon [icon]="faChevronLeft" class="icon"></fa-icon> Back to make a
      payment</a
    >
  </div>
</div>
<section
  class="row makePaymentView u-border-left-grey pb-4"
  id="productList-area"
>
  <div class="col-md-12 pay-section">
    <app-payment-header
      [selectedHeader]="header"
      [tableCategory]="creditInvoice"
    ></app-payment-header>
    <section class="row pl-md-3">
      <section class="col-md-12 col-xs-12 op_ovice">
        <h2 class="font-bold u-border-bottom-grey pb-4 mb-4">
          <span *ngIf="header == 'paymentDetails'">Payment</span>
          <span *ngIf="header == 'creditDetails'">Credits</span>
        </h2>
      </section>
    </section>

    <!-- Credits -->
    <form
      *ngIf="creditPaymentList.length > 0"
      [formGroup]="creditPaymentForm"
      class="invoice-form row pl-lg-4"
    >
      <div
        id="left-box"
        class="col-md-6 Fields-container register-zip paymentStep1View Fields-container-default-error form-group add-input c-text-input"
      >
        <label for="person_name" class="Fields-label">{{
          'makeAPayment.nameofPersonAuthorizingCredit' | cxTranslate
        }}</label>
        <input
          id="person_name"
          name="authorizerName"
          data-valid="valid_name"
          class="Fields-input auth-name form-control"
          formControlName="personName"
          (keypress)="omit_special_char($event)"
          [ngClass]="{ 'is-invalid': submitted && f.personName.errors }"
          data-di-id="#person_name"
          autocomplete="off"
          placeholder="Name"
        />
        <section
          *ngIf="submitted && f.personName.errors"
          class="invalid-feedback"
        >
          <section *ngIf="f.personName.errors.required">
            {{ 'makeAPayment.fieldRequired' | cxTranslate }}
          </section>
        </section>
      </div>

      <section
        *ngIf="!isAsmEnabled"
        id="right-box"
        class="col-md-6 text-md-right Fields-container register-zip paymentStep1View c-text-input mb-4"
      >
        <label for="nonASMToMail" class="Fields-label credit_name">
          {{ 'makeAPayment.EmailforConfirmation' | cxTranslate }}</label
        >
        <span class="user-email c-link">{{ userData?.email }}</span>
        <input
          id="nonASMToMail"
          name="nonASMToMail"
          [value]="userData?.email"
          type="hidden"
          data-di-id="#nonASMToMail"
        />
      </section>

      <!-- ASM -->
      <section
        *ngIf="isAsmEnabled"
        id="right-box"
        class="col-xs-9 col-sm-6 col-md-5 p0 Fields-container register-zip paymentStep1View req-fields"
        [formGroup]="editStdForm"
      >
        <section class="col-md-12 emails-ngSelect-Div">
          <label for="nonASMToMail" class="Fields-label credit_name">
            {{ 'makeAPayment.ASMEmailforConfirmation' | cxTranslate
            }}{{ 'addToCartItems.optional' | cxTranslate }} :</label
          >
          <ng-select
            class="w-100 email-Styling detailsEmail"
            [items]="emailsArray"
            bindLabel="emailId"
            bindValue="emailId"
            [multiple]="false"
            formControlName="emailId"
            [clearable]="false"
            [(ngModel)]="chosenEmail"
            placeholder="Please Select"
            dropdownPosition="bottom"
          >
          </ng-select>
        </section>
        <section
          class="col-md-12 add-Email"
          *ngIf="chosenEmail === 'Add Other E-mail Address'"
        >
          <input
            class="form-control edit-Form-Styling"
            type="email"
            formControlName="newemailId"
            maxlength="241"
            [ngClass]="{
              invalidClass:
                editStdForm.get('newemailId').invalid &&
                (editStdForm.get('newemailId').touched ||
                  editStdForm.get('newemailId').dirty)
            }"
          />
          <span
            class="txt-error"
            *ngIf="
              editStdForm.get('newemailId').invalid &&
              (editStdForm.get('newemailId').dirty ||
                editStdForm.get('newemailId').touched) &&
              editStdForm.get('newemailId').value !== 0
            "
          >
            You have entered an invalid email address
          </span>
        </section>
      </section>

      <section
        *ngIf="creditPaymentList.length > 0"
        class="col-md-12 tablemain-padding"
      >
        <!-- Table Desktop -->
        <table
          aria-describedby="Invoice Table"
          id="applyCreditsTable"
          class="credit-table display dataTable no-footer c-table c-table--branded d-none d-lg-block mt-2"
        >
          <thead>
            <tr class="heading-table">
              <th
                id="table1col1"
                class="sorting white-space-wrap"
                tabindex="0"
                aria-controls="applyCreditsTable"
                aria-label="Payment-option: activate to sort column ascending"
              >
                {{ 'makeAPayment.credit#' | cxTranslate }} &nbsp;
              </th>
              <th
                id="table1col2"
                class="sorting_desc white-space-wrap"
                tabindex="0"
                aria-controls="applyCreditsTable"
                aria-sort="descending"
                aria-label="Invoice #: activate to sort column ascending"
              >
                {{ 'makeAPayment.apply_to_invoice' | cxTranslate }}
              </th>
              <th
                id="table1col3"
                class="sorting white-space-wrap"
                tabindex="0"
                aria-controls="applyCreditsTable"
                aria-label="Due date: activate to sort column ascending"
              >
                {{ 'makeAPayment.credit_applied' | cxTranslate }}
              </th>
              <th
                id="table1col4"
                class="pr8 sorting white-space-wrap"
                tabindex="0"
                aria-controls="applyCreditsTable"
                aria-label="Invoice balance: activate to sort column ascending"
              >
                {{ 'makeAPayment.credit_balance' | cxTranslate }}
              </th>
              <th
                id="table1col5"
                class="pr8 sorting_desc white-space-wrap"
                tabindex="0"
                aria-controls="applyCreditsTable"
                aria-label="Payment amount: activate to sort column ascending"
              >
                {{ 'makeAPayment.invoice_balance' | cxTranslate }} &nbsp;
              </th>
              <th
                id="table1col6"
                class="sorting payment-method white-space-wrap"
                tabindex="0"
                aria-controls="applyCreditsTable"
                aria-label="Payment method: activate to sort column ascending"
              >
                {{ 'makeAPayment.credit_applied_date' | cxTranslate }}
                &nbsp;
              </th>
            </tr>
          </thead>
          <tbody class="datatable-row popup-comp">
            <tr class="odd" *ngFor="let data of creditPaymentList">
              <td headers="table1col1">
                <a
                  *ngIf="
                    data?.docType === 'RV' ||
                    data?.docType === 'RD' ||
                    data?.docType === 'RF'
                  "
                  href="javascript: void(0);"
                  class="underline"
                  (click)="creditPdf(data?.refDocNo)"
                >
                  {{ data?.refDocNo }}
                </a>
                <a
                  *ngIf="
                    data?.docType === 'DZ' && data?.allocNmbr?.startsWith('7')
                  "
                  href="javascript: void(0);"
                  class="underline"
                  (click)="creditPdf(data?.allocNmbr)"
                >
                  {{ data?.allocNmbr }}
                </a>
                <span
                  *ngIf="
                    data?.docType === 'DR' ||
                    data?.docType === 'DG' ||
                    data?.docType === 'DY'
                  "
                >
                  {{ data?.refDocNo }}
                </span>
                <span
                  *ngIf="
                    data?.docType === 'DZ' && !data?.allocNmbr?.startsWith('7')
                  "
                >
                  {{ data?.allocNmbr }}
                </span>
                <span *ngIf="data?.docType === 'YR'">
                  {{ data?.refhdr }}
                </span>
              </td>
              <td headers="table1col2">{{ data?.applyToInvoice }}</td>
              <td headers="table1col3" class="right-text">
                {{ data?.creditsApplied | currency }}
              </td>
              <td headers="table1col4" class="right-text">
                {{ data?.creditBalance | currency }}
              </td>
              <td headers="table1col5" class="right-text">
                {{ data?.invoiceBalance | currency }}
              </td>
              <td headers="table1col6">
                {{ paymentDateDefault | date : 'dd/MM/yyyy' }}
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Table Mobile -->
        <table
          class="c-table c-table--branded c-table--branded--two-col d-table d-lg-none not-pdf"
        >
          <tr *ngFor="let data of creditPaymentList">
            <td class="two-column-lay header w-50">
              {{ 'makeAPayment.credit#' | cxTranslate }}
            </td>
            <td class="two-column-lay">
              <p headers="table1col1 mb-0">
                <a
                  *ngIf="
                    data?.docType === 'RV' ||
                    data?.docType === 'RD' ||
                    data?.docType === 'RF'
                  "
                  href="javascript: void(0);"
                  class="underline"
                  (click)="creditPdf(data?.refDocNo)"
                >
                  {{ data?.refDocNo }}
                </a>
                <a
                  *ngIf="
                    data?.docType === 'DZ' && data?.allocNmbr?.startsWith('7')
                  "
                  href="javascript: void(0);"
                  class="underline"
                  (click)="creditPdf(data?.allocNmbr)"
                >
                  {{ data?.allocNmbr }}
                </a>
                <span
                  *ngIf="
                    data?.docType === 'DR' ||
                    data?.docType === 'DG' ||
                    data?.docType === 'DY'
                  "
                >
                  {{ data?.refDocNo }}
                </span>
                <span
                  *ngIf="
                    data?.docType === 'DZ' && !data?.allocNmbr?.startsWith('7')
                  "
                >
                  {{ data?.allocNmbr }}
                </span>
                <span *ngIf="data?.docType === 'YR'">
                  {{ data?.refhdr }}
                </span>
              </p>
            </td>
            <td class="two-column-lay header">
              {{ 'makeAPayment.apply_to_invoice' | cxTranslate }}
            </td>
            <td class="two-column-lay">{{ data?.applyToInvoice }}</td>
            <td class="two-column-lay header">
              {{ 'makeAPayment.credit_applied' | cxTranslate }}
            </td>
            <td class="two-column-lay">
              {{ data?.creditsApplied | currency }}
            </td>
            <td class="two-column-lay header">
              {{ 'makeAPayment.credit_balance' | cxTranslate }}
            </td>
            <td class="two-column-lay">
              {{ data?.creditBalance | currency }}
            </td>
            <td class="two-column-lay header">
              {{ 'makeAPayment.invoice_balance' | cxTranslate }}
            </td>
            <td class="two-column-lay">
              {{ data?.invoiceBalance | currency }}
            </td>
            <td class="two-column-lay header">
              {{ 'makeAPayment.credit_applied_date' | cxTranslate }}
            </td>
            <td class="two-column-lay">
              {{ paymentDateDefault | date : 'dd/MM/yyyy' }}
            </td>
          </tr>
        </table>
        <section class="invoice-btngrps pull-right d-flex mb-4 mt-lg-3">
          <button
            type="button"
            (click)="back()"
            class="c-button c-button--secondary oi-white-button mr-4"
            data-di-id="di-id-9f8fe96-b6e2fb87"
          >
            {{ 'makeAPayment.back_btn' | cxTranslate }}
          </button>
          <button
            type="button"
            class="c-button c-button--primary reviewvalidbtn"
            (click)="Continue('Credit')"
            data-di-id="di-id-7a6cb2cd-cb37948a"
          >
            {{ 'makeAPayment.continue_button_txt' | cxTranslate }}
          </button>
        </section>
      </section>
    </form>

    <!-- Invoice -->
    <form
      *ngIf="invoicePaymentList?.length > 0"
      [formGroup]="creditPaymentForm"
      class="invoice-form row pl-lg-4"
    >
      <div
        id="left-box"
        class="col-md-6 Fields-container register-zip paymentStep1View Fields-container-default-error form-group add-input c-text-input"
      >
        <label for="person_name" class="Fields-label">
          {{
            'makeAPayment.nameofPersonAuthorizingPayment' | cxTranslate
          }}</label
        >
        <input
          id="person_name_unique"
          name="authorizerName"
          data-valid="valid_name"
          class="Fields-input auth-name form-control"
          formControlName="personName"
          (keypress)="omit_special_char($event)"
          [ngClass]="{ 'is-invalid': submitted && f.personName.errors }"
          data-di-id="#person_name"
          autocomplete="off"
        />
        <section
          *ngIf="submitted && f.personName.errors"
          class="invalid-feedback"
        >
          <section *ngIf="f.personName.errors.required">
            {{ 'makeAPayment.fieldRequired' | cxTranslate }}
          </section>
        </section>
      </div>

      <section
        *ngIf="!isAsmEnabled"
        id="right-box"
        class="col-md-6 text-md-right Fields-container register-zip paymentStep1View c-text-input mb-4"
      >
        <label for="nonASMToMail" class="Fields-label credit_name">
          {{ 'makeAPayment.EmailforConfirmation' | cxTranslate }}</label
        >
        <span class="user-email c-link">{{ userData?.email }}</span>
      </section>

      <!-- ASM -->
      <section
        *ngIf="isAsmEnabled"
        id="right-box"
        class="col-xs-9 col-sm-6 col-md-5 p0 Fields-container register-zip paymentStep1View req-fields"
        [formGroup]="editStdForm"
      >
        <section class="col-md-12 emails-ngSelect-Div">
          <label for="nonASMToMail" class="Fields-label credit_name">
            {{ 'makeAPayment.ASMEmailforConfirmation' | cxTranslate
            }}{{ 'addToCartItems.optional' | cxTranslate }} :</label
          >
          <ng-select
            class="w-100 email-Styling detailsEmail"
            [items]="emailsArray"
            bindLabel="emailId"
            bindValue="emailId"
            [multiple]="false"
            formControlName="emailId"
            [clearable]="false"
            [(ngModel)]="chosenEmail"
            placeholder="Please Select"
            dropdownPosition="bottom"
          >
          </ng-select>
        </section>
        <section
          class="col-md-12 add-Email"
          *ngIf="chosenEmail === 'Add Other E-mail Address'"
        >
          <input
            class="form-control edit-Form-Styling"
            type="email"
            formControlName="newemailId"
            maxlength="241"
            [ngClass]="{
              invalidClass:
                editStdForm.get('newemailId').invalid &&
                (editStdForm.get('newemailId').touched ||
                  editStdForm.get('newemailId').dirty)
            }"
          />
          <span
            class="txt-error"
            *ngIf="
              editStdForm.get('newemailId').invalid &&
              (editStdForm.get('newemailId').dirty ||
                editStdForm.get('newemailId').touched) &&
              editStdForm.get('newemailId').value !== 0
            "
          >
            You have entered an invalid email address
          </span>
        </section>
      </section>

      <section
        *ngIf="invoicePaymentList.length > 0"
        class="col-md-12 tablemain-padding"
      >
        <!-- Table Desktop -->
        <table
          aria-describedby="creditsTable"
          id="applyCreditsTable"
          class="payment-table display dataTable no-footer c-table c-table--branded d-none d-lg-block mt-2"
        >
          <thead>
            <tr class="heading-table">
              <th
                id="table1col1"
                class="sorting white-space-wrap"
                tabindex="0"
                aria-controls="applyCreditsTable"
                aria-label="Payment-option: activate to sort column ascending"
              >
                {{ 'makeAPayment.payment_option' | cxTranslate }} &nbsp;
              </th>
              <th
                id="table1col2"
                class="sorting_desc white-space-wrap"
                tabindex="0"
                aria-controls="applyCreditsTable"
                aria-sort="descending"
                aria-label="Invoice #: activate to sort column ascending"
              >
                {{ 'makeAPayment.invoice#' | cxTranslate }} &nbsp;
              </th>
              <th
                id="table1col3"
                class="sorting white-space-wrap"
                tabindex="0"
                aria-controls="applyCreditsTable"
                aria-label="Due date: activate to sort column ascending"
              >
                {{ 'makeAPayment.due_date' | cxTranslate }} &nbsp;
              </th>
              <th
                id="table1col4"
                class="pr8 sorting white-space-wrap"
                tabindex="0"
                class=""
                aria-controls="applyCreditsTable"
                aria-label="Invoice balance: activate to sort column ascending"
              >
                {{ 'makeAPayment.invoice_balance' | cxTranslate }} &nbsp;
              </th>
              <th
                id="table1col5"
                class="pr8 sorting_desc white-space-wrap"
                tabindex="0"
                class=""
                aria-controls="applyCreditsTable"
                aria-label="Payment amount: activate to sort column ascending"
              >
                {{ 'makeAPayment.payment_amount' | cxTranslate }} &nbsp;
              </th>

              <th
                id="table1col7"
                class="sorting white-space-wrap"
                tabindex="0"
                aria-controls="applyCreditsTable"
                aria-label="Payment date: activate to sort column ascending"
              >
                {{ 'makeAPayment.payment_date' | cxTranslate }}
              </th>
            </tr>
          </thead>
          <tbody class="datatable-row popup-comp">
            <tr class="odd" *ngFor="let data of invoicePaymentList">
              <td headers="table1col1">{{ paymentOptionDefault }}</td>
              <td headers="table1col2" class="darkblue sorting_1">
                <a
                  *ngIf="
                    data?.docType === 'RV' ||
                    data?.docType === 'RD' ||
                    data?.docType === 'RF'
                  "
                  href="javascript: void(0);"
                  class="underline"
                  (click)="invoicePdf(data?.refDocNo)"
                >
                  {{ data?.refDocNo }}
                </a>
                <a
                  *ngIf="
                    data?.docType === 'DZ' && data?.allocNmbr?.startsWith('7')
                  "
                  href="javascript: void(0);"
                  class="underline"
                  (click)="invoicePdf(data?.allocNmbr)"
                >
                  {{ data?.allocNmbr }}
                </a>
                <span
                  *ngIf="
                    data?.docType === 'DR' ||
                    data?.docType === 'DG' ||
                    data?.docType === 'DY'
                  "
                >
                  {{ data?.refDocNo }}
                </span>
                <span
                  *ngIf="
                    data?.docType === 'DZ' && !data?.allocNmbr.startsWith('7')
                  "
                >
                  {{ data?.allocNmbr }}
                </span>
                <span *ngIf="data?.docType === 'YR'">
                  {{ data?.refhdr }}
                </span>
                <input
                  id="invoiceDatas0.credit"
                  name="invoiceDatas[0].credit"
                  type="hidden"
                  value="92258122"
                  data-di-id="#invoiceDatas0.credit"
                />
              </td>
              <td headers="table1col3">{{ data?.dueDate }}</td>
              <td headers="table1col4" class="right-text">
                {{ data?.invoiceBalance | currency }}
                <input
                  id="invoiceDatas0.invoiceBalance"
                  name="invoiceDatas[0].invoiceBalance"
                  value="1479.49"
                  type="hidden"
                  data-di-id="#invoiceDatas0.invoiceBalance"
                />
              </td>

              <td headers="table1col5" class="right-text">
                {{ data?.paymentAmount }}
              </td>

              <td headers="table1col7">
                {{ paymentDateDefault | date : 'dd/MM/yyyy' }}
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Table Mobile -->
        <table
          class="c-table c-table--branded c-table--branded--two-col d-table d-lg-none not-pdf"
        >
          <tr *ngFor="let data of invoicePaymentList">
            <td class="two-column-lay header">
              {{ 'makeAPayment.payment_option' | cxTranslate }}
            </td>
            <td class="two-column-lay">{{ paymentOptionDefault }}</td>
            <td class="two-column-lay header">
              {{ 'makeAPayment.invoice#' | cxTranslate }}
            </td>
            <td class="two-column-lay">
              <a
                *ngIf="
                  data?.docType === 'RV' ||
                  data?.docType === 'RD' ||
                  data?.docType === 'RF'
                "
                href="javascript: void(0);"
                class="underline"
                (click)="invoicePdf(data?.refDocNo)"
              >
                {{ data?.refDocNo }}
              </a>
              <a
                *ngIf="
                  data?.docType === 'DZ' && data?.allocNmbr?.startsWith('7')
                "
                href="javascript: void(0);"
                class="underline"
                (click)="invoicePdf(data?.allocNmbr)"
              >
                {{ data?.allocNmbr }}
              </a>
              <span
                *ngIf="
                  data?.docType === 'DR' ||
                  data?.docType === 'DG' ||
                  data?.docType === 'DY'
                "
              >
                {{ data?.refDocNo }}
              </span>
              <span
                *ngIf="
                  data?.docType === 'DZ' && !data?.allocNmbr?.startsWith('7')
                "
              >
                {{ data?.allocNmbr }}
              </span>
              <span *ngIf="data?.docType === 'YR'">
                {{ data?.refhdr }}
              </span>
              <input
                id="invoiceDatas0.credit"
                name="invoiceDatas[0].credit"
                type="hidden"
                value="92258122"
                data-di-id="#invoiceDatas0.credit"
              />
            </td>
            <td class="two-column-lay header">
              {{ 'makeAPayment.due_date' | cxTranslate }}
            </td>
            <td class="two-column-lay">{{ data?.dueDate }}</td>
            <td class="two-column-lay header">
              {{ 'makeAPayment.invoice_balance' | cxTranslate }}
            </td>
            <td class="two-column-lay">
              {{ data?.invoiceBalance | currency }}
              <input
                id="invoiceDatas0.invoiceBalance"
                name="invoiceDatas[0].invoiceBalance"
                value="1479.49"
                type="hidden"
                data-di-id="#invoiceDatas0.invoiceBalance"
              />
            </td>
            <td class="two-column-lay header">
              {{ 'makeAPayment.payment_amount' | cxTranslate }}
            </td>
            <td class="two-column-lay">{{ data?.paymentAmount }}</td>
            <td class="two-column-lay header">
              {{ 'makeAPayment.payment_date' | cxTranslate }}
            </td>
            <td class="two-column-lay">
              {{ paymentDateDefault | date : 'dd/MM/yyyy' }}
            </td>
          </tr>
        </table>
      </section>
      <!-- Payment Method -->
      <div class="col-md-12 mt-4">
        <div class="row">
          <div class="col-md-4 mb-4"></div>
          <div class="col-md-8 mb-4 u-border-bottom-grey pb-4 mb-4">
            <!-- Heading -->
            <h2 class="font-bold mb-4">
              {{ 'makeAPayment.payment_method' | cxTranslate }}
            </h2>
            <!-- Existing card -->
            <div class="row pl-4">
              <div class="col-md-4 pl-4">
                <div class="existingDropdown asses_dropdown">
                  <div
                    id="existingCard"
                    class="sorting_1 payment-details radio_grp"
                  >
                    <input
                      id="existing1"
                      type="radio"
                      name="billing_loc0"
                      checked="data.checked"
                      data-di-id="#existing1"
                    />
                    <span
                      aria-labelled="existing1"
                      class="card-details-padding"
                      >{{ 'makeAPayment.existing_card' | cxTranslate }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="existingDropdown asses_dropdown">
                  <section
                    class="sort-by-region fst-dropdown custom-selectpay select-pay-adj"
                  >
                    <ng-select
                      [(ngModel)]="selectedCard"
                      (change)="cardChange(selectedCard)"
                      [ngModelOptions]="{ standalone: true }"
                    >
                      <ng-option
                        *ngFor="let data of formatCardData"
                        [value]="data"
                        >{{ data?.displayCardNumber }}
                      </ng-option>
                    </ng-select>
                  </section>
                </div>
              </div>
            </div>
            <!-- New card -->
            <div class="row pl-4">
              <div class="col-md-4 pl-4">
                <div
                  class="sorting_1 radio_grp show-content card-details"
                  *ngIf="!isAsmEnabled"
                >
                  <input
                    id="new_card"
                    type="radio"
                    data-toggle="modal"
                    data-keyboard="true"
                    (click)="open()"
                    name="billing_loc0"
                    class="newCard"
                    data-di-id="di-id-b58cbc10-235349aa"
                  />
                  <span>{{ 'makeAPayment.new_card' | cxTranslate }}</span>
                  <input
                    id="newCrmID"
                    name="invoiceDatas[0].newCrmCardId"
                    type="hidden"
                    value=""
                    data-di-id="#newCrmID"
                  />
                </div>
              </div>
              <div class="col-md-8"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- Pay now -->
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4 mb-4"></div>
          <div class="col-md-8 mb-4">
            <div class="row">
              <div class="col-md-7">
                <h2 class="font-bold mb-4">
                  {{ 'makeAPayment.pay_now' | cxTranslate }}
                </h2>
              </div>
              <div class="col-md-5 text-right">
                <h4 class="total amount font-bold">
                  {{ totalInvoiceAmount | currency }}
                </h4>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p class="text-fixed-medium">
                  {{ 'makeAPayment.totalamountinfo' | cxTranslate }}
                </p>
              </div>
            </div>
            <div class="row grey-bg p-3">
              <div class="col-md-6">
                <h4 class="font-bold m-0">
                  {{ 'makeAPayment.payment_total' | cxTranslate }}
                </h4>
              </div>
              <div class="col-md-6 text-right">
                <h4 class="total font-bold m-0">
                  {{ totalInvoiceAmount | currency }}
                </h4>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-12 d-flex justify-content-end">
                <button
                  type="button"
                  (click)="back()"
                  class="c-button c-button--secondary mr-4"
                >
                  {{ 'makeAPayment.cancel' | cxTranslate }}
                </button>
                <button
                  type="submit"
                  class="c-button c-button--primary"
                  data-di-id="di-id-5896ea52-8b55cbae"
                  (click)="Continue('Invoice')"
                >
                  {{ 'makeAPayment.continue_button_txt' | cxTranslate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- End Invoice -->
  </div>
</section>
