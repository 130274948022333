<section class="no-print container mb-3">
  <div class="row">
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 text-right mb-2 mb-sm-0">
      <a
        id="continueShoppingId"
        data-di-id="#continueShoppingId"
        href="/CAD/Open-Catalogue/c/1"
        target=""
        class="c-link"
      >
        <fa-icon [icon]="faChevronLeft" class="icon"></fa-icon>
        {{ 'addToCartItems.continueShopping' | cxTranslate }}
      </a>
    </div>
    <div
      *ngIf="productEntries?.length > 0"
      class="col-lg-4 col-md-4 col-sm-12 col-xs-12 text-right d-flex flex-wrap gap-1 justify-content-md-end"
    >
      <a
        id="tealium-xls"
        data-di-id="#tealium-xls"
        class="c-link"
        aria-label="download as"
        href="javascript:void(0)"
        target=""
        (click)="exportXls()"
      >
        <span>{{ 'addToCartItems.xls' | cxTranslate }}</span>
        <fa-icon [icon]="faFileExcel" class="icon"></fa-icon>
      </a>
      <a
        id="printLinkId"
        data-di-id="#printLinkId"
        class="c-link"
        href="javascript:void(0)"
        target=""
        onclick="window.print();"
      >
        <span>{{ 'addToCartItems.print' | cxTranslate }}</span>
        <fa-icon [icon]="faPrint" class="icon"></fa-icon>
      </a>
    </div>
  </div>
</section>
<app-checkout
  [cartDetails]="cartDetails"
  [stdCartType]="stdCart"
></app-checkout>
