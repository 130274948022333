import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  GlobalMessageService,
  GlobalMessageType,
  LanguageService,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { CustomRegistrationService } from '../../custom-registration.service';
import {
  globalErrorMsgEng,
  globalErrorMsgFr,
  siteLanguagesEng,
} from '../../registration.constants';

const specificCharsNotAllowed = '[^!$%*()=]+$';

@Component({
  selector: 'app-billing-information',
  templateUrl: './billing-information.component.html',
  styleUrls: ['./billing-information.component.scss'],
})
export class BillingInformationComponent implements OnInit, OnDestroy {
  @Input() formDetails: any;
  @Input() multipleFormData: FormGroup[];

  billingForm: FormGroup;
  provinces: any[] = [];
  practiceOrFacility: any[] = [];
  yellowFever: boolean[] = [true, false];
  isShippingAddressSame: boolean[] = [true, false];
  errorMessageText: string = globalErrorMsgEng;
  provinceSub: Subscription;
  practiceOrFacilitySub: Subscription;
  emailRegex =
    /^([A-Za-z0-9\._-]+)@([A-Za-z0-9\._-]+)\.([A-Za-z\.]{2,8})(\.[A-Za-z\.]{2,8})?$/g;
  salesOrgs: any[] = [{id:'CA04',name: 'Synvisc'},{id:'CA02',name:'VaccineShoppe'}];
  
  constructor(
    private readonly accountRegService: CustomRegistrationService,
    private readonly cdr: ChangeDetectorRef,
    private readonly user: UserAccountFacade,
    private readonly router: Router,
    private readonly languageService: LanguageService,
    private readonly globalMessage: GlobalMessageService
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    this.languageService.getActive().subscribe((langRes) => {
      this.errorMessageText =
        langRes === siteLanguagesEng ? globalErrorMsgEng : globalErrorMsgFr;
    });
    this.accountRegService.getProvinces({
      fields: 'DEFAULT',
    });
    this.accountRegService.getPracticeOrFacilityType({
      enumerationType: 'PracticeOrFacilityType',
      fields: 'DEFAULT',
    });
    const maxlengthChar = 256;
    const charlengthSix = 6;
    const maxlengthTwelve = 12;
    const maxlengthSeven = 7;
    this.billingForm = new FormGroup({
      billingAddress: new FormGroup({
        facilityName: new FormControl(null, [
          Validators.required,
          Validators.pattern(specificCharsNotAllowed),
          Validators.maxLength(maxlengthChar),
        ]),
        legalName: new FormControl(null, [
          Validators.required,
          Validators.pattern(specificCharsNotAllowed),
          Validators.maxLength(maxlengthChar),
        ]),
        line1: new FormControl(null, [
          Validators.required,
          Validators.pattern(specificCharsNotAllowed),
          Validators.maxLength(maxlengthChar),
        ]),
        line2: new FormControl(null, [
          Validators.pattern('[a-zA-Z0-9 ]+$'),
          Validators.maxLength(maxlengthChar),
        ]),
        town: new FormControl(null, [
          Validators.required,
          Validators.pattern('[a-zA-Z ]+$'),
          Validators.maxLength(maxlengthChar),
        ]),
        region: new FormGroup({
          isocode: new FormControl(null, Validators.required),
          countryIso: new FormControl('CA'),
        }),
        postalCode: new FormControl(null, [
          Validators.required,
          Validators.pattern(
            '^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] [0-9][A-Z][0-9]$'
          ),
          Validators.maxLength(maxlengthSeven),
        ]),
        phone: new FormControl('', [
          Validators.required,
          Validators.pattern('^[0-9]{3}-[0-9]{3}-[0-9]{4}$'),
          Validators.maxLength(maxlengthTwelve),
        ]),
        extension: new FormControl(null, [
          Validators.pattern('[0-9]*$'),
          Validators.maxLength(charlengthSix),
        ]),
        fax: new FormControl('', [
          Validators.pattern('^[0-9]{3}-[0-9]{3}-[0-9]{4}$'),
          Validators.maxLength(maxlengthTwelve),
        ]),
        email: new FormControl(null, [
          Validators.required,
          Validators.pattern(
            '^([A-Za-z0-9\\._-]+)@([A-Za-z0-9\\._-]+)\\.([A-Za-z\\.]{2,8})(\\.[A-Za-z\\.]{2,8})?$'
          ),
          Validators.minLength(charlengthSix),
          Validators.maxLength(maxlengthChar),
        ]),
        confirmEmail: new FormControl(null, [
          Validators.required,
          Validators.pattern(
            '^([A-Za-z0-9\\._-]+)@([A-Za-z0-9\\._-]+)\\.([A-Za-z\\.]{2,8})(\\.[A-Za-z\\.]{2,8})?$'
          ),
          Validators.minLength(charlengthSix),
          Validators.maxLength(maxlengthChar),
        ]),
        practiceOrFacilityType: new FormControl(null, Validators.required),
        yellowFever: new FormControl(this.yellowFever[1], Validators.required),
        billingAddress: new FormControl(
          this.isShippingAddressSame[0],
          Validators.required
        ),
        shippingAddress: new FormControl(
          this.isShippingAddressSame[1],
          Validators.required
        ),
        salesOrg: new FormControl(null),
      }),
    });

    if (!!this.formDetails) {
      const formVals = this.formDetails.value;
      
      let salesOrgVal;
      if (formVals.salesOrg == 'OrgSynvisc') {
        salesOrgVal = 'CA04';
      } else if(formVals.salesOrg == 'OrgVaccineShoppe') {
        salesOrgVal = 'CA02';
      }
      this.billingForm.setValue(
        {
          billingAddress: {
            facilityName: formVals.billingAddress.facilityName,
            legalName: formVals.billingAddress.legalName,
            line1: formVals.billingAddress.line1,
            line2: formVals.billingAddress.line2,
            town: formVals.billingAddress.town,
            region: {
              isocode: formVals.billingAddress.region.isocode,
              countryIso: formVals.billingAddress.region.countryIso,
            },
            postalCode: formVals.billingAddress.postalCode,
            phone: formVals.billingAddress.phone,
            extension: formVals.billingAddress.extension,
            fax: formVals.billingAddress.fax,
            email: formVals.billingAddress.email,
            confirmEmail: formVals.billingAddress.confirmEmail,
            practiceOrFacilityType:
              formVals.billingAddress.practiceOrFacilityType,
            yellowFever: formVals.billingAddress.yellowFever,
            billingAddress: formVals.billingAddress.billingAddress,
            shippingAddress: formVals.billingAddress.shippingAddress,
            salesOrg: salesOrgVal
          },
        },
        { emitEvent: false }
      );
      
    }

    this.provinceSub = this.accountRegService.provinceData$.subscribe((res) => {
      if (res !== false) {
        this.provinces = res?.regions;
      }
    });

    this.practiceOrFacilitySub =
      this.accountRegService.practiceOrFacilityTypeData$.subscribe(
        (response) => {
          if (response !== false) {
            this.practiceOrFacility = response;
          }
        }
      );
  }

  proceedToShipping() {
    this.billingForm.markAllAsTouched();
    if (
      this.billingForm.valid &&
      this.billingForm.get('billingAddress.email').value ===
        this.billingForm.get('billingAddress.confirmEmail').value
    ) {
      if (!!this.multipleFormData && this.multipleFormData.length > 0) {
        this.multipleFormData[0] = this.billingForm;
      } else {
        this.multipleFormData = [];
      }
      const billingDetails = {
        header: 'shippingDetails',
        form: this.billingForm,
        forms: this.multipleFormData,
      };
      this.accountRegService.sectionChange.next(billingDetails);
    } else {
      window.scroll(0, 0);
      this.globalMessage.add(
        this.errorMessageText,
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }
  }

  ngOnDestroy() {
    this.practiceOrFacilitySub?.unsubscribe();
    this.provinceSub?.unsubscribe();
  }
}
