import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import {
  CmsService,
  OccEndpointsService,
  ProductService,
} from '@spartacus/core';
@Component({
  selector: 'app-product-monograph',
  template: `<div class="row mb-4 product-monograph">
    <div class="col-md-12">
      <div
        *ngFor="let prodMon of this.prodcutsInfo"
        class=" u-border-bottom-grey py-4"
      >
        <a
          class="c-link my-2"
          target="_blank"
          href="{{ prodMon?.productDocuments?.productDocument[0]?.url }}"
          >{{ prodMon?.name }}&nbsp;<fa-icon
            [icon]="faFilePdf"
            class="icon"
          ></fa-icon
        ></a>
        <div
          class="sub-text"
          [innerHTML]="sanitizer.bypassSecurityTrustHtml(prodMon?.description)"
        ></div>
      </div>
      <div
        *ngFor="let prodMon of this.prodcutsInfoSynvisc"
        class=" u-border-bottom-grey py-4"
      >
        <a
          class="c-link my-2"
          target="_blank"
          href="{{ prodMon?.productDocuments?.productDocument[0]?.url }}"
          >{{ prodMon?.name }}&nbsp;<fa-icon
            [icon]="faFilePdf"
            class="icon"
          ></fa-icon
        ></a>
        <div
          class="sub-text"
          [innerHTML]="sanitizer.bypassSecurityTrustHtml(prodMon?.description)"
        ></div>
      </div>
    </div>
  </div> `,
})
export class ProductMonogragphComponent implements OnInit {
  cmsResponse: any = '';
  news: any;
  productCodes: any;
  prodcutsInfo: any = [];
  prodDocs: any = [];
  prodDocus: any = [];
  faFilePdf = faFilePdf;
  prodcutsInfoSynvisc: any = [];
  constructor(
    private readonly cmsData: CmsService,
    private readonly occEndPoint: OccEndpointsService,
    private readonly cdr: ChangeDetectorRef,
    protected productService: ProductService,
    private readonly sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.cmsData
      .getComponentData('ProductMonographComponent')
      .subscribe((res) => {
        if (res) {
          this.cmsResponse = res;
          this.news = this.cmsResponse.content;
          this.cdr.detectChanges();
          this.productMonograph('vaccine');
        }
      });
  
    this.cmsData
      .getComponentData('CanadaSynviscProductMonographComponent')
      .subscribe((res) => {
        if (res) {
          this.cmsResponse = res;
          this.news = this.cmsResponse.content;
          this.cdr.detectChanges();
          this.productMonograph('synvisc');
        }
      });
 
  }
  productMonograph(type) {
    this.productCodes = this.cmsResponse.productCodes;
    this.productCodes = this.productCodes.split(' ');
    this.productCodes.forEach((element) => {
      this.productService.get(element).subscribe((res) => {
        if (res) {
          if (type=='vaccine') {
            this.prodcutsInfo.push(res);
          } else {
            this.prodcutsInfoSynvisc.push(res);
          }
          this.cdr.detectChanges();
        }
      });
    });
  }
}
