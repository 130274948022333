<div class="no-print container mt-3 mb-1">
  <div class="row">
    <div class="col-md-6">
      <h3 class="font-bold">
        {{ activeSeasonId }} {{ 'selectVaccines.season' | cxTranslate }}
      </h3>
    </div>

    <div
      class="col-md-6 text-right d-flex flex-wrap gap-1 justify-content-md-end"
    >
      <span class="c-link" (click)="backToDashBoard(content, false)">
        <fa-icon [icon]="faChevronLeft" class="icon"></fa-icon>
        {{ 'selectVaccines.back_to_db' | cxTranslate }}</span
      >

      <span class="c-link" (click)="OnFluPrint()">
        <fa-icon [icon]="faPrint" class="icon"></fa-icon>
        {{ 'selectVaccines.print' | cxTranslate }}</span
      >
    </div>
  </div>
</div>

<app-flu-header [selectedHeader]="header"></app-flu-header>

<!---------------------------------------select vaccine------------------------------->

<section class="container select-Container">
  <section class="row">
    <!-- =====================iphone======================= -->
    <section
      class="container proceed-small-Container proceed-BtnView no-print d-md-none"
    >
      <section class="row">
        <section class="col-md-12 col-lg-12 col-xl-12">
          <button
            class="c-button c-button--primary w-100"
            [ngClass]="{ 'block-Click': apiCallUnderProcess }"
            [disabled]="duplicateProdCheckArray.length === 0 || disableButton"
            (click)="moveProductsToCart()"
          >
            <i class="fa fa-spinner fa-spin" *ngIf="apiCallUnderProcess"></i>
            {{ 'selectVaccines.proceed_toCart' | cxTranslate }}
          </button>
        </section>
      </section>
    </section>
    <!--left sec-->
    <section
      class="col-xs-12 col-sm-12 col-md-8 col-lg-8 float-left u-background--white pb-4 pb-md-4 u-border-top-grey mt-4"
    >
      <h3 class="font-bold my-4">
        {{ 'selectVaccines.select_Inf_Msg' | cxTranslate }}
      </h3>

      <hr />
      <section
        class="row row-Gap"
        *ngFor="let user of fluProductsList; let i = index"
        id="{{ user.code }}"
      >
        <!-- Image -->
        <section class="col-md-4 col-lg-2">
          <a (click)="productDetails(user)">
            <img
              *ngIf="user.thumbNailImg !== ''"
              src="{{ baseUrl }}{{ user.thumbNailImg }}"
              class="prod-Image"
              title="{{ user.name }}"
              alt="prodImg"
            />
            <img
              *ngIf="user.thumbNailImg == ''"
              [src]="fallbackImage"
              class="prod-Image"
              title="{{ user.name }}"
              alt="defImg"
            />
          </a>
        </section>
        <!-- Details -->

        <section class="col-md-8 col-lg-5 c-prod-details">
          <a (click)="productDetails(user)">
            <h2
              class="c-heading c-heading-h4 mb-4"
              [innerHTML]="user.name"
            ></h2>
          </a>
          <p
            class="text-fixed-medium color-grey"
            [innerHTML]="user.description"
          ></p>
          <p class="text-fixed-legal-copy color-grey-dark mb-1">
            <span class="font-bold">
              {{ 'selectVaccines.product_number' | cxTranslate }}</span
            >: &nbsp;{{ user.code }}
          </p>
        </section>
        <!-- ============================Price=========================================== -->
        <section class="col-xs-6 col-md-6 col-lg-3 price-list">
          <section class="row">
            <section class="col-md-12">
              <h6
                class="text-fixed-legal-copy color-grey-dark font-weight-bold"
              >
                {{ 'selectVaccines.yourPrice' | cxTranslate }}
              </h6>
            </section>
            <section class="col-md-12">
              <span class="c-heading c-heading-h3"
                >{{ user?.price?.value | currency }}
                {{ user?.price?.currencyIso }}</span
              >
            </section>
            <section
              class="col-md-12 col-lg-12 col-xl-12 vol-Pricing"
              *ngIf="!user?.discontinued && user?.available"
            >
              <a
                class="link vol-Price"
                placement="top"
                *ngIf="user?.volumePrices?.length > 0"
                [ngbPopover]="popContent"
                popoverTitle="{{ 'selectVaccines.volumePrice' | cxTranslate }}"
              >
                {{ 'selectVaccines.volumePrice' | cxTranslate }}
              </a>
              <ng-template #popContent>
                <table
                  class="tb"
                  id="vol-price-pdp"
                  aria-describedby="volTable"
                >
                  <thead>
                    <th scope="col"></th>
                  </thead>
                  <tbody
                    *ngFor="let prices of user?.volumePrices; let i = index"
                  >
                    <tr>
                      <td>
                        {{ prices?.minQuantity }} - {{ prices?.maxQuantity }}
                        {{ 'selectVaccines.units' | cxTranslate }}:
                      </td>
                      <td>{{ prices?.value | currency }}</td>
                      <td>
                        <span *ngIf="i > 0" class="save-price"
                          >{{ 'selectVaccines.save' | cxTranslate }}
                          {{
                            user?.volumePrices[0].value -
                              user?.volumePrices[i].value | currency
                          }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-template>
            </section>
          </section>
        </section>
        <!-- ==============================units========================================== -->
        <section class="col-5 col-sm-5 col-md-2 col-xs-6 p0 pad-Port">
          <section class="container p0">
            <section
              class="units text-center"
              *ngIf="
                user.available === true && user.discontinued === false;
                else NotAccept
              "
            >
              <div class="mb-2 text-center font-bold color-grey-dark">
                {{ 'plp.units' | cxTranslate }}
              </div>
              <section class="unit-area">
                <span
                  class="disabled"
                  aria-hidden="true"
                  id="{{ 'minusIcon' + user?.code }}"
                >
                  <img
                    src="../../assets/icons/less.svg"
                    id="{{ 'unitMinus' + user?.code }}"
                    class="disabled-img"
                    alt=""
                    (click)="
                      performQuantityComputation(
                        2,
                        user?.code,
                        user?.quantityPerUnit
                      )
                    "
                  />
                  <img
                    src="../../assets/icons/less-disable.svg"
                    id="{{ 'unitMinusDisable' + user?.code }}"
                    alt=""
                  />
                </span>
                <input
                  type="number"
                  class="prod-quantity text-center"
                  value="{{ user?.totalQuantity ? user?.totalQuantity : '0' }}"
                  onkeypress="return /[0-9a-zA-Z]/i.test(event.key)"
                  (keyup)="
                    performQuantityComputation(
                      null,
                      user?.code,
                      user?.quantityPerUnit
                    )
                  "
                  id="{{ 'prodQuantity' + user?.code }}"
                  min="0"
                  max="99999"
                />
                <span aria-hidden="true" id="{{ 'plusIcon' + user?.code }}">
                  <img
                    src="../../assets/icons/more.svg"
                    (click)="
                      performQuantityComputation(
                        1,
                        user?.code,
                        user?.quantityPerUnit
                      )
                    "
                    id="{{ 'unitPlus' + user?.code }}"
                    alt=""
                  />
                  <img
                    src="../../assets/icons/more-disable.svg"
                    id="{{ 'unitPlusDisable' + user?.code }}"
                    class="disabled-img"
                    alt=""
                  />
                </span>
              </section>

              <section
                class="mt-2 text-center"
                id="{{ 'noOfDoses' + user?.code }}"
              >
                {{ user?.totalDoses ? user?.totalDoses : '' }}
                {{ user?.totalDoses ? 'Doses' : '' }}
              </section>
              <span
                class="mt-2 available-now"
                *ngIf="
                  user.onsn === 'ON' &&
                  user.discontinued === false &&
                  user.available === true
                "
              >
                {{ 'selectVaccines.available_now' | cxTranslate }}
              </span>
            </section>

            <ng-template #NotAccept>
              <section class="units-Non-Accepting">
                <span
                  *ngIf="!user.available && user.discontinued === false"
                  class="Not-Available"
                >
                  {{
                    user?.availabilityText
                      ? user?.availabilityText
                      : ('selectVaccines.product_unavailable' | cxTranslate)
                  }}
                </span>
                <span
                  class=""
                  *ngIf="user.discontinued === true"
                  class="Not-Available"
                >
                  {{
                    'selectVaccines.product_discontinued' | cxTranslate
                  }}</span
                >
              </section>
            </ng-template>
          </section>
        </section>
        <section
          class="col-md-12 col-lg-12 col-xl-12"
          *ngIf="fluProductsList.length > 1"
        >
          <hr />
        </section>
      </section>
    </section>

    <!--right sec-->
    <section class="col-md-4 col-sm-12 cart-main-page">
      <section class="row proceed-Container proceed-BtnNormal px-md-4">
        <section class="col-md-12 text-center">
          <button
            class="c-button c-button--primary mt-3 proceedCheckout no-print w-100"
            (click)="moveProductsToCart()"
            [ngClass]="{ 'block-Click': apiCallUnderProcess }"
            [disabled]="duplicateProdCheckArray.length === 0 || disableButton"
          >
            <i class="fa fa-spinner fa-spin" *ngIf="apiCallUnderProcess"></i>
            {{ 'selectVaccines.proceed_toCart' | cxTranslate }}
          </button>
        </section>
        <section class="col-md-12">
          <section class="banner-Section big-Device-Section text-center my-4">
            <app-flu-dashboard-banner
              [bannerComponent]="compName"
            ></app-flu-dashboard-banner>
          </section>
        </section>
      </section>
    </section>
  </section>
</section>
<section class="custome-modal">
  <ng-template #content let-modal id="content-modal-data-id">
    <section class="modal-header">
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <img alt="" src="../../assets/icons/close.png" />
      </button>
      <h4 class="modal-title" id="modal-basic-title">
        {{ 'selectVaccines.discard_Changes' | cxTranslate }}
      </h4>
    </section>
    <section class="modal-body modal-body-discard">
      <span>{{ 'selectVaccines.leavePage_Msg' | cxTranslate }}</span>
    </section>
    <section class="modal-footer">
      <section class="row w-100 m-0">
        <section
          class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        >
          <button
            type="button"
            class="btn yesBtn-Css"
            (click)="modal.close('Save click'); backToDashBoard('', true)"
          >
            {{ 'selectVaccines.yes' | cxTranslate }}
          </button>
        </section>
        <section
          class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2"
        >
          <button
            type="button"
            class="btn cancelBtn-Css"
            (click)="modal.close('Save click')"
          >
            {{ 'selectVaccines.no' | cxTranslate }}
          </button>
        </section>
      </section>
    </section>
  </ng-template>
</section>
