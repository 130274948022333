import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import * as config from '../../config/app.config';
import { BaseService } from '../../services/base.service';


@Injectable({
  providedIn: 'root'
})
export class LoactionStripService {
  userAccountType: any;
  userAccountData: any;
  public endSession = new BehaviorSubject<any>(false);
  endSession$ = this.endSession.asObservable();
  public salesOrg$ = new BehaviorSubject<string>('');
  constructor(private readonly baseService: BaseService) {
    this.userAccountData = new Subject();
   }

  public getUserAccountDataFromDb(userId): void {
    this.baseService
      .get(config.USER_ACCOUNT + userId + '/b2bUnits', { fields: 'FULL' })
      .subscribe((res) => {
        if (res) {
          this.userAccountType = res.type;
          this.userAccountData.next({ res });  
          this.salesOrg$.next(res?.defaultB2BUnit?.salesOrg)  ;        
        }
      });
  }
  public disableLocStrip(endSessionFlag):void{
    this.endSession.next(endSessionFlag);
  }
}
