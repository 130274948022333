<section class="checkout-modal email-popup">
  <div class="modal-body">
    <div class="body-Div" [innerHtml]="emailConsentText"></div>
    <section class="d-flex">
      <button
        class="c-button c-button--secondary mb-4 mr-4"
        (click)="onRegister()"
      >
        {{ acceptBtnText }}
      </button>
      <button class="c-button c-button--primary" (click)="onNotNow()">
        {{ rejectBtnText }}
      </button>
    </section>
  </div>
</section>
