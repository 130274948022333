
    <div class="modal-header overlay-heading">
        <h4 class="modal-title checkout-title restore-header">
            {{ "externalpopover.modalheader" | cxTranslate }}  </h4>
    </div>
    
    <div class="modal-body text-center confirm-order">
        <p> 
            {{ "externalpopover.modalContentBody" | cxTranslate }} 
           
        </p>
    
        <button type="submit" class="btn btn-default blue-button blue_white " (click)="redirectto()"> {{ "externalpopover.modalWhiteBtn" | cxTranslate }}
        </button>
        <button type="button" class="btn btn-default white-button" (click)="dismissModal()">{{ "externalpopover.modalBluebtn" | cxTranslate }}
        </button>
    </div>