import { Component, Input, OnInit } from '@angular/core';
import { CustomInfluenzaService } from '../custom-influenza.service';

@Component({
  selector: 'app-flu-header',
  templateUrl: './flu-header.component.html',
})
export class FluHeaderComponent implements OnInit {
  @Input() selectedHeader: string;
  @Input() tableCategory: string;
  constructor(private readonly influenzaService: CustomInfluenzaService) {}

  ngOnInit() {}
}
