import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OccEndpointsService, RoutingService } from '@spartacus/core';
import { CustomPdpService } from 'src/app/custom-pdp/custom-pdp.service';
import { CheckoutService } from 'src/app/shared/modules/checkout/checkout.service';
import { CustomInfluenzaService } from '../custom-influenza.service';
import { ProductDetailsInfluenzaComponent } from '../product-details-influenza/product-details-influenza.component';

@Component({
  selector: 'app-flu-reservation-details',
  templateUrl: './flu-reservation-details.component.html',
  styleUrls: ['./flu-reservation-details.component.scss'],
})
export class FluReservationDetailsComponent implements OnInit, OnDestroy {
  fallbackImage = '../../assets/i18n-assets/sanofi_missing_product.jpg';
  totalProducts: any;
  totalDoses: number;
  estTotal: any;
  dataForFluConfirmation: any = [];
  productEntries: any = [];
  salesTax = 0;
  currencyIso: any;
  totalwithSavings: number;
  subTotal: any;
  directSavings: any;
  onlineSavings: any;
  baseUrl: any;
  duplicateProductCheckArray: any = [];
  displayProductForUiArray: any = [];
  reservationId: any = '';
  statusDisplay: any = '';
  seasonId: any = '';
  detailedResSubscriber: any;
  estimatedDeliveryDate: any;

  constructor(
    private readonly checkoutService: CheckoutService,
    private readonly router: RoutingService,
    private readonly occEndPoint: OccEndpointsService,
    private readonly influenzaService: CustomInfluenzaService,
    private readonly ref: ChangeDetectorRef,
    private readonly pdpService: CustomPdpService,
    private readonly modalService: NgbModal
  ) {}

  ngOnInit() {
    this.baseUrl = this.occEndPoint.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    });
    this.detailedResSubscriber =
      this.influenzaService.detailedReservation.subscribe((data) => {
        if (data) {
          this.dataForFluConfirmation = [];
          this.dataForFluConfirmation.push(data);
        }
        this.detailedResSubscriber?.unsubscribe();
      });
    if (this.dataForFluConfirmation) {
      this.dataForFluConfirmation.forEach((element, index) => {
        this.reservationId = element?.guid;
        this.statusDisplay = element.statusDisplay;
        this.seasonId = element.seasonId;
        this.estTotal = element?.totalPriceWithTax?.value;
        this.currencyIso = element?.totalPriceWithTax?.currencyIso;
        this.salesTax = element?.totalTax?.value;
        this.directSavings = element?.productDiscounts?.value;
        this.onlineSavings = element?.totalDiscounts?.value;
        this.totalwithSavings = element.totalPrice?.value;
        this.subTotal = element?.subTotal?.value;
        this.productEntries = [];
        this.totalDoses = 0;
        this.totalProducts = 0;
        this.productEntries = element?.entries;
        this.duplicateProductCheckArray = [];
        this.displayProductForUiArray = [];
        if (this.productEntries) {
          this.productEntries?.forEach((elem) => {
            if (elem?.estimatedDeliveryDate) {
              this.estimatedDeliveryDate = elem?.estimatedDeliveryDate;
            }
            let displayobj = {};
            if (
              this.duplicateProductCheckArray?.indexOf(elem?.product?.code) ==
              -1
            ) {
              this.duplicateProductCheckArray.push(elem.product.code);
              let thumbNailImg = '';
              displayobj = {
                prodCode: elem?.product?.code,
                prodName: elem?.product?.name,
                prodDesc: elem?.product?.description,
                basePrice: elem?.basePrice?.value,
                quantity: elem?.quantity,
                doses: elem?.product?.quantityPerUnit,
                onsn: elem?.product?.onsn,
              };
              elem?.product?.images
                ? (thumbNailImg = elem?.product?.images[2]?.url)
                : '';
              thumbNailImg !== ''
                ? (displayobj['thumbNailImg'] = this.baseUrl + thumbNailImg)
                : (displayobj['thumbNailImg'] = '');
              this.displayProductForUiArray.push(displayobj);
            } else {
              const prodObjectData = this.displayProductForUiArray.find(
                (dataObj) => {
                  this.displayProduct(dataObj, elem);
                }
              );

              this.displayProductForUiArray[
                this.displayProductForUiArray.indexOf(prodObjectData)
              ].quantity += elem.quantity;
            }

            this.totalProducts += parseInt(elem?.quantity);
            const a = parseInt(elem?.quantity);
            const b = parseInt(elem?.product?.quantityPerUnit);
            this.totalDoses = this.totalDoses + a * b;
          });
        }
        this.ref.detectChanges();
      });
    }
  }
  ngOnDestroy() {
    this.detailedResSubscriber?.unsubscribe();
  }
  displayProduct(dataObj, elem) {
    if (dataObj.prodCode === elem?.product?.code) {
      return dataObj;
    }
  }
  OnFluPrint() {
    window.print();
  }
  backToDashBoard() {
    this.router.goByUrl('/influenza/dashboard');
  }
  productDetails(productDetails) {
    this.pdpService.getPDPCategory(productDetails.prodCode);
    this.pdpService.productCode$.subscribe((res) => {
      if (res && res?.code === productDetails.prodCode) {
        this.modalService.dismissAll();
        const modalRef = this.modalService.open(
          ProductDetailsInfluenzaComponent,
          { windowClass: 'product-details-inf-modal' }
        );

        modalRef.componentInstance.productDetails = res;
        modalRef.componentInstance.standardCartCode = res.code;
      }
    });
  }
}
