import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import {
  NgbModal,
  NgbPopover,
  NgbPopoverConfig,
} from '@ng-bootstrap/ng-bootstrap';
import { RoutingService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';
import { CustomAddToCartService } from '../custom-add-to-cart.service';
@Component({
  selector: 'app-saved-orders',
  templateUrl: './saved-orders.component.html',
  styleUrls: ['./saved-orders.component.scss'],
})
export class SavedOrdersComponent implements OnInit, OnDestroy {
  particularCartDetails() {
    throw new Error('Method not implemented.');
  }
  @ViewChild('p2') public savePopover: NgbPopover;
  userAccountgetData: any;
  savedcarts: any;
  saveCartName: string;
  usergetDataObj: any;
  getuserData: any;
  searchedClientList: any;
  selectedData: any;
  cartItemsNo: number;
  cartId: any;
  stdCartId: any;
  errorMsg: string;
  renamedCart = '';
  cartData: any;
  cartItems: any;
  stdCartData: any;
  renameSub: Subscription;
  saveCartSub: Subscription;
  allCartSub: Subscription;
  updateSaveSub: Subscription;
  cartItemsSub: Subscription;
  userAccountgetSub: Subscription;
  deleteSavedCartSub: Subscription;
  createdSub: Subscription;
  restoredErrorSub: Subscription;
  invalidCartSub: Subscription;
  userDataSub: Subscription;
  faChevronLeft = faChevronLeft;

  constructor(
    private readonly addToCartService: CustomAddToCartService,
    private readonly modalService: NgbModal,
    private readonly user: UserAccountFacade,
    private readonly cdr: ChangeDetectorRef,
    private readonly config: NgbPopoverConfig,
    private readonly route: RoutingService,
    public datepipe: DatePipe
  ) {
    config.autoClose = 'outside';
  }

  ngOnInit(): void {
    this.renamedCart = '';
    this.renameSub = this.addToCartService.renameSaveCart$.subscribe((res) => {
      if (res) {
        this.renamedCart = res?.savedCartData?.name;
        this.addToCartService.renameSaveCart.next(false);
      }
    });
    this.userDataSub = this.user.get().subscribe((res) => {
      if (res && res != null) {
        this.userAccountgetData = res;
        this.getUserAccountData(this.userAccountgetData.userName);
        this.addToCartService.savedOrderDetails(
          this.userAccountgetData?.userName
        );
        this.saveCartSub = this.addToCartService.savedCartDetails$.subscribe(
          (res1) => {
            if (res1) {
              this.savedcarts = res1?.carts;
              this.cdr.detectChanges();
            }
          }
        );
        this.allCartSub = this.addToCartService.allCartItems$.subscribe(
          (resp) => {
            if (resp) {
              this.cartItems = resp?.carts;
              this.cartItems = this.cartItems.filter(
                (item) => item?.saveTime === undefined
              );
              this.cartItems = this.cartItems[this.cartItems?.length - 1];
              this.cartId = this.cartItems?.code;
              if (this.cartItems === undefined) {
                this.cartItemsNo = 0;
                this.cdr.detectChanges();
              }
              this.getSessionCartDetails();
            }
          }
        );
      }
    });
    this.updateSaveSub = this.addToCartService
      .getUpdateSavedOrder()
      .subscribe((res) => {
        if (res) {
          this.ngOnInit();
        }
      });
  }
  getSessionCartDetails() {
    this.cartItemsSub = this.addToCartService.cartItems$.subscribe(
      (res: any) => {
        if (res) {
          this.cartItemsNo = 0;
          this.cartData = res;
          let cartItems = res?.childCarts;
          this.cartId = res?.code;
          cartItems = cartItems.filter((item) => item.cartType === 'STANDARD');
          for (const carts of cartItems) {
            this.stdCartId = carts?.code;
            this.stdCartData = carts;
            if (this.stdCartData?.shipToParty) {
              this.selectedData =
                this.stdCartData?.shipToParty?.shippingAddresses.filter(
                  (item) => item.unitID === this.stdCartData.shipToParty.uid
                );
              this.selectedData = _.first(this.selectedData);
            }
            for (const entries of carts?.entries) {
              this.cartItemsNo += entries.quantity;
            }
          }
        }
        this.cdr.detectChanges();
      }
    );
  }
  getUserAccountData(userId): void {
    this.userAccountgetSub = this.addToCartService.userAccountDetails.subscribe(
      (userDataget) => {
        if (userDataget) {
          this.getuserData = userDataget.res.shippingAddresses;
          this.saveCartName = this.selectedData?.companyName?.slice(0, 30);
        }
      }
    );
    this.cdr.detectChanges();
  }
  toggle(popover) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
    }
    this.errorMsg = '';
    this.saveCartName = this.selectedData?.companyName?.slice(0, 30);
  }

  getQuantity(childCarts) {
    let quantity = 0;
    childCarts = childCarts.filter((item) => item.cartType === 'STANDARD');
    for (const carts of childCarts) {
      for (const entries of carts?.entries) {
        quantity += entries?.quantity;
      }
    }
    return quantity;
  }
  getOrders(childCarts) {
    let orderedDate = '-';
    childCarts = childCarts.filter((item) => item.cartType === 'STANDARD');
    for (const carts of childCarts) {
      if (carts?.lastOrdered) {
        orderedDate = carts?.lastOrdered.slice(0, 10);
        orderedDate = this.datepipe.transform(orderedDate, 'dd/MM/yyyy');
      }
    }
    return orderedDate;
  }
  restoreClick(restorecart, cartId) {
    if (this.cartItemsNo === 0) {
      this.restoreCart(cartId);
    } else {
      this.modalService.open(restorecart);
    }
  }
  deleteCart(cartId): void {
    this.addToCartService.deleteSavedCart(cartId);
    this.deleteSavedCartSub =
      this.addToCartService.deleteSavedCartItem$.subscribe((res) => {
        if (res) {
          this.ngOnInit();
        }
      });
  }
  clearSessionRestoreCart(cartId): void {
    this.restoreCart(cartId);
    this.modalService.dismissAll();
  }
  restoreCart(cartId): void {
    if (this.cartData && this.cartData?.code) {
      if (this.cartId === undefined) {
        this.cartId = this.cartData?.code;
      }
      this.addToCartService.restoreSavedCart(cartId, this.cartId);
    } else {
      this.addToCartService.createCart(this.userAccountgetData?.userName);
      this.createdSub = this.addToCartService.createdCart$.subscribe((res) => {
        if (res) {
          this.cartId = res?.code;
          this.getSessionCartDetails();
          this.addToCartService.restoreSavedCart(cartId, this.cartId);
        }
      });
    }
    this.restoredErrorSub = this.addToCartService.restoreErrored$.subscribe(
      (res) => {
        if (res) {
          this.addToCartService.getAllCarts(this.userAccountgetData?.userName);
        }
      }
    );
  }
  saveOrder(): void {
    const reg = /^[^`!@#~$%\^&*()_+\-={};':"\\|[\]\\.,<>/\/?~]*$/;
    if (this.saveCartName && RegExp(reg).test(this.saveCartName)) {
      this.addToCartService.saveCartDetails(this.saveCartName, this.cartId);
      this.saveCartSub = this.addToCartService.saveCartSuccess$.subscribe(
        (res) => {
          if (res) {
            if (this.savePopover) {
              this.savePopover.close();
              this.errorMsg = '';
              this.saveCartName = this.selectedData?.companyName?.slice(0, 30);
            }
            this.ngOnInit();
          }
        }
      );
      this.invalidCartSub = this.addToCartService.invalidCartName$.subscribe(
        (res) => {
          if (res) {
            for (const error of res?.error?.errors) {
              this.errorMsg = error?.message;
            }
          }
        }
      );
    } else {
      if (this.saveCartName) {
        this.errorMsg = 'Save cart name is not valid.';
      } else {
        this.errorMsg = 'Cart name cannot be blank';
      }
    }
  }
  viewOrEditOrder(saveCartId): void {
    this.route.goByUrl(`/cart/manage-saved-cart?savedCartID=${saveCartId}`);
  }
  closeModal(): void {
    this.modalService.dismissAll();
  }
  closeAlert() {
    this.renamedCart = '';
  }
  omit_special_char(event) {
    this.errorMsg = '';
    const reg = /^[^`!@#~$%\^&*()_+\-={};':"\\|[\]\\.,<>/\/?~]*$/;
    return new RegExp(reg).test(event.key);
  }
  ngOnDestroy() {
    this.renameSub?.unsubscribe();
    this.saveCartSub?.unsubscribe();
    this.allCartSub?.unsubscribe();
    this.updateSaveSub?.unsubscribe();
    this.cartItemsSub?.unsubscribe();
    this.userAccountgetSub?.unsubscribe();
    this.deleteSavedCartSub?.unsubscribe();
    this.createdSub?.unsubscribe();
    this.restoredErrorSub?.unsubscribe();
    this.invalidCartSub?.unsubscribe();
    this.userDataSub?.unsubscribe();
  }
}
