<section class="row">
  <div class="col-md-12 pt-3 pb-4 mb-4">
    <form [formGroup]="loginForm">
      <div class="col-sm-8 col-lg-5">
        <h2 class="mb-4 c-form-title">
          {{ "forgotUserPassword.YourPasswordHasBeenChanged" | cxTranslate }}
        </h2>
        <div class="c-text-input mb-4">
          <label for="Password" class="c-form-label">{{
            "forgotUserPassword.username" | cxTranslate
          }}</label>
          <input
            id="userId"
            name="userId"
            type="text"
            formControlName="userId"
            autocomplete="off"
            (ngModelChange)="validateUsername()"
          />
          <cx-form-errors
            role="alert"
            aria-live="assertive"
            *ngIf="
              (loginForm.get('userId').hasError('required') &&
                loginForm.get('userId').touched) ||
              userIdRequired
            "
            class="error-message"
            ><p>
              {{ "forgotUserPassword.requiredField" | cxTranslate }}
            </p></cx-form-errors
          >
        </div>

        <div class="c-text-input">
          <label for="confirmPassword" class="c-form-label">{{
            "forgotUserPassword.password" | cxTranslate
          }}</label>
          <input
            name="password"
            type="password"
            formControlName="password"
            autocomplete="off"
            (ngModelChange)="validatePassword()"
          />
          <cx-form-errors
            class="text-danger"
            role="alert"
            aria-live="assertive"
            *ngIf="
              (loginForm.get('password').hasError('required') &&
                loginForm.get('password').touched) ||
              passwordRequired
            "
            ><p>{{ "forgotUserPassword.requiredField" | cxTranslate }}</p>
          </cx-form-errors>
        </div>

        <div class="mt-4">
          <button
            type="submit"
            class="c-button c-button--primary"
            (click)="onSubmit()"
          >
            {{ "forgotUserPassword.Login" | cxTranslate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</section>
