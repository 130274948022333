import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { CmsService, LanguageService, RoutingService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { SharedService } from 'src/app/shared/services/shared.service';
import { CustomInfluenzaService } from '../custom-influenza.service';

@Component({
  selector: 'app-influenza-dashboard',
  templateUrl: './influenza-dashboard.component.html',
  styleUrls: ['./influenza-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class InfluenzaDashboardComponent implements OnInit, OnDestroy {
  userData: any = [];
  idofReservation: any;
  orderHistory: any = ['1'];
  chosenReservation: any;
  reserveNowClicked = false;
  statusDisplay: any;
  infContact: any;
  activeSeasons: any;
  cmsResponse: any;
  fluParagraph: any;
  endDate: any;
  todayDate: Date;
  displayActiveSeasons = true;
  startDate: any;
  orderHistorySubscriber: any;
  activeSeaonsSubscriber: any;
  languageUsed: string;
  editNotClicked = true;
  chosenLang = '';
  preferredLang: string;
  saveClicked = false;
  updateConatctSubscirber: any;
  emailPattern = '^[a-z0-9_.]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
  faxPattern = '^[0-9]{3}-[0-9]{3}-[0-9]{4}$';
  influenzaConatctSubscriber: any;
  @Output() newItemEvent = new EventEmitter<string>();
  faPenToSquare = faPenToSquare;

  editForm = new FormGroup({
    firstName: new FormControl('', [
      Validators.required,
      Validators.pattern("^[a-zA-Z0-9-' ]+('[a-zA-Z0-9-])?[-a-zA-Z0-9]*$"),
    ]),
    lastName: new FormControl('', [
      Validators.required,
      Validators.pattern("^[a-zA-Z0-9-' ]+('[a-zA-Z0-9-])?[-a-zA-Z0-9]*$"),
    ]),
    emailId: new FormControl('', [
      Validators.pattern('^[a-z0-9_.]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    ]),

    faxNo: new FormControl(
      '',
      Validators.pattern('^[0-9]{3}-[0-9]{3}-[0-9]{4}$')
    ),
    language: new FormControl(''),
    preferredContact: new FormControl(''),
  });
  constructor(
    private readonly cmsData: CmsService,
    private readonly influenzaService: CustomInfluenzaService,
    private readonly user: UserAccountFacade,
    private readonly renderer: Renderer2,
    private readonly ref: ChangeDetectorRef,
    private readonly router: RoutingService,
    private readonly sharedService: SharedService,
    private readonly languageService: LanguageService
  ) {}

  ngOnInit() {
    this.languageService.getActive().subscribe((langRes) => {
      this.languageUsed = langRes;
      this.ref.detectChanges();
    });
    this.renderer.listen('document', 'click', (event) => {
      if (event.target.classList.contains('close-banner')) {
        const displayInflunenzaBanner = document.getElementsByClassName(
          'FluParaMiddleSlottop'
        );
        for (let i = 0; i < displayInflunenzaBanner.length; i++) {
          displayInflunenzaBanner[i].className += ' displayNone';
        }
      }
    });

    this.user.get().subscribe((res: any) => {
      if (res) {
        this.userData = res;
        this.getInfContact();
      }
    });
    this.activeSeaonsSubscriber = this.influenzaService.activeSeaons.subscribe(
      (data) => {
        if (data) {
          this.activeSeasons = data.categoryData[0]?.code;
          this.influenzaService.getUserOrderHistory(
            this.userData.userName,
            this.activeSeasons
          );
          this.sharedService.setSessionStorage(
            'activeSeasons',
            this.activeSeasons
          );
          this.todayDate = new Date();
          this.endDate = data.categoryData[0]?.endDate;
          this.startDate = data.categoryData[0]?.startDate;
          if (
            this.todayDate > new Date(this.endDate) ||
            this.todayDate < new Date(this.startDate) ||
            !data.categoryData?.[0]
          ) {
            this.displayActiveSeasons = false;
            this.ref.detectChanges();
          }
        }
        this.activeSeaonsSubscriber?.unsubscribe();
      }
    );
    this.orderHistorySubscriber = this.influenzaService.ordersHistory.subscribe(
      (orderData) => {
        if (orderData) {
          this.orderHistory = orderData?.orders;

          this.chosenReservation = this.orderHistory?.[0]?.guid;
          this.idofReservation = this.chosenReservation;
          this.statusDisplay = this.orderHistory?.[0]?.statusDisplay;
          if (this.idofReservation) {
            this.influenzaService.getDetailedReservation(
              this.userData.userName,
              this.idofReservation,
              false
            );
          }
          this.ref.detectChanges();
        }
        this.orderHistorySubscriber?.unsubscribe();
      }
    );
    this.getFluParagraph();
    this.ref.detectChanges();
  }
  ngOnDestroy() {
    this.activeSeaonsSubscriber?.unsubscribe();
    this.orderHistorySubscriber?.unsubscribe();
    this.updateConatctSubscirber?.unsubscribe();
  }
  changeReservation(data) {
    data !== null
      ? (this.idofReservation = data.guid)
      : (this.idofReservation = this.chosenReservation);
    data !== null ? (this.statusDisplay = data.statusDisplay) : '';

    this.influenzaService.getDetailedReservation(
      this.userData.userName,
      this.idofReservation,
      false
    );
  }
  viewReservation() {
    this.influenzaService.getDetailedReservation(
      this.userData.userName,
      this.idofReservation,
      true
    );
  }
  proceedToCategory(event) {
    this.infContact = event;
  }
  reserveNow() {
    if (this.infContact) {
      this.router.goByUrl('/influenza/category');
    } else {
      this.reserveNowClicked = true;
    }
  }
  getFluParagraph() {
    this.cmsData.getComponentData('FluParagraphComponent').subscribe((res) => {
      this.cmsResponse = res;
    });
    if (this.cmsResponse && this.cmsResponse.content) {
      this.fluParagraph = this.cmsResponse.content;
    }
  }

  editContact() {
    this.editNotClicked = false;
    if (this.infContact) {
      this.editForm.patchValue({
        firstName: this.infContact[0].firstName,
        lastName: this.infContact[0].lastName,
        emailId: this.infContact[0].email,
        faxNo: this.infContact[0].fax,
        language:
          this.infContact[0].preferredLanguage === 'EN'
            ? this.setEnglish()
            : this.setFrench(),
        preferredContact: this.infContact[0].contactMethod,
      });
      this.chosenLang =
        this.infContact[0].preferredLanguage === 'EN'
          ? this.setEnglish()
          : this.setFrench();
    } else {
      this.chosenLang = this.setEnglish();
      this.editForm.controls['preferredContact'].setValue('INT');
    }

    this.formValidations();
  }

  onCancelClick() {
    this.editNotClicked = true;
    this.editForm.reset();
  }
  onSave() {
    this.saveClicked = true;
    const params = {
      email: this.editForm.controls.emailId.value
        ? this.editForm.controls.emailId.value
        : '',
      firstName: this.editForm.controls.firstName.value,
      lastName: this.editForm.controls.lastName.value
        ? this.editForm.controls.lastName.value
        : '',
      phoneNo: this.infContact ? this.infContact[0]?.phoneNo : '',
      contactMethod: this.editForm.controls.preferredContact.value,
      fax: this.editForm.controls.faxNo.value
        ? this.editForm.controls.faxNo.value
        : '',
      fluContactId: this.infContact ? this.infContact[0]?.fluContactId : '',
      preferredLanguage: this.chosenLang === this.setEnglish() ? 'EN' : 'FR',
    };
    this.formValidations();
    if (!this.editForm.invalid) {
      this.influenzaService.updateContact(params, this.userData.userName);
      this.updateConatctSubscirber =
        this.influenzaService.updatedInfContact.subscribe((result) => {
          if (result) {
            this.infContact = [];
            this.infContact[0] = result;
          }
          this.updateConatctSubscirber?.unsubscribe();
          this.ref.detectChanges();
        });
      this.editNotClicked = true;
    } else {
      this.editForm?.markAllAsTouched();
    }
  }

  formValidations() {
    if (this.editForm.controls.preferredContact.value === 'INT') {
      this.editForm.controls.emailId.setValidators([
        Validators.required,
        Validators.pattern(this.emailPattern),
      ]);
      this.editForm.controls.emailId.updateValueAndValidity();
      this.editForm.controls.faxNo.clearValidators();
      this.editForm.controls.faxNo.setValidators([
        Validators.pattern(this.faxPattern),
      ]);
      this.editForm.controls.faxNo.updateValueAndValidity();
    }
    if (this.editForm.controls.preferredContact.value === 'FAX') {
      this.editForm.controls.faxNo.setValidators([
        Validators.required,
        Validators.pattern(this.faxPattern),
      ]);
      this.editForm.controls.faxNo.updateValueAndValidity();
      this.editForm.controls.emailId.clearValidators();
      this.editForm.controls.emailId.setValidators([
        Validators.pattern(this.emailPattern),
      ]);
      this.editForm.controls.emailId.updateValueAndValidity();
    }
  }

  setEnglish() {
    if (this.languageUsed === 'en') {
      this.preferredLang = 'English';
    } else {
      this.preferredLang = 'Anglais';
    }
    return this.preferredLang;
  }
  setFrench() {
    if (this.languageUsed === 'en') {
      this.preferredLang = 'French';
    } else {
      this.preferredLang = 'Français';
    }
    return this.preferredLang;
  }

  onChange(event) {
    this.formValidations();
  }

  getInfContact() {
    this.influenzaConatctSubscriber =
      this.influenzaService.influenzaContact.subscribe((resp) => {
        if (resp) {
          this.infContact = resp?.defaultB2BUnit?.influenzaContactInfos;
          if (this.infContact) {
            this.newItemEvent.emit(this.infContact[0].firstName);
            this.ref.detectChanges();
          }
        }
        this.influenzaConatctSubscriber?.unsubscribe();
      });
  }
}
