const minimumQuantity = 1;
const maximumQuantity = 9999;
const influenzaMaxQuantity = 99999;
const base = 10;
const zero = 0;
const one = 1;
const two = 2;
let minQuantityforInfluenza = 0;
export class ProductUtils {
  static alterQuantity(operator, idData, quantityOfUnits): any {
    if (!isNaN(quantityOfUnits)) {
      const inputElement = document.getElementById(
        `prodQuantity${idData}`
      ) as HTMLInputElement;
      const numInputs = document.querySelectorAll('input[type=number]');
      numInputs.forEach(function (input) {
        input.addEventListener('change', function (e) {
          if ((e.target as HTMLInputElement).value === '') {
            (e.target as HTMLInputElement).value = '1';
          }
        });
      });
      const minusIcon = document.getElementById(
        `minusIcon${idData}`
      ) as HTMLElement;

      const plusIcon = document.getElementById(
        `plusIcon${idData}`
      ) as HTMLElement;
      const noOfDoses =
        document.getElementById(`noOfDoses${idData}`) ||
        ({} as HTMLInputElement);

      const unitMinus = document.getElementById(`unitMinus${idData}`);
      const unitMinusDisable = document.getElementById(
        `unitMinusDisable${idData}`
      ) as HTMLElement;

      const unitPlus = document.getElementById(`unitPlus${idData}`);
      const unitPlusDisable = document.getElementById(
        `unitPlusDisable${idData}`
      ) as HTMLElement;
      let orderValue = parseInt(inputElement.value, base);

      // inputElement value check
      const regex = new RegExp('^[0-9]{1,4}$', 'g');

      if (regex.test(inputElement.value) === true) {
        if (operator === one) {
          orderValue += 1;
        } else if (operator === two) {
          orderValue = orderValue - 1;
        } else {
          orderValue = parseInt(inputElement.value, base);
        }
        // icon chnages
        if (orderValue <= minimumQuantity) {
          minusIcon?.classList.add('disabled');
          unitMinus?.classList.add('disabled-img');
          unitMinusDisable?.classList.remove('disabled-img');
          plusIcon?.classList.remove('disabled');
          unitPlus?.classList.remove('disabled-img');
          unitPlusDisable?.classList.add('disabled-img');
          if (orderValue === zero) {
            orderValue = minimumQuantity;
          }
        } else if (orderValue >= maximumQuantity) {
          orderValue = maximumQuantity;
          minusIcon?.classList.remove('disabled');
          unitMinus?.classList.remove('disabled-img');
          unitMinusDisable?.classList.add('disabled-img');
          plusIcon?.classList.add('disabled');
          unitPlus?.classList.add('disabled-img');
          unitPlusDisable?.classList.remove('disabled-img');
        } else {
          minusIcon?.classList.remove('disabled');
          plusIcon?.classList.remove('disabled');
          unitPlus?.classList.remove('disabled-img');
          unitMinus?.classList.remove('disabled-img');
          unitPlusDisable?.classList.add('disabled-img');
          unitMinusDisable?.classList.add('disabled-img');
        }

        // noOfDoses Check
        if (inputElement.value !== '') {
          inputElement.value = orderValue.toString();
          noOfDoses.innerHTML = `${orderValue * quantityOfUnits}  Doses`;
        } else {
          noOfDoses.innerHTML = `${quantityOfUnits}  Doses`;
        }
      } else {
        if (orderValue > maximumQuantity) {
          orderValue = maximumQuantity;
          inputElement.value = maximumQuantity.toString();
          plusIcon?.classList.add('disabled');
          unitPlus?.classList.add('disabled-img');
          unitPlusDisable?.classList.remove('disabled-img');
          noOfDoses.innerHTML = `${orderValue * quantityOfUnits}  Doses`;
        } else {
          noOfDoses.innerHTML = `${quantityOfUnits}  Doses`;
          if (!orderValue) {
            minusIcon?.classList.add('disabled');
            unitMinus?.classList.add('disabled-img');
            unitMinusDisable?.classList.remove('disabled-img');
            plusIcon?.classList.remove('disabled');
            unitPlus?.classList.remove('disabled-img');
            unitPlusDisable?.classList.add('disabled-img');
          }
        }
      }
    } else {
      return false;
    }
  }
  // Custom-Prpduct Refinement Category Formatting
  static categoryFormatting(data): any {
    const value = [];
    if (data.id === one || data.id === one.toString()) {
      value.push({ name: 'All', value: data.url });
    }
    data.subcategories.forEach((obj) => {
      value.push({ name: obj.name, value: obj.url });
    });
    return value;
  }
  static alterQuantityforInfluenza(
    operator,
    idData,
    quantityOfUnits,
    page,
    cartFlag
  ): any {
    if (page === 'cart') {
      minQuantityforInfluenza = 1;
    } else {
      minQuantityforInfluenza = 0;
    }
    if (!isNaN(quantityOfUnits)) {
      const inputElement = document.getElementById(
        `prodQuantity${idData}`
      ) as HTMLInputElement;
      const numInputs = document.querySelectorAll('input[type=number]');
      numInputs.forEach(function (input) {
        input.addEventListener('change', function (e) {
          if ((e.target as HTMLInputElement).value === '') {
            (e.target as HTMLInputElement).value = '1';
          }
        });
      });
      const minusIcon = document.getElementById(
        `minusIcon${idData}`
      ) as HTMLElement;

      const plusIcon = document.getElementById(
        `plusIcon${idData}`
      ) as HTMLElement;
      const noOfDoses = document.getElementById(
        `noOfDoses${idData}`
      ) as HTMLInputElement;

      const unitMinus = document.getElementById(`unitMinus${idData}`);
      const unitMinusDisable = document.getElementById(
        `unitMinusDisable${idData}`
      ) as HTMLElement;

      const unitPlus = document.getElementById(`unitPlus${idData}`);
      const unitPlusDisable = document.getElementById(
        `unitPlusDisable${idData}`
      ) as HTMLElement;
      let orderValue = parseInt(inputElement?.value, base);

      // inputElement value check
      const regex = new RegExp('^[0-9]{1,5}$', 'g');

      if (regex.test(inputElement?.value) === true) {
        if (operator === one) {
          orderValue += 1;
        } else if (operator === two && orderValue !== 0) {
          orderValue = orderValue - 1;
        } else {
          orderValue = parseInt(inputElement?.value, base);
        }
        // icon chnages
        if (orderValue <= minQuantityforInfluenza) {
          minusIcon?.classList.add('disabled');
          unitMinus?.classList.add('disabled-img');
          unitMinusDisable?.classList.remove('disabled-img');
          plusIcon?.classList.remove('disabled');
          unitPlus?.classList.remove('disabled-img');
          unitPlusDisable?.classList.add('disabled-img');
          if (orderValue === -1) {
            orderValue = minQuantityforInfluenza;
          }
        } else if (orderValue >= influenzaMaxQuantity) {
          orderValue = influenzaMaxQuantity;
          minusIcon?.classList.remove('disabled');
          unitMinus?.classList.remove('disabled-img');
          unitMinusDisable?.classList.add('disabled-img');
          plusIcon?.classList.add('disabled');
          unitPlus?.classList.add('disabled-img');
          unitPlusDisable?.classList.remove('disabled-img');
        } else {
          minusIcon?.classList.remove('disabled');
          plusIcon?.classList.remove('disabled');
          unitPlus?.classList.remove('disabled-img');
          unitMinus?.classList.remove('disabled-img');
          unitPlusDisable?.classList.add('disabled-img');
          unitMinusDisable?.classList.add('disabled-img');
        }

        if (page === 'cart' && orderValue < 1) {
          orderValue = 1;
        }

        // noOfDoses Check
        if (inputElement.value !== '') {
          inputElement.value = orderValue?.toString();
          noOfDoses.innerHTML = `${orderValue * quantityOfUnits}  Doses`;
        } else {
          noOfDoses.innerHTML = `${quantityOfUnits}  Doses`;
        }
        if (orderValue === 0) {
          noOfDoses.innerHTML = '';
        }
      } else {
        if (orderValue > influenzaMaxQuantity) {
          orderValue = influenzaMaxQuantity;
          inputElement.value = influenzaMaxQuantity.toString();
          plusIcon?.classList.add('disabled');
          unitPlus?.classList.add('disabled-img');
          unitPlusDisable?.classList.remove('disabled-img');
          noOfDoses.innerHTML = `${orderValue * quantityOfUnits}  Doses`;
        } else {
          if (noOfDoses) {
            noOfDoses.innerHTML = `${quantityOfUnits}  Doses`;
          }

          if (!orderValue) {
            minusIcon?.classList.add('disabled');
            unitMinus?.classList.add('disabled-img');
            unitMinusDisable?.classList.remove('disabled-img');
            plusIcon?.classList.remove('disabled');
            unitPlus?.classList.remove('disabled-img');
            unitPlusDisable?.classList.add('disabled-img');
          }
        }
      }
    } else {
      return false;
    }
  }
}
