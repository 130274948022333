import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { translationChunksConfig } from '@spartacus/assets';
import {
  AuthGuard,
  CmsConfig,
  ConfigModule,
  I18nModule,
  provideConfig,
} from '@spartacus/core';
import { OrderCoreModule } from '@spartacus/order/core';
import { OrderOccModule } from '@spartacus/order/occ';
import { IconModule } from '@spartacus/storefront';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FluDashboardBannerModule } from '../shared/modules/flu-dashboard-banner/flu-dashboard-banner.module';
import { NeedToGoBackModule } from '../shared/modules/need-to-go-back/need-to-go-back.module';
import { ListOrdersComponent } from './list-orders/list-orders.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";

@NgModule({
  declarations: [ListOrdersComponent, OrderDetailsComponent],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    NgSelectModule,
    FormsModule,
    NgbModule,
    RouterModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    NeedToGoBackModule,
    FluDashboardBannerModule,
    OrderCoreModule,
    OrderOccModule,
    ConfigModule.withConfig({
      cmsComponents: {
        AccountOrderHistoryComponent: {
          component: ListOrdersComponent,
          guards: [AuthGuard],
        },
        AccountOrderDetailsOverviewComponent: {
          component: OrderDetailsComponent,
          guards: [AuthGuard],
        },
        OrderHistoryBannerComponent: {
          component: ListOrdersComponent,
        },
      },
    } as CmsConfig),
    FontAwesomeModule,
  ],
  providers: [
    provideConfig({
      i18n: {
        backend: {
          loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json',
        },
        chunks: translationChunksConfig,
      },
    }),
  ],
})
export class OrderHistoryModule {}
