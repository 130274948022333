<section class="no-print container mt-3 mb-1">
  <div class="row">
    <div class="col-md-6">
      <h3 class="font-bold">2022-2023 Season</h3>
    </div>

    <div
      class="col-md-6 text-right d-flex flex-wrap gap-1 justify-content-md-end"
    >
      <a
        id="continueShoppingId"
        data-di-id="#continueShoppingId"
        (click)="backToDashBoard()"
        class="c-link"
      >
        <fa-icon [icon]="faChevronLeft" class="icon"></fa-icon>
        {{ 'selectVaccines.back_to_db' | cxTranslate }}
      </a>
      <a
        id="tealium-xls"
        data-di-id="#tealium-xls"
        class="c-link"
        aria-label="download as"
        href="javascript:void(0)"
        target=""
        (click)="downloadExcel()"
      >
        <span>{{ 'addToCartItems.xls' | cxTranslate }}</span>
        <fa-icon [icon]="faFileExcel" class="icon"></fa-icon>
      </a>
      <a
        id="printLinkId"
        data-di-id="#printLinkId"
        class="c-link"
        href="javascript:void(0)"
        target=""
        onclick="window.print();"
      >
        <span>{{ 'addToCartItems.print' | cxTranslate }}</span>
        <fa-icon [icon]="faPrint" class="icon"></fa-icon>
      </a>
    </div>
  </div>
</section>
<app-flu-header [selectedHeader]="header"></app-flu-header>
<app-checkout
  [cartDetails]="cartDetails"
  [stdCartType]="stdCart"
  [activeSeasonId]="activeSeasonId"
></app-checkout>
