import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  AuthActions,
  OCC_USER_ID_CURRENT,
  StateWithClientAuth,
  UserIdService,
} from '@spartacus/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { VscaOAuthLibWrapperService } from '../../core/auth/user-auth/service/vsca-oauth-lib-wrapper.service';
import * as config from '../shared/config/app.config';
import { BaseService } from '../shared/services/base.service';

@Injectable({
  providedIn: 'root',
})
export class CustomRegistrationService {
  public provinceData = new BehaviorSubject<any>(false);
  provinceData$ = this.provinceData.asObservable();
  public practiceOrFacilityTypeData = new BehaviorSubject<any>(false);
  practiceOrFacilityTypeData$ = this.practiceOrFacilityTypeData.asObservable();
  public contactTitleData = new BehaviorSubject<any>(false);
  contactTitleData$ = this.contactTitleData.asObservable();
  public contactRoleData = new BehaviorSubject<any>(false);
  contactRoleData$ = this.contactRoleData.asObservable();
  public passwordRecoveryQuestionsData = new BehaviorSubject<any>(false);
  passwordRecoveryQuestionsData$ =
    this.passwordRecoveryQuestionsData.asObservable();
  public sectionChange = new Subject<{
    header: string;
    form?: FormGroup;
    forms?: FormGroup[];
    totalFormData?: any;
    msgData?: any;
  }>();

  authRedirectService: any;

  constructor(
    private readonly baseService: BaseService,
    private readonly oAuthLibWrapperService: VscaOAuthLibWrapperService,
    private readonly userIdService: UserIdService,
    private readonly store: Store<StateWithClientAuth>
  ) {}

  public getProvinces(params): void {
    this.baseService.get(config.GET_PROVINCES, params).subscribe((res) => {
      this.provinceData.next(res);
    });
  }

  public getPracticeOrFacilityType(params): void {
    this.baseService
      .get(config.GET_PRACTICEORFACILITYTYPE, params)
      .subscribe((res) => {
        this.practiceOrFacilityTypeData.next(res);
      });
  }

  public getContactTitle(params): void {
    this.baseService
      .get(config.GET_PRACTICEORFACILITYTYPE, params)
      .subscribe((res) => {
        this.contactTitleData.next(res);
      });
  }

  public getContactRole(params): void {
    this.baseService
      .get(config.GET_PRACTICEORFACILITYTYPE, params)
      .subscribe((res) => {
        this.contactRoleData.next(res);
      });
  }

  public getPasswordRecoveryQuestions(params): void {
    this.baseService
      .get(config.GET_PRACTICEORFACILITYTYPE, params)
      .subscribe((res) => {
        this.passwordRecoveryQuestionsData.next(res);
      });
  }

  public getAccountData(userId: string, accountId: string): Observable<any> {
    return this.baseService.get(
      `${config.USER_ACCOUNT}${userId}/b2bUnits/${accountId}`,
      { fields: 'FULL' }
    );
  }

  public postAccountRegistration(params?: any): Observable<any> {
    return this.baseService.post(
      config.POST_ACCOUNT_REGISTRATION_DETAILS,
      params
    );
  }

  public postValidateAccountInfo(params?: any): Observable<any> {
    return this.baseService.post(
      config.VALIDATE_ACCOUNT_INFORMATION_USER_REGISTRATION,
      params
    );
  }

  public postUserRegistrationInfo(params?: any): Observable<any> {
    return this.baseService.post(config.POST_USER_REGISTRATION_INFO, params);
  }

  loginWithCredentials(userId: string, password: string) {
    this.oAuthLibWrapperService
      .vscaAuthorizeWithPasswordFlowForbaseSiteId(userId, password, 'vsca')
      .then((res) => {
        this.userIdService.setUserId(OCC_USER_ID_CURRENT);

        this.store.dispatch(new AuthActions.Login());
        this.baseService.get(config.USER_NAME).subscribe((resp) => {
          if (resp !== null && resp !== undefined && resp?.length !== 0) {
            if (resp?.janrainId !== undefined) {
              localStorage.setItem('user_id', resp?.janrainId);
            } else {
              localStorage.setItem('user_id', 'NA');
            }
            if (localStorage.getItem('sso_enabled')) {
              localStorage.setItem('user_type', 'sales_rep');
            } else {
              localStorage.setItem('user_type', 'customer');
            }
            if (resp?.role !== 'Unknown') {
              localStorage.setItem('user_function', resp?.role);
            } else {
              localStorage.setItem('user_function', 'NA');
            }
            localStorage.setItem('user_login_id', resp?.userName);
            localStorage.setItem('user_account_id', resp?.orgUnit.uid);
          }
        });
      });

    // OCC specific user id handling. Customize when implementing different backend
  }
}
