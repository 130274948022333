import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RoutingService } from '@spartacus/core';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-email-popup',
  templateUrl: './email-popup.component.html',
  providers: [DatePipe],
})
export class EmailPopupComponent implements OnInit {
  @Input() public emailConsentObj;
  @Input() public userId;
  acceptBtnText: any;
  rejectBtnText: any;
  emailConsentText: any;
  url: any;
  emailConsent: boolean;
  constructor(
    public activeModal: NgbActiveModal,
    private datePipe: DatePipe,
    private readonly router: RoutingService,
    private readonly sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.acceptBtnText = this.emailConsentObj.emailConsentAcceptLabel;
    this.rejectBtnText = this.emailConsentObj.emailConsentRejectLabel;
    this.emailConsentText = this.emailConsentObj.emailConsentText;
    this.url = this.emailConsentObj.emailConsentNavigationUrl;
  }
  onNotNow() {
    this.activeModal.dismiss();
    this.emailConsent = false;
    this.sharedService.setEmailConsent(this.userId, this.emailConsent);
  }
  onRegister() {
    const url = 'https://' + this.url;
    window.open(url, '_blank');
    this.activeModal.close();
    this.emailConsent = true;
    this.sharedService.setEmailConsent(this.userId, this.emailConsent);
  }
}
