<section class="no-print container mt-3 mb-1">
  <div class="row">
    <div class="col-md-6">
      <h3 class="font-bold">
        {{ activeSeasonId }} {{ 'selectVaccines.season' | cxTranslate }}
      </h3>
    </div>
    <div
      *ngIf="fluProductsList?.length > 0"
      class="col-md-6 text-right d-flex flex-wrap gap-1 justify-content-md-end"
    >
      <a
        id="continueShoppingId"
        data-di-id="#continueShoppingId"
        (click)="backToCategory()"
        target=""
        class="c-link"
      >
        <fa-icon [icon]="faChevronLeft" class="icon"></fa-icon>
        {{ 'addToCartItems.continueShopping' | cxTranslate }}
      </a>
      <ng-template #popTitle
        ><span class="text-fixed-legal-copy">
          {{ 'addToCartItems.clearTitle' | cxTranslate }}</span
        >
      </ng-template>
      <ng-template #popClearCartContent>
        <div class="c-popover">
          <button
            type="reset"
            class="popover-close popover-cancel-button c-button c-button--secondary"
            data-di-id="di-id-d01008be-fb6e135c"
            (click)="toggle(p1)"
          >
            {{ 'addToCartItems.cancel' | cxTranslate }}
          </button>
          <button
            type="submit"
            class="popover-cancel-button c-button c-button--primary"
            data-di-id="di-id-3064f17d-e97cc628"
            (click)="deleteEntireCart(p1)"
          >
            {{ 'addToCartItems.clearCart' | cxTranslate }}
          </button>
        </div>
      </ng-template>
      <a
        class="c-link"
        [ngbPopover]="popClearCartContent"
        [popoverTitle]="popTitle"
        popoverClass="popover-links"
        triggers="manual"
        placement="top"
        container="body"
        #p1="ngbPopover"
        href="javascript:;"
        (click)="toggle(p1)"
        popoverClass="popover-links"
      >
        <span>{{ 'addToCartItems.clearCart' | cxTranslate }}</span>
        <fa-icon [icon]="faTrashCan" class="icon"></fa-icon>
      </a>
      <a
        id="printLinkId"
        data-di-id="#printLinkId"
        class="c-link"
        href="javascript:void(0)"
        target=""
        onclick="window.print();"
      >
        <span>{{ 'addToCartItems.print' | cxTranslate }}</span>
        <fa-icon [icon]="faPrint" class="icon"></fa-icon>
      </a>
    </div>
  </div>
</section>
<div *ngIf="deleteSuccess" class="global-alerts">
  <div class="c-alert-banner alert-info alert-dismissable getAccAlert">
    <button
      (click)="closeAlert()"
      class="close closeDelAlert"
      aria-hidden="true"
      data-dismiss="alert"
      type="button"
    >
      ×
    </button>
    <span [innerHTML]="deleteSuccess" class="delete-Sucess-Prod-Name"></span>
    {{ 'selectVaccines.deleteSuccessMsg' | cxTranslate }}
  </div>
</div>

<div
  *ngIf="blockCheckout && showDangerAlert && fluProductsList?.length > 0"
  class="global-alerts"
>
  <div class="alert alert-danger alert-dismissable getAccAlert">
    <button
      (click)="closeDangerAlert()"
      class="close closeAccAlert"
      aria-hidden="true"
      data-dismiss="alert"
      type="button"
      data-di-id="di-id-4f6d7b93-70563360"
    >
      ×
    </button>
    {{ 'selectVaccines.orderBlockMsg' | cxTranslate }}
  </div>
</div>

<app-flu-header [selectedHeader]="header"></app-flu-header>

<section class="container c-prod-details mb-0 mb-sm-3" *ngIf="!showEmptyCart">
  <section class="row">
    <section
      class="col-md-8 float-left u-background--white pb-4 pt-4 pt-md-4 pb-md-4 u-border-top-grey mt-4"
      *ngIf="!showEmptyCart"
    >
      <section
        class="col-md-12 Fields-container delivary_ord u-border-bottom-grey"
      >
        <section class="row shipping-Bill-Main-Section">
          <!-- Shipping -->
          <section class="col-md-7 mb-4 mb-md-0">
            <h3 class="mb-3 font-bold">
              {{ 'orderConfirmation.shiptoLoc' | cxTranslate }}
            </h3>
            <div>
              <i class="fa fa-map-marker-alt font-n"></i
              ><span class="px-3 text-fixed-medium"
                >{{ selectedData?.companyName }}
                {{ '(' + selectedData?.unitID + ')' }}</span
              >
            </div>
            <div class="pl-4">
              <p class="mb-2 text-fixed-medium">
                {{ selectedData?.line1 ? selectedData?.line1 + ', ' : ''
                }}<br *ngIf="selectedData?.line1" />
                {{ selectedData?.line2 ? selectedData?.line2 + ', ' : ''
                }}<br *ngIf="selectedData?.line2" />
                {{ selectedData?.town ? selectedData?.town + ', ' : ''
                }}<br *ngIf="selectedData?.town" />
                {{
                  selectedData?.region?.isocodeShort
                    ? selectedData?.region?.isocodeShort + ' '
                    : ''
                }}
                {{ selectedData?.postalCode }}
              </p>
            </div>
            <div class="pl-3">
              <span
                role="button"
                *ngIf="changeLocation"
                (click)="open(content)"
                class="c-link ml-1"
                >Change Location</span
              >
            </div>
            <div class="firstLevel-acc cartShipToHeader">
              <section class="col-sm-7 col-md-7 col-lg-7 locleft">
                <section class="ship-address">
                  <section>
                    <section class="checkout-modal">
                      <section class="modal-dialog">
                        <section class="modal-content">
                          <ng-template #content let-modal>
                            <section class="checkout-modal modal-selector">
                              <section class="modal-dialog">
                                <section class="modal-content">
                                  <div class="close">
                                    <i
                                      class="fa-solid fa-x c-icon-primary c-icon-primary-sm c-icon-round-hover mr-3 mt-4"
                                      (click)="modal.dismiss('Cross click')"
                                    ></i>
                                  </div>
                                  <section
                                    class="modal-header cart-Modal-Header"
                                  >
                                    <h2
                                      class="c-heading-36 font-bold u-border-bottom-grey pb-4 w-100"
                                      id="modal-basic-title"
                                    >
                                      {{
                                        'account.selectLocation' | cxTranslate
                                      }}
                                    </h2>
                                  </section>
                                  <section class="modal-body">
                                    <section class="input-group loc-SearchBar">
                                      <div class="c-text-input">
                                        <cx-icon
                                          class="cx-icon fas fa-search"
                                        ></cx-icon>
                                        <input
                                          type="text"
                                          #clientName
                                          (keyup)="
                                            SearchClientName(clientName.value)
                                          "
                                          class="form-control searchboxnew"
                                          placeholder="{{
                                            'account.filterByLocation'
                                              | cxTranslate
                                          }}	"
                                        />
                                      </div>
                                    </section>
                                    <section>
                                      <section
                                        [ngClass]="{
                                          displayNone:
                                            searchedClientList?.length === 0
                                        }"
                                        class="custom-radio radio_grp label-location w-100 mt-4"
                                      >
                                        <table
                                          aria-describedby="shiptolocation"
                                          *ngFor="
                                            let data of searchedClientList;
                                            let i = index
                                          "
                                          class="w-100 mt-2"
                                        >
                                          <tr
                                            class="line-location"
                                            [ngClass]="{
                                              'label-location':
                                                i !==
                                                searchedClientList.length - 1
                                            }"
                                          >
                                            <td class="u-border-top-grey pt-1">
                                              <input
                                                type="radio"
                                                (change)="
                                                  onOptionsSelected(data)
                                                "
                                                [id]="data?.id"
                                                [checked]="
                                                  selectedData?.id == data?.id
                                                "
                                                name="customRadio"
                                                class="custom-control-input"
                                              />
                                              <label
                                                class="custom-control-label secloct w-100 text-fixed-medium font-bold"
                                                [for]="data?.id"
                                              >
                                                {{ data?.companyName }}
                                                {{ '(' + data?.unitID + ')' }}
                                              </label>
                                              <p
                                                class="labelpp mb-2 text-fixed-medium"
                                              >
                                                {{
                                                  data?.line1
                                                    ? data?.line1 + ', '
                                                    : ''
                                                }}
                                                {{
                                                  data?.line2
                                                    ? data?.line2 + ', '
                                                    : ''
                                                }}
                                                {{
                                                  data?.town
                                                    ? data?.town + ', '
                                                    : ''
                                                }}
                                                {{
                                                  data?.region?.isocodeShort
                                                    ? data?.region
                                                        ?.isocodeShort + ' '
                                                    : ''
                                                }}
                                                {{ data?.postalCode }}
                                              </p>
                                            </td>
                                          </tr>
                                        </table>
                                      </section>
                                    </section>
                                  </section>
                                  <section
                                    class="modal-footer justify-content-start"
                                  >
                                    <button
                                      type="button"
                                      class="c-button c-button--primary ml-3"
                                      (click)="onSubmit()"
                                      (click)="modal.close('Save click')"
                                    >
                                      {{ 'account.done' | cxTranslate }}
                                    </button>
                                  </section>
                                </section>
                              </section>
                            </section>
                          </ng-template>
                        </section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </div>
          </section>
          <!-- Date Picker -->
          <section class="col-md-5" *ngIf="showDatePicker">
            <section class="delivery-content multipleLocDelivery">
              <section class="delivary-details cart-delivery-date">
                <h6 class="text-fixed-legal-copy mb-2">
                  {{ 'orderConfirmation.reqDeliveryDate' | cxTranslate }}
                </h6>
                <section class="control-group">
                  <section
                    class="controls input-append date cart-delivery-date-picker"
                    data-date=""
                    data-date-format="mm/dd/yyyy"
                    data-link-field="dtp_input2"
                    data-link-format="mm/dd/yyyy"
                  >
                    <span class="add-on"><i class="icon-th"></i></span>

                    <input
                      type="text"
                      placeholder="DD/MM/YYYY"
                      class="delivery-pickup-calendar"
                      bsDatepicker
                      [(ngModel)]="deliveryDate"
                      [daysDisabled]="[0, 6]"
                      [minDate]="minDeliveryDate"
                      [maxDate]="maxDeliveryDate"
                      (ngModelChange)="onDeliveryDateChange($event)"
                      [ngClass]="{ required: dateIsRequired }"
                      [bsConfig]="{
                        containerClass: 'theme-green deliveryPickup'
                      }"
                    />
                  </section>

                  <p class="text-fixed-legal-copy mt-2">
                    {{ 'orderConfirmation.deliveryDisclaimer' | cxTranslate }}
                  </p>
                </section>
              </section>
            </section>
          </section>
        </section>

        <!-- Products -->
        <section
          class="pdp-list-data row pb-2"
          [ngClass]="{ 'margin-top-cart-row': i !== 0 }"
          *ngFor="let user of fluProductsList; let i = index"
          id="{{ user.code }}"
        >
          <!-- Image -->
          <section class="col-md-4 col-lg-2">
            <a (click)="productDetails(user)">
              <img
                *ngIf="user?.thumbNailImg !== ''"
                src="{{ baseUrl }}{{ user.thumbNailImg }}"
                class="prod-Image"
                alt="prodImg"
              />
              <img
                *ngIf="user?.thumbNailImg == ''"
                [src]="fallbackImage"
                class="prod-Image"
                alt="defImg"
              />
            </a>
            <section class="container">
              <section class="row">
                <section
                  class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 delete-popover-container delete-Div"
                >
                  <i class="fas fa-trash-alt pointer"></i> &nbsp;
                  <span
                    [ngbPopover]="popContentDelete"
                    [popoverTitle]="popTitleDelete"
                    triggers="manual"
                    #p2="ngbPopover"
                    placement="top"
                    class="trash cart-edit-delete"
                    (click)="toggle(p2)"
                    class="delete-Prod"
                    >{{ 'orderConfirmation.delete' | cxTranslate }}</span
                  >

                  <ng-template #popTitleDelete>
                    {{
                      'addToCartItems.deleteTitle' | cxTranslate
                    }}</ng-template
                  >
                  <ng-template #popContentDelete>
                    <button
                      type="reset"
                      class="popover-close btn white-button popover-cancel-button"
                      data-di-id="di-id-d01008be-fb6e135c"
                      (click)="toggle(p2)"
                    >
                      {{ 'addToCartItems.cancel' | cxTranslate }}
                    </button>
                    <button
                      class="btn blue-button popover-cancel-button"
                      type="submit"
                      data-di-id="di-id-3064f17d-e97cc628"
                      (click)="deleteIndividualCartItem(user, p2)"
                    >
                      {{ 'addToCartItems.delete' | cxTranslate }}
                    </button>
                  </ng-template>
                </section>
              </section>
            </section>
          </section>
          <!-- Product Detail -->
          <section class="col-md-8 col-lg-5">
            <a (click)="productDetails(user)">
              <h2
                class="c-heading c-heading-h4 mb-4"
                [innerHTML]="user.prodName"
              ></h2>
            </a>
            <p class="text-fixed-medium color-grey" [innerHTML]="user.desc"></p>
            <p class="text-fixed-legal-copy color-grey-dark mb-1">
              <span class="font-bold">
                {{ 'selectVaccines.product_number' | cxTranslate }}</span
              >: &nbsp;{{ user.code }}
            </p>
          </section>
          <!-- Price -->
          <section class="col-xs-6 col-md-6 col-lg-3 price-list">
            <section class="col-md-12 col-lg-12 col-xl-12">
              <h6
                class="text-fixed-legal-copy color-grey-dark font-weight-bold"
              >
                {{ 'selectVaccines.yourPrice' | cxTranslate }}
              </h6>
              <span class="c-heading c-heading-h3">
                {{ user?.price | currency }}
                {{ 'plp.cad' | cxTranslate }}
              </span>
            </section>

            <section
              class="col-md-12 col-lg-12 col-xl-12 vol-Pricing"
              *ngFor="let volPrice of volumePrices"
            >
              <a
                class="vol-link"
                placement="top"
                *ngIf="
                  user?.code === volPrice?.code &&
                  volPrice?.volumePrices?.length > 0
                "
                [ngbPopover]="popPricingContent"
                popoverTitle="Volume Pricing"
              >
                {{ 'selectVaccines.volumePrice' | cxTranslate }}
              </a>
              <ng-template #popPricingContent>
                <table
                  class="tb-vol"
                  id="vol-price-pdp"
                  aria-describedby="volTable"
                >
                  <thead>
                    <th scope="col"></th>
                  </thead>
                  <tbody
                    *ngFor="let prices of volPrice?.volumePrices; let i = index"
                  >
                    <tr>
                      <td>
                        {{ prices?.minQuantity }} -
                        {{ prices?.maxQuantity }}
                        {{ 'selectVaccines.units' | cxTranslate }}:
                      </td>
                      <td>{{ prices?.value | currency }}</td>
                      <td>
                        <span *ngIf="i > 0" class="save-price"
                          >{{ 'selectVaccines.save' | cxTranslate }}
                          {{
                            volPrice?.volumePrices[0].value -
                              volPrice?.volumePrices[i].value | currency
                          }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-template>
            </section>
          </section>
          <!-- Units -->
          <section class="col-xs-6 col-md-6 col-lg-2 unit-list">
            <div class="mb-2 text-center font-weight-bold color-grey-dark">
              {{ 'plp.units' | cxTranslate }}
            </div>
            <section class="container pad-0">
              <!-- <section class="row"> -->
              <section class="prodUnits">
                <div
                  class="unit-area d-flex flex-nowrap align-items-center justify-content-center"
                >
                  <span
                    id="{{ 'unitMinus' + user.code }}"
                    class="d-none"
                  ></span>
                  <span
                    id="{{ 'unitMinusDisable' + user.code }}"
                    class="d-none"
                  ></span>
                  <span id="{{ 'unitPlus' + user.code }}" class="d-none"></span>
                  <span
                    id="{{ 'unitPlusDisable' + user.code }}"
                    class="d-none"
                  ></span>
                  <button
                    id="{{ 'minusIcon' + user.code }}"
                    class="c-count-change-btn"
                    [ngClass]="{ disabled: productentry?.quantity < 2 }"
                    aria-hidden="true"
                    (click)="
                      performQuantityComputation(
                        user,
                        2,
                        user?.code,
                        user?.dosesPerUnit
                      )
                    "
                  >
                    <i class="fa fa-minus" aria-hidden="true"></i>
                  </button>
                  <input
                    type="number"
                    class="prod-quantity text-center"
                    value="{{ user.totalQuantity }}"
                    onkeypress="return /[0-9a-zA-Z]/i.test(event.key)"
                    (keyup)="
                      performQuantityComputation(
                        user,
                        null,
                        user?.code,
                        user?.dosesPerUnit
                      )
                    "
                    id="{{ 'prodQuantity' + user?.code }}"
                    min="1"
                    max="99999"
                  />
                  <button
                    id="{{ 'plusIcon' + user.code }}"
                    class="c-count-change-btn"
                    [ngClass]="{ disabled: productentry?.quantity > 998 }"
                    aria-hidden="true"
                    (click)="
                      performQuantityComputation(
                        user,
                        1,
                        user?.code,
                        user?.dosesPerUnit
                      )
                    "
                  >
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </button>
                </div>
                <section
                  class="mt-2 text-center"
                  id="{{ 'noOfDoses' + user?.code }}"
                >
                  {{ user?.totalDoses }}
                  {{ 'selectVaccines.doses' | cxTranslate }}
                </section>

                <section
                  class="col-md-12 col-lg-12 col-xl-12 p-0"
                  [ngClass]="{
                    'frenchAvailable-Now': languageUsed === 'fr',
                    'availableNow-Div': languageUsed != 'fr'
                  }"
                >
                  <span class="available-now-text" *ngIf="user.onsn === 'ON'">
                    {{ 'selectVaccines.available_now' | cxTranslate }}
                  </span>
                  <span
                    class="not-available-text"
                    *ngIf="user.availableFlag === false && user.onsn !== 'ON'"
                  >
                    Product is not available
                  </span>
                  <span
                    class="not-available-text"
                    *ngIf="user.discontinuedFlag === true && user.onsn !== 'ON'"
                  >
                    Product is discontinued
                  </span>
                </section>
              </section>
              <!-- </section> -->
            </section>
          </section>
          <section
            class="col-md-12 col-lg-12 col-xl-12"
            *ngIf="fluProductsList.length > 1"
          >
            <hr />
          </section>
        </section>
      </section>
    </section>

    <!-- Right -->
    <section class="col-md-4 col-sm-12 cart-main-page">
      <!-- =====================estimation======================= -->
      <section class="container est-Main-Container normalDeviceConatiner">
        <section class="row">
          <section
            class="col-md-12 col-lg-12 col-xl-12 estHeader-ipad cart-Proceed-Section"
          >
            <h3
              class="c-widget-title c-heading c-heading-h3 text-center"
              *ngIf=""
            >
              {{ 'orderConfirmation.orderSubTotal' | cxTranslate }}:
              {{ estimatedSubtotal | currency }}CAD
            </h3>
            <h3
              class="c-widget-title c-heading c-heading-h3 text-center"
              *ngIf="btnText != 'Update Order' && !showEmptyCart"
            >
              {{ 'orderConfirmation.orderSubTotal' | cxTranslate }}:
              {{ estimatedSubtotal | currency }}CAD
            </h3>
            <h3
              class="c-widget-title c-heading c-heading-h3 text-center"
              *ngIf="btnText === 'Update Order' && !showEmptyCart"
            >
              Order Subtotal: $- CAD
            </h3>
          </section>
          <section class="col-md-12 col-lg-12 col-xl-12">
            <button
              class="c-button c-button--primary mt-3 proceedCheckout no-print w-100"
              (click)="navigateToCheckout()"
              *ngIf="btnText != 'Update Order'"
              [ngClass]="{ 'block-Click': disableButton }"
              [disabled]="fluProductsList.length == 0 || blockCheckout"
            >
              <i class="fa fa-spinner fa-spin" *ngIf="disableButton"></i>
              {{ 'selectVaccines.proceedToCheckout' | cxTranslate }}
            </button>
            <button
              class="c-button c-button--secondary mt-3 proceedCheckout no-print w-100"
              *ngIf="btnText === 'Update Order'"
              (click)="navigateToCheckout()"
              [ngClass]="{ 'block-Click': disableButton }"
              [disabled]="fluProductsList.length == 0 || blockCheckout"
            >
              <i class="fa fa-spinner fa-spin" *ngIf="disableButton"></i>
              {{ 'selectVaccines.updateOrder' | cxTranslate }}
            </button>
          </section>
        </section>
      </section>
      <section class="banner-Sect text-center my-4">
        <app-flu-dashboard-banner
          [bannerComponent]="compName"
        ></app-flu-dashboard-banner>
      </section>
    </section>
  </section>
</section>

<!-- Empty cart -->
<section class="container my-4" *ngIf="showEmptyCart">
  <div class="row">
    <div class="col-md-8 u-border-right-grey pr-md-4 pr-lg-5">
      <section class="edit-saved-cart u-border-top-grey py-4">
        <h3 class="font-bold u-border-bottom-grey pb-4">
          {{ 'orderConfirmation.emptyCart' | cxTranslate }}
        </h3>
        <div class="darkblue font-14 empty-cart-options">
          <div>
            <a
              class="c-link my-2"
              (click)="navigateToProducts()"
              title="Navigate to Products"
              >{{ 'orderConfirmation.orderProds' | cxTranslate }}</a
            >
          </div>
          <div>
            <a
              class="c-link my-2"
              (click)="backToDashBoard()"
              title="Back to dashboard"
              >{{ 'orderConfirmation.reserveVaccines' | cxTranslate }}</a
            >
          </div>
        </div>
      </section>
    </div>
    <div class="col-md-4 pl-md-4 pl-lg-5">
      <app-flu-dashboard-banner
        [bannerComponent]="compName"
      ></app-flu-dashboard-banner>
    </div>
  </div>
</section>
